import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  details,
  FetchCancelledEarningDetails,
  FetchEarningDetails,
} from "./PaymentSlice";
import BackButton from "./../../component/BackButton";
import DatePicker from "react-multi-date-picker";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { areaCodeListingSelector, FetchAreaCodeList } from "../areacode/slice";
import { SchoolListReducer } from "../slice";
import { CSVLink, CSVDownload } from "react-csv";
import Loader from "../../component/Loader";
function CancelledEarning() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state?.payment?.detail);
  const [loading, setLoading] = useState(false);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [schoolId, setSchoolId] = useState("");
  const [areacode, setAreacode] = useState("");
  const Schoollist = useSelector((state) => state.main.schools);
  const arealist = useSelector(areaCodeListingSelector);
  const [earnings, setEarnings] = useState({
    grossEarning: 0,
    netEarning: 0,
    adminEarning: 0,
  });
  const adminPercent = 10;
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(FetchAreaCodeList({ page: 1, limit: 100000000 }));
  }, [dispatch]);

  useEffect(() => {
    if (areacode) {
      dispatch(
        SchoolListReducer({
          page: 1,
          limit: 100000000,
          search: "",
          areacode,
        })
      );
    }
  }, [dispatch, areacode]);
  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(
        FetchCancelledEarningDetails({
          teacherId: id,
          page: page,
          limit: limit,
          areacode,
          schoolId,
        })
      ).then((res) => {
        if (res?.payload?.code === 1) {
          // setShow(!show);
          setLoading(false);

          let adminCut = res?.payload?.data?.admin_percentage;
          let arr = res?.payload?.data?.earning_details;
          let a = 0;
          let n = 0;
          let g = 0;
          arr?.forEach((item) => {
            let x =
              Number(item?.amount) -
              (Number(item?.amount) * Number(adminCut)) / 100;
            a += (Number(item?.amount) * Number(adminCut)) / 100;
            g += Number(item?.amount);

            n += x;
          });
          setEarnings({
            grossEarning: parseFloat(g).toFixed(2),
            netEarning: parseFloat(n).toFixed(2),
            adminEarning: parseFloat(a).toFixed(2),
          });
        }
      });
    }
  }, [dispatch, id, areacode, schoolId, page, limit]);

  useEffect(() => {
    return () => {
      dispatch(details(null));
    };
  }, [dispatch]);

  const handleSubmit = () => {
    if (id) {
      dispatch(
        FetchEarningDetails({
          page: 1,
          limit: 10000,
          teacherId: id,
          start_date: moment(start_date).format("YYYY-MM-DD"),
          end_date: moment(end_date).format("YYYY-MM-DD"),
        })
      ).then((res) => {
        if (res?.payload?.code === 1) {
          setShow(!show);
          let adminCut = res?.payload?.data?.admin_percentage;
          let arr = res?.payload?.data?.earning_details;
          let a = 0;
          let n = 0;
          let g = 0;
          arr?.forEach((item) => {
            let x =
              Number(item?.amount) -
              (Number(item?.amount) * Number(adminCut)) / 100;
            a += (Number(item?.amount) * Number(adminCut)) / 100;
            g += Number(item?.amount);

            n += x;
          });
          setEarnings({
            grossEarning: parseFloat(g).toFixed(2),
            netEarning: parseFloat(n).toFixed(2),
            adminEarning: parseFloat(a).toFixed(2),
          });
        }
      });
    }
  };

  const getCsvData = () => {
    let arr = [...data.student_list];
    let datas = arr.map((student) => {
      return {
        id: student?.id,
        bookingId: student?.bookingId,
        date: student?.date,
        first_name: student?.first_name,
        last_name: student?.last_name,
        medical_note: student?.medical_note,
        address: student?.address,
        discipline: student?.discipline,
        image: student?.image,
        emergency_contactNo: student?.emergency_contactNo,
        emergency_countryCode: student?.emergency_countryCode,
        dob: student?.dob ? moment(student?.dob).format("DD MMM YYYY") : "",
        student_type: student?.student_type,
        areacode_details: student?.areacode_details?.name,
        school_details: student?.school_details?.name,
        gross_amount: student?.gross_amount,
        admin_earning: student?.admin_earning,
        net_amount: student?.net_amount,
        "payment date": student?.createdAt
          ? moment(student?.createdAt).format("DD MMM YYYY")
          : "",
      };
    });
    return datas;
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <BackButton />

          <div className="col-sm-12 col-md-6 col-lg-7 col-xl-7 mb-3 mt-2">
            {/* <ul
              className="nav nav-pills group-nav mb-0 mt-0 payment-refund"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <Link to="/earnings-by-staff" className="nav-link active">
                  earnings by teacher
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link to="/earnings-by-membership" className="nav-link">
                  earnings by membership
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link to="/refund" className="nav-link">
                  refund
                </Link>
              </li>
            </ul> */}
            <h3>Teacher Earning Details</h3>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-5 col-xl-5 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search earning by teacher name"
                />
              </form>
              <button
                className="btn filter"
                onClick={() => {
                  setShow(!show);
                }}
              >
                <img src="assets/images/filter.png" alt="" />
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-9 col-md-9 col-lg-10">
            <ul className="nav nav-pills group-nav mb-3 mt-0">
              <li className="nav-item">
                <button
                  className="nav-link"
                  type="button"
                  onClick={() => {
                    navigate(`/earnings-by-staff/${id}`);
                  }}
                >
                  Active
                </button>
              </li>
              <li className="nav-item ">
                <button className="nav-link active" type="button">
                  Cancelled
                </button>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div className="dropSchool">
              <select
                className="form-control form-select"
                onChange={(e) => {
                  if (e.target.value === "") {
                    setSchoolId("");
                  }
                  setAreacode(e.target.value);
                }}
              >
                <option value="">Select Area</option>
                {arealist?.map((item, i) => (
                  <option value={item?.id} key={i}>
                    {item?.areacode}
                  </option>
                ))}
              </select>
              {areacode && (
                <select
                  className="form-control form-select"
                  onChange={(e) => {
                    setSchoolId(e.target.value);
                  }}
                >
                  <option value="">Select Venue</option>
                  {Schoollist?.map((item, i) => (
                    <option value={item?.id} key={i}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-end">
            {data && (
              <CSVLink
                filename={"earnings.csv"}
                data={data?.student_list?.length ? getCsvData() : []}
              >
                <button type="button" className="btn btn-save p-2 fs-6">
                  Download CSV
                </button>
              </CSVLink>
            )}
          </div>
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">Student name</th>
                    <th scope="col">Booked For</th>
                    <th scope="col">Venue </th>
                    <th scope="col">Order Id</th>
                    <th scope="col">Class date</th>
                    <th scope="col">Gross Amount</th>
                    <th scope="col">Admin's earning</th>
                    <th scope="col">Net Amount</th>
                    {/* <th scope="col">action</th> */}
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={8}>
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {data?.length > 0 ? (
                        data?.map((item, i) => {
                          const netAmount = (item?.amount * adminPercent) / 100;
                          return (
                            <tr key={i}>
                              <td>
                                {item?.student_details?.first_name}{" "}
                                {item?.student_details?.last_name}
                              </td>
                              <td>
                                {item?.class_details
                                  ? `${item?.class_details?.name}`
                                  : item?.event_details
                                  ? `${item?.event_details?.name}`
                                  : "NA"}
                              </td>
                              <td>
                                {item?.class_details
                                  ? `${item?.class_details?.school_details?.name}, ${item?.class_details?.school_details?.address}`
                                  : item?.event_details
                                  ? `${item?.event_details?.school_details?.name}, ${item?.event_details?.school_details?.address}`
                                  : "NA"}
                              </td>
                              <td>{item?.id}</td>
                              <td>
                                {item?.date
                                  ? moment(item?.date).format("DD MMM YYYY")
                                  : "NA"}
                              </td>
                              <td>
                                <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                                {parseFloat(Number(item?.amount)) || 0}
                              </td>
                              <td>
                                <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                                {netAmount || 0}
                              </td>
                              <td>
                                <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                                {item?.amount - netAmount || 0}
                              </td>

                              {/* <td>
                      <div className="d-flex justify-content-center action">
                        <button className="btn">
                          <Link to="/earnings-by-staff/1">
                            <img src="assets/images/view.png" alt="" />
                          </Link>
                        </button>
                        <button
                          className="btn"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteModal"
                        >
                          <img src="assets/images/delete.png" alt="" />
                        </button>
                        <button className="btn">
                          <input
                            type="checkbox"
                            className="toggle"
                            checked=""
                          />
                        </button>
                      </div>
                    </td> */}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colspan={8}>No data found.</td>
                        </tr>
                      )}
                      {data?.length > 0 && (
                        <tr>
                          <td colSpan={5}></td>
                          <td>
                            <b>Total:</b> {earnings?.grossEarning || 0}
                          </td>
                          <td>
                            <b>Total:</b> {earnings?.adminEarning || 0}
                          </td>
                          <td>
                            <b>Total:</b> {earnings?.netEarning || 0}
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {data?.length > 0 && (
              <div className="text-center">
                <span
                  className={
                    limit === 10
                      ? "manualPagination active"
                      : "manualPagination"
                  }
                  onClick={() => {
                    setLimit(10);
                    setPage("1");
                  }}
                >
                  10
                </span>
                <span
                  className={
                    limit === 25
                      ? "manualPagination active"
                      : "manualPagination"
                  }
                  onClick={() => {
                    setLimit(25);
                    setPage("1");
                  }}
                >
                  25
                </span>
                <span
                  className={
                    limit === 100
                      ? "manualPagination active"
                      : "manualPagination"
                  }
                  onClick={() => {
                    setLimit(100);
                    setPage("1");
                  }}
                >
                  100
                </span>
                <span
                  className={
                    limit === ""
                      ? "manualPagination active"
                      : "manualPagination"
                  }
                  onClick={() => {
                    setLimit("");
                    // setPage("1");
                  }}
                >
                  All
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          setShow(!show);
        }}
        centered
        size="md"
      >
        <div class="modal-content body-bg p-3">
          <div class="modal-header border-0 justify-content-center">
            <h6>Filter</h6>
            <button
              type="button"
              class="btn-close"
              onClick={() => {
                setShow(!show);
              }}
            ></button>
          </div>
          <div class="modal-body mb-4">
            <form class="form-style" onSubmit={handleSubmit}>
              {/* <div class="form-group">
                  <h5>By Teacher</h5>
                  <select class="form-control form-select">
                    <option>Select Teacher Name</option>
                  </select>
                </div> */}
              <div class="form-group">
                <h5>Start Date</h5>
                <div class="input-container">
                  {/* <input
                      type="text"
                      class="form-control"
                      placeholder="Date"
                    /> */}
                  <DatePicker
                    value={start_date}
                    onChange={(val) => {
                      setStart_date(val.toDate());
                    }}
                    containerClassName="form-control"
                  />
                  <img
                    src="assets/images/choose-date.png"
                    class="field-icon"
                    alt=""
                  />
                </div>
              </div>
              <div class="form-group">
                <h5>End Date</h5>
                <div class="input-container">
                  {/* <input
                      type="text"
                      class="form-control"
                      placeholder="Date"
                    /> */}
                  <DatePicker
                    value={end_date}
                    onChange={(val) => {
                      setEnd_date(val.toDate());
                    }}
                    containerClassName="form-control"
                    minDate={start_date || new Date()}
                  />
                  <img
                    src="assets/images/choose-date.png"
                    class="field-icon"
                    alt=""
                  />
                </div>
              </div>
              <div class="text-center">
                <span
                  onClick={() => {
                    setStart_date("");
                    setEnd_date("");
                  }}
                  className="text-dark cursor-pointer"
                >
                  Reset
                </span>
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-accept btn-view mt-2">
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </article>
  );
}

export default CancelledEarning;
