import React from "react";
import { useNavigate } from "react-router";

function BackButton() {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(-1);
      }}
      className="cursor-pointer"
    >
      <i className="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
    </div>
  );
}

export default BackButton;
