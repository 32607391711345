import { deleteData, getData, getDataforUrl, postFormData } from "./index";

export const consentList = async (payload) => {
  return getData("admin/consentList", payload).then((data) => {
    return data;
  });
};

export const addConsent = async (payload) => {
  return postFormData("admin/addConsent", payload).then((data) => {
    return data;
  });
};
