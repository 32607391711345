import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import GooglePlace from "../../component/GooglePlace.js";

import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Progress } from "react-sweet-progress";
import { uploadFile } from "react-s3";
import { config } from "./../../utils/index";
import { AddSchoolReducer, SchoolDetailReducer } from "../slice.js";
import { toast } from "react-toastify";
import { areaCodeListingSelector } from "../areacode/slice.js";
import BackButton from "../../component/BackButton.jsx";
import Loader from "../../component/Loader.jsx";

function AddSchool() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [time, setTime] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const areaList = useSelector(areaCodeListingSelector);
  const [loading, setLoading] = useState(false);
  const details = useSelector((state) => state.main.schoolDetail);
  const [submitting, setSubmitting] = useState(false);

  const getDetails = useCallback(() => {
    setLoading(true);
    dispatch(
      SchoolDetailReducer({
        id: id,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, id]);
  const initialValues = {
    areacode: details?.areacode || "",
    image: id ? details?.image : "",
    name: id ? details?.name : "",
    description: id ? details?.description : "",
    address: id ? details?.address : "",
    lat: id ? details?.latitude : "",
    lng: id ? details?.longitude : "",
  };

  useEffect(() => {
    if (id) {
      getDetails();
    }
  }, [getDetails, id]);

  // useEffect(() => {
  //   dispatch(FetchAreaCodeList({ page: 1, limit: 10000 }));
  // }, [dispatch]);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  if (loading) {
    return (
      <article className="col-lg-9 articleClass">
        {" "}
        <Loader />
      </article>
    );
  }
  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <BackButton />
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>{id ? "Edit" : "Add New"} Venue</h3>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object({
                image: Yup.string().notRequired(),
                name: Yup.string().required("Required"),
                description: Yup.string().nullable().notRequired().optional(),
                address: Yup.string().required("Required"),
                areacode: Yup.string().required("Required"),
              })}
              enableReinitialize
              onSubmit={(values) => {
                setSubmitting(true);
                dispatch(
                  AddSchoolReducer({
                    name: values?.name,
                    image: values?.image,
                    description: values?.description,
                    address: values?.address,
                    longitude: values?.lng,
                    latitude: values?.lat,
                    school_id: id ? id : "",
                    areacode: values.areacode.toString(),
                  })
                ).then((res) => {
                  setSubmitting(false);

                  if (res?.payload?.code === 1) {
                    toast.success(res?.payload?.message);
                    navigate("/school", { replace: true });
                  } else {
                    toast?.error(res?.payload?.message);
                  }
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFormikState,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form className="form-style" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 mb-4">
                      <div className="form-group text-center">
                        <div className="upload-btn-wrapper">
                          <button className="btn">
                            {time > 0 && isActive ? (
                              <Progress
                                type="circle"
                                width={50}
                                percent={time}
                              />
                            ) : (
                              <img
                                src={
                                  values.image
                                    ? values.image
                                    : "assets/images/upload-img.png"
                                }
                                alt=""
                              />
                            )}

                            {time > 0 && isActive ? null : (
                              <div className="camera-icon">
                                <i className="fa fa-camera"></i>
                              </div>
                            )}
                          </button>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              if (e.target.files.length > 0) {
                                const { files } = e.target;
                                setIsActive(true);

                                uploadFile(files[0], config)
                                  .then((data) => {
                                    setFieldValue("image", data?.location);
                                    setTime(100);
                                    setIsActive(true);
                                    setTimeout(() => {
                                      setTime(0);
                                      setIsActive(false);
                                    }, 2000);
                                  })
                                  .catch((err) => console.error(err));
                              }
                            }}
                          />
                        </div>
                      </div>
                      {errors.image && touched.image && (
                        <div className="text-red">{errors.image}</div>
                      )}
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <label className="fw-bold">School Name</label>
                      {errors.name && touched.name && (
                        <span className="text-red ms-1">{errors.name}</span>
                      )}
                      <div className="form-group">
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="School Name"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          <img
                            src="assets/images/event-name.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">School Address</label>
                        {errors.address && touched.address && (
                          <span className="text-red ms-1">
                            {errors.address}
                          </span>
                        )}
                        <div className="input-container">
                          {/* <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Location"
                          /> */}
                          <GooglePlace
                            name="address"
                            value={values?.address}
                            latLong={{
                              lat: values.lat,
                              lng: values.lng,
                            }}
                            onBlur={handleBlur}
                            onChange={(val, latLong) => {
                              if (val) {
                                setFormikState((prevState) => {
                                  return {
                                    ...prevState,
                                    values: {
                                      ...prevState.values,
                                      address: val,
                                      lat: latLong.lat,
                                      lng: latLong.lng,
                                    },
                                  };
                                });
                              } else {
                                setFormikState((prevState) => {
                                  return {
                                    ...prevState,
                                    values: {
                                      ...prevState.values,
                                      address: "",
                                      lat: "",
                                      lng: "",
                                    },
                                  };
                                });
                              }
                            }}
                          />
                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">School Area name</label>
                        {errors.areacode && touched.areacode && (
                          <span className="text-red ms-1">
                            {errors.areacode}
                          </span>
                        )}
                        <div className="input-container">
                          <select
                            className="form-control pl-5 form-select"
                            name="areacode"
                            onChange={(e) => {
                              setFieldValue("areacode", e.target.value);
                            }}
                            value={values.areacode}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Area name</option>

                            {areaList?.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.areacode}
                              </option>
                            ))}
                          </select>
                          <img
                            src="assets/images/event-name.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 mt-4 mb-2">
                      <div className="form-group d-flex">
                        <h5>Description</h5>
                        {errors.description && touched.description && (
                          <span className="text-red ms-1">
                            {errors.description}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 mb-4">
                      <div className="form-group">
                        <textarea
                          className="textarea-control p-3"
                          rows="7"
                          placeholder="Write here..."
                          name="description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-save"
                        disabled={submitting}
                      >
                        {submitting ? <Loader /> : "Done"}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddSchool;
