import { deleteData, getData, getDataforUrl, postFormData } from "./index";

export const eventTypeList = async (payload) => {
  return getData("admin/eventTypeList", payload).then((data) => {
    return data;
  });
};
export const getEventTypeDetails = async (payload) => {
  return getDataforUrl("admin/getEventTypeDetails", payload).then((data) => {
    return data;
  });
};
export const addEventType = async (payload) => {
  return postFormData("admin/addEventType", payload).then((data) => {
    return data;
  });
};
export const removeEventType = async (payload) => {
  return deleteData("admin/removeEventType", payload).then((data) => {
    return data;
  });
};
