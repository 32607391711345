import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/input";
function Phone({ className, onChange, value, name, onBlur, isValid }) {
  return (
    <PhoneInput
      name={name}
      defaultCountry="GB"
      country="GB"
      className={className}
      placeholder={`Start with ‘0’ and no spaces please`}
      onBlur={(e) => {
        isValid(value.replace(" ", ""));
      }}
      // countryCallingCodeEditable
      useNationalFormatForDefaultCountryValue
      limitMaxLength
      value={value}
      onChange={(val) => {
        console.log(val);
        onChange(val);
      }}
      // onBlur={onBlur}
    />
  );
}

export default Phone;
