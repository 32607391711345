import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { consentList } from "../../api/consent";

const initialState = {
  list: [],
  total: 0,
  details: localStorage.getItem("consentDetails")
    ? JSON.parse(localStorage.getItem("consentDetails"))
    : null,
};

export const ConsentSlice = createSlice({
  name: "consent",
  initialState,
  reducers: {
    list: (state, action) => {
      state.list = action.payload;
    },
    count: (state, action) => {
      state.total = action.payload;
    },
    consentDetails: (state, action) => {
      state.details = action.payload;
    },
  },
});

export const FetchConsentList = createAsyncThunk(
  "ConsentList",
  async (obj, { dispatch }) => {
    try {
      const response = await consentList(obj);
      if (response?.code === 1) {
        console.log(response, "Consents");
        dispatch(list(response?.data?.consent_list));
        dispatch(count(response?.data?.total_consent));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const { list, count, consentDetails } = ConsentSlice.actions;

export default ConsentSlice.reducer;
