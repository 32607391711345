import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import BackButton from "../../component/BackButton";
import { SchoolDetailReducer } from "../slice";
import Loader from "../../component/Loader";

function SchoolDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const details = useSelector((state) => state.main.schoolDetail);
  const classList = useSelector((state) => state.main.classesForASchool);
  const [loading, setLoading] = useState(false);

  const getDetails = useCallback(() => {
    setLoading(true);
    dispatch(
      SchoolDetailReducer({
        id: id,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, id]);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getDetails();
    }

    return () => {
      isRendered = false;
    };
  }, [getDetails]);
  useEffect(() => {
    return () => {
      dispatch(details(null));
    };
  }, [dispatch]);

  if (loading) {
    return (
      <article className="col-lg-9 articleClass">
        {" "}
        <Loader />
      </article>
    );
  }
  return (
    <>
      <article className="col-lg-9 articleClass">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <BackButton />
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3>Venue Details</h3>
            </div>
          </div>
          <div className="row mt-5 px-md-5">
            {details?.image?.includes("http") && (
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 text-center">
                <img
                  src={
                    details?.image?.includes("http")
                      ? details?.image
                      : "assets/images/employe.png"
                  }
                  alt=""
                  className="detailImg"
                />
              </div>
            )}
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">name</p>
                <h6 className="">{details?.name || "NA"}</h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Address</p>
                <h6 className="text-break">{details?.address || "NA"}</h6>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">description</p>
                <h6 className="text-break">{details?.description || "NA"}</h6>
              </div>
            </div>

            {classList?.length > 0 && (
              <div className="col-sm-12 mb-3">
                <label className="fw-bold largeLabel">Classes Available</label>
              </div>
            )}

            {classList?.length > 0 && (
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                <div className=" px-5 py-3 text-capitalize position-relative h-100">
                  <div className="row">
                    {classList?.map((item, i) => (
                      <div
                        className="bg-white px-4 py-3 shadow col-sm-12 col-md-6 col-lg-4 h-100"
                        key={i}
                      >
                        <p className="mb-2">
                          <label className="fw-bold">Class Name: </label>{" "}
                          {item?.name || "NA"}
                        </p>
                        <p className="text-break mb-0">
                          <label className="fw-bold">Teacher Name: </label>{" "}
                          {item?.assigned_teacher?.teacher_details?.full_name ||
                            "NA"}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </article>
    </>
  );
}

export default SchoolDetail;
