import { FormikProvider } from "formik";
import moment from "moment";
import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import BackButton from "../../component/BackButton";
import Loader from "../../component/Loader";
import DeleteModal from "../../component/Modal/Delete";
import Paginations from "../../component/Pagination";
import { useToggle } from "../../utils/hooks";
import { FetchAdminSMSList } from "./SmsSlice";

function SmsListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, handleShow] = useToggle(false);
  const [delShow, delHandleShow] = useToggle(false);
  const [delId, setDelId] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const data = useSelector((state) => state.sms.list);
  const total = useSelector((state) => state.sms.total);
  const confirmDelete = () => {};

  useEffect(() => {
    setLoading(true);
    dispatch(
      FetchAdminSMSList({
        page,
        limit: 10,
        search,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, search, page]);
  const handlePage = (val) => {
    setPage(val);
  };
  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-6 col-md-6 col-lg-6 col-6 ">
            <div class="link-active shadow">
              <p>
                <img src="assets/images/event-calender-selected.png" alt="" />{" "}
                Manage SMS {total && total}
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-6 float-end">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search by title"
                  value={search}
                  onChange={(e) => {
                      setPage(1)

                    setSearch(e.target.value);
                  }}
                />
              </form>
              <Link to="/add/sms" className="float-end">
                <img
                  src="assets/images/addBtn.png"
                  alt=""
                  className="add-new"
                />
              </Link>
            </div>
          </div>
          {/* <div class="col-sm-12 col-md-12 col-lg-12 ">
            <ul
              class="nav nav-pills group-nav mb-3 newsletter-tabs"
              id="pills-tab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class={
                    status === 0 ? "nav-link active px-2" : "nav-link px-2"
                  }
                  id="pills-mail-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-mail"
                  type="button"
                  role="tab"
                  aria-controls="pills-mail"
                  aria-selected="true"
                  onClick={() => {
                    setPage(1);
                    setStatus(0);
                  }}
                >
                  mail list for newsletters
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class={status === 1 ? "nav-link active" : "nav-link"}
                  id="pills-sent-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-sent"
                  type="button"
                  role="tab"
                  aria-controls="pills-sent"
                  aria-selected="false"
                  onClick={() => {
                    setPage(1);
                    setStatus(1);
                  }}
                >
                  sent newsletter
                </button>
              </li>
            </ul>
          </div> */}
        </div>
        <div class="row ">
          <div class="col-sm-12">
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-mail"
                role="tabpanel"
                aria-labelledby="pills-mail-tab"
              >
                <div class="table-responsive text-center">
                  <table class="table storetable">
                    <thead class="text-center">
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Title</th>
                        <th scope="col">Action</th>
                      </tr>
                      <tr class="border-0 bg-none invisible">
                        <td class="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <td colSpan={3}>
                          {" "}
                          <Loader />
                        </td>
                      ) : data?.length > 0 ? (
                        data?.map((item, i) => (
                          <tr key={i}>
                            <td>
                              {moment(item?.createdAt).format("DD MMM YYYY")}
                            </td>
                            <td class="td-wd ">
                              <div
                                class="line-clam innerHtmlWrapper"
                                dangerouslySetInnerHTML={{
                                  __html: item?.content,
                                }}
                              ></div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center action align-items-baseline">
                                <button className="btn" title="View User">
                                  <Link to={`/sms/${item?.id}`}>
                                    <img src="assets/images/view.png" alt="" />
                                  </Link>
                                </button>
                                {/* <button className="btn" title="Edit User">
                                  <Link to={`/edit/sms/${item?.id}`}>
                                    <img src="assets/images/edit.png" alt="" />
                                  </Link>
                                </button> */}
                                {/* <button
                                  class="btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteModal"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    delHandleShow();
                                  }}
                                >
                                  <img src="assets/images/delete.png" alt="" />
                                </button> */}
                                {/* <h6
                                  class="cursor-pointer theme-color"
                                  data-bs-toggle="modal"
                                  data-bs-target="#FilterModal"
                                  onClick={() => {
                                    setNewsletterId(item?.id);
                                    handleShow();
                                  }}
                                >
                                  send newsletter
                                </h6> */}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <td colSpan={3}>No data found.</td>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div
                class="tab-pane fade"
                id="pills-sent"
                role="tabpanel"
                aria-labelledby="pills-sent-tab"
              >
                <div class="table-responsive text-center">
                  <table class="table storetable">
                    <thead class="text-center">
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col" class="td-wd">
                          Newsletter
                        </th>
                        <th scope="col">Sent to</th>
                      </tr>
                      <tr class="border-0 bg-none invisible">
                        <td class="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <td colSpan={3}>
                          {" "}
                          <Loader />
                        </td>
                      ) : data?.length > 0 ? (
                        data?.map((item, i) => (
                          <tr key={i}>
                            <td>
                              {moment(item?.createdAt).format("DD MMM YYYY")}
                            </td>
                            <td class="td-wd">
                              <div
                                class="line-clam innerHtmlWrapper"
                                dangerouslySetInnerHTML={{
                                  __html: item?.news_letter,
                                }}
                              ></div>
                            </td>

                            <td>
                              <div className="d-flex justify-content-center action align-items-baseline">
                                <button class="btn">
                                  <Link to={`/newsletter/${item?.id}`}>
                                    <img src="assets/images/view.png" alt="" />
                                  </Link>
                                </button>
                                <button
                                  class="btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteModal"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    delHandleShow();
                                  }}
                                >
                                  <img src="assets/images/delete.png" alt="" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <td colSpan={3}>No data found.</td>
                      )}
                    </tbody>
                  </table>
                </div>
              </div> */}
            </div>

            {data?.length > 0 && (
              <Paginations
                handlePage={handlePage}
                page={page}
                total={total && total}
              />
            )}
          </div>
        </div>
      </div>
      {/* {newsletterId && show && (
        <FilterModal
          show={show}
          handleShow={handleShow}
          newsletterId={newsletterId}
        />
      )} */}
      <DeleteModal
        show={delShow}
        handleShow={delHandleShow}
        page="Newsletter"
        confirmDelete={confirmDelete}
        cancel={() => {
          delHandleShow();
        }}
      />
    </article>
  );
}

export default SmsListing;
