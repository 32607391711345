import { deleteData, getData, getDataforUrl, postFormData } from "./index";

export const addMembership = async (payload) => {
  return postFormData("admin/addMembership", payload).then((data) => {
    return data;
  });
};

export const membershipList = async (payload) => {
  return getData("admin/membershipList", payload).then((data) => {
    return data;
  });
};

export const getMembershipDetails = async (payload) => {
  return getDataforUrl("admin/getMembershipDetails", payload).then((data) => {
    return data;
  });
};

export const removeMembership = async (payload) => {
  return deleteData("admin/removeMembership", payload).then((data) => {
    return data;
  });
};

export const sendMembershipSticker = async (payload) => {
  return postFormData("/user/sendMembershipSticker", payload).then((data) => {
    return data;
  });
};
