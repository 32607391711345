import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteTeacher } from "../../api/teacher";
import DeleteModal from "../../component/Modal/Delete";
import Paginations from "../../component/Pagination";
import { variants } from "./../../constant/Variants";
import {
  activeTeacher,
  ChangeTeacherStatus,
  GetTeacher,
  inactiveTeacher,
  totalTeacher,
} from "./TeacherSlice";

function Teacher() {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.teacher.list);
  const totalActive = useSelector(activeTeacher);
  const totalInActive = useSelector(inactiveTeacher);
  const total = useSelector(totalTeacher);
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState("");
  const [page, setPage] = useState(1);
  const handlePage = (value) => {
    setPage(value);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const getList = useCallback(() => {
    dispatch(GetTeacher({ page: page, limit: 10, search: search }));
  }, [dispatch, page, search]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleToggle = (id, stat) => {
    dispatch(ChangeTeacherStatus({ teacher_id: id, status: stat }));
  };

  const confirmDelete = () => {
    deleteTeacher(delId).then((res) => {
      handleShow();
      if (res?.code === 1) {
        toast.success(res?.message);
        getList();
      } else {
        toast.error(res?.message);
      }
    });
  };
  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="link-active shadow">
              <p>
                <img src="assets/images/teachers-selected.png" alt="" /> Total
                teacher {total || 0}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search teacher by teacher ID"
                  value={search}
                  onChange={(e) => {
                    setPage(1);

                    setSearch(e.target.value);
                  }}
                />
              </form>
              <Link to={"/add-teacher"} className="">
                <img
                  src="assets/images/addBtn.png"
                  alt=""
                  className="add-new"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">teacher ID</th>
                    <th scope="col">photo</th>
                    <th scope="col">teacher name</th>
                    <th scope="col">email ID</th>
                    <th scope="col">mobile no.</th>
                    <th scope="col">highest qualification</th>
                    <th scope="col">expertise in subject</th>
                    <th scope="col">award name</th>
                    <th scope="col">action</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>

                <tbody>
                  {list?.length > 0 ? (
                    list?.map((item, i) => {
                      return (
                        <tr variants={variants.card} key={i}>
                          <td>{item?.id}</td>
                          <td>
                            <img
                              src={
                                item?.image || "assets/images/headerlogo.png"
                              }
                              className="table-img"
                              alt=""
                            />
                          </td>
                          <td>{item?.full_name || "NA"}</td>
                          <td>{item?.email || "NA"}</td>
                          <td>
                            {item?.mobile
                              ? item?.countryCode + item?.mobile
                              : "NA"}
                          </td>
                          <td>
                            {item?.qualification_list
                              ?.slice(0, 3)
                              ?.map((it) => it?.qualification)
                              ?.join(", ") || "NA"}
                          </td>
                          <td>
                            {" "}
                            {item?.subject_list
                              ?.slice(0, 3)
                              ?.map((it) => it?.subject)
                              ?.join(", ") || "NA"}
                          </td>
                          <td>
                            {" "}
                            {item?.award_list
                              ?.slice(0, 3)
                              ?.map((it) => it?.award)
                              ?.join(", ") || "NA"}
                          </td>
                          <td>
                            <div className="d-flex justify-content-center action">
                              <button className="btn" title="View Teacher">
                                <Link to={`/teacher/${item?.id}`}>
                                  <img src="assets/images/view.png" alt="" />
                                </Link>
                              </button>
                              <button className="btn" title="Edit Teacher">
                                <Link to={`/edit-teacher/${item?.id}`}>
                                  <img src="assets/images/edit.png" alt="" />
                                </Link>
                              </button>
                              <button
                                className="btn"
                                title="Delete Teacher"
                                onClick={() => {
                                  handleShow();
                                  setDelId(item.id);
                                }}
                              >
                                <img src="assets/images/delete.png" alt="" />
                              </button>
                              {/* <button className="btn">
                                <input
                                  type="checkbox"
                                  className="toggle"
                                  checked={item.isActive}
                                  onChange={() => {
                                    handleToggle(item?.id, !item?.isActive);
                                  }}
                                />
                              </button> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={9}>No data found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {list?.length > 0 && (
            <Paginations
              handlePage={handlePage}
              page={page}
              total={total && total}
            />
          )}
        </div>
      </div>
      <DeleteModal
        page="Teacher"
        show={show}
        handleShow={handleShow}
        cancel={() => {
          setShow(false);
        }}
        confirmDelete={confirmDelete}
      />
    </article>
  );
}

export default Teacher;
