import { getData, patchFormData } from "./index";

export const deleteAccountRequesteList = async (payload) => {
  return getData("admin/deleteAccountRequesteList", payload).then((data) => {
    return data;
  });
};
export const acceptRejectRequest = async (payload) => {
  return patchFormData("admin/acceptRejectRequest", payload).then((data) => {
    return data;
  });
};
