import { deleteData, getData, getDataforUrl, postFormData } from "./index";

export const disciplineList = async (payload) => {
  return getData("admin/disciplineList", payload).then((data) => {
    return data;
  });
};
export const getDisciplineDetails = async (payload) => {
  return getDataforUrl("admin/getDisciplineDetails", payload).then((data) => {
    return data;
  });
};
// name, image, discipline_id
export const addDiscipline = async (payload) => {
  return postFormData("admin/addDiscipline", payload).then((data) => {
    return data;
  });
};
// discipline_id, status (0 = inactive, 1 active)
export const activateDeactivateDiscipline = async (payload) => {
  return postFormData("admin/activateDeactivateDiscipline", payload).then(
    (data) => {
      return data;
    }
  );
};
// discipline_id
export const removeDiscipline = async (payload) => {
  return deleteData("admin/removeDiscipline", payload).then((data) => {
    return data;
  });
};
