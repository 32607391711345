export const variants = {
  container: {
    animate: {
      overflow: "hidden",
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
        delayChildren: 0.3,
      },
    },
  },
  card: {
    initial: {
      opacity: 0,
      x: -200,
      borderBottom: "transparent",
    },

    animate: {
      opacity: 1,
      x: 0,
      overflow: "hidden",

      transition: {
        duration: 1,
      },
    },
  },
};

export const pageTransitionVariant = {
  initial: {
    opacity: 0,
    y: -100,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      type: "tween",
      ease: "easeInOut",
    },
  },
};
