import { deleteData, getData, getDataforUrl, postFormData } from "./index";

export const saveAdminSMS = async (payload) => {
  return postFormData("admin/saveAdminSMS", payload).then((data) => {
    return data;
  });
};

export const adminSMSList = async (payload) => {
  return getData("admin/adminSMSList", payload).then((data) => {
    return data;
  });
};

export const getAdminSMSDetails = async (payload) => {
  return getDataforUrl("admin/getAdminSMSDetails", payload).then((data) => {
    return data;
  });
};
