import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, HashRouter, useLocation } from "react-router-dom";
import Routers, { RenderRoutes } from "./routes/Router";

// /bookinclass-admin-react-js/build/
function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <RenderRoutes routers={Routers} />
      </BrowserRouter>
    </>
  );
}

export default App;
