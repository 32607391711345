import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  adminSMSList,
  getAdminSMSDetails,
  getSmsDetails,
  SmsList,
} from "../../api/SMS";

const initialState = {
  list: [],
  total: 0,
  detail: null,
};

export const SmsSlice = createSlice({
  name: "SmsSlice",
  initialState,
  reducers: {
    listing: (state, action) => {
      state.list = action.payload;
    },
    count: (state, action) => {
      state.total = action.payload;
    },
    detailing: (state, action) => {
      state.detail = action.payload;
    },
  },
});

export const FetchAdminSMSList = createAsyncThunk(
  "smsListing",
  async (obj, { dispatch }) => {
    try {
      const response = await adminSMSList(obj);
      console.log(response);
      if (response?.code === 1) {
        dispatch(listing(response?.data?.data));
        dispatch(count(response?.data?.total));
      }
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const FetchSmsDetail = createAsyncThunk(
  "FetchSmsDetail",
  async (obj, { dispatch }) => {
    try {
      const response = await getAdminSMSDetails(obj);
      console.log(response);
      if (response?.code === 1) {
        dispatch(detailing(response?.data));
      }
    } catch (error) {
      toast.error(error?.message);
    }
  }
);
export const { listing, detailing, count } = SmsSlice.actions;

export default SmsSlice.reducer;
