import React, { useCallback, useEffect, useState } from "react";
import DeleteModal from "../../component/Modal/Delete";
import Paginations from "../../component/Pagination";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { moment } from "moment";
import { variants } from "../../constant/Variants";
import {
  areaCodeListingSelector,
  FetchAreaCodeList,
  totalAreaCodeSelector,
} from "./slice";
import { toast } from "react-toastify";
import { removeAreaCode } from "../../api/areacode";

function AreaCodeList() {
  const dispatch = useDispatch();
  const list = useSelector(areaCodeListingSelector);
  const total = useSelector(totalAreaCodeSelector);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState("");
  const [page, setPage] = useState(1);
  const handlePage = (val) => {
    setPage(val);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const getList = useCallback(() => {
    dispatch(FetchAreaCodeList({ page: page, limit: 10, search: search }));
  }, [dispatch, page, search]);

  useEffect(() => {
    getList();
  }, [getList]);

  const confirmDelete = () => {
    removeAreaCode({ areacode_id: delId }).then((res) => {
      if (res?.code === 1) {
        getList();
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    });
  };
  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="link-active shadow">
              <p>
                <img src="assets/images/users-selected.png" alt="" /> Total area{" "}
                {total}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search area by Area Name or ID"
                  value={search}
                  onChange={(e) => {
                    setPage(1);
                    setSearch(e.target.value);
                  }}
                />
              </form>
              <Link to="/add/area-code" className="">
                <img
                  src="assets/images/addBtn.png"
                  alt=""
                  className="add-new"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Area Name</th>

                    <th scope="col">Action</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {list?.length > 0 ? (
                    list?.map((item, i) => {
                      return (
                        <tr variants={variants.card} key={i}>
                          <td>{item?.id || "NA"}</td>
                          <td>{item?.areacode ? item?.areacode : "NA"}</td>

                          <td>
                            <div className="d-flex justify-content-center action">
                              {/* <button className="btn">
                                <Link to={`/user/${item?.id}`}>
                                  <img src="assets/images/view.png" alt="" />
                                </Link>
                              </button> */}
                              <button className="btn" title="Edit">
                                <Link to={`/edit/area-code/${item?.id}`}>
                                  <img src="assets/images/edit.png" alt="" />
                                </Link>
                              </button>
                              <button
                                className="btn"
                                onClick={() => {
                                  handleShow();
                                  setDelId(item?.id);
                                }}
                                title="Delete"
                              >
                                <img src="assets/images/delete.png" alt="" />
                              </button>
                              {/* <button className="btn">
                                <input
                                  type="checkbox"
                                  className="toggle"
                                  checked=""
                                />
                              </button> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10}>No data found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {list?.length > 0 && (
            <Paginations
              handlePage={handlePage}
              page={page}
              total={total && total}
            />
          )}
        </div>
      </div>
      <DeleteModal
        page="Area Name"
        show={show}
        handleShow={handleShow}
        cancel={() => {
          setShow(false);
        }}
        confirmDelete={confirmDelete}
      />
    </article>
  );
}

export default AreaCodeList;
