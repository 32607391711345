import {
  deleteData,
  getData,
  getDataforUrl,
  getDataforUrlWithParams,
  patchFormData,
  postFormData,
} from "./index";
// booking_for (class)
export const bookingList = async (payload) => {
  return getData("admin/bookingList", payload).then((data) => {
    return data;
  });
};
export const getBookingDetails = async (payload) => {
  return getDataforUrlWithParams("admin/getBookingDetails", payload).then(
    (data) => {
      return data;
    }
  );
};
//  booking_id, status
export const changeBookingStatus = async (payload) => {
  return patchFormData("admin/changeBookingStatus", payload).then((data) => {
    return data;
  });
};
// bookingId
export const removeBooking = async (payload) => {
  return deleteData("admin/removeBooking", payload).then((data) => {
    return data;
  });
};
export const removeEventBooking = async (payload) => {
  return deleteData("admin/removeEventBooking", payload).then((data) => {
    return data;
  });
};
export const removeBookingTimeSlot = async (payload) => {
  return deleteData("admin/removeBookingTimeSlot", payload).then((data) => {
    return data;
  });
};
