import React from "react";
import { Modal } from "react-bootstrap";

function Accepted({
  show,
  handleShow,
  pageName,
  actionName,
  approvedReject,
  getList,
}) {
  return (
    <Modal show={show} onHide={handleShow} centered>
      <div className="modal-content body-bg">
        <div className="modal-header border-0">
          <button
            type="button"
            className="btn-close"
            onClick={handleShow}
          ></button>
        </div>
        <div className="modal-body text-center mb-4">
          <h4>
            {actionName} this {pageName}?
          </h4>
          <p>
            Are you sure, you want to {actionName} this {pageName}?
          </p>
          <div className="view-btns">
            <button
              className="btn btn-reject btn-view mt-2"
              onClick={() => {
                handleShow("no");
              }}
            >
              No
            </button>
            <button
              className="btn btn-accept btn-view mt-2"
              onClick={() => {
                approvedReject();
                getList();
                handleShow();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Accepted;
