import React from "react";
import moment from "moment";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Loader from "../../component/Loader";
import Paginations from "../../component/Pagination";
import { FetchTNC } from "./tncSlice.js";
import DeleteModal from "../../component/Modal/Delete";
import { toast } from "react-toastify";
import { deleteData } from "../../api";
function TNCList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [delId, setDelId] = useState("");
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const list = useSelector((state) => state.tnc.list);
  const total = useSelector((state) => state.tnc.total);
  const [search, setSearch] = useState("");

  const handlePage = (val) => {
    setPage(val);
  };
  const handleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    dispatch(FetchTNC({ page, limit: 10 }));
  }, [dispatch, page]);

  const confirmDelete = () => {
    deleteData("admin/removeTermAndCondition", {
      tnc_id: delId,
    }).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        handleShow();
        dispatch(FetchTNC({ page, limit: 10 }));
      } else {
        toast.error(res?.message);
      }
    });
  };
  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="link-active shadow">
              <p>
                <img src="assets/images/users-selected.png" alt="" /> Total TNCs{" "}
                {total}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search Terms & Conditions"
                  value={search}
                  onChange={(e) => {
                    setPage(1);

                    setSearch(e.target.value);
                  }}
                />
              </form>
              <Link to="/add/terms-and-conditions" className="">
                <img
                  src="assets/images/addBtn.png"
                  alt=""
                  className="add-new"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Type</th>

                    <th scope="col">Action</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td colSpan={9}>
                      <Loader />
                    </td>
                  ) : (
                    <>
                      {list?.length > 0 ? (
                        list?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item?.id || "NA"}</td>
                              <td>
                                {item?.type === "company"
                                  ? "BOOKING TERMS and CONDITIONS"
                                  : item?.type
                                  ? item?.type
                                  : "NA"}
                              </td>
                              {/* <td className="ellipse-1">
                                {item?.terms || "NA"}
                              </td> */}

                              <td>
                                <div className="d-flex justify-content-center action align-items-center">
                                  {/* <button className="btn" title="View Student">
                                    <a
                                      href={item?.file}
                                      target={"_blank"}
                                      rel="noreferrer"
                                      className="cursor-pointer"
                                    >
                                      <img
                                        src="assets/images/downloadActive.png"
                                        alt=""
                                      />
                                    </a>
                                  </button> */}
                                  <button className="btn">
                                    <Link
                                      to={`/edit/terms-and-conditions/${item?.id}`}
                                    >
                                      <img
                                        src="assets/images/edit.png"
                                        alt=""
                                      />
                                    </Link>
                                  </button>
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      setDelId(item.id);
                                      handleShow();
                                    }}
                                    title="Delete Student"
                                  >
                                    <img
                                      src="assets/images/delete.png"
                                      alt=""
                                    />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10}>No data found.</td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {list?.length > 0 && (
            <Paginations
              handlePage={handlePage}
              page={page}
              total={total && total}
            />
          )}
        </div>
      </div>

      <DeleteModal
        page="Terms and Condition"
        show={show}
        handleShow={handleShow}
        cancel={() => {
          setShow(false);
        }}
        confirmDelete={confirmDelete}
      />
    </article>
  );
}

export default TNCList;
