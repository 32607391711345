import { getData, getDataforUrl, patchFormData } from "./index";

export const termAndConditionList = async (payload) => {
  return getData("admin/termAndConditionList", payload).then((data) => {
    return data;
  });
};

export const termAndConditionDetails = async (payload) => {
  return getDataforUrl("admin/getTermAndConditionDetails", payload).then(
    (data) => {
      return data;
    }
  );
};

export const changeTermAndCondition = async (payload) => {
  return patchFormData("admin/changeTermAndCondition", payload).then((data) => {
    return data;
  });
};
