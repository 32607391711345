import { useFormik } from "formik";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import S3FileUpload from "react-s3";
import BackButton from "../../component/BackButton";
import * as Yup from "yup";
import { Progress } from "react-sweet-progress";
import { config } from "../../utils";
import { changeTermAndCondition } from "../../api/tnc";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { FetchTNCDetail } from "./tncSlice";
import { useDispatch, useSelector } from "react-redux";
import WYSIWYG from "../../component/wysiwyg/WYSIWYG";
import Loader from "../../component/Loader";
function AddTnc() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const detail = useSelector((state) => state.tnc.detail);
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);
  useEffect(() => {
    if (id) {
      dispatch(FetchTNCDetail(id));
    }
  }, [id, dispatch]);

  const formik = useFormik({
    initialValues: {
      file: detail?.terms || "",
      type: detail?.type || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      file: Yup.string().required("Required"),
      type: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      setSubmitting(true);
      changeTermAndCondition({ type: values?.type, terms: values.file }).then(
        (res) => {
          setSubmitting(false);
          if (res?.code === 1) {
            navigate("/terms-and-conditions");
            toast.success(res?.message);
          } else {
            toast.danger(res?.message);
          }
        }
      );
    },
  });
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <BackButton />
        <div className="pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>{"Add Terms and Condition"}</h4>
            </div>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-sm-12">
            <form className="form-style" onSubmit={formik.handleSubmit}>
              <div className="row">
                {/* <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="form-group text-center">
                    <div className="upload-btn-wrapper">
                      <button className="btn">
                        {time > 0 && isActive ? (
                          <Progress type="circle" width={80} percent={time} />
                        ) : (
                          <img src={"assets/images/upload-event.png"} alt="" />
                        )}

                        {time > 0 && isActive ? null : (
                          <div className="camera-icon">
                            <i className="fa fa-camera"></i>
                          </div>
                        )}
                      </button>

                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            setIsActive(true);
                            S3FileUpload.uploadFile(e.target.files[0], config)
                              .then((data) => {
                                formik.setFieldValue("file", data.location);
                                setTime(100);
                                setIsActive(true);
                                setTimeout(() => {
                                  setTime(0);
                                  setIsActive(false);
                                }, 3000);
                              })
                              .catch((data) => console.log(data));
                          }
                        }}
                      />
                    </div>
                  </div>
                </div> */}
                {/* {formik.values.file && (
                  <>
                    {" "}
                    <div className="col-sm-2 position-relative">
                      <div>
                        {formik.values.file && (
                          <img src="assets/images/pdf-icon.png" alt="file" />
                        )}
                        <img
                          src="assets/images/delete-sm.png"
                          alt="file"
                          className="pdfCrossIcon"
                          onClick={() => {
                            formik.setFieldValue("file", "");
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-8"></div>
                  </>
                )} */}
                <div className={"col-sm-12 col-md-6 col-lg-6 mb-4"}>
                  <div className="form-group">
                    <label className="fw-bold">Terms and Condition On</label>

                    {formik.errors?.type && formik.touched?.type && (
                      <span className="text-red ms-1">
                        {formik.errors?.type}
                      </span>
                    )}
                    <div className="input-container">
                      <select
                        onChange={(e) => {
                          formik.setFieldValue("type", e.target.value);
                        }}
                        value={formik.values.type || ""}
                        className="form-control ps-4"
                        name="type"
                      >
                        <option value="">Select type</option>
                        <option value="company">
                          BOOKING TERMS and CONDITIONS
                        </option>
                        <option value="signup for new account">
                          Signup for new account
                        </option>
                        <option value="add new student">Add new student</option>
                        {/* <option value="purchase trial lesson">
                          Purchase trial lesson
                        </option> */}
                        <option value="purchase membership">
                          Purchase membership
                        </option>
                        <option value="purchase lessons">
                          Purchase lessons
                        </option>
                        <option value="purchase events">Purchase events</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6"></div>
                <div className={"col-sm-12 col-md-12 col-lg-12 mb-4"}>
                  <div className="form-group">
                    <label className="fw-bold">Terms and Condition</label>

                    {formik.errors?.file && formik.touched?.file && (
                      <span className="text-red ms-1">
                        {formik.errors?.file}
                      </span>
                    )}
                    <div className="input-container">
                      <WYSIWYG
                        value={formik.values.file}
                        setValue={(val) => {
                          formik.setFieldValue("file", val);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-save"
                    disabled={submitting}
                  >
                    {submitting ? <Loader /> : "Done"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddTnc;
