import {
  deleteData,
  deleteURLData,
  getData,
  getDataforUrl,
  patchFormData,
  postFormData,
  putFormData,
} from "./index";

export const TeacherListApi = async (payload) => {
  return getData("admin/teacherList", payload).then((data) => {
    return data;
  });
};

export const AddTeacherApi = async (payload) => {
  return postFormData("admin/addTeacher", payload).then((data) => {
    return data;
  });
};

export const EditTeacherProfile = async (payload) => {
  return postFormData("admin/addTeacher", payload).then((data) => {
    return data;
  });
};

export const TeacherStatus = async (payload) => {
  return patchFormData("admin/changeTeacherStatus", payload).then((data) => {
    return data;
  });
};

export const deleteTeacher = async (payload) => {
  return deleteURLData("admin/deleteTeacher", payload).then((data) => {
    return data;
  });
};
