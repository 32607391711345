import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useToggle } from "./../../utils/hooks/index";
import Loader from "./../../component/Loader";
import moment from "moment/moment";
import { useEffect } from "react";
import { getData } from "../../api";
import Paginations from "./../../component/Pagination";
import {
  FetchUserList,
  totalUserCountSelector,
  userListSelector,
} from "../user/UserSlice";

function MailingList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, handleShow] = useToggle(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);
  const data = useSelector(userListSelector);
  const total = useSelector(totalUserCountSelector);

  // const [total, setTotal] = useState(0);
  const handlePage = (num) => {
    setPage(num);
  };

  useEffect(() => {
    dispatch(
      FetchUserList({
        page: page,
        limit: 10,
        isSubscribed: 1,
        search: search,
      })
    );
  }, [dispatch, page, search]);

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever mb-2">
          <div class="col-sm-6 col-md-6 col-lg-6 col-6 ">
            <div class="link-active shadow">
              <p>
                <img src="assets/images/event-calender-selected.png" alt="" />{" "}
                Subscribed Users {total && total}
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-6 float-end">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search user by name"
                  value={search}
                  onChange={(e) => {
                    setPage(1);

                    setSearch(e.target.value);
                  }}
                />
              </form>
              {/* <Link to="/add/newsletter" className="float-end">
                <img
                  src="assets/images/addBtn.png"
                  alt=""
                  className="add-new"
                />
              </Link> */}
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-sm-12">
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-mail"
                role="tabpanel"
                aria-labelledby="pills-mail-tab"
              >
                <div class="table-responsive text-center">
                  <table class="table storetable">
                    <thead class="text-center">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Address</th>
                        <th scope="col">Action</th>
                      </tr>
                      <tr class="border-0 bg-none invisible">
                        <td class="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <td colSpan={5}>
                          {" "}
                          <Loader />
                        </td>
                      ) : data?.length > 0 ? (
                        data?.map((item, i) => (
                          <tr key={i}>
                            <td>{item?.id || "NA"}</td>
                            <td class="td-wd ">{item?.first_name || "NA"}</td>
                            <td class="td-wd ">{item?.email || "NA"}</td>
                            <td class="td-wd ">
                              {item?.address_details
                                ? `${
                                    item?.address_details?.property_number
                                      ? `${item?.address_details?.property_number}, `
                                      : ""
                                  }${
                                    item?.address_details?.property_name
                                      ? `${item?.address_details?.property_name}, `
                                      : ""
                                  }${
                                    item?.address_details?.street_number
                                      ? `${item?.address_details?.street_number}, `
                                      : ""
                                  }${
                                    item?.address_details?.town
                                      ? `${item?.address_details?.town}, `
                                      : ""
                                  }${
                                    item?.address_details?.country
                                      ? `${item?.address_details?.country}`
                                      : ""
                                  }${
                                    item?.address_details?.postal_code
                                      ? `-${item?.address_details?.postal_code}`
                                      : ""
                                  }`
                                : "NA"}
                            </td>
                            <td>
                              <div className="d-flex justify-content-center action align-items-baseline">
                                <button className="btn" title="View User">
                                  <Link to={`/user/${item?.id}`}>
                                    <img src="assets/images/view.png" alt="" />
                                  </Link>
                                </button>
                                {/* <button className="btn" title="Edit User">
                                  <Link to={`/edit/newsletter/${item?.id}`}>
                                    <img src="assets/images/edit.png" alt="" />
                                  </Link>
                                </button> */}
                                {/* <button
                                  class="btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteModal"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    delHandleShow();
                                  }}
                                >
                                  <img src="assets/images/delete.png" alt="" />
                                </button> */}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <td colSpan={5}>No data found.</td>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {data?.length > 0 && (
                <Paginations
                  handlePage={handlePage}
                  page={page}
                  total={total && total}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default MailingList;
