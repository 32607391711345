import {
  deleteData,
  getData,
  getDataforUrl,
  postFormData,
  putFormData,
} from "./index";
// booking_for (class)
export const studentList = async (payload) => {
  return getData("admin/studentList", payload).then((data) => {
    return data;
  });
};
export const getStudentDetails = async (payload) => {
  return getDataforUrl("admin/getStudentDetails", payload).then((data) => {
    return data;
  });
};
//  studentId
export const removeStudent = async (payload) => {
  return deleteData("admin/removeStudent", payload).then((data) => {
    return data;
  });
};

export const editStudent = async (payload) => {
  return putFormData("admin/editStudent", payload).then((data) => {
    return data;
  });
};

export const addStudent = async (payload) => {
  return postFormData("admin/addStudent", payload).then((data) => {
    return data;
  });
};

export const getAllOtherStudents = async (payload) => {
  return getData("class/getAllOtherStudents", payload).then((data) => {
    return data;
  });
};

export const getAllOtherStudentsForEvent = async (payload) => {
  return getDataforUrl("class/getAllOtherStudentsForEvent", payload).then(
    (data) => {
      return data;
    }
  );
};

export const importStudentFromExcel = async (payload) => {
  return postFormData("admin/importStudentData", payload).then((data) => {
    return data;
  });
};

export const allStudentListForAClass = async (payload) => {
  return getDataforUrl("class/getAllClassStudents", payload).then((data) => {
    return data;
  });
};

export const allStudentListForAnEvent = async (payload) => {
  return getDataforUrl("class/getAllEventStudents", payload).then((data) => {
    return data;
  });
};
