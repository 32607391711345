import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

function ViewAccountDeletion() {
  const dispatch = useDispatch();
  const details = useSelector((state) => state.accountDeletionRequest.details);

  const getDetails = useCallback(() => {}, [dispatch]);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getDetails();
    }

    return () => {
      isRendered = false;
    };
  }, [getDetails]);
  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>Completed Request Details</h3>
          </div>
        </div>
        <div className="row mt-3 px-md-5 booking-dtl">
          <div className="col-sm-12 col-md-12 col-lg-12 my-5">
            <h5 className="mb-2">Reason</h5>
            <div className="bg-white ps-4 py-3 text-capitalize position-relative">
              <p className="p-4">
                Lorem Ipsum Dolor Sit Amet, Consectetuer Adipiscing Elit. Aenean
                Commodo Ligula Eget Dolor.Lorem Ipsum Dolor Sit Amet,
                Consectetuer Adipiscing Elit. Aenean Commodo Ligula Eget
                Dolor.Lorem Ipsum Dolor Sit Amet, Consectetuer Adipiscing Elit.
                Aenean Commodo Ligula Eget Dolor.Lorem Ipsum Dolor Sit Amet,
                Consectetuer Adipiscing Elit. Aenean Commodo Ligula Eget
                Dolor.Lorem Ipsum Dolor Sit Amet, Consectetuer Adipiscing Elit.
                Aenean Commodo Ligula Eget Dolor.Lorem Ipsum Dolor Sit Amet,
                Consectetuer Adipiscing Elit. Aenean Commodo Ligula Eget
                Dolor.Lorem Ipsum Dolor Sit Amet, Consectetuer Adipiscing Elit.
                Aenean Commodo Ligula Eget Dolor.Lorem Ipsum Dolor Sit Amet,
                Consectetuer Adipiscing Elit. Aenean Commodo Ligula Eget
                Dolor.Lorem Ipsum Dolor Sit Amet, Consectetuer Adipiscing Elit.
                Aenean Commodo Ligula Eget Dolor.
              </p>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="view-btns justify-content-sm-end">
              <a href="#">
                <button className="btn btn-reject btn-view mt-2 bg-white">
                  Pending
                </button>
              </a>
              <a href="#">
                <button className="btn btn-accept btn-view mt-2">Delete</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default ViewAccountDeletion;
