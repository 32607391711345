import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useSelector } from "react-redux";
import { bookingList, getBookingDetails } from "../../api/booking";
import { getEventDetails, getEventList } from "../../api/event";
const initialState = {
  list: [],
  total: 0,
  status: "idle",
  booking_for: localStorage.getItem("booking_for")
    ? localStorage.getItem("booking_for")
    : "",
  detail: null,
};

export const fetchBookingList = createAsyncThunk(
  "BookingList",
  async (obj, { dispatch }) => {
    return bookingList(obj).then((response) => {
      if (response.code === 1) {
        dispatch(list(response?.data.booking_list));
        dispatch(count(response?.data?.booking_count));
      }
    });
  }
);

export const fetchBookingDetails = createAsyncThunk(
  "bookingDetails",
  async (obj, { dispatch }) => {
    return getBookingDetails(obj).then((response) => {
      dispatch(details(response?.data?.booking_details));
    });
  }
);
export const getStatus = (state) => state.booking.status;
export const getDetails = (state) => state.booking.detail;
export const getList = (state) => state.booking.list;
export const getTotalCount = (state) => state.booking.total;

export const Booking = createSlice({
  name: "booking",
  initialState,
  reducers: {
    loading: (state, action) => {
      state.status = "loading";
    },
    idle: (state, action) => {
      state.status = "idle";
    },
    list: (state, action) => {
      state.list = action.payload;
    },
    details: (state, action) => {
      state.detail = action.payload;
    },
    count: (state, action) => {
      state.total = action.payload;
    },
    changeBooking_for: (state, action) => {
      state.booking_for = action.payload;
    },
  },
});

export const { list, details, count, loading, idle, changeBooking_for } =
  Booking.actions;

export default Booking.reducer;
