import { FormikProvider, useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import BackButton from "../../component/BackButton";
import Loader from "../../component/Loader";
import { areaCodeListingSelector } from "../areacode/slice";
import * as Yup from "yup";
import { config } from "../../utils";
import Phone from "../../component/Phone/Phone";
import moment from "moment";
import {
  details,
  FetchStudentDetails,
  studentDetailSelector,
} from "./studentslice";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { userListSelector } from "../user/UserSlice";
import { Progress } from "react-sweet-progress";
import S3FileUpload from "react-s3";
import CreatableSelectInput from "./../../component/CreatableSelectField/CreatableSelectInput";
import { AddTeacherReducer } from "../teacher/TeacherSlice";

const getYears = () => {
  let startYear = 1970;
  let endYear = moment().get("y");
  let datesArray = [];
  while (startYear <= endYear) {
    datesArray.unshift(startYear);
    startYear++;
  }
  return datesArray;
};

export const months = [
  {
    id: 1,
    value: "January",
  },
  {
    id: 2,
    value: "February",
  },
  {
    id: 3,
    value: "March",
  },
  {
    id: 4,
    value: "April",
  },
  {
    id: 5,
    value: "May",
  },
  {
    id: 6,
    value: "June",
  },
  {
    id: 7,
    value: "July",
  },
  {
    id: 8,
    value: "August",
  },
  {
    id: 9,
    value: "September",
  },
  {
    id: 10,
    value: "October",
  },
  {
    id: 11,
    value: "November",
  },
  {
    id: 12,
    value: "December",
  },
];

function StudentToTeacher() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const data = useSelector(studentDetailSelector);
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const [submitting, setSubmitting] = useState(false)
  const years = getYears();

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(FetchStudentDetails(id)).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(details(null));
    };
  }, [dispatch]);

  const editSutdentSchema = Yup.object({
    image: Yup.string().notRequired(),
    fullName: Yup.string().required("Required"),
    password: Yup.string()
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#£€\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .required("Required"),
    email: Yup.string()
      .email("Kindly enter a valid email.")
      .required("Required"),
    mobile: Yup.string().required("Required"),
    expertise_in_subject: Yup.array().notRequired().nullable().optional(),
    award_name: Yup.array().notRequired().nullable().optional(),
    highest_qualification: Yup.array().notRequired().nullable().optional(),
    document: Yup.array().notRequired(),
    address: Yup.object({
      property_number: Yup.string().required("Required"),
      street_number: Yup.string().required("Required"),
      town: Yup.string().required("Required"),
      country: Yup.string().required("Required"),
      postal_code: Yup.string().required("Required"),

      // dob: Yup.string().required("Required"),
      day: Yup.string().required("Required"),
      month: Yup.string().required("Required"),
      year: Yup.string().required("Required"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      image: data?.image || "",
      fullName: `${data?.first_name} ${data?.last_name}` || "",
      email: data?.email || "",

      mobile: data?.emergency_contactNo
        ? `${data?.emergency_countryCode}${data?.emergency_contactNo?.replace(
            data?.emergency_countryCode,
            ""
          )}`
        : "",
      highest_qualification:
        data?.qualification_list?.map((it) => {
          return {
            label: it?.qualification,
            value: it?.qualification,
          };
        }) || [],
      expertise_in_subject:
        data?.subject_list?.map((it) => {
          return { label: it?.subject, value: it?.subject };
        }) || [],
      award_name:
        data?.award_list?.map((it) => {
          return { label: it?.award, value: it?.award };
        }) || [],
      document: data?.document_list?.map((it) => it?.document) || [],
      toggle: "false",
      address: {
        property_number: data?.address?.property_number || "",
        property_name: data?.address?.property_name || "",
        street_number: data?.address?.street_number || "",
        town: data?.address?.town || "",
        country: data?.address?.country || "",
        postal_code: data?.address?.postal_code || "",
        // dob: data?.address?.dob || "",
        year: data?.dob
          ? moment(data?.dob).format("YYYY")
          : data?.dob_year
          ? moment(data?.dob_year).format("YYYY")
          : "",
        month: data?.dob
          ? new Date(data?.dob).getMonth() + 1
          : data?.dob_month
          ? new Date(data?.dob_month).getMonth() + 1
          : "",
        day: data?.dob
          ? new Date(data?.dob).getDay()
          : data?.dob_day
          ? new Date(data?.dob_day).getDay()
          : "",
      },
    },
    enableReinitialize: true,
    validationSchema: editSutdentSchema,
    onSubmit: (values) => {
      setSubmitting(true)
      console.log(values);
      const countryCode = values?.mobile
        ? formatPhoneNumberIntl(values?.mobile).split(" ")[0]
        : "";
      const payload = {
        image: values?.image,
        full_name: values?.fullName,
        email: values?.email,
        mobile: values?.mobile ? values?.mobile.replace(countryCode, "") : "",
        countryCode: values?.mobile ? countryCode : "",
        password: values?.password || "",
        // dob: moment(values?.dob).format("YYYY-MM-DD"),
        dob_day: values?.address?.day,
        dob_month: values?.address?.month,
        dob_year: values?.address?.year,
        awards: JSON.stringify(values?.award_name?.map((it) => it.value)),
        qualifications: JSON.stringify(
          values?.highest_qualification?.map((it) => it.value)
        ),
        expertise_in_subjects: JSON.stringify(
          values?.expertise_in_subject?.map((it) => it.value)
        ),
        document: JSON.stringify(values?.document),
        property_number: values?.address?.property_number,
        property_name: values?.address?.property_name,
        street_number: values?.address?.street_number,
        town: values?.address?.town,
        country: values?.address?.country,
        postal_code: values?.address?.postal_code,
      };
      console.log(payload, "Payload");

      dispatch(AddTeacherReducer(payload)).then((res) => {
        setSubmitting(false)
        if (res?.payload?.code === 1) {
          toast.success(res?.payload?.message);
          navigate(-1, { replace: true });
        } else {
          toast.error(res?.payload?.message);
        }
      });
    },
  });

  const isValid = (num) => {
    console.log(num, "NUm");
    if (num) {
      const valid = isValidPhoneNumber(num);

      console.log(valid, num);
      if (valid) {
        formik.setFieldValue("mobile", num);
      } else {
        formik.setFieldError("mobile", "Kindly enter a valid number.");
      }
      return valid;
    }
    return false;
  };

  if (loading) {
    return (
      <article className="col-lg-9 articleClass">
        <Loader />
      </article>
    );
  } else {
    return (
      <article className="col-lg-9 articleClass">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <BackButton />

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3>Student To Teacher</h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12">
              <FormikProvider value={formik}>
                <form className="form-style" onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 mb-4">
                      <div className="form-group text-center">
                        <div className="upload-btn-wrapper">
                          <button className="btn">
                            {time > 0 && isActive ? (
                              <Progress
                                type="circle"
                                width={80}
                                percent={time}
                              />
                            ) : (
                              <img
                                src={
                                  formik.values?.image
                                    ? formik.values?.image
                                    : "assets/images/upload-img.png"
                                }
                                alt=""
                              />
                            )}

                            {time > 0 && isActive ? null : (
                              <div className="camera-icon">
                                <i className="fa fa-camera"></i>
                              </div>
                            )}
                          </button>
                          <input
                            type="file"
                            onChange={(e) => {
                              if (e.target.files.length > 0) {
                                setIsActive(true);
                                S3FileUpload.uploadFile(
                                  e.target.files[0],
                                  config
                                )
                                  .then((data) => {
                                    formik.setFieldValue(
                                      "image",
                                      data.location
                                    );
                                    setTime(100);
                                    setIsActive(true);
                                    setTimeout(() => {
                                      setTime(0);
                                      setIsActive(false);
                                    }, 3000);
                                  })
                                  .catch((data) => console.log(data));
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Full Name</label>
                        {formik.errors.fullName && formik.touched.fullName && (
                          <span className="text-red ms-1">
                            {formik.errors.fullName}
                          </span>
                        )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Full Name"
                            name="fullName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.fullName}
                          />
                          <img
                            src="assets/images/First-Name.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Email</label>
                        {formik.errors.email && formik.touched.email && (
                          <span className="text-red ms-1">
                            {formik.errors.email}
                          </span>
                        )}
                        <div className="input-container">
                          <input
                            type="email"
                            className="form-control pl-5"
                            placeholder="Email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.email}
                          />
                          <img
                            src="assets/images/Email-Address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Mobile No.</label>
                        {formik.errors.mobile && (
                          <span className="text-red ms-1">
                            {formik.errors.mobile}
                          </span>
                        )}
                        <div className="input-container">
                          {/* <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Mobile Number"
                          /> */}

                          {/* <PhoneInput
                            country="gb"
                            inputProps={{
                              name: "mobile",
                            }}
                            enableSearch
                            value={
                              formik.values?.countryCode + formik.values?.mobile
                            }
                            onChange={(phone, country, e, formattedValue) => {
                              if (phone.length > 0) {
                                const raw = phone.slice(
                                  country?.dialCode?.length
                                );
                                formik.setFieldValue("mobile", raw);

                                formik.setFieldValue(
                                  "countryCode",
                                  formattedValue?.split(" ")[0]
                                );
                              } else {
                                formik.setFieldValue("mobile", "");

                                formik.setFieldValue("countryCode", "");
                              }
                            }}
                            onBlur={formik.handleBlur}
                            placeholder="Mobile Number"
                            containerClass="form-control signupClass"
                            inputClass="phoneInputClass"
                          /> */}

                          <Phone
                            onChange={(val) => {
                              if (val) {
                                isValid(val);
                              } else {
                                formik.setFieldValue("mobile", "");
                              }
                            }}
                            value={formik.values.mobile || undefined}
                            name={`mobile`}
                            className="form-control signupClass"
                            onBlur={isValid}
                          />
                          {/* <img
                            src="assets/images/mobile.png"
                            className="input-img"
                            alt=""
                          /> */}
                        </div>
                      </div>
                    </div>
                    {
                      <div className="col-sm-6 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Password</label>
                          {formik.errors.password &&
                            formik.touched.password && (
                              <span className="text-red ms-1">
                                {formik.errors.password}
                              </span>
                            )}
                          <div className="input-container">
                            <input
                              type={formik.values?.toggle ? "password" : "text"}
                              className="form-control pl-5"
                              placeholder="Set Password"
                              name="password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values?.password}
                            />
                            <img
                              src="assets/images/password.png"
                              className="input-img"
                              alt=""
                            />
                            <span
                              className={
                                formik.values?.toggle
                                  ? "pass-view field-icon "
                                  : "pass-hide field-icon "
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                formik.setFormikState((prevState) => {
                                  return {
                                    ...prevState,
                                    values: {
                                      ...prevState.values,
                                      toggle: !formik.values?.toggle,
                                    },
                                  };
                                });
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="col-sm-12 col-md-12 mt-5 mb-4">
                      <div className="form-group">
                        <h3>Qualification Info</h3>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Highest Qualification</label>
                        {formik.errors.highest_qualification &&
                          formik.touched.highest_qualification && (
                            <span className="text-red ms-1">
                              {formik.errors.highest_qualification}
                            </span>
                          )}
                        <div className="input-container">
                          {/* <select
                            className="form-control pl-5 form-select"
                            name="highest_qualification"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.highest_qualification}
                          >
                            <option value="">Highest Qualification</option>
                            <option value="BA">BA</option>
                            <option value="MA">MA</option>
                          </select> */}

                          {/* <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Type of qualification"
                            name="highest_qualification"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.highest_qualification}
                          /> */}
                          {
                            <CreatableSelectInput
                              className="form-control ps-4 py-2"
                              name="highest_qualification"
                              handleChange={(val) => {
                                formik.setFieldValue(
                                  "highest_qualification",
                                  val
                                );
                              }}
                              value={formik.values?.highest_qualification}
                              onBlur={formik.handleBlur}
                              placeholder="Type Qualifications and press tab to add"
                            />
                          }
                          <img
                            src="assets/images/edu-black.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Expertise in Subject</label>
                        {formik.errors.expertise_in_subject &&
                          formik.touched.expertise_in_subject && (
                            <span className="text-red ms-1">
                              {formik.errors.expertise_in_subject}
                            </span>
                          )}
                        <div className="input-container">
                          {/* <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Expertise in Subject"
                            name="expertise_in_subject"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.expertise_in_subject}
                          /> */}
                          {
                            <CreatableSelectInput
                              className="form-control ps-4 py-2"
                              name="expertise_in_subject"
                              handleChange={(val) => {
                                formik.setFieldValue(
                                  "expertise_in_subject",
                                  val
                                );
                              }}
                              value={formik.values?.expertise_in_subject}
                              onBlur={formik.handleBlur}
                              placeholder="Type Subject Expertise and press tab to add"
                            />
                          }
                          <img
                            src="assets/images/expertise.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Award</label>
                        {formik.errors.award_name &&
                          formik.touched.award_name && (
                            <span className="text-red ms-1">
                              {formik.errors.award_name}
                            </span>
                          )}
                        <div className="input-container">
                          {/* <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Award Name"
                            name="award_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.award_name}
                          /> */}
                          {
                            <CreatableSelectInput
                              className="form-control ps-4 py-2"
                              name="award_name"
                              handleChange={(val) => {
                                formik.setFieldValue("award_name", val);
                              }}
                              value={formik.values?.award_name}
                              onBlur={formik.handleBlur}
                              placeholder="Type Award Name and press tab to add"
                            />
                          }
                          <img
                            src="assets/images/award.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 mb-4">
                      <div className="form-group">
                        <h3>Address Details</h3>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Property Number</label>
                        {formik.errors?.address?.property_number &&
                          formik.touched?.address?.property_number && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.property_number}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Property Number"
                            name="address.property_number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.address.property_number}
                          />

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Property Name</label>
                        {formik.errors?.address?.property_name &&
                          formik.touched?.address?.property_name && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.property_name}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Property Name"
                            name="address.property_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.address.property_name}
                          />

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Street Name</label>
                        {formik.errors?.address?.street_number &&
                          formik.touched?.address?.street_number && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.street_number}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Street Name"
                            name="address.street_number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.address.street_number}
                          />

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Town</label>
                        {formik.errors?.address?.town &&
                          formik.touched?.address?.town && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.town}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Town"
                            name="address.town"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.address.town}
                          />

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Country</label>
                        {formik.errors?.address?.country &&
                          formik.touched?.address?.country && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.country}
                            </span>
                          )}
                        <div className="input-container">
                          <select
                            className="form-control pl-5"
                            placeholder="Country"
                            name="address.country"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.address.country}
                          >
                            <option value="">Select Country</option>
                            <option value="U.K">U.K</option>
                          </select>

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Postal Code</label>
                        {formik.errors?.address?.postal_code &&
                          formik.touched?.address?.postal_code && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.postal_code}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Postal Code"
                            name="address.postal_code"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.address.postal_code}
                          />

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 mb-2">
                      <label className="fw-bold">Date of Birth</label>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Year</label>

                        <div className="input-container">
                          <select
                            className="form-control pl-5"
                            placeholder="Year"
                            name="address.year"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.address.year}
                          >
                            <option value="">Select Year</option>
                            {years?.map((item, i) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {formik.errors?.address?.year &&
                        formik.touched?.address?.year && (
                          <span className="text-red ms-1">
                            {formik.errors?.address?.year}
                          </span>
                        )}
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Month</label>

                        <div className="input-container">
                          <select
                            className="form-control pl-5"
                            placeholder="Month"
                            name="address.month"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.address.month}
                          >
                            <option value="">Select Month</option>
                            {months?.map((item, i) => (
                              <option value={item?.id}>{item?.value}</option>
                            ))}
                          </select>

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {formik.errors?.address?.month &&
                        formik.touched?.address?.month && (
                          <span className="text-red ms-1">
                            {formik.errors?.address?.month}
                          </span>
                        )}
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Day</label>

                        <div className="input-container">
                          <select
                            className="form-control pl-5"
                            placeholder="Select Day"
                            name="address.day"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.address.day}
                          >
                            <option value="">Select Day</option>
                            {[
                              ...Array(
                                formik.values?.address.month === "2" ? 28 : 31
                              ).keys(),
                            ].map((item) => {
                              return (
                                <option value={item + 1}>{item + 1}</option>
                              );
                            })}
                          </select>

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {formik.errors?.address?.month &&
                        formik.touched?.address?.month && (
                          <span className="text-red ms-1">
                            {formik.errors?.address?.month}
                          </span>
                        )}
                    </div>

                    {/* <div className="col-sm-6 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Date of Birth</label>

                          {formik.errors?.address?.dob && formik.touched?.address?.dob && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.dob}
                            </span>
                          )}
                          <div className="input-container">
                            <DatePicker
                              containerClassName="form-control pl-5"
                              value={formik.values?.address.dob || null}
                              onChange={(val) => {
                                formik.setFieldValue("address.dob", val.toDate());
                              }}
                              placeholder="DOB"
                              inputClass="dateInputClass"
                              maxDate={new Date()}
                              name="address.dob"
                            />
                            <img
                              src="assets/images/Date-of-Birth.png"
                              className="input-img"
                              alt=""
                            />
                            <img
                              src="assets/images/choose-date.png"
                              className="field-icon"
                              alt=""
                            />
                          </div>
                        </div>
                      </div> */}

                    <div className="col-sm-12 col-md-12 mt-5 mb-4">
                      <div className="form-group">
                        <h5>
                          <img src="assets/images/document.png" alt="" />
                          Upload Documents
                        </h5>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-4">
                      <div className="row">
                        {formik.values?.document?.map((item, i) => (
                          <div className="form-group col-6 col-sm-4 col-md-4 col-lg-4 mb-4 position-relative ">
                            <div className="upload-btn-wrapper documents ">
                              <button className="btn">
                                <img
                                  src={item || "assets/images/upload-doc.png"}
                                  alt=""
                                  className="rounded-0"
                                />
                              </button>
                            </div>
                            <img
                              src={"assets/images/delete.png"}
                              alt=""
                              className="rounded-0"
                              onClick={() => {
                                let val = [...formik.values?.document];
                                val.splice(i, 1);
                                formik.setFieldValue("document", val);
                              }}
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                bottom: -20,
                                right: 0,
                              }}
                            />
                          </div>
                        ))}

                        <div className="form-group col-6 col-sm-4 col-md-4 col-lg-4 mb-4 ps-4">
                          <div className="upload-btn-wrapper documents">
                            <button className="btn">
                              {time > 0 && isActive ? (
                                <Progress
                                  type="circle"
                                  width={80}
                                  percent={time}
                                />
                              ) : (
                                <img
                                  src="assets/images/upload-doc.png"
                                  alt=""
                                  className="rounded-0"
                                />
                              )}
                            </button>
                            <input
                              type="file"
                              name="myfile"
                              onChange={(e) => {
                                if (e.target.files.length > 0) {
                                  setIsActive(true);
                                  S3FileUpload.uploadFile(
                                    e.target.files[0],
                                    config
                                  )
                                    .then((data) => {
                                      formik.setFieldValue("document", [
                                        ...formik.values?.document,
                                        data.location,
                                      ]);
                                      setTime(100);
                                      setIsActive(true);
                                      setTimeout(() => {
                                        setTime(0);
                                        setIsActive(false);
                                      }, 3000);
                                    })
                                    .catch((data) => console.log(data));
                                }
                              }}
                            />
                          </div>
                        </div>
                        {formik.errors.document && formik.touched.document && (
                          <div className="text-red">
                            {formik.errors.document}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 text-center">
                      <button type="submit" className="btn btn-save" disabled={submitting}>
                        {submitting ? <Loader /> : "Done"}
                      </button>
                    </div>
                  </div>
                </form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default StudentToTeacher;
