import { deleteData, getData, getDataforUrl, postFormData } from "./index";

export const addNewsLetter = async (payload) => {
  return postFormData("admin/addNewsLetter", payload).then((data) => {
    return data;
  });
};
export const saveEflyerEmail = async (payload) => {
  return postFormData("admin/saveEflyerEmail", payload).then((data) => {
    return data;
  });
};

export const newsLetterList = async (payload) => {
  return getData("admin/newsLetterList", payload).then((data) => {
    return data;
  });
};

export const GetEflyerEmailList = async (payload) => {
  return getData("admin/eflyerEmailList", payload).then((data) => {
    return data;
  });
};

export const getEflyerEmaiLDetails = async (payload) => {
  return getDataforUrl("admin/getEflyerEmaiLDetails", payload).then((data) => {
    return data;
  });
};
export const getNewsLetterDetails = async (payload) => {
  return getDataforUrl("admin/getNewsLetterDetails", payload).then((data) => {
    return data;
  });
};

export const deleteNewsLetter = async (payload) => {
  return deleteData("admin/deleteNewsLetter", payload).then((data) => {
    return data;
  });
};

export const sendNewsLetterToUsers = async (payload) => {
  return postFormData("admin/sendNewsLetterToUsers", payload).then((data) => {
    return data;
  });
};
