import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { removeStudent } from "../../api/student";
import { removeUser } from "../../api/user";
import Loader from "../../component/Loader";
import Paginations from "../../component/Pagination";
import { variants } from "../../constant/Variants";
import { areaCodeListingSelector, FetchAreaCodeList } from "../areacode/slice";
import { FetchDisciplineList } from "../discipline/DisciplineSlice";
import { SchoolListReducer } from "../slice";
import DeleteModal from "./../../component/Modal/Delete";
import {
  FetchStudentList,
  list,
  studentListSelector,
  totalStudentCountSelector,
} from "./studentslice";

function Student() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lists = useSelector(studentListSelector);
  const total = useSelector(totalStudentCountSelector);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  // const [schoolId, setSchoolId] = useState("");
  // const [areacode, setAreacode] = useState("");
  // const [discipline, setDiscipline] = useState("");
  // const [membership_status, setMembership_status] = useState("");
  const [age, setAge] = useState("");
  const arealist = useSelector(areaCodeListingSelector);
  const Schoollist = useSelector((state) => state.main.schools);
  const disciplineList = useSelector((state) => state.discipline.list);
  const [filters, setFilters] = useState({
    schoolId: "",
    areacode: "",
    discipline: "",
    membership_status: "",
    age: "",
    student_type: "",
  });

  const handlePage = (val) => {
    setPage(val);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const getList = useCallback(() => {
    setLoading(true);
    dispatch(
      FetchStudentList({
        page: page,
        limit: 10,
        search: search,
        discipline: filters?.discipline,
        schoolId: filters?.schoolId,
        areacode: filters?.areacode,
        trialAccountStatus: filters?.membership_status,
        age: filters?.age,
        student_type: filters?.student_type,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [
    dispatch,
    page,
    search,
    filters.discipline,
    filters.schoolId,
    filters.areacode,
    filters.membership_status,
    filters.student_type,
    filters.age,
  ]);

  useEffect(() => {
    getList();
  }, [getList]);

  const confirmDelete = () => {
    removeStudent({ studentId: delId }).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getList();
      } else {
        toast.error(res?.message);
      }
    });
  };

  useEffect(() => {
    return () => {
      dispatch(list(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (filters?.areacode) {
      dispatch(
        SchoolListReducer({
          page: page,
          limit: 1000,
          search: search,
          areacode: filters?.areacode,
        })
      );
    }
  }, [dispatch, page, search, filters.areacode]);

  useEffect(() => {
    dispatch(FetchAreaCodeList({ page: 1, limit: 100000000 }));
    dispatch(FetchDisciplineList({ page: 1, limit: 10000000000 }));
  }, [dispatch]);

  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="link-active shadow">
              <p>
                <img src="assets/images/users-selected.png" alt="" /> Total
                users {total}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search student by name"
                  value={search}
                  onChange={(e) => {
                    setPage(1);

                    setSearch(e.target.value);
                  }}
                />
              </form>
              <Link to="/add-student" className="">
                <img
                  src="assets/images/addBtn.png"
                  alt=""
                  className="add-new"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-sm-9 col-md-9 col-lg-10">
            <ul
              className="nav nav-pills group-nav mb-3 mt-0"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-new-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-new"
                  type="button"
                  role="tab"
                  aria-controls="pills-new"
                  aria-selected="true"
                  onClick={() => {
                    navigate("/users");
                  }}
                >
                  Users
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-completed-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-completed"
                  type="button"
                  role="tab"
                  aria-controls="pills-completed"
                  aria-selected="false"
                >
                  Students
                </button>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 my-2">
            <div className="dropSchool">
              <select
                className="form-control form-select"
                onChange={(e) => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      areacode: e.target.value,
                    };
                  });
                }}
                value={filters?.areacode}
              >
                <option value="">Select Area</option>
                {arealist?.map((item, i) => (
                  <option value={item?.id} key={i}>
                    {item?.areacode}
                  </option>
                ))}
              </select>
              {filters?.areacode && (
                <select
                  className="form-control form-select"
                  onChange={(e) => {
                    setFilters((prev) => {
                      return {
                        ...prev,
                        schoolId: e.target.value,
                      };
                    });
                  }}
                  value={filters?.schooldId}
                >
                  <option value="">Select Venue</option>
                  {Schoollist?.map((item, i) => (
                    <option value={item?.id} key={i}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              )}

              <select
                className="form-control form-select"
                onChange={(e) => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      discipline: e.target.value,
                    };
                  });
                }}
                value={filters?.discipline}
              >
                <option value="">Select Discipline</option>
                {disciplineList?.map((item, i) => (
                  <option value={item?.id} key={i}>
                    {item?.name}
                  </option>
                ))}
              </select>
              <select
                className="form-control form-select"
                onChange={(e) => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      membership_status: e.target.value,
                    };
                  });
                }}
                value={filters?.membership_status}
              >
                <option value="">Select Membership Status</option>
                <option value="1">Trail</option>
                <option value="2">Active</option>
                <option value="3">Inactive</option>
              </select>
              <select
                className="form-control form-select"
                onChange={(e) => {
                  setPage(1);
                  setFilters((prev) => {
                    return {
                      ...prev,
                      student_type: e.target.value,
                    };
                  });
                }}
                value={filters?.student_type}
              >
                <option value="">Select Student Type</option>
                <option value="child">Child</option>
                <option value="adult">Adult</option>
              </select>
              <input
                type="number"
                className="form-control"
                value={filters?.age}
                onChange={(e) => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      age: e.target.value,
                    };
                  });
                }}
                placeholder={"Search Student By Age"}
              />
              <button
                type="button"
                className="btn btn-save p-2 fs-6"
                onClick={() => {
                  setFilters({
                    schoolId: "",
                    areacode: "",
                    discipline: "",
                    membership_status: "",
                    age: "",
                  });
                }}
              >
                Reset
              </button>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">User ID</th>
                    <th scope="col">User Email</th>

                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Student Type</th>
                    <th scope="col">Discipline</th>
                    <th scope="col">Date of Birth</th>
                    <th scope="col">Emergency Contact Number</th>
                    <th scope="col">Venue</th>
                    <th scope="col">Membership Status</th>

                    <th scope="col">action</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td colSpan={12}>
                      <Loader />
                    </td>
                  ) : (
                    <>
                      {lists?.length > 0 ? (
                        lists?.map((item, i) => {
                          return (
                            <tr variants={variants.card} key={i}>
                              <td>{item?.id || "NA"}</td>
                              <td>{item?.parent_details?.id || "NA"}</td>
                              <td>
                                {item?.parent_details?.email?.toLowerCase() ||
                                  "NA"}
                              </td>
                              <td>
                                {item?.first_name ? item?.first_name : "NA"}
                              </td>
                              <td>
                                {item?.last_name ? item?.last_name : "NA"}
                              </td>
                              <td>
                                {item?.student_type ? item?.student_type : "NA"}
                              </td>
                              <td>
                                {item?.discipline_details
                                  ? item?.discipline_details?.name
                                  : "NA"}
                              </td>
                              <td>
                                {item?.dob && item?.dob !== "0000-00-00"
                                  ? moment(item?.dob).format("DD MMM YYYY")
                                  : item?.dob_year
                                  ? moment(
                                      `${item?.dob_year}-${item?.dob_month}-${item?.dob_day}`
                                    ).format("DD MMM YYYY")
                                  : "NA"}
                              </td>
                              <td>
                                {item?.emergency_contactNo
                                  ? `${item?.emergency_countryCode}${item?.emergency_contactNo}`
                                  : "NA"}
                              </td>
                              {/* <td>
                            {item?.address_details
                              ? `${item?.address_details?.property_number}, ${
                                  item?.address_details?.property_name
                                    ? item?.address_details?.property_name +
                                      ", "
                                    : ", "
                                } ${item?.address_details?.street_number}, ${
                                  item?.address_details?.town
                                }, ${item?.address_details?.country}-${
                                  item?.address_details?.postal_code
                                }`
                              : "NA"}
                          </td> */}
                              <td>
                                {item?.school_details
                                  ? `${item?.school_details?.name}, ${item?.school_details?.address}`
                                  : "NA"}
                              </td>
                              <td>
                                {item?.trialAccountStatus === "1"
                                  ? "Trial"
                                  : moment(
                                      item?.membership_details?.valid_till
                                    ).diff(moment()) < 0
                                  ? "Inactive"
                                  : item?.trialAccountStatus === "3"
                                  ? "Active"
                                  : "NA"}
                              </td>

                              <td>
                                <div className="d-flex justify-content-center action align-items-center">
                                  <button className="btn" title="View Student">
                                    <Link to={`/student/${item?.id}`}>
                                      <img
                                        src="assets/images/view.png"
                                        alt=""
                                      />
                                    </Link>
                                  </button>
                                  <button className="btn">
                                    <Link to={`/edit-student/${item?.id}`}>
                                      <img
                                        src="assets/images/edit.png"
                                        alt=""
                                      />
                                    </Link>
                                  </button>
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      setDelId(item.id);
                                      handleShow();
                                    }}
                                    title="Delete Student"
                                  >
                                    <img
                                      src="assets/images/delete.png"
                                      alt=""
                                    />
                                  </button>
                                  <span
                                    className="px-1 cursor-pointer"
                                    title="Make Teacher"
                                    onClick={() => {
                                      navigate(
                                        `/student-to-teacher/${item?.id}`
                                      );
                                    }}
                                  >
                                    <i
                                      class="fa fa-exchange fa-xl theme-color"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={12}>No data found.</td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {lists?.length > 0 && (
            <Paginations
              handlePage={handlePage}
              page={page}
              total={total && total}
            />
          )}
        </div>
      </div>
      <DeleteModal
        page="Student"
        show={show}
        handleShow={handleShow}
        cancel={() => {
          setShow(false);
        }}
        confirmDelete={confirmDelete}
      />
    </article>
  );
}

export default Student;
