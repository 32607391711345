import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getStudentDetails, studentList } from "../../api/student";
import { getUserDetails, userList } from "../../api/user";

const initialState = {
  list: [],
  total: 0,
  detail: null,
};

export const StudentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    list: (state, action) => {
      state.list = action.payload;
    },
    totalCount: (state, action) => {
      state.total = action.payload;
    },
    details: (state, action) => {
      state.detail = action.payload;
    },
  },
});

export const { list, totalCount, details } = StudentSlice.actions;

export const totalStudentCountSelector = (state) => state.student.total;
export const studentListSelector = (state) => state.student.list;

export const FetchStudentList = createAsyncThunk(
  "FetchStudentList",
  async (obj, { dispatch }) => {
    try {
      const response = await studentList(obj);
      if (response?.code === 1) {
        dispatch(list(response?.data?.student_list));
        dispatch(totalCount(response?.data?.total_student));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const studentDetailSelector = (state) => state?.student?.detail;

export const FetchStudentDetails = createAsyncThunk(
  "FetchStudentDetails",
  async (obj, { dispatch }) => {
    try {
      const response = await getStudentDetails(obj);
      if (response?.code === 1) {
        dispatch(details(response?.data?.student_details));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export default StudentSlice.reducer;
