import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  AddTeacherApi,
  TeacherListApi,
  TeacherStatus,
} from "./../../api/teacher";

const initialState = {
  list: [],
  total: 10,
  details: {},
};

export const GetTeacher = createAsyncThunk(
  "GetTeacher",
  async (obj, { dispatch }) => {
    try {
      const response = await TeacherListApi(obj);

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const AddTeacherReducer = createAsyncThunk(
  "AddTeacherReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await AddTeacherApi(obj);
      dispatch(GetTeacher({ page: 1, limit: 10, search: "" }));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const ChangeTeacherStatus = createAsyncThunk(
  "ChangeTeacherStatus",
  async (obj, { dispatch }) => {
    try {
      const response = await TeacherStatus(obj);
      dispatch(GetTeacher({ page: 1, limit: 10, search: "" }));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const TeacherSlice = createSlice({
  name: "teacher",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
  extraReducers: {
    [GetTeacher.fulfilled]: (state, action) => {
      state.list = action?.payload?.data?.teacher_list;
      state.total_active_teacher = action?.payload?.data?.total_active_teacher;
      state.total_inactive_teacher =
        action?.payload?.data?.total_inactive_teacher;
      state.total_teacher = action?.payload?.data?.total_teacher;
    },
  },
});

export const totalTeacher = (state) => state.teacher.total_teacher;
export const activeTeacher = (state) => state.teacher.total_active_teacher;
export const inactiveTeacher = (state) => state.teacher.total_inactive_teacher;

export const { reducerName } = TeacherSlice.actions;

export default TeacherSlice.reducer;
