import React from "react";

function ViewImportExport() {
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>Data Details</h3>
          </div>
        </div>
        <div className="row mt-5 px-md-5">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-5">
            <div className="bg-white p-4 rounded h-100 shadow position-relative">
              <div className="row">
                <div className="col-12 col-sm-10 col-md-10 col-lg-10">
                  <div className="event-box-flex align-items-normal">
                    <span>
                      <img
                        src="assets/images/kick.png"
                        className="event-img"
                        alt=""
                      />
                    </span>
                    <span>
                      <h4>kick boxing</h4>
                      <p className="mb-0 d-flex flex-wrap gap-row-col">
                        <span>
                          <img
                            src="assets/images/Date-of-Birth.png"
                            className="evt-dtl-icon"
                            alt=""
                          />
                          7 mar 2022
                        </span>
                        <span>
                          <img
                            src="assets/images/time.png"
                            className="evt-dtl-icon"
                            alt=""
                          />
                          5:00pm
                        </span>
                        <span>
                          <img
                            src="assets/images/address.png"
                            className="evt-dtl-icon"
                            alt=""
                          />
                          liverpool
                        </span>
                        <span>
                          <img
                            src="assets/images/total-seats.png"
                            className="evt-dtl-icon"
                            alt=""
                          />
                          40
                        </span>
                        <span>
                          <img
                            src="assets/images/First-Name.png"
                            className="evt-dtl-icon"
                            alt=""
                          />
                          adult
                        </span>
                      </p>
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-2 col-md-2 col-lg-2">
                  <span className="event-price">
                    <i className="fa fa-gbp" aria-hidden="true"></i> 35
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
            <div className="bg-white p-4 rounded h-100 shadow">
              <h3>About Event</h3>
              <p className="mt-2">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim. Donec pede justo,
                fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo,
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default ViewImportExport;
