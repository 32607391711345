import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import S3FileUpload from "react-s3";
import { config } from "../../utils";
import { Progress } from "react-sweet-progress";
import { fetchSizeList, getSizeListSelector } from "../sizes/slice";
import { useSelector } from "react-redux";
import Loader from "./../../component/Loader";
import { addMembership } from "./../../api/memberships";
import {
  fetchMembershipdetails,
  membershipDetails,
  membershipDetailSelector,
} from "./MembershipSlice";
import { toast } from "react-toastify";
import moment from "moment";
import { FetchDisciplineList } from "../discipline/DisciplineSlice";
import BackButton from "./../../component/BackButton";

function AddMembership() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const sizeList = useSelector(getSizeListSelector);
  const details = useSelector(membershipDetailSelector);
  const [time, setTime] = useState(0);
  const getList = useCallback(() => {}, []);
  const [loading, setLoading] = useState(false);
  const disciplines = useSelector((state) => state.discipline.list);
  console.log(disciplines);
  const [submitting, setSubmitting] = useState(false);

  const getDisciplines = useCallback(() => {
    dispatch(FetchDisciplineList({ page: 1, limit: 1000000 }));
  }, [dispatch]);

  useEffect(() => {
    getDisciplines();
  }, [getDisciplines]);

  useEffect(() => {
    getList();
  }, [dispatch, getList]);
  useEffect(() => {
    dispatch(fetchSizeList({}));
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(fetchMembershipdetails(id)).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(membershipDetails(null));
    };
  }, [dispatch]);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <article className="col-lg-9 articleClass">
        <div className="article-body store-bd-gy">
          <BackButton />

          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3>Add New Membership</h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12">
              <Formik
                initialValues={{
                  student_type: details?.student_type || "",
                  discipline: details?.discipline || "",
                  name: details?.name || "",
                  child_price: details?.child_price || "",
                  adult_price: details?.adult_price || "",
                  duration: details?.duration || "",
                  time_period: details?.time_period || "",
                  about: details?.about || "",
                  terms_and_condition: details?.terms_and_condition || "",
                  image: details?.image || "",
                }}
                enableReinitialize={true}
                validationSchema={Yup.object({
                  image: Yup.string().notRequired(),
                  name: Yup.string().required("Required"),
                  discipline: Yup.string().required("Required"),
                  student_type: Yup.string().required("Required"),

                  child_price: Yup.number()
                    .positive("Kindly enter a valid number.")

                    .typeError("Kindly enter a valid number.")
                    .notRequired()
                    .when("student_type", {
                      is: (value) => value === "child",
                      then: Yup.number()
                        .positive("Kindly enter a valid number.")

                        .typeError("Kindly enter a valid number.")
                        .required("Required"),
                    }),
                  adult_price: Yup.number()
                    .positive("Kindly enter a valid number.")

                    .typeError("Kindly enter a valid number.")
                    .notRequired()
                    .when("student_type", {
                      is: (value) => value === "adult",
                      then: Yup.number()
                        .positive("Kindly enter a valid number.")

                        .typeError("Kindly enter a valid number.")
                        .required("Required"),
                    }),
                  duration: Yup.number()
                    .moreThan(0, "Field can not be 0.")
                    .lessThan(10000, "Field can not be more than 4 digits.")
                    .positive("Kindly enter a valid number.")
                    .integer("Kindly enter a valid number.")
                    .typeError("Kindly enter a valid number.")
                    .required("Required"),
                  time_period: Yup.string().required("Required"),
                  about: Yup.string().required("Required"),
                  terms_and_condition: Yup.string().required("Required"),
                })}
                onSubmit={(values) => {
                  setSubmitting(true);
                  const today = moment();
                  const validTillDate = moment().add(
                    values.duration,
                    values.time_period
                  );

                  const noOfDays = validTillDate.diff(today, "days");
                  console.log(noOfDays);
                  const payload = {
                    ...values,
                    no_of_days: noOfDays || "",
                    membership_id: id ? id : "",
                  };

                  addMembership(payload).then((res) => {
                    setSubmitting(false);
                    if (res?.code === 1) {
                      toast.success(res?.message);
                      navigate("/membership", { replace: true });
                    } else {
                      toast.error(res?.message);
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFormikState,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <form className="form-style" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 mb-4">
                        <div className="form-group text-center">
                          <div className="upload-btn-wrapper">
                            <button className="btn">
                              {time > 0 && isActive ? (
                                <Progress
                                  type="circle"
                                  width={80}
                                  percent={time}
                                />
                              ) : (
                                <img
                                  src={
                                    values.image
                                      ? values.image
                                      : "assets/images/upload-membership.png"
                                  }
                                  alt=""
                                />
                              )}

                              {time > 0 && isActive ? null : (
                                <div className="camera-icon">
                                  <i className="fa fa-camera"></i>
                                </div>
                              )}
                            </button>
                            <input
                              type="file"
                              onChange={(e) => {
                                if (e.target.files.length > 0) {
                                  setIsActive(true);
                                  S3FileUpload.uploadFile(
                                    e.target.files[0],
                                    config
                                  ).then((data) => {
                                    setFieldValue("image", data.location);
                                    setTime(100);
                                    setIsActive(true);
                                    setTimeout(() => {
                                      setTime(0);
                                      setIsActive(false);
                                    }, 3000);
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Membership Name</label>
                          {errors.name && touched.name && (
                            <span className="text-danger ms-1">
                              {errors.name}
                            </span>
                          )}
                          <div className="input-container">
                            <input
                              type="text"
                              className="form-control pl-5"
                              placeholder="Membership Name"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                            />
                            <img
                              src="assets/images/membership-name.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Membership Level</label>
                          {errors.student_type && touched.student_type && (
                            <span className="text-danger ms-1">
                              {errors.student_type}
                            </span>
                          )}
                          <div className="checkboxs">
                            <label>
                              <input
                                type="radio"
                                value="child"
                                name="student_type"
                                checked={values.student_type === "child"}
                                onChange={(e) => {
                                  setFieldValue("student_type", e.target.value);
                                  setFieldValue("adult_price", "");
                                }}
                              />
                              <div className="div-bg">
                                <p>
                                  <span>child</span>
                                </p>
                              </div>
                            </label>

                            <label>
                              <input
                                type="radio"
                                value="adult"
                                name="student_type"
                                checked={values.student_type === "adult"}
                                onChange={(e) => {
                                  setFieldValue("student_type", e.target.value);
                                  setFieldValue("child_price", "");
                                }}
                              />
                              <div className="div-bg">
                                <p>
                                  <span>adult</span>
                                </p>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Discipline</label>
                          {errors.discipline && touched.discipline && (
                            <span className="text-danger ms-1">
                              {errors.discipline}
                            </span>
                          )}
                          <div className="checkboxs">
                            {disciplines?.map((disciplin, ind) => {
                              return (
                                <label>
                                  <input
                                    type="radio"
                                    value={disciplin?.id}
                                    name="discipline"
                                    checked={
                                      values.discipline.toString() ===
                                      disciplin?.id?.toString()
                                    }
                                    onChange={(e) => {
                                      setFieldValue(
                                        "discipline",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <div className="div-bg">
                                    <p>
                                      <span>{disciplin?.name}</span>
                                    </p>
                                  </div>
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-sm-6 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Fixed Price</label>
                          {errors.total_price && touched.total_price && (
                            <span className="text-danger ms-1">
                              {errors.total_price}
                            </span>
                          )}
                          <div className="input-container">
                            <input
                              type="text"
                              className="form-control pl-5"
                              placeholder="Membership Price"
                              name="price"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.total_price}
                            />
                            <img
                              src="assets/images/price.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                        </div>
                      </div> */}
                      {values?.student_type === "child" && (
                        <div className="col-sm-6 col-md-6 mb-4">
                          <div className="form-group">
                            <label className="fw-bold">Child Price</label>
                            {errors.child_price && touched.child_price && (
                              <span className="text-danger ms-1">
                                {errors.child_price}
                              </span>
                            )}
                            <div className="input-container">
                              <input
                                type="text"
                                className="form-control pl-5"
                                placeholder="Membership Price"
                                name="child_price"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.child_price}
                              />
                              <img
                                src="assets/images/price.png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {values.student_type === "adult" && (
                        <div className="col-sm-6 col-md-6 mb-4">
                          <div className="form-group">
                            <label className="fw-bold">Adult Price</label>
                            {errors.adult_price && touched.adult_price && (
                              <span className="text-danger ms-1">
                                {errors.adult_price}
                              </span>
                            )}
                            <div className="input-container">
                              <input
                                type="text"
                                className="form-control pl-5"
                                placeholder="Membership Price"
                                name="adult_price"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.adult_price}
                              />
                              <img
                                src="assets/images/price.png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-sm-6 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Duration</label>

                          {errors.duration && touched.duration && (
                            <span className="text-danger ms-1">
                              {errors.duration}
                            </span>
                          )}
                          <div className="input-container">
                            {/* <DatePicker
                            containerClassName="form-control pl-5"
                            value={values.validTill}
                            onChange={(val) => {
                              setFieldValue("validTill", val.toDate());
                            }}
                            placeholder="Valid Till"
                            inputClass="dateInputClass"
                            maxDate={new Date()}
                            name="validTill"
                          /> */}
                            <input
                              type="number"
                              className="form-control pl-5"
                              placeholder="Enter a number of days/weeks/years"
                              name="duration"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.duration}
                            />
                            <img
                              src="assets/images/Date-of-Birth.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Time Period</label>

                          {errors.time_period && touched.time_period && (
                            <span className="text-danger ms-1">
                              {errors.time_period}
                            </span>
                          )}
                          <div className="input-container">
                            <select
                              className="form-control pl-5 form-select"
                              name="time_period"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.time_period}
                            >
                              <option value="">Select Time Period</option>
                              <option value="day">
                                {values?.duration > 1 ? "Days" : "Day"}
                              </option>
                              <option value="weeks">
                                {values?.duration > 1 ? "Weeks" : "Week"}
                              </option>
                              <option value="year">
                                {values?.duration > 1 ? "Years" : "Year"}
                              </option>
                            </select>
                            <img
                              src="assets/images/Date-of-Birth.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-sm-12 col-md-12 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Sizes</label>

                          {errors.sizes && touched.sizes && (
                            <span className="text-danger ms-1">
                              {errors.sizes}
                            </span>
                          )}
                          <div className="input-container">
                            {
                              <Select
                                isMulti
                                name="sizes"
                                options={
                                  sizeList
                                    ?.filter(
                                      (it) =>
                                        it.student_type === values.student_type
                                    )
                                    ?.filter(
                                      (it) =>
                                        it.discipline === values.discipline
                                    ) || undefined
                                }
                                getOptionLabel={(option) => option.size}
                                getOptionValue={(option) => option.id}
                                className="form-control pl-5"
                                styles={customStyles}
                                placeholder="Select sizes"
                                value={values?.sizes || undefined}
                                onChange={(arr) => {
                                  setFieldValue("sizes", arr);
                                }}
                              />
                            }
                            <img
                              src="assets/images/Date-of-Birth.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className="col-sm-12 col-md-12 mt-4 mb-2">
                        <div className="form-group d-flex">
                          <h5>About Membership</h5>
                          {errors.about && touched.about && (
                            <div className="text-danger ms-1">
                              {errors.about}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 mb-4">
                        <div className="form-group">
                          <textarea
                            className="textarea-control p-3"
                            rows="7"
                            placeholder="Write here..."
                            name="about"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.about}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 mt-4 mb-2">
                        <div className="form-group d-flex">
                          <h5>Add Terms & Conditions</h5>
                          {errors.terms_and_condition &&
                            touched.terms_and_condition && (
                              <span className="text-danger ms-1">
                                {errors.terms_and_condition}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 mb-4">
                        <div className="form-group">
                          <textarea
                            className="textarea-control p-3"
                            rows="7"
                            placeholder="Write here..."
                            name="terms_and_condition"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.terms_and_condition}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-save"
                          disabled={submitting}
                        >
                          {submitting ? <Loader /> : "Done"}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default AddMembership;
