import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getDataforUrl } from "../../api";
import { addConsent } from "../../api/consent";
import BackButton from "../../component/BackButton";
import CreatableSelectInput from "../../component/CreatableSelectField/CreatableSelectInput";
import Loader from "../../component/Loader";
import { consentDetails } from "./ConsentSlice";

function EditConsent() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const detail = useSelector((store) => store.consent.details);
  const schema = Yup.object({
    label: Yup.string().required("Required"),
    type: Yup.string().nullable().optional().notRequired(),
    input_field_type: Yup.string().nullable().optional().notRequired(),
    values: Yup.array()
      .notRequired()
      .nullable()
      .optional()
      .when("input_field_type", {
        is: (value) => value !== "input_field",
        then: Yup.array().min(1, "Required"),
      }),
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    defaultValues: {
      values: [],
    },
  });
  useEffect(() => {
    if (id) {
      setLoading(true);
      getDataforUrl("admin/getConsentDetails", id).then((res) => {
        if (res?.code === 1) {
          let detail = res?.data;
          reset({
            label: detail?.label,
            type: "required",
            input_field_type: "radio",
            values: detail?.values?.map((it) => {
              return { label: it?.value, value: it?.value };
            }),
          });
        }
      });

      setLoading(false);
    }
  }, [dispatch, id, reset]);

  const onSubmit = (data) => {
    console.log(data);
    const payload = {
      label: data?.label,
      type: data?.type,
      input_field_type: data?.input_field_type,
      values: JSON.stringify(data?.values?.map((it) => it?.value)) || [],
      consent_id: id ? id : "",
    };
    console.log(payload);
    addConsent(payload).then((res) => {
      if (res?.code === 1) {
        navigate("/consents", { replace: true });
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    });
  };

  useEffect(() => {
    return () => {
      dispatch(consentDetails(null));
    };
  }, [dispatch]);

  const watchValues = watch("values");
  if (loading) {
    return (
      <article className="col-lg-9 articleClass">
        <Loader />
      </article>
    );
  } else {
    return (
      <article className="col-lg-9 articleClass">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <BackButton />

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3>{id ? "Edit" : " New"} Consent</h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12">
              <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-sm-6 col-md-6 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Label</label>
                      {errors?.label && (
                        <span className="text-red ms-1">
                          {errors?.label?.message}
                        </span>
                      )}
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Label Text"
                          name="name"
                          {...register("label")}
                        />
                        <img
                          src="assets/images/event-name.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6 mb-4 d-none">
                    <div className="form-group">
                      <label className="fw-bold">Field Type</label>
                      {errors?.input_field_type && (
                        <span className="text-red ms-1">
                          {errors?.input_field_type?.message}
                        </span>
                      )}
                      <div className="input-container">
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <select
                              className="form-control pl-5"
                              onChange={(e) => {
                                onChange(e.target.value);
                                if (e.target.value === "input_field") {
                                  setValue("values", []);
                                }
                              }}
                              value={value}
                            >
                              <option value="">Select Input Field Type</option>
                              <option value="radio">Radio</option>
                              <option value="checkbox">Checkbox</option>
                              <option value="input_field">Input</option>
                            </select>
                          )}
                          control={control}
                          name="input_field_type"
                          // defaultValue={null}
                        />
                        <img
                          src="assets/images/event-name.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 mb-4 d-none">
                    <div className="form-group">
                      <label className="fw-bold">Type</label>
                      {errors?.type && (
                        <span className="text-red ms-1">
                          {errors?.type?.message}
                        </span>
                      )}
                      <div className="input-container">
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <select
                              className="form-control pl-5"
                              onChange={(e) => {
                                onChange(e.target.value);
                              }}
                              value={value}
                            >
                              <option value="">Select Type</option>
                              <option value="required">Required</option>
                              <option value="optional">Optional</option>
                            </select>
                          )}
                          control={control}
                          name="type"
                          // defaultValue={null}
                        />
                        <img
                          src="assets/images/event-name.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  {
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Options</label>
                        {errors?.values && (
                          <span className="text-red ms-1">
                            {errors?.values?.message}
                          </span>
                        )}
                        <div className="input-container">
                          {
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <CreatableSelectInput
                                  className="form-control ps-4 py-2"
                                  name="values"
                                  handleChange={(val) => {
                                    console.log(val, "Creatable Values");
                                    // onChange(val);
                                    setValue("values", val);
                                  }}
                                  value={value}
                                  isLoading={loading}
                                  placeholder="Add Option for the above field."
                                />
                              )}
                              control={control}
                              // defaultValue={nu}
                              name="values"
                            />
                          }
                          <img
                            src="assets/images/event-name.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  }

                  <div className="col-sm-12 col-md-12 text-center">
                    <button type="submit" className="btn btn-save">
                      {loading ? <Loader /> : "Save"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default EditConsent;
