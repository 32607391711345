import moment from "moment";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";

export default function PrintEventComponent({ data, sortedData }) {
  let componentRef = useRef();

  return (
    <>
      <div>
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => (
            <button className="btn btn-view btn-accept ms-0">Print</button>
          )}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          {/* Pass sortedData as a prop */}
          <ComponentToPrint
            ref={(el) => (componentRef = el)}
            data={data}
            sortedData={sortedData}
          />
        </div>
      </div>
    </>
  );
}

class ComponentToPrint extends React.Component {
  render() {
    const { data, sortedData } = this.props;

    return (
      <div className="p-3">
        <h2 className="">Attendance</h2>
        <p className="m-0">
          <label className="fw-bold">Event Name: </label> {data?.name}
        </p>
        {data?.level_details && (
          <p className="m-0">
            <label className="fw-bold">Level Name:</label>{" "}
            {data?.level_details?.name}{" "}
          </p>
        )}
        <p className="m-0">
          <label className="fw-bold">Event Type: </label>{" "}
          {data?.event_types_details?.name}
        </p>
        <p className="m-0">
          <label className="fw-bold">Date & Time: </label>{" "}
          {data?.date
            ? `${moment(data?.date).format("DD MMM YYYY")} ${
                data?.start_time
                  ? moment(data?.start_time, "hh:mm:ss").format("hh:mm a")
                  : data?.level_details
                  ? moment(data?.level_details?.time, "hh:mm:ss").format(
                      "hh:mm a"
                    )
                  : ""
              }`
            : "NA"}
        </p>
        <p className="m-0">
          <label className="fw-bold">Area:</label>{" "}
          {data?.areacode_details?.areacode}{" "}
        </p>
        <p className="m-0">
          <label className="fw-bold">Venue:</label> {data?.school_details?.name}{" "}
          {data?.school_details?.address}
        </p>
        <p className="m-0">
          <label className="fw-bold">Teacher:</label>{" "}
          {data?.teacher_details?.full_name}{" "}
        </p>
        <div className="table-responsive text-center table-bordered">
          <table className="table border mt-4">
            <thead className="text-center border">
              <tr>
                <th scope="col border" style={{ width: "150px" }}>
                  Name
                </th>
                <th scope="col border" style={{ width: "120px" }}>
                  Attendance
                </th>
                <th scope="col border" style={{ width: "100px" }}>
                  Mark
                </th>
                <th scope="col border">Notes</th>
              </tr>
            </thead>
            <tbody>
              {/* Sorted Data applied here */}
              {sortedData(data?.student_list?.pending_student)?.map(
                (student, i) => {
                  return (
                    <tr key={i} className="py-3 border">
                      <td className="border ellipsis">
                        {student?.first_name} {student?.last_name}
                      </td>
                      <td className="border" style={{ width: "120px" }}></td>
                      <td className="border" style={{ width: "100px" }}></td>
                      <td className="border"></td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
