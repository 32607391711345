import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { addAreaCode } from "../../api/areacode";
import { addSize } from "../../api/size";
import Loader from "./../../component/Loader";
import { fetchSizeList } from "./slice";
function AddSize() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchSizeList({ page: 1, limit: 10000 })).then((res) => {
        if (res?.payload?.code === 1) {
          let arr = [...res?.payload?.data?.size_list];
          let dt = arr.find((it) => it.id === parseInt(id));
          setData(dt);
        }
      });
    }
  }, [dispatch, id]);

  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>Add Size</h3>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <Formik
              initialValues={{
                size: data?.size || "",
                student_type: data?.student_type || "",
                discipline: data?.discipline || "",
              }}
              enableReinitialize={true}
              validationSchema={Yup.object({
                size: Yup.string().required("Required"),
                student_type: Yup.string().required("Required"),
                discipline: Yup.string().required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setLoading(true);

                addSize({
                  size: values?.size || "",
                  student_type: values?.student_type || "",
                  discipline: values?.discipline || "",
                  size_id: id ? id : "",
                }).then((res) => {
                  setLoading(false);
                  if (res?.code === 1) {
                    toast.success(res?.message);
                    navigate("/sizes", { replace: true });
                  } else {
                    toast.error(res?.message);
                  }
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFormikState,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form className="form-style" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 mb-5">
                      <div className="form-group">
                        <label className="fw-bold">Size</label>
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Size Name"
                            name="size"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.size}
                          />
                          <img
                            src="assets/images/First-Name.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                        {errors.size && touched.size && (
                          <div className="text-red">{errors.size}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Membership Level</label>
                        {errors.student_type && touched.student_type && (
                          <span className="text-danger ms-1">
                            {errors.student_type}
                          </span>
                        )}
                        <div className="checkboxs">
                          <label>
                            <input
                              type="radio"
                              value="child"
                              name="student_type"
                              checked={values.student_type === "child"}
                              onChange={(e) => {
                                setFieldValue("student_type", e.target.value);
                              }}
                            />
                            <div className="div-bg">
                              <p>
                                <span>child</span>
                              </p>
                            </div>
                          </label>

                          <label>
                            <input
                              type="radio"
                              value="adult"
                              name="student_type"
                              checked={values.student_type === "adult"}
                              onChange={(e) => {
                                setFieldValue("student_type", e.target.value);
                              }}
                            />
                            <div className="div-bg">
                              <p>
                                <span>adult</span>
                              </p>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Discipline</label>
                        {errors.discipline && touched.discipline && (
                          <span className="text-danger ms-1">
                            {errors.discipline}
                          </span>
                        )}
                        <div className="checkboxs">
                          <label>
                            <input
                              type="radio"
                              value="karate"
                              name="discipline"
                              checked={values.discipline === "karate"}
                              onChange={(e) => {
                                setFieldValue("discipline", e.target.value);
                              }}
                            />
                            <div className="div-bg">
                              <p>
                                <span>karate</span>
                              </p>
                            </div>
                          </label>

                          <label>
                            <input
                              type="radio"
                              value="kickboxing"
                              name="discipline"
                              checked={values.discipline === "kickboxing"}
                              onChange={(e) => {
                                setFieldValue("discipline", e.target.value);
                              }}
                            />
                            <div className="div-bg">
                              <p>
                                <span>kick Boxing</span>
                              </p>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 text-center">
                      <button type="submit" className="btn btn-save">
                        {loading ? <Loader /> : "Done"}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddSize;
