import {
  deleteData,
  getData,
  patchFormData,
  postFormData,
  postRawData,
} from "./index";

export const addUser = async (payload) => {
  return postRawData("admin/addUser", payload).then((data) => {
    return data;
  });
};

export const userList = async (payload) => {
  return getData("admin/userList", payload).then((data) => {
    return data;
  });
};

export const getUserDetails = async (payload) => {
  return getData("admin/getUserDetails", payload).then((data) => {
    return data;
  });
};

export const changeUserStatus = async (payload) => {
  return patchFormData("admin/changeUserStatus", payload).then((data) => {
    return data;
  });
};
export const removeUser = async (payload) => {
  return deleteData("admin/removeUser", payload).then((data) => {
    return data;
  });
};
export const creditAmount = async (payload) => {
  return postFormData("admin/creditAmount", payload).then((data) => {
    return data;
  });
};

export const importUserFromExcel = async (payload) => {
  return postFormData("admin/importUserData", payload).then((data) => {
    return data;
  });
};
