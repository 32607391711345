import React, { Component } from "react";

import CreatableSelect from "react-select/creatable";

const createOption = (label) => ({
  label,
  value: label,
});

const customStyles = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    width: "95%",
    height: "40px",
    overflowY: "scroll",
    borderColor: "transparent",
    "&:focus": {
      borderColor: "transparent",
    },
    "&:hover": {
      borderColor: "transparent",
    },
    "&::-webkit-scrollbar": {
      width: "0px",
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: "13px",
  }),
};

export default class CreatableSelectInput extends Component {
  state = {
    inputValue: "",
    value: this.props.value?.length > 0 ? this.props.value : [],
  };
  handleChange = (value, actionMeta) => {
    if (actionMeta?.action === "clear") {
      let filteredValues = [...this.state.value]?.filter((it) => {
        // main array to compare/filter
        let arrayToRemove = [...actionMeta?.removedValue];
        return !arrayToRemove?.some((el) => {
          // subset array to remove
          return el.value === it?.value;
        });
      });

      this.setState({ value: filteredValues });
      this.props.handleChange([...filteredValues]);
    } else if (actionMeta?.action === "remove-value") {
      let objToClear = { ...actionMeta?.removedValue };
      let filteredValues = [...this.state.value]?.filter((it) => {
        return objToClear.value !== it?.value;
      });
      this.setState({ value: filteredValues });
      this.props.handleChange([...filteredValues]);
    } else {
      this.setState({ value });
    }
  };
  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };
  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        this.setState({
          inputValue: "",
          value:
            value?.length > 0
              ? [...value, createOption(inputValue)]
              : [createOption(inputValue)],
        });
        this.props.handleChange(
          value?.length > 0
            ? [...value, createOption(inputValue)]
            : [createOption(inputValue)]
        );
        event.preventDefault();
        break;
      default:
        return false;
    }
  };
  componentDidUpdate(prev, state) {
    if (JSON.stringify(prev.value) !== JSON.stringify(this.state.value))
      this.setState({
        ...this.state,
        value: prev.value,
      });
  }
  render() {
    const { inputValue, value } = this.state;
    const components = {
      DropdownIndicator: null,
    };
    return (
      <CreatableSelect
        isClearable
        isMulti
        menuIsOpen={false}
        className={this.props.className}
        components={components}
        inputValue={inputValue}
        name={this.props.name}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        placeholder={this.props.placeholder}
        value={value || null}
        styles={customStyles}
        allowCreateWhileLoading={false}
        onBlur={this.props.onBlur}
        isLoading={this.props.isLoading}
      />
    );
  }
}
