import React, { useImperativeHandle } from "react";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import moment from "moment";
import { getData, postFormData } from "../../api";
import { useEffect, useState } from "react";
import Loader from "../Loader";
import { toast } from "react-toastify";

require("react-dom");
window.React2 = require("react");
console.log(window.React1 === window.React2);

const ChangeStudentEventComponent = ({
  show,
  handleShow,
  changeEventForStudentData,
  //   class_type,
  setChangeEventForStudentData,
  id,
  getDetails,
  submitting,
  setChangeEventSubmitting,
}) => {
  // const dispatch = useDispatch();
  const [lists, setLists] = useState([]);
  const [levelLists, setLeveLists] = useState([]);

  useEffect(() => {
    getData("admin/eventList", { page: 1, limit: 1000000000 }).then((res) => {
      if (res?.code === 1) {
        // setLists(res?.data?.event_list.filter((it) => it?.id != id));
        setLists(res?.data?.event_list);
      }
    });
  }, [id]);
  // useImperativeHandle({})
  const formik = useFormik({
    initialValues: {
      selectedEvent: null,
      selectedLevel: null,
      //   availableTimeSlots: [],
      //   availableDates: [],
    },
    // validationSchema: Yup.object({
    //   selectedEvent: Yup.object().required("Required"),
    //   selectedLevel: Yup.object().required("Required"),
    // }),
    validationSchema: Yup.object().shape({
      selectedEvent: Yup.object().nullable().required("Required"),
      // selectedLevel: Yup.object().nullable().required("Required"),
      selectedLevel: Yup.object()
        .nullable()
        .when("selectedEvent", {
          is: (event) => event && event.levels && event.levels.length > 0,
          then: Yup.object().nullable().required("Required"),
          otherwise: Yup.object().nullable(),
        }),
    }),
    context: { levelLists },
    onSubmit: (values) => {
      //   const handleChangeEvent = (data) => {
      //   console.log("data", data);
      let payloadData = {
        userId: changeEventForStudentData?.userId,
        total_amount:
          changeEventForStudentData?.student_type?.toLowerCase() == "child"
            ? levelLists.length > 0
              ? values?.selectedLevel?.child_price
              : values?.selectedEvent?.child_price
            : changeEventForStudentData?.student_type?.toLowerCase() == "adult"
            ? levelLists.length > 0
              ? values?.selectedLevel?.adult_price
              : values?.selectedEvent?.adult_price
            : 0,
        schoolId: changeEventForStudentData?.schoolId,
        eventId: values?.selectedEvent?.id,
        levelId: values?.selectedLevel?.id ? values?.selectedLevel?.id : "",
        date: values?.selectedEvent?.date,
        area: values?.selectedEvent?.areacode,
        age_group: values?.selectedEvent?.age_group,
        // teacherId: changeEventForStudentData?.teacherId,
        teacherId: "",
        price:
          changeEventForStudentData?.student_type?.toLowerCase() == "child"
            ? levelLists.length > 0
              ? values?.selectedLevel?.child_price
              : values?.selectedEvent?.child_price
            : changeEventForStudentData?.student_type?.toLowerCase() == "adult"
            ? levelLists.length > 0
              ? values?.selectedLevel?.adult_price
              : values?.selectedEvent?.adult_price
            : 0,
        studentId: changeEventForStudentData?.studentId,
        trialAccountStatus: changeEventForStudentData?.trialAccountStatus,
        old_booking_student_id:
          changeEventForStudentData?.old_booking_student_id,
      };

      setChangeEventSubmitting(true);

      postFormData("class/changeStudentEvent", payloadData).then((res) => {
        setChangeEventSubmitting(false);
        if (res?.code === 1) {
          toast.success(res?.message);
          getDetails();
          handleShow();
          setChangeEventForStudentData(null);
        } else {
          toast.error(res?.message);
        }
      });
      // };
      // changeEventForStudentData(
      //   values.selectedEvent?.id,
      //   values.selectedLevel.id,
      //   values.selectedEvent,
      //   values.selectedEvent?.date,
      //   values.area,
      //   values.time_slot_id
      // );
    },
  });

  const handleLevel = (classs) => {
    if (classs?.levels) {
      let notCancelledLevel = classs?.levels?.filter(
        (item) => item?.cancel_reason == null
      );
      setLeveLists(notCancelledLevel);
    } else {
      setLeveLists([]);
    }
    formik.setFieldValue("selectedLevel", null);
  };

  return (
    <Modal show={show} onHide={handleShow} size="lg" centered>
      <div className=" modal-dialog-centered">
        <div className="modal-content body-bg">
          <div className="modal-header border-0 justify-content-center">
            <h5>Change Event</h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleShow}
            ></button>
          </div>
          <div className="modal-body mb-4 p-4">
            <form className="form-style" onSubmit={formik.handleSubmit}>
              <label className="fw-bold">Select Event</label>
              <div className="mb-4">
                <Select
                  // isMulti
                  options={lists?.length > 0 ? lists : undefined}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  //   className="mb-4"
                  value={formik.values.selectedEvent}
                  onChange={(val) => {
                    formik.setFieldValue("selectedEvent", val);
                    handleLevel(val);
                  }}
                />
                {formik.errors.selectedEvent &&
                  formik.touched.selectedEvent && (
                    <span className="text-danger">
                      {formik.errors.selectedEvent}
                    </span>
                  )}
              </div>

              <div className="">
                <div className="">
                  <label className="fw-bold">Select Level</label>

                  <Select
                    // isMulti
                    options={levelLists?.length > 0 ? levelLists : undefined}
                    // getOptionLabel={(option) =>
                    //   `${moment(option?.start_time, "hh:mm:ss").format(
                    //     "hh:mm a"
                    //   )}-${moment(option?.end_time, "hh:mm:ss").format(
                    //     "hh:mm a"
                    //   )}, ${moment(option?.date).format("DD MMM YYYY")}`
                    // }
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    // getOptionValue={(option) => option}
                    className="w-auto"
                    value={formik.values.selectedLevel}
                    // styles={customStyles}
                    onChange={(val) => {
                      formik.setFieldValue("selectedLevel", val);
                    }}
                  />
                  {formik.errors.selectedLevel &&
                    formik.touched.selectedLevel && (
                      <span className="text-danger">
                        {formik.errors.selectedLevel}
                      </span>
                    )}
                </div>
              </div>

              <div className="text-center mt-4">
                <button
                  type="submit"
                  className="btn btn-accept btn-view mt-2"
                  disabled={submitting}
                >
                  {submitting ? <Loader /> : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeStudentEventComponent;
