import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getEflyerEmaiLDetails,
  GetEflyerEmailList,
  getNewsLetterDetails,
  newsLetterList,
} from "../../api/newsletter";

const initialState = {
  list: [],
  total: 0,
  detail: null,
};

export const NewsletterSlice = createSlice({
  name: "NewsletterSlice",
  initialState,
  reducers: {
    listing: (state, action) => {
      state.list = action.payload;
    },
    count: (state, action) => {
      state.total = action.payload;
    },
    detailing: (state, action) => {
      state.detail = action.payload;
    },
  },
});

export const FetchNewsletter = createAsyncThunk(
  "newsletterListing",
  async (obj, { dispatch }) => {
    try {
      const response = await GetEflyerEmailList(obj);
      console.log(response);
      if (response?.code === 1) {
        dispatch(listing(response?.data?.data));
        dispatch(count(response?.data?.total));
      }
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const FetchNewsletterDetail = createAsyncThunk(
  "FetchNewsletterDetail",
  async (obj, { dispatch }) => {
    try {
      const response = await getEflyerEmaiLDetails(obj);
      console.log(response);
      if (response?.code === 1) {
        dispatch(detailing(response?.data));
      }
    } catch (error) {
      toast.error(error?.message);
    }
  }
);
export const { listing, detailing, count } = NewsletterSlice.actions;

export default NewsletterSlice.reducer;
