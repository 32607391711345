import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { deleteAccountRequesteList } from "../../api/accountDeletion";

const initialState = {
  list: [],
  total: 0,
  totalReqTab: 0,
};

export const AccountDeletionRequestSlice = createSlice({
  name: "deletionRequest",
  initialState,
  reducers: {
    list: (state, action) => {
      state.list = action.payload;
    },
    totalCount: (state, action) => {
      state.total = action.payload;
    },
    totalReqTabCount: (state, action) => {
      state.totalReqTab = action.payload;
    },
  },
});

export const FetchAccountDeletionList = createAsyncThunk(
  "FetchAccountDeletionList",
  async (obj, { dispatch }) => {
    try {
      const response = await deleteAccountRequesteList(obj);
      if (response?.code === 1) {
        dispatch(list(response?.data?.request_list));
        dispatch(totalCount(response?.data?.total_request));
        dispatch(totalReqTabCount(response?.data?.total_request_tab));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const { list, totalCount, totalReqTabCount } =
  AccountDeletionRequestSlice.actions;

export default AccountDeletionRequestSlice.reducer;
