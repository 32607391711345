import moment from "moment/moment";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { acceptRejectRequest } from "../../api/accountDeletion";
import Loader from "../../component/Loader";
import Accepted from "../../component/Modal/Accepted";
import DeleteModal from "../../component/Modal/Delete";
import { variants } from "../../constant/Variants";
import { FetchAccountDeletionList } from "./AccountDeletionRequestSlice";
import Paginations from "../../component/Pagination";

const AccountDeletion = () => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.accountDeletionRequest.list);
  const total = useSelector((state) => state.accountDeletionRequest.total);
  const totalReqTab = useSelector(
    (state) => state.accountDeletionRequest.totalReqTab
  );

  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState("");
  const [status, setStatus] = useState("");
  const [tabName, setTabName] = useState("pending");
  const [actionName, setActionName] = useState("");
  const [type, setType] = useState("user");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const handleShow = () => {
    setShow(!show);
  };

  const getList = useCallback(() => {
    setLoading(true);
    dispatch(
      FetchAccountDeletionList({
        page: page,
        limit: 10,
        status: tabName,
        search: search,
        type,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, tabName, search, type, page]);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getList();
    }

    return () => {
      isRendered = false;
    };
  }, [getList]);

  const approveReject = () => {
    acceptRejectRequest({
      status:
        actionName === "Accept"
          ? "accepted"
          : actionName === "Reject"
          ? "rejected"
          : actionName === "Delete"
          ? "deleted"
          : "",
      request_id: delId,
    }).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getList();
      } else {
        toast.success(res?.message);
      }
    });
  };
  const handlePage = (val) => {
    setPage(val);
  };

  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="link-active shadow">
              <p>
                <img
                  src="assets/images/account-deletion-request-selected.png"
                  alt=""
                />
                account & deletion request {total}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search by name"
                  value={search}
                  onChange={(e) => {
                    setPage(1);
                    setSearch(e.target.value);
                  }}
                />
              </form>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 d-flex justify-content-center">
            <ul className="nav nav-pills group-nav mb-3 mt-0 ">
              <li className="nav-item">
                <button
                  className={type === "user" ? "nav-link active" : "nav-link"}
                  type="button"
                  onClick={() => {
                    setType("user");
                    setPage(1);
                  }}
                >
                  User
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    type === "student" ? "nav-link active" : "nav-link"
                  }
                  type="button"
                  onClick={() => {
                    setType("student");
                    setPage(1);
                  }}
                >
                  Student
                </button>
              </li>
            </ul>
          </div>
          <div className="col-sm-12">
            <ul
              className="nav nav-pills group-nav mb-3"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={
                    tabName === "pending" ? "nav-link active" : "nav-link"
                  }
                  id="pills-pending-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-pending"
                  type="button"
                  role="tab"
                  aria-controls="pills-pending"
                  aria-selected="false"
                  onClick={() => {
                    setTabName("pending");
                    setPage(1);
                  }}
                >
                  pending
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    tabName === "accepted" ? "nav-link active" : "nav-link"
                  }
                  id="pills-completed-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-completed"
                  type="button"
                  role="tab"
                  aria-controls="pills-completed"
                  aria-selected="false"
                  onClick={() => {
                    setTabName("accepted");
                    setPage(1);
                  }}
                >
                  accepted
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    tabName === "rejected" ? "nav-link active" : "nav-link"
                  }
                  id="pills-new-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-new"
                  type="button"
                  role="tab"
                  aria-controls="pills-new"
                  aria-selected="true"
                  onClick={() => {
                    setTabName("rejected");
                    setPage(1);
                  }}
                >
                  Rejected
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className={
                    tabName === "deleted" ? "nav-link active" : "nav-link"
                  }
                  id="pills-deleted-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-deleted"
                  type="button"
                  role="tab"
                  aria-controls="pills-deleted"
                  aria-selected="false"
                  onClick={() => {
                    setTabName("deleted");
                    setPage(1);
                  }}
                >
                  deleted
                </button>
              </li>
            </ul>
          </div>
          <div className="col-sm-12">
            <div className="tab-content" id="pills-tabContent">
              <div
                className={
                  tabName === "pending"
                    ? "tab-pane fade show active"
                    : "tab-pane fade "
                }
                id="pills-pending"
                role="tabpanel"
                aria-labelledby="pills-pending-tab"
              >
                <div className="table-responsive text-center">
                  <table className="table storetable">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Photo</th>
                        <th scope="col">{`${
                          type.charAt(0).toUpperCase() + type.slice(1)
                        } name`}</th>
                        <th scope="col">Reason</th>
                        <th scope="col">Date</th>

                        <th scope="col">Action</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={6}>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {list?.length > 0 ? (
                            list?.map((item, i) => {
                              return (
                                <tr key={i} variants={variants.card}>
                                  <td>{item?.id}</td>
                                  <td>
                                    <img
                                      src={
                                        item?.user_detail?.image?.includes(
                                          "http"
                                        )
                                          ? item?.user_details?.image
                                          : "assets/images/headerlogo.png"
                                      }
                                      className="table-img"
                                      alt=""
                                    />
                                  </td>
                                  <td>
                                    {type === "user"
                                      ? `${item?.user_details?.first_name} ${item?.user_details?.last_name}`
                                      : type === "student"
                                      ? `${item?.student_details?.first_name} ${item?.student_details?.last_name}`
                                      : "NA"}
                                  </td>
                                  <td>
                                    <span>{item?.reason || "NA"}</span>
                                  </td>
                                  <td>
                                    {item?.createdAt
                                      ? moment(item?.createdAt).format(
                                          "DD MMM YYYY"
                                        )
                                      : "NA"}
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center action">
                                      <button className="btn">
                                        <Link
                                          to={`/user/${item?.user_details?.id}`}
                                        >
                                          <img
                                            src="assets/images/view.png"
                                            alt=""
                                          />
                                        </Link>
                                      </button>
                                      <button
                                        className="btn"
                                        type="button"
                                        onClick={() => {
                                          setActionName("Accept");
                                          setDelId(item?.id);
                                          setShow(!show);
                                        }}
                                      >
                                        <img
                                          src="assets/images/approve.png"
                                          alt=""
                                        />
                                      </button>
                                      <button
                                        className="btn"
                                        type="button"
                                        onClick={() => {
                                          setActionName("Reject");
                                          setDelId(item?.id);
                                          setShow(!show);
                                        }}
                                      >
                                        <img
                                          src="assets/images/canceled.png"
                                          alt=""
                                        />
                                      </button>
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          setActionName("Delete");
                                          setDelId(item?.id);
                                          setShow(!show);
                                        }}
                                      >
                                        <img
                                          src="assets/images/delete.png"
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={6}>No data found.</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className={
                  tabName === "accepted"
                    ? "tab-pane fade show active"
                    : "tab-pane fade "
                }
                id="pills-completed"
                role="tabpanel"
                aria-labelledby="pills-completed-tab"
              >
                <div className="table-responsive text-center">
                  <table className="table storetable">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">photo</th>
                        <th scope="col">{`${
                          type.charAt(0).toUpperCase() + type.slice(1)
                        } name`}</th>
                        <th scope="col">reason</th>
                        <th scope="col">date</th>

                        <th scope="col">action</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={6}>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {" "}
                          {list?.length > 0 ? (
                            list?.map((item, i) => {
                              return (
                                <tr key={i} variants={variants.card}>
                                  <td>{item?.id}</td>
                                  <td>
                                    <img
                                      src={
                                        item?.user_detail?.image?.includes(
                                          "http"
                                        )
                                          ? item?.user_details?.image
                                          : "assets/images/headerlogo.png"
                                      }
                                      className="table-img"
                                      alt=""
                                    />
                                  </td>
                                  <td>
                                    {type === "user"
                                      ? `${item?.user_details?.first_name} ${item?.user_details?.last_name}`
                                      : type === "student"
                                      ? `${item?.student_details?.first_name} ${item?.student_details?.last_name}`
                                      : "NA"}
                                  </td>
                                  <td>
                                    <span>{item?.reason || "NA"}</span>
                                  </td>
                                  <td>
                                    {item?.createdAt
                                      ? moment(item?.createdAt).format(
                                          "DD MMM YYYY"
                                        )
                                      : "NA"}
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center action">
                                      <button
                                        className="btn"
                                        type="button"
                                        onClick={() => {
                                          setActionName("Reject");
                                          setDelId(item?.id);
                                          setShow(!show);
                                        }}
                                      >
                                        <img
                                          src="assets/images/canceled.png"
                                          alt=""
                                        />
                                      </button>
                                      <button className="btn">
                                        <Link
                                          to={`/user/${item?.user_details?.id}`}
                                        >
                                          <img
                                            src="assets/images/view.png"
                                            alt=""
                                          />
                                        </Link>
                                      </button>
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          setActionName("Delete");
                                          setDelId(item?.id);
                                          setShow(!show);
                                        }}
                                      >
                                        <img
                                          src="assets/images/delete.png"
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={6}>No data found.</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className={
                  tabName === "rejected"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="pills-new"
                role="tabpanel"
                aria-labelledby="pills-new-tab"
              >
                <div className="table-responsive text-center">
                  <table className="table storetable">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">photo</th>
                        <th scope="col">{`${
                          type.charAt(0).toUpperCase() + type.slice(1)
                        } name`}</th>
                        <th scope="col">reason</th>
                        <th scope="col">date</th>

                        <th scope="col">action</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={6}>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {" "}
                          {list?.length > 0 ? (
                            list?.map((item, i) => {
                              return (
                                <tr key={i} variants={variants.card}>
                                  <td>{item?.id}</td>
                                  <td>
                                    <img
                                      src={
                                        item?.user_detail?.image?.includes(
                                          "http"
                                        )
                                          ? item?.user_details?.image
                                          : "assets/images/headerlogo.png"
                                      }
                                      className="table-img"
                                      alt=""
                                    />
                                  </td>
                                  <td>
                                    {type === "user"
                                      ? `${item?.user_details?.first_name} ${item?.user_details?.last_name}`
                                      : type === "student"
                                      ? `${item?.student_details?.first_name} ${item?.student_details?.last_name}`
                                      : "NA"}
                                  </td>
                                  <td>
                                    <span>{item?.reason || "NA"}</span>
                                  </td>
                                  <td>
                                    {item?.createdAt
                                      ? moment(item?.createdAt).format(
                                          "DD MMM YYYY"
                                        )
                                      : "NA"}
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center action">
                                      <button className="btn">
                                        <Link
                                          to={`/user/${item?.user_details?.id}`}
                                        >
                                          <img
                                            src="assets/images/view.png"
                                            alt=""
                                          />
                                        </Link>
                                      </button>
                                      <button
                                        className="btn"
                                        type="button"
                                        onClick={() => {
                                          setActionName("Accept");
                                          setDelId(item?.id);
                                          setShow(!show);
                                        }}
                                      >
                                        <img
                                          src="assets/images/approve.png"
                                          alt=""
                                        />
                                      </button>
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          setActionName("Delete");
                                          setDelId(item?.id);
                                          setShow(!show);
                                        }}
                                      >
                                        <img
                                          src="assets/images/delete.png"
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={6}>No data found.</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                className={
                  tabName === "deleted"
                    ? "tab-pane fade show active"
                    : "tab-pane fade "
                }
                id="pills-deleted"
                role="tabpanel"
                aria-labelledby="pills-canceled-tab"
              >
                <div className="table-responsive text-center">
                  <table className="table storetable">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">photo</th>
                        <th scope="col">{`${
                          type.charAt(0).toUpperCase() + type.slice(1)
                        } name`}</th>
                        <th scope="col">reason</th>
                        <th scope="col">date</th>
                        <th scope="col">action</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={6}>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {" "}
                          {list?.length > 0 ? (
                            list?.map((item, i) => {
                              return (
                                <tr key={i} variants={variants.card}>
                                  <td>{item?.id}</td>
                                  <td>
                                    <img
                                      src={
                                        item?.user_detail?.image?.includes(
                                          "http"
                                        )
                                          ? item?.user_details?.image
                                          : "assets/images/headerlogo.png"
                                      }
                                      className="table-img"
                                      alt=""
                                    />
                                  </td>
                                  <td>
                                    {type === "user"
                                      ? `${item?.user_details?.first_name} ${item?.user_details?.last_name}`
                                      : type === "student"
                                      ? `${item?.student_details?.first_name} ${item?.student_details?.last_name}`
                                      : "NA"}
                                  </td>
                                  <td>
                                    <span>{item?.reason || "NA"}</span>
                                  </td>
                                  <td>
                                    {item?.createdAt
                                      ? moment(item?.createdAt).format(
                                          "DD MMM YYYY"
                                        )
                                      : "NA"}
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center action">
                                      <button className="btn">
                                        <Link
                                          to={`/user/${item?.user_details?.id}`}
                                        >
                                          <img
                                            src="assets/images/view.png"
                                            alt=""
                                          />
                                        </Link>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={6}>No data found.</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {list?.length > 0 && (
              <Paginations
                handlePage={handlePage}
                page={page}
                total={totalReqTab && totalReqTab}
              />
            )}
          </div>
        </div>
      </div>
      {show && actionName && (
        <Accepted
          pageName="Account Deletion Request"
          show={show}
          handleShow={(no) => {
            if (no) {
              handleShow();
              setDelId("");
            } else {
              handleShow();
            }
          }}
          cancel={() => {
            setShow(false);
          }}
          actionName={actionName}
          getList={getList}
          approvedReject={approveReject}
        />
      )}
    </article>
  );
};

export default AccountDeletion;
