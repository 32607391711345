import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "../../utils/index.js";
import S3FileUpload from "react-s3";
import { Progress } from "react-sweet-progress";
import * as yup from "yup";
import Loader from "../../component/Loader.jsx";
import { detail, FetchSubAdminsDetails } from "./SubAdminSlice.js";
import { addSubadmin } from "../../api/subAdmin.js";
// import { addSubadminSchema } from "../../utils/schema";
// import { addSubAdminReducer, getAdminDetails } from "../slice";
import BackButton from "../../component/BackButton";

export const addSubadminSchema = yup.object().shape({
  id: yup.string(),
  image: yup.string().notRequired().nullable().optional(),
  name: yup.string().required("Required"),
  email: yup.string().required("Required"),
  password: yup.string().when("id", {
    is: (item) => item?.length < 0,
    then: yup.string().required("Field is required"),
  }),
  modules: yup.object({
    account_deletion: yup.boolean(),
    area: yup.boolean(),
    booking: yup.boolean(),
    classes: yup.boolean(),
    discipline: yup.boolean(),
    earning: yup.boolean(),
    event_Type: yup.boolean(),
    events: yup.boolean(),
    import_export_data: yup.boolean(),
    membership: yup.boolean(),
    newsletter: yup.boolean(),
    notification: yup.boolean(),
    register: yup.boolean(),
    teacher: yup.boolean(),
    user: yup.boolean(),
    venue: yup.boolean(),
  }),
});

function AddSubadmin() {
  const { id } = useParams();
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const loading = useSelector((store) => store.loading);
  // const [roles, setRoles] = useState(subAdminRolesModel);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);
  const {
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    setValue,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: yupResolver(addSubadminSchema),
    shouldFocusError: true,
    defaultValues: {
      modules: {
        account_deletion: false,
        area: false,
        booking: false,
        classes: false,
        discipline: false,
        earning: false,
        event_Type: false,
        events: false,
        import_export_data: false,
        membership: false,
        newsletter: false,
        notification: false,
        register: false,
        teacher: false,
        user: false,
        venue: false,
      },
    },
  });
  const onSubmit = (data) => {
    console.log(data);
    setSubmitting(true)
    let payload = {};
    for (const key in data.modules) {
      if (Object.hasOwnProperty.call(data.modules, key)) {
        const element = data.modules[key];
        console.log(key, element);
        if (key === "id" || key === "adminId") {
          continue;
        } else {
          payload = {
            ...payload,
            [key]: element === true ? "1" : "0",
          };
        }
      }
    }
    console.log(payload);

    addSubadmin({
      email: data?.email,
      name: data?.name,
      password: data?.password,
      image: data?.image,
      modules: payload,
      sub_admin_id: id ? id : "",
    }).then((res) => {
    setSubmitting(false);

      if (res?.code === 1) {
        toast.success(res?.message);
        navigate("/sub-admin", { replace: true });
      } else {
        toast.error(res?.message);
      }
    });
  };

  const getDetails = useCallback(() => {
    if (id) {
      setLoading(true);
      dispatch(FetchSubAdminsDetails(id)).then((res) => {
        console.log(res);
        let data = res?.payload?.data?.user_details;
        reset({
          id: data?.id,
          name: data?.name,
          email: data?.email,
          image: data?.image,
          modules: data?.modules,
        });
        setLoading(false);
      });
    }
  }, [dispatch, id, reset]);

  useEffect(() => {
    if (id) {
      getDetails();
    }
  }, [getDetails, id]);

  const watchRoles = watch("modules");

  useEffect(() => {
    return () => {
      dispatch(detail(null));
    };
  }, [dispatch]);

  if (id && loading) {
    return (
      <article className="col-lg-9">
        <Loader />
      </article>
    );
  } else {
    return (
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <BackButton />
          <div className="pt-3 px-3 rad">
            <div className="row align-items-center col-rever">
              <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <h4>{id ? "Edit Sub-Admin" : "Add Sub-Admin"}</h4>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-sm-12">
              <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                    <div className="form-group text-center">
                      <div className="upload-btn-wrapper">
                        <button className="btn">
                          {time > 0 && isActive ? (
                            <Progress type="circle" width={80} percent={time} />
                          ) : (
                            <img
                              src={
                                getValues("image")
                                  ? getValues("image")
                                  : "assets/images/upload-img.png"
                              }
                              alt=""
                            />
                          )}

                          {time > 0 && isActive ? null : (
                            <div className="camera-icon">
                              <i className="fa fa-camera"></i>
                            </div>
                          )}
                        </button>
                        <input
                          type="file"
                          onChange={(e) => {
                            if (e.target.files.length > 0) {
                              setIsActive(true);
                              S3FileUpload.uploadFile(e.target.files[0], config)
                                .then((data) => {
                                  setValue("image", data.location);
                                  setTime(100);
                                  setIsActive(true);
                                  setTimeout(() => {
                                    setTime(0);
                                    setIsActive(false);
                                  }, 3000);
                                })
                                .catch((data) => console.log(data));
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-4">
                    <label>Name</label>
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Name"
                          {...register("name")}
                        />
                        <img
                          src="assets/images/user.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.name && (
                      <span className="text-danger">{errors.name.message}</span>
                    )}
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                    <div className="form-group">
                      <label>Email</label>
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Enter Primary Email"
                          {...register(`email`)}
                        />
                        <img
                          src="assets/images/email.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.email && (
                      <span className="text-danger">
                        {errors.email.message}
                      </span>
                    )}
                  </div>

                  {!id && (
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                      <div className="form-group">
                        <label>Password</label>
                        <div className="input-container">
                          <input
                            type="password"
                            className="form-control pl-5"
                            placeholder="Password"
                            {...register(`password`)}
                          />

                          <img
                            src="assets/images/user.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {errors.password && (
                        <span className="text-danger">
                          {errors.password.message}
                        </span>
                      )}
                    </div>
                  )}

                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <h3>Permission & Privileges</h3>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="table-responsive">
                      <table className="table storetable">
                        <thead className="bg-transparent">
                          <tr>
                            <th scope="col" className="bg-transparent">
                              Modules
                            </th>
                            <th scope="col" className="text-end bg-transparent">
                              action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(watchRoles)
                            ?.slice(2)
                            .map(function (key, index) {
                              return (
                                <tr>
                                  <td className="ps-3">{key}</td>
                                  <td>
                                    <div className="d-flex justify-content-end action">
                                      <button class="btn">
                                        <Controller
                                          name={`modules.${key}`}
                                          control={control}
                                          render={({
                                            field: { onChange, value },
                                          }) => (
                                            <input
                                              type="checkbox"
                                              className="toggle"
                                              checked={getValues(
                                                `modules.${key}`
                                              )}
                                              onChange={(e) => {
                                                onChange(e.target.checked);
                                              }}
                                            />
                                          )}
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                    <div className="form-group">
                      <div className="input-container">
                        <button className="btn btn-save" disabled={submitting}>
                          {submitting ? <Loader /> : "Save"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default AddSubadmin;
