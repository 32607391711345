import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { changePassword } from "../../api/setting";
import Loader from "../../component/Loader";

function ChangePassword() {
  const [loading, setLoading] = useState(false);
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="form-style mt-5 addFormMw">
          <Formik
            initialValues={{
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
              toggle: false,
              toggle2: false,
              toggle3: false,
            }}
            validationSchema={Yup.object({
              currentPassword: Yup.string().required("Required"),
              newPassword: Yup.string().required("Required"),
              confirmPassword: Yup.string().oneOf(
                [Yup.ref("newPassword"), null],
                "Password doesn't match."
              ),
            })}
            onSubmit={(values, { isSubmitting, resetForm }) => {
              setLoading(true);
              changePassword({
                current_password: values.currentPassword,
                new_password: values.newPassword,
              }).then((res) => {
                setLoading(false);

                if (res?.code === 1) {
                  resetForm();
                  toast.success(res?.message);
                } else {
                  toast.error(res?.message);
                }
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFormikState,
            }) => (
              <form className="row" onSubmit={handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                  <div className="">
                    <img
                      src="assets/images/change-password.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <h2 className="mdl-ttl">Change Password</h2>
                  <p>Please change your password here</p>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  <div className="input-container">
                    <input
                      type={values.toggle ? "text" : "password"}
                      className="form-control ps-4"
                      placeholder="Current Password"
                      name="currentPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.currentPassword}
                    />
                    <img
                      src="assets/images/password.png"
                      className="input-img"
                      alt=""
                    />
                    <span
                      className={
                        values.toggle
                          ? "pass-hide field-icon "
                          : "pass-view field-icon "
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setFormikState((prevState) => {
                          return {
                            ...prevState,
                            values: {
                              ...prevState.values,
                              toggle: !prevState.values.toggle,
                            },
                          };
                        });
                      }}
                    ></span>
                  </div>
                  {errors.currentPassword && touched.currentPassword && (
                    <div className="text-red">{errors.currentPassword}</div>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  <div className="input-container">
                    <p>
                      <Link
                        to="/forget-password"
                        className="theme-color fw-bold"
                      >
                        Forgotten Password?
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  <div className="input-container">
                    <input
                      type={values.toggle2 ? "text" : "password"}
                      className="form-control ps-4"
                      placeholder="Set New Password"
                      name="newPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newPassword}
                    />
                    <img
                      src="assets/images/password.png"
                      className="input-img"
                      alt=""
                    />
                    <span
                      className={
                        values.toggle2
                          ? "pass-hide field-icon "
                          : "pass-view field-icon "
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setFormikState((prevState) => {
                          return {
                            ...prevState,
                            values: {
                              ...prevState.values,
                              toggle2: !prevState.values.toggle2,
                            },
                          };
                        });
                      }}
                    ></span>
                  </div>
                  {errors.newPassword && touched.newPassword && (
                    <div className="text-red">{errors.newPassword}</div>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  <div className="input-container">
                    <input
                      type={values.toggle3 ? "text" : "password"}
                      className="form-control ps-4"
                      placeholder="Set Confirm New Password"
                      name="confirmPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                    />
                    <img
                      src="assets/images/password.png"
                      className="input-img"
                      alt=""
                    />
                    <span
                      className={
                        values.toggle3
                          ? "pass-hide field-icon "
                          : "pass-view field-icon "
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setFormikState((prevState) => {
                          return {
                            ...prevState,
                            values: {
                              ...prevState.values,
                              toggle3: !prevState.values.toggle3,
                            },
                          };
                        });
                      }}
                    ></span>
                  </div>
                  {errors.confirmPassword && touched.confirmPassword && (
                    <div className="text-red">{errors.confirmPassword}</div>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-save"
                    disabled={loading}
                  >
                    {loading ? <Loader /> : "Done"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </article>
  );
}

export default ChangePassword;
