import React from "react";

function AddAccount() {
  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy px-5">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div class="form-style">
              <h3>Add Account Detail</h3>
            </div>
          </div>
        </div>
        <div class="row mt-3 align-items-center">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <form class="form-style">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <h5>Set your account details</h5>
                  <p>
                    In this payment source, you will be receiving your money
                  </p>
                </div>
                <div class="col-sm-6 col-md-6 mb-4">
                  <div class="form-group">
                    <div class="input-container">
                      <select class="form-control form-select  pl-5">
                        <option>Select Your Bank</option>
                      </select>
                      <img
                        src="assets/images/bank.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 mb-4"></div>
                <div class="col-sm-6 col-md-6 mb-4">
                  <div class="form-group">
                    <div class="input-container">
                      <input
                        type="text"
                        class="form-control pl-5"
                        placeholder="Account Number"
                      />
                      <img
                        src="assets/images/account.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 mb-4">
                  <div class="form-group">
                    <div class="input-container">
                      <input
                        type="text"
                        class="form-control pl-5"
                        placeholder="Confirm Account Number"
                      />
                      <img
                        src="assets/images/account.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 mb-4">
                  <div class="form-group">
                    <div class="input-container">
                      <input
                        type="text"
                        class="form-control pl-5"
                        placeholder="IBAN"
                      />
                      <img
                        src="assets/images/iban.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 mb-4">
                  <div class="form-group">
                    <div class="input-container">
                      <input
                        type="text"
                        class="form-control pl-5"
                        placeholder="Account Holder's Name"
                      />
                      <img
                        src="assets/images/address.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 text-center">
                  <button type="submit" class="btn btn-save">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="my-5">
          <hr />
        </div>
        <div class="row mt-3 align-items-center">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <form class="form-style">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <h5>Add Credit/Debit Card</h5>
                  <p>
                    In this payment source, you will be sending the refund money
                  </p>
                </div>
                <div class="col-sm-6 col-md-6 mb-4">
                  <div class="form-group">
                    <div class="input-container">
                      <input
                        type="text"
                        class="form-control pl-5"
                        placeholder="Account Number"
                      />
                      <img
                        src="assets/images/account.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 mb-4">
                  <div class="form-group">
                    <div class="input-container">
                      <input
                        type="text"
                        class="form-control pl-5"
                        placeholder="Card Holder's Name"
                      />
                      <img
                        src="assets/images/account.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 mb-4">
                  <div class="form-group">
                    <div class="input-container">
                      <input
                        type="text"
                        class="form-control pl-5"
                        placeholder="Card Expiry"
                      />
                      <img
                        src="assets/images/iban.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 text-center">
                  <button type="submit" class="btn btn-save">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddAccount;
