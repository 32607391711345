import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getMembershipDetails, membershipList } from "../../api/memberships";
const initialState = {
  list: [],
  total: 10,
  details: null,
};
export const membershipListSelector = (state) => state.membership.list;
export const totalMembershipSelector = (state) => state.membership.total;
export const fetchMembershipList = createAsyncThunk(
  "MembershipList",
  async (obj, { dispatch }) => {
    const response = await membershipList(obj);
    return response;
  }
);

export const membershipDetailSelector = (state) => state.membership.details;
export const fetchMembershipdetails = createAsyncThunk(
  "Membershipdetails",
  async (obj, { dispatch }) => {
    const response = await getMembershipDetails(obj);
    return response;
  }
);

export const MembershipSlice = createSlice({
  name: "membership",
  initialState,
  reducers: {
    membershipDetails: (state, action) => {
      state.details = action.payload;
    },
  },
  extraReducers: {
    [fetchMembershipList.fulfilled]: (state, action) => {
      state.list = action?.payload?.data?.membership_list;
      state.total = action?.payload?.data?.total_membership;
    },
    [fetchMembershipdetails.fulfilled]: (state, action) => {
      state.details = action?.payload?.data?.membership_details;
    },
  },
});

export const { membershipDetails } = MembershipSlice.actions;

export default MembershipSlice.reducer;
