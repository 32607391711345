import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteURLData, getData, patchFormData } from "../../api";

const initialState = {
  total: {
    active: 0,
    inactive: 0,
    pending: 0,
    all: 0,
  },
  list: [],
};

export const EditRequestSlice = createSlice({
  name: "editRequest",
  initialState,
  reducers: {
    listing: (state, action) => {
      state.list = action.payload;
    },
    count: (state, action) => {
      state.total = action.payload;
    },
  },
});

export const FetchEditProfileRequestList = createAsyncThunk(
  "FetchEditProfileRequestList",
  async (obj, { dispatch }) => {
    try {
      const res = await getData("admin/editProfileRequestList", obj);
      console.log(res);
      if (res?.code === 1) {
        dispatch(listing(res?.data?.request_list));
        dispatch(
          count({
            active: res?.data?.total_accepted_request,
            inactive: res?.data?.total_rejected_request,
            pending: res?.data?.total_pending_request,
            all: res?.data?.total_request,
          })
        );
      }

      return res;
    } catch (error) {
      console.log(error);
    }
  }
);
export const ChangeEditProfileRequestStatus = createAsyncThunk(
  "ChangeEditProfileRequestStatus",
  async (obj, { dispatch }) => {
    try {
      const res = await patchFormData(
        "admin/changeEditProfileRequestStatus",
        obj
      );

      return res;
    } catch (error) {
      console.log(error);
    }
  }
);
export const DeleteEditProfileRequest = createAsyncThunk(
  "deleteEditProfileRequest",
  async (obj, { dispatch }) => {
    try {
      const res = await deleteURLData("admin/deleteEditProfileRequest", obj);

      return res;
    } catch (error) {
      console.log(error);
    }
  }
);
export const { listing, count } = EditRequestSlice.actions;

export default EditRequestSlice.reducer;
