import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { forgotPassword } from "../../api/setting";
import Loader from "../../component/Loader";

function ForgetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  return (
    <section className="bg-image pageAnimation">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-5 col-lg-6">
            <div className="mb-5">
              <img
                src="assets/images/headerlogo.png"
                className="img-fluid"
                alt="logo"
              />
            </div>
            <div className="logo-bg">
              <div className="mt-2">
                <img
                  src="assets/images/activity.png"
                  className="img-fluid lft-img"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-7 col-lg-6 mr-0 pr-0">
            <div className="form-bg">
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Kindly enter a valid email.")
                    .required("Required"),
                })}
                onSubmit={(values) => {
                  setLoading(true)
                  forgotPassword({
                    email: values.email?.trim()?.toLowerCase(),
                  }).then((res) => {
                    setLoading(false)
                    if (res?.code === 1) {
                      navigate(`/otp/${values.email?.trim()?.toLowerCase()}`, {
                        replace: true,
                      });
                      toast.success(res?.message);
                    } else {
                      toast.error(res?.message);
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form className="form-style" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 text-center mb-4">
                        <img
                          src="assets/images/forgot-password.png"
                          className="img-fluid my-3"
                          alt=""
                        />
                        <h2 className="mdl-ttl">Forgot Password</h2>
                        <p className="mdl-sbttl">
                          Enter your registered email address.
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-12 mb-4">
                        <div className="form-group">
                          <div className="input-container">
                            <input
                              className="form-control pl-5"
                              placeholder="Email Address"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <img
                              src="assets/images/Email-Address.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                        </div>
                        {errors.email && touched.email && (
                          <div className="text-danger">{errors.email}</div>
                        )}
                      </div>
                      <div className="col-sm-12 col-md-12 text-center">
                        <button type="submit" className="btn btn-save" disabled={loading}>
                          {loading ? <Loader /> : "Submit"}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgetPassword;
