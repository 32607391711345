import { FormikProvider, useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import BackButton from "../../component/BackButton";
import { SchoolListReducer } from "../slice";
import { FetchUserList, userListSelector } from "../user/UserSlice";
import Select from "react-select";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { detailing, FetchSmsDetail } from "./SmsSlice";
import Loader from "../../component/Loader";
import { areaCodeListingSelector, FetchAreaCodeList } from "../areacode/slice";
import { FetchDisciplineList } from "../discipline/DisciplineSlice";
import { saveAdminSMS } from "../../api/SMS";
function AddSMS() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const detail = useSelector((state) => state.sms.detail);
  const userList = useSelector(userListSelector);
  const schoolList = useSelector((state) => state.main.schools);
  console.log(schoolList, "SchoolList");
  const [loading, setLoading] = useState(false);
  const areacodeList = useSelector(areaCodeListingSelector);
  const disciplineList = useSelector((state) => state.discipline.list);
  // useEffect(() => {
  //   dispatch(SchoolListReducer());
  // }, [dispatch]);
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(FetchSmsDetail(id)).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, id]);
  useEffect(() => {
    return () => {
      dispatch(detailing(null));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(FetchAreaCodeList({}));
    dispatch(FetchDisciplineList({}));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      areacode: detail?.areacode || [],
      discipline: detail?.discipline || [],
      schoolId: detail?.schoolId || [],
      users: detail?.user_list?.map((it) => it?.user_details) || [],
      content: detail?.content,
      allVenues: false,
      onlySubscribedUser: false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      content: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      setSubmitting(true);

      const {
        areacode,
        discipline,
        schoolId,
        users,
        content,
        onlySubscribedUser,
      } = values;
      console.log(users, "Users list");

      let payload;
      payload = {
        areacode: JSON.stringify(areacode?.map((it) => it?.id)),
        discipline: JSON.stringify(discipline?.map((it) => it?.id)),
        schoolId: JSON.stringify(schoolId?.map((it) => it?.id)),
        users:
          users?.length > 0 ? JSON.stringify(users?.map((it) => it?.id)) : [],
        content,
        onlySubscribedUser,
      };
      console.log(payload, "Payload 1");
      saveAdminSMS(payload).then((res) => {
        setSubmitting(false);

        if (res?.code === 1) {
          toast.success(res?.message);
          navigate(-1);
        } else {
          toast.error(res?.message);
        }
      });
    },
  });
  useEffect(() => {
    dispatch(
      FetchUserList({
        venues:
          formik.values.schoolId?.length > 0
            ? JSON.stringify(formik.values.schoolId?.map((it) => it?.id))
            : "",
        areacode:
          formik.values.areacode?.length > 0
            ? JSON.stringify(formik.values?.areacode?.map((it) => it?.id))
            : "",
        student_type: formik.values.age_group,
        discipline:
          formik.values.discipline?.length > 0
            ? JSON.stringify(formik.values?.discipline?.map((it) => it?.id))
            : "",
      })
    );
  }, [
    dispatch,
    formik.values.schoolId,
    formik.values.age_group,
    formik.values.discipline,
    formik.values?.areacode,
  ]);

  useEffect(() => {
    if (formik.values?.areacode) {
      dispatch(
        SchoolListReducer({
          areacode_ids:
            formik.values?.areacode?.length > 0
              ? JSON.stringify(formik.values?.areacode?.map((it) => it?.id))
              : "",
        })
      );
    }
  }, [dispatch, formik?.values?.areacode]);

  const selectAllVenues = (checked) => {
    if (checked) {
      let arr = [...schoolList];
      formik.setFieldValue("schoolId", arr);
      formik.setFieldValue("allVenues", true);
    } else if (!checked) {
      formik.setFieldValue("schoolId", []);
      formik.setFieldValue("allVenues", false);
    }
  };

  const subscribedUser = () => {
    FetchUserList({ isSubscribed: 1 }).then((res) => {
      if (res?.code === 1) {
        let arr = res?.data?.user_list?.map((it) => it?.id);
        formik.setFieldValue("users", arr);
        formik.setFieldValue("onlySubscribedUser", true);
      }
    });
  };
  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <BackButton />

          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>{id ? "Edit Newsletter" : "Add New Newsletter"}</h3>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            {loading ? (
              <Loader />
            ) : (
              <FormikProvider value={formik}>
                <form class="form-style" onSubmit={formik.handleSubmit}>
                  <div class="form-group">
                    <div className="checkboxs">
                      <label>
                        <input
                          type="radio"
                          value={"onlySubscribedUser"}
                          name={`onlySubscribedUser`}
                          checked={formik.values.onlySubscribedUser === false}
                          onChange={(e) => {
                            formik.setFieldValue(`onlySubscribedUser`, false);
                          }}
                        />
                        <div className="div-bg">
                          <p>
                            <span>All user</span>
                          </p>
                        </div>
                      </label>
                      <label>
                        <input
                          type="radio"
                          value={"onlySubscribedUser"}
                          name={`onlySubscribedUser`}
                          checked={formik.values?.onlySubscribedUser}
                          onChange={(e) => {
                            formik.setFieldValue(`onlySubscribedUser`, true);
                            subscribedUser();
                          }}
                        />
                        <div className="div-bg">
                          <p>
                            <span>Susbscribed User</span>
                          </p>
                        </div>
                      </label>
                    </div>
                  </div>
                  {!formik.values.onlySubscribedUser && (
                    <>
                      {" "}
                      <div class="form-group">
                        <h5>Area</h5>
                        <Select
                          name="areacode"
                          isMulti
                          options={areacodeList}
                          getOptionLabel={(option) => `${option?.areacode}`}
                          getOptionValue={(option) => option?.id}
                          value={formik.values?.areacode || undefined}
                          onChange={(val) => {
                            console.log(val);
                            formik.setFieldValue("areacode", val);
                          }}
                        />
                        {/* <select
                      class="form-control form-select"
                      name="age_group"
                      onChange={formik.handleChange}
                      value={formik.values.age_group}
                    >
                      <option value="">Select Area</option>
                      {areacodeList?.map((it, i) => (
                        <option value={it?.areacode} key={it?.id}>
                          {it?.areacode}
                        </option>
                      ))}
                    </select> */}
                      </div>
                      <div class="form-group">
                        <h5>Discipline</h5>
                        <Select
                          name="discipline"
                          isMulti
                          options={disciplineList}
                          getOptionLabel={(option) => `${option?.name}`}
                          getOptionValue={(option) => option?.id}
                          value={formik.values?.discipline || undefined}
                          onChange={(val) => {
                            formik.setFieldValue("discipline", val);
                          }}
                        />
                        {/* <select
                      class="form-control form-select"
                      name="age_group"
                      onChange={formik.handleChange}
                      value={formik.values.discipline}
                    >
                      <option value="">Select Discipline</option>
                      {disciplineList?.map((it, i) => (
                        <option value={it?.name} key={it?.id}>
                          {it?.name}
                        </option>
                      ))}
                    </select> */}
                      </div>
                      <div class="form-group">
                        <h5>Select Venue</h5>

                        <Select
                          name="schoolId"
                          isMulti
                          options={schoolList}
                          getOptionLabel={(option) => `${option?.name}`}
                          getOptionValue={(option) => option}
                          value={formik.values?.schoolId || undefined}
                          onChange={(val) => {
                            console.log(val, "Venue");
                            formik.setFieldValue("schoolId", val);
                          }}
                        />
                        {schoolList?.length > 0 && (
                          <>
                            {" "}
                            <input
                              type="checkbox"
                              className="px-1"
                              checked={formik.values.allVenues}
                              onChange={(e) => {
                                let checked = e.target.checked;
                                selectAllVenues(checked);
                              }}
                            />
                            <label htmlFor="allVenues">All Venues</label>
                          </>
                        )}
                      </div>
                      <div class="form-group">
                        <h5>Select Users</h5>

                        <Select
                          name="users"
                          isMulti
                          options={userList}
                          getOptionLabel={(option) =>
                            `${option?.first_name} ${option?.last_name} - ${option?.email}`
                          }
                          getOptionValue={(option) => option?.user_details}
                          value={formik.values?.users || undefined}
                          onChange={(val) => {
                            console.log(val);
                            formik.setFieldValue("users", val);
                          }}
                        />
                      </div>
                    </>
                  )}

                  <div className="form-group">
                    <h5>Content</h5>
                    {formik.errors.content && formik.touched.content && (
                      <span className="text-danger">
                        {formik.errors.content}
                      </span>
                    )}
                    <div>
                      <textarea
                        rows={20}
                        height={"100px"}
                        value={formik.values.content}
                        name="content"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Write content..."
                        className="form-control p-2"
                      ></textarea>
                      {/* <ReactQuill
                        theme="snow"
                        ref={quillRef}
                        modules={modules}
                        className="form-control h-auto p-2"
                        value={formik.values.email_content}
                        onChange={(val, delta, source, editor) => {
                          console.log(val);
                          formik.setFieldValue(
                            "email_content",
                            editor.getHTML()
                          );
                        }}
                      /> */}
                    </div>
                  </div>

                  <div class="text-center">
                    <button
                      class="btn btn-accept btn-view mt-2"
                      type="submit"
                      // onClick={handleSubmit}
                      disabled={submitting}
                    >
                      {submitting ? <Loader /> : "Add"}
                    </button>
                  </div>
                </form>
              </FormikProvider>
            )}
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddSMS;
