import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { removeDiscipline } from "../../api/Discipline";
import Loader from "../../component/Loader";
import DeleteModal from "../../component/Modal/Delete";
import Paginations from "../../component/Pagination";
import { FetchDisciplineList } from "./DisciplineSlice";
import BackButton from "./../../component/BackButton";

function Discipline() {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.discipline.list);
  const total = useSelector((state) => state.discipline.total);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  const confirmDelete = () => {
    removeDiscipline({ discipline_id: delId }).then((res) => {
      if (res?.code === 1) {
        setDelId("");
        getList();
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    });
  };

  const getList = useCallback(() => {
    setLoading(true);
    dispatch(FetchDisciplineList({ page, search, limit: 10 })).then(() => {
      setLoading(false);
    });
  }, [dispatch, search, page]);

  useEffect(() => {
    getList();
  }, [getList]);
  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="link-active shadow">
              <p>
                <img src="assets/images/event-calender-selected.png" alt="" />{" "}
                discipline {total}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search Discipline By Name"
                  value={search}
                  onChange={(e) => {
                    setPage(1);

                    setSearch(e.target.value);
                  }}
                />
              </form>
              <Link to={`/add-discipline`} className="">
                <img
                  src="assets/images/addBtn.png"
                  alt=""
                  className="add-new"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">ID</th>
                    {/* <th scope="col">Image</th> */}
                    <th scope="col">Discipline name</th>

                    <th scope="col">action</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      {" "}
                      <td colSpan={3}>
                        <Loader />
                      </td>
                    </tr>
                  ) : list?.length > 0 ? (
                    list?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item?.id || "NA"}</td>
                          {/* <td>
                            <img
                              src={
                                item?.image?.includes("http")
                                  ? item?.image
                                  : "assets/images/headerlogo.png"
                              }
                              className="table-img"
                              alt=""
                            />
                          </td> */}
                          <td>{item?.name || "NA"}</td>

                          <td>
                            <div className="d-flex justify-content-center action">
                              {/* <button className="btn">
                                <Link to={`/view/event-type/${item?.id}`}>
                                  <img src="assets/images/view.png" alt="" />
                                </Link>
                              </button> */}
                              <button className="btn" title="Edit">
                                <Link to={`/edit/discipline/${item?.id}`}>
                                  <img src="assets/images/edit.png" alt="" />
                                </Link>
                              </button>
                              <button
                                className="btn"
                                onClick={() => {
                                  setDelId(item.id);
                                  handleShow();
                                }}
                                title="Delete"
                              >
                                <img src="assets/images/delete.png" alt="" />
                              </button>
                              {/* <button className="btn">
                                <input
                                  type="checkbox"
                                  className="toggle"
                                  checked=""
                                />
                              </button> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10}>No data found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {total > 10 && total && (
              <Paginations handlePage={setPage} page={page} total={total} />
            )}
          </div>
        </div>
      </div>

      <DeleteModal
        page="Discipline"
        show={show}
        handleShow={() => {
          handleShow();
        }}
        cancel={() => {
          setShow(false);
        }}
        confirmDelete={confirmDelete}
      />
    </article>
  );
}

export default Discipline;
