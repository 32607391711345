import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  list: [1, 1],
  total: 10,
  details: {},
};

export const ClassSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
  // extraReducers: {
  //   [someapi.fulfilled]: (state, action) =>{
  //     state.details = action?.payload?.data?.event
  //   }
  // }
});

export const { reducerName } = ClassSlice.actions;

export default ClassSlice.reducer;
