import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { eventTypeList, getEventTypeDetails } from "../../api/eventType";
import { registerDetails, registerList } from "../../api/register";
const initialState = {
  list: [],
  total: 0,
  details: {},
};

export const EventTypeSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    list: (state, action) => {
      state.list = action.payload;
    },
    detail: (state, action) => {
      state.details = action.payload;
    },
    totalCount: (state, action) => {
      state.total = action.payload;
    },
  },
});

export const FetchEventTypeList = createAsyncThunk(
  "eventTypeList",
  async (obj, { dispatch }) => {
    try {
      const response = await eventTypeList(obj);
      if (response?.code === 1) {
        dispatch(list(response?.data?.event_type_list));
        dispatch(totalCount(response?.data?.total_event_type));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchEventTypeDetail = createAsyncThunk(
  "EventTypeDetail",
  async (obj, { dispatch }) => {
    const response = await getEventTypeDetails(obj);
    if (response?.code === 1) {
      console.log(response, "Event type details");
      dispatch(detail(response?.data?.event_type_details));
    }
    return response;
  }
);

export const { list, detail, totalCount } = EventTypeSlice.actions;

export default EventTypeSlice.reducer;
