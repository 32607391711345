import React, { Component, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { detail, FetchSubAdminsDetails } from "./SubAdminSlice";
// import { getAdminDetails } from "../slice";

function ViewSubadmin() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((store) => store.loading);
  const {
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    setValue,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",

    shouldFocusError: true,
    defaultValues: {
      modules: {
        account_deletion: false,
        area: false,
        booking: false,
        classes: false,
        discipline: false,
        earning: false,
        event_Type: false,
        events: false,
        import_export_data: false,
        membership: false,
        newsletter: false,
        notification: false,
        register: false,
        teacher: false,
        user: false,
        venue: false,
      },
    },
  });
  const watchRoles = watch("modules");

  const getDetails = useCallback(() => {
    if (id) {
      dispatch(FetchSubAdminsDetails(id)).then((res) => {
        console.log(res);
        let data = res?.payload?.data?.user_details;
        reset({
          id: data?.id,
          name: data?.name,
          email: data?.email,
          image: data?.image,
          modules: data?.modules,
        });
      });
    }
  }, [dispatch, id, reset]);

  useEffect(() => {
    if (id) {
      getDetails();
    }
  }, [id, getDetails]);

  useEffect(() => {
    return () => {
      dispatch(detail(null));
    };
  }, [dispatch]);

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Sub Admin Details</h4>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-sm-12">
            <div className="form-style">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="form-group text-center">
                    <div className="upload-btn-wrapper">
                      <button className="btn">
                        <img
                          src={
                            getValues("image")
                              ? getValues("image")
                              : "assets/images/upload-img.png"
                          }
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-4">
                  <label>Name</label>
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Name"
                        value={getValues("name")}
                        disabled
                      />
                      <img
                        src="assets/images/user.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                  <div className="form-group">
                    <label>Email</label>
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Enter Primary Email"
                        disabled
                        value={getValues("email")}
                      />
                      <img
                        src="assets/images/email.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group">
                    <h3>Permission & Privileges</h3>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="table-responsive">
                    <table className="table storetable">
                      <thead className="bg-transparent">
                        <tr>
                          <th scope="col" className="bg-transparent">
                            Modules
                          </th>
                          <th scope="col" className="text-end bg-transparent">
                            action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object?.keys(watchRoles)
                          ?.slice(2)
                          ?.map(function (key, index) {
                            return (
                              <tr>
                                <td className="ps-3">{key}</td>
                                <td>
                                  <div className="d-flex justify-content-end action">
                                    <button class="btn">
                                      <Controller
                                        name={`modules.${key}`}
                                        control={control}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <input
                                            type="checkbox"
                                            className="toggle"
                                            checked={getValues(
                                              `modules.${key}`
                                            )}
                                            disabled
                                            onChange={(e) => {
                                              onChange(e.target.checked);
                                            }}
                                          />
                                        )}
                                      />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default ViewSubadmin;
