import {
  deleteData,
  getData,
  getDataforUrl,
  patchFormData,
  postFormData,
} from "./index";
// register_for (class/event), page, limit, schoolId, teacherId
export const registerList = async (payload) => {
  return getData("class/registerList", payload).then((data) => {
    return data;
  });
};
export const registerDetails = async (payload) => {
  return getData("class/registerDetails", payload).then((data) => {
    return data;
  });
};
// //  booking_id, status
// export const changeBookingStatus = async (payload) => {
//   return patchFormData("admin/changeBookingStatus", payload).then((data) => {
//     return data;
//   });
// };
// // bookingId
// export const removeBooking = async (payload) => {
//   return deleteData("admin/removeBooking", payload).then((data) => {
//     return data;
//   });
// };

export const saveClassAttendance = async (payload) => {
  return postFormData("teacher/saveAttendance", payload).then((data) => {
    return data;
  });
};

export const saveEventAttendance = async (payload) => {
  return postFormData("teacher/saveEventAttendance", payload).then((data) => {
    return data;
  });
};

export const addStudentInClass = async (payload) => {
  return postFormData("class/addStudentInClass", payload).then((data) => {
    return data;
  });
};

export const addStudentInEvent = async (payload) => {
  return postFormData("class/addStudentInEvent", payload).then((data) => {
    return data;
  });
};
