import {
  deleteData,
  getData,
  getDataforUrl,
  patchFormData,
  postFormData,
  putFormData,
} from "./index";

export const SchoolListApi = async (payload) => {
  return getData("admin/schoolList", payload).then((data) => {
    return data;
  });
};

export const AddSchoolApi = async (payload) => {
  return postFormData("admin/addSchool", payload).then((data) => {
    return data;
  });
};

export const SchoollDetailApi = async (payload) => {
  return getData(`admin/getSchoolDetails/${payload?.id}`).then((data) => {
    return data;
  });
};

export const TeacherdetailApi = async (payload) => {
  return getData(`admin/getTeacherDetails/${payload?.id}`).then((data) => {
    return data;
  });
};

export const SchoollDeleteApi = async (payload) => {
  return deleteData("admin/removeSchool", payload).then((data) => {
    return data;
  });
};
// params: search, page, limit, schoolId

export const ClassListApi = async (payload) => {
  return getData("admin/classList", payload).then((data) => {
    return data;
  });
};

export const ClassDetailApi = async (payload) => {
  return getData(`admin/getClassDetails/${payload?.id}`).then((data) => {
    return data;
  });
};
export const ClassDeleteApi = async (payload) => {
  return deleteData("admin/removeClass", payload).then((data) => {
    return data;
  });
};
// params: class_id, status(0/1)

export const UpdateClassApi = async (payload) => {
  return patchFormData("admin/activateDeactivateClass", payload).then(
    (data) => {
      return data;
    }
  );
};
// params: schoolId, name, image, total_seats, child_price, adult_price, about, time_slots [{date: "yyyy-mm-dd", time: 13:30, time_slot_for}], class_id
export const AddClassApi = async (payload) => {
  return postFormData("admin/addClass", payload).then((data) => {
    return data;
  });
};

export const cancelClass = async (payload) => {
  return patchFormData("class/cancelClass", payload).then((data) => {
    return data;
  });
};
export const changeStudentClass = async (payload) => {
  return postFormData("class/changeStudentClass", payload).then((data) => {
    return data;
  });
};

export const RemoveClassTimeSlot = async (payload) => {
  return deleteData("admin/removeClassTimeSlot", payload).then((data) => {
    return data;
  });
};
