import React from "react";
import { Modal } from "react-bootstrap";

function UnArchive({ show, handleShow, pageName }) {
  const Comp = () => {
    return (
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content body-bg">
          <div className="modal-header border-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                handleShow();
              }}
            ></button>
          </div>
          <div className="modal-body text-center mb-4">
            <h4>Unarchive the {pageName}?</h4>
            <p>Are you sure, you want to unarchive this {pageName}?</p>
            <div className="view-btns">
              <button className="btn btn-reject btn-view mt-2">No</button>
              <button className="btn btn-accept btn-view mt-2">Yes</button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <Modal show={show} onHide={handleShow} dialogAs={Comp} />;
}

export default UnArchive;
