import React from "react";
import { Link } from "react-router-dom";

function Refund() {
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-7 mb-3">
            <ul
              className="nav nav-pills group-nav mb-0 mt-0 payment-refund"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <Link to="/earnings-by-staff" className="nav-link">
                  earnings by teacher
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link to="/earnings-by-membership" className="nav-link">
                  earnings by membership
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link to="/refund" className="nav-link active">
                  refund
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-5 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search earning by teacher name"
                />
              </form>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="text-end">
              <Link
                to="/add/payment-detail"
                className="btn btn-view mb-3 btn-accept"
              >
                Add Payment Details
              </Link>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">teacher name</th>
                    <th scope="col">amount earned</th>
                    <th scope="col">admin's earning</th>
                    <th scope="col">amount paid</th>
                    <th scope="col">amount due</th>
                    <th scope="col">payment date</th>
                    <th scope="col">action</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>thomas luke</td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 30,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 20,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>12-01-22</td>
                    <td>
                      <div className="d-flex justify-content-center action">
                        <button className="btn">
                          <a href="earnings-by-teacher.html">
                            <img src="assets/images/view.png" alt="" />
                          </a>
                        </button>
                        <button
                          className="btn"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteModal"
                        >
                          <img src="assets/images/delete.png" alt="" />
                        </button>
                        <button className="btn">
                          <input
                            type="checkbox"
                            className="toggle"
                            checked=""
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>thomas luke</td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 30,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 20,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>12-01-22</td>
                    <td>
                      <div className="d-flex justify-content-center action">
                        <button className="btn">
                          <a href="payment-detail.html">
                            <img src="assets/images/view.png" alt="" />
                          </a>
                        </button>
                        <button
                          className="btn"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteModal"
                        >
                          <img src="assets/images/delete.png" alt="" />
                        </button>
                        <button className="btn">
                          <input
                            type="checkbox"
                            className="toggle"
                            checked=""
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>thomas luke</td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 30,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 20,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>12-01-22</td>
                    <td>
                      <div className="d-flex justify-content-center action">
                        <button className="btn">
                          <a href="payment-detail.html">
                            <img src="assets/images/view.png" alt="" />
                          </a>
                        </button>
                        <button
                          className="btn"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteModal"
                        >
                          <img src="assets/images/delete.png" alt="" />
                        </button>
                        <button className="btn">
                          <input
                            type="checkbox"
                            className="toggle"
                            checked=""
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>thomas luke</td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 30,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 20,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>12-01-22</td>
                    <td>
                      <div className="d-flex justify-content-center action">
                        <button className="btn">
                          <a href="payment-detail.html">
                            <img src="assets/images/view.png" alt="" />
                          </a>
                        </button>
                        <button
                          className="btn"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteModal"
                        >
                          <img src="assets/images/delete.png" alt="" />
                        </button>
                        <button className="btn">
                          <input
                            type="checkbox"
                            className="toggle"
                            checked=""
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>thomas luke</td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 30,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 20,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>12-01-22</td>
                    <td>
                      <div className="d-flex justify-content-center action">
                        <button className="btn">
                          <a href="payment-detail.html">
                            <img src="assets/images/view.png" alt="" />
                          </a>
                        </button>
                        <button
                          className="btn"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteModal"
                        >
                          <img src="assets/images/delete.png" alt="" />
                        </button>
                        <button className="btn">
                          <input
                            type="checkbox"
                            className="toggle"
                            checked=""
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>thomas luke</td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 30,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 20,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>12-01-22</td>
                    <td>
                      <div className="d-flex justify-content-center action">
                        <button className="btn">
                          <a href="payment-detail.html">
                            <img src="assets/images/view.png" alt="" />
                          </a>
                        </button>
                        <button
                          className="btn"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteModal"
                        >
                          <img src="assets/images/delete.png" alt="" />
                        </button>
                        <button className="btn">
                          <input
                            type="checkbox"
                            className="toggle"
                            checked=""
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>thomas luke</td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 30,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 20,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>12-01-22</td>
                    <td>
                      <div className="d-flex justify-content-center action">
                        <button className="btn">
                          <a href="payment-detail.html">
                            <img src="assets/images/view.png" alt="" />
                          </a>
                        </button>
                        <button
                          className="btn"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteModal"
                        >
                          <img src="assets/images/delete.png" alt="" />
                        </button>
                        <button className="btn">
                          <input
                            type="checkbox"
                            className="toggle"
                            checked=""
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>thomas luke</td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 30,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 20,000
                    </td>
                    <td>
                      <i className="fa fa-gbp" aria-hidden="true"></i> 5,000
                    </td>
                    <td>12-01-22</td>
                    <td>
                      <div className="d-flex justify-content-center action">
                        <button className="btn">
                          <a href="payment-detail.html">
                            <img src="assets/images/view.png" alt="" />
                          </a>
                        </button>
                        <button
                          className="btn"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteModal"
                        >
                          <img src="assets/images/delete.png" alt="" />
                        </button>
                        <button className="btn">
                          <input
                            type="checkbox"
                            className="toggle"
                            checked=""
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default Refund;
