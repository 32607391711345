import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Routers from "../routes/Router";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FetchSubAdminsDetails } from "../features/subAdmin/SubAdminSlice";

function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = useSelector((store) => store.main.id);
  const data = useSelector((store) => store.subAdmin.detail);

  async function logout(e) {
    await localStorage.removeItem('token');
    navigate("/");
  }

  // const [data, setData] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(FetchSubAdminsDetails(id));
      // .then((res) => {
      //   console.log(res);
      //   let datas = res?.payload?.data?.user_details;
      //   setData(datas);
      // });
    }
  }, [dispatch, id]);

  return (
    <div className="col-lg-3 store-sidebar admin-sidebar">
      <div className="shadow-part">
        <Link
          className="navbar-brand bg-none px-3 mx-lg-auto d-lg-block"
          to="/users"
        >
          <img
            src="assets/images/headerlogo.png"
            className="img-fluid"
            alt="img"
          />
        </Link>
        <button
          className="btn btn-blue float-end menu-toggle d-lg-none"
          data-bs-target="#sidebar"
          data-bs-toggle="collapse"
        >
          <i className="fa fa-bars"></i>
        </button>
        <nav className="collapse d-lg-block" id="sidebar">
          <ul className="list-unstyled mb-0">
            {Routers?.map((props, i) => {
              if (props?.hidden) {
                return null;
              } else {
                if (data?.admin_type?.toLowerCase() === "super admin") {
                  return (
                    <li key={i}>
                      <Link
                        to={props?.path}
                        className={
                          location?.pathname?.includes(props?.subname) &&
                          location?.pathname === props.path
                            ? "active"
                            : ""
                        }
                      >
                        <div>
                          <img
                            src={props.inactiveImage}
                            className="img-fluid sidebar-icon inactive-icon"
                            alt=""
                          />
                          <img
                            src={props.activeImage}
                            className="img-fluid sidebar-icon active-icon"
                            alt=""
                          />
                        </div>
                        <span>{props?.name} </span>
                      </Link>
                    </li>
                  );
                } else {
                  for (const key in data?.modules) {
                    if (Object.hasOwnProperty.call(data?.modules, key)) {
                      const element = data?.modules[key];
                      if (key.includes(props.subname) && element === true) {
                        return (
                          <li key={i}>
                            <Link
                              to={props?.path}
                              className={
                                location?.pathname?.includes(props?.subname) &&
                                location?.pathname === props.path
                                  ? "active"
                                  : ""
                              }
                            >
                              <div>
                                <img
                                  src={props.inactiveImage}
                                  className="img-fluid sidebar-icon inactive-icon"
                                  alt=""
                                />
                                <img
                                  src={props.activeImage}
                                  className="img-fluid sidebar-icon active-icon"
                                  alt=""
                                />
                              </div>
                              <span>{props?.name} </span>
                            </Link>
                          </li>
                        );
                      }
                    }
                  }
                }
              }
            })}
            <li className="mt-1">
              <Link className="dropdown-item" to="/change-password">
                <span>change password</span>
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to={() => {
                  return false;
                }}
                onClick={(e) => {
                  logout(e);
                }}
              >
                <span>
                  <i className="fa fa-sign-out" aria-hidden="true"></i> logout
                </span>
              </Link>
            </li>

            {/* <li className="">
              <a
                href="#setting"
                className="show-collape-border"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="setting"
              >
                <div>
                  <img
                    src="assets/images/Settings.png"
                    className="img-fluid sidebar-icon inactive-icon"
                    alt=""
                  />
                  <img
                    src="assets/images/Settings-selected.png"
                    className="img-fluid sidebar-icon active-icon"
                    alt=""
                  />
                </div>
                <span>setting </span>
              </a>
              <div className="collapse collapse-border" id="setting">
               
              </div>
            </li> */}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Sidebar;
