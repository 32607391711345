import { FormikProvider, useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import BackButton from "../../component/BackButton";
import Loader from "../../component/Loader";
import { areaCodeListingSelector, FetchAreaCodeList } from "../areacode/slice";
import { FetchDisciplineList } from "../discipline/DisciplineSlice";
import * as Yup from "yup";
import { config, htmlStringToPdf, monthOption } from "../../utils";
import Phone from "../../component/Phone/Phone";
import moment from "moment";
import { fetchMembershipList } from "../membership/MembershipSlice";
import { SchoolListReducer } from "../slice";
import { details } from "./studentslice";
import { FetchUserList, userListSelector } from "../user/UserSlice";
import Select from "react-select";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { isValidPhoneNumber } from "libphonenumber-js";
import { addStudent } from "../../api/student";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import S3 from "aws-s3";
import { sendMembershipSticker } from "../../api/memberships";
const S3Client = new S3(config);
const styles = {
  control: (sty) => {
    return {
      ...sty,
      borderWidth: 0,
    };
  },
};

function AddStudent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [areacode, setAreacode] = useState("");
  const areaList = useSelector(areaCodeListingSelector);
  const disciplines = useSelector((state) => state.discipline.list);
  const list = useSelector(userListSelector);
  const [yearList, setYearList] = useState([]);
  const [membershipYearList, setMembershipYearList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const getDisciplines = useCallback(() => {
    dispatch(FetchDisciplineList({ page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    if (membershipYearList?.length === 0) {
      let years = [];
      let now = moment().add(10, "years").format("YYYY");
      let startFrom = moment().format("YYYY");
      for (let i = startFrom; i <= now; i++) {
        years.push(i?.toString());
      }
      years.sort((a, b) => b - a);
      setMembershipYearList(years);
    }
  }, [membershipYearList?.length]);

  const getUserList = useCallback(() => {
    dispatch(FetchUserList({ page: 1 })).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    getUserList();
  }, [getUserList]);

  useEffect(() => {
    getDisciplines();
  }, [getDisciplines]);

  useEffect(() => {
    dispatch(FetchAreaCodeList({}));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(details(null));
    };
  }, [dispatch]);

  const editSutdentSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    dob_year: Yup.string().required("Required"),
    dob_month: Yup.string().required("Required"),
    dob_day: Yup.string().required("Required"),
    // student_type: Yup.string().required("Required"),
    discipline: Yup.string().required("Required"),
    mobile: Yup.string(),
    medical_note: Yup.string(),
    image: Yup.string(),
    schoolId: Yup.string().required("Required"),
    areacode: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    trialAccountStatus: Yup.string().required("Required"),
    membership_level: Yup.object().when("trialAccountStatus", {
      is: (value) => {
        return value === "3";
      },
      then: Yup.object().required("Required"),
    }),
    userId: Yup.object().required("Kindly select the account holder."),
  });

  const formik = useFormik({
    initialValues: {
      userId: "",
      venueList: [],
      membership_levels: [],
      first_name: "",
      last_name: "",
      dob_year: "",
      dob_month: "",
      dob_day: "",
      student_type: "",
      discipline: "",
      address: "",
      mobile: "",
      medical_note: "",
      image: "",
      schoolId: "",
      areacode: "",
      gender: "",
      membership_level: "",
      trialAccountStatus: "",
    },
    enableReinitialize: true,
    validationSchema: editSutdentSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      console.log(values);
      const countryCode = values?.mobile
        ? formatPhoneNumberIntl(values?.mobile).split(" ")[0]
        : "";
      const payload = {
        userId: values?.userId?.id,
        first_name: values?.first_name,
        last_name: values?.last_name,
        dob: values?.dob_year
          ? moment(
              `${values?.dob_year}-${values?.dob_month}-${values?.dob_day}`
            ).format("YYYY-MM-DD")
          : "",
        student_type: st_type,
        discipline: values?.discipline,
        address: values?.address,
        emergency_contactNo: values?.mobile
          ? values?.mobile.replace("countryCode", "")
          : "",
        emergency_countryCode: values?.mobile ? countryCode : "",
        medical_note: values?.medical_note,
        image: values?.image,
        schoolId: values?.schoolId,
        areacode: values?.areacode,

        gender: values?.gender,
        trial_membership: values.trialAccountStatus === "1" ? 1 : 0,
        membership_level:
          values.trialAccountStatus === "3" ? values?.membership_level?.id : "",
        expiry_date: moment(
          `${values?.membership_year}-${
            values?.membership_month < 10
              ? `0${values?.membership_month}`
              : values?.membership_month
          }-${
            values?.membership_day < 10
              ? `0${values?.membership_day}`
              : values?.membership_day
          }`,
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD"),
        // no_of_days:
        //   values.trialAccountStatus === "3"
        //     ? values?.membership_level?.no_of_days
        //     : "",
        // price:
        //   values?.student_type?.toLowerCase() === "child"
        //     ? values?.membership_level?.child_price
        //     : values?.student_type?.toLowerCase() === "adult"
        //     ? values?.membership_level?.adult_price
        //     : "",
      };
      console.log(payload, "Payload");
      addStudent(payload).then((res) => {
        if (res?.code === 1) {
          toast.success(res?.message);

          console.log(res, "Return after add student");
          const studentData = res?.data?.student;
          const userData = res?.data?.user_address;

          if (payload?.membership_level) {
            const sendHtmlAsString = async (nameD = "") => {
              let htmlPart = `<!DOCTYPE html>
  <html lang="en">

  <head>
      <meta charset="utf-8">
      <meta http-equiv="Content-Type" content="text/html;charset=UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">

      <title>Membership Details</title>

  </head>

  <body style="width: 100 mm;height: 56 mm;">
      <div class="mw-body"
          style="width: 100%;height: 100%;
      font-family: Roboto Slab,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji">
          <div >
              <table style="width: 100%;height: 100%;border-collapse: separate;
              border-spacing: 0 0em;text-transform: uppercase; font-size: 12px; font-weight: 700;margin: 1mm; padding: 5px; box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);">
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
      border-right: 0;"><span>name</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span style="color: #ff0000;"> ${
                        studentData?.first_name
                      } ${studentData?.last_name}</span></td>

                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>address</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span
                              style="color: #ff0000;"> ${
                                userData?.property_name
                              }, ${
                userData?.property_number
                  ? `${userData?.property_number}, `
                  : ""
              } ${userData?.street_number}</span></td>

                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>town</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;"> ${
                            userData?.town
                          }</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>postcode</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;"> ${
                            userData?.postal_code
                          }</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>dob</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;"> ${moment(
                            studentData?.dob
                          ).format("DD MMM YYYY")}</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>school</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;"> ${
                            studentData?.school_details?.name
                          }</span></td>
                  </tr>
                     <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>discipline</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;"> ${
                            studentData?.discipline_details?.name
                          }</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>first licence</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;"> ${moment(
                            studentData?.membership_details?.start_date
                          ).format("DD MMM YYYY")}</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;
                          border-right: 0;"><span>date</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;
                          "><span style="color: #ff0000;"> ${moment(
                            studentData?.membership_details?.valid_till
                          ).format("DD MMM YYYY")}</span></td>
                  </tr>
              </table>
          </div>
  </body>

  </html>`;

              const pdfOutput = await htmlStringToPdf(htmlPart);

              return pdfOutput;
            };

            sendHtmlAsString().then((ob) => {
              if (ob) {
                console.log(ob);
                const newFileName = uuidv4();
                S3Client.uploadFile(ob, newFileName)
                  .then((data) => {
                    console.log(data, "S3 data");

                    if (data?.location) {
                      const stickerPayload = {
                        user_membership_id: studentData?.membership_details?.id,
                        file_url: data?.location,
                        userId: studentData?.userId,
                      };
                      sendMembershipSticker(stickerPayload).then((res) => {
                        setSubmitting(false);

                        if (res?.code === 1) {
                          // setShowBuyMembership(false);
                          navigate("/student", { replace: true });
                          toast.success(res?.message);
                        } else {
                          toast.error(res?.message);
                        }
                      });
                    }
                  })

                  .catch((err) => console.error(err));
              }
            });
          }

          setSubmitting(false);
          navigate(-1, { replace: true });
        } else {
          toast.error(res?.meessage);
        }
      });
    },
  });

  const getMembershipListings = useCallback(
    (student_type, discipline) => {
      if (student_type && discipline) {
        dispatch(
          fetchMembershipList({
            page: 1,
            limit: 10000,
            student_type: student_type,
            discipline: discipline,
          })
        ).then((res) => {
          if (res?.payload?.code === 1) {
            const data = res?.payload?.data?.membership_list;
            formik.setFieldValue(`membership_levels`, data);
          }
        });
      }
    },
    [dispatch, formik]
  );
  const handleStudentType = () => {
    // if (day && month && year) {
    //   const bday = moment(`${year}-${month}-${day}`);
    //   const today = moment(new Date());
    //   const diff = today.diff(bday, "years");

    //   if (diff >= 4 && diff <= 16) {
    //     formik.setFieldValue(`student_type`, "child");
    //     formik.setFieldError(`student_type`, "");
    //   } else if (diff >= 16) {
    //     formik.setFieldValue(`student_type`, "adult");
    //     formik.setFieldError(`student_type`, "");
    //   } else {
    //     formik.setFieldValue(`student_type`, "");
    //     formik.setFieldErro(`student_type`, "Must be at least 4 years.");
    //   }
    // }

    let day = formik.values.dob_day;
    let month = formik.values.dob_month;
    let year = formik.values.dob_year;
    if (day && month && year) {
      const date1 = new Date();
      const date2 = new Date(`${month}/${day}/${year}`);
      let diff = (date1.getTime() - date2.getTime()) / 1000;
      diff /= 60 * 60 * 24;
      diff = Math.abs(Math.round(diff / 365.25));

      return diff >= 4 && diff <= 16 ? "child" : "adult";
    }
  };

  const st_type = handleStudentType();

  const getList = (area) => {
    if (area) {
      dispatch(
        SchoolListReducer({
          page: 1,
          limit: 10000,
          areacode: area,
        })
      ).then((res) => {
        if (res?.payload?.code === 1) {
          const data = res?.payload?.data?.school_list;
          formik.setFieldValue(`venueList`, data);
        }
      });
    }
  };

  const isValid = (num) => {
    console.log(num, "NUm");
    if (num) {
      const valid = isValidPhoneNumber(num);

      console.log(valid, num);
      if (valid) {
        formik.setFieldValue("mobile", num);
      } else {
        formik.setFieldError("mobile", "Kindly enter a valid number.");
      }
      return valid;
    }
    return false;
  };

  useEffect(() => {
    if (yearList?.length === 0) {
      let minYear = moment().subtract(4, "years").format("YYYY");
      let years = [];
      for (let i = 1920; i <= minYear; i++) {
        years.push(i?.toString());
      }
      years.sort((a, b) => b - a);
      setYearList(years);
    }
  }, [yearList?.length]);

  if (loading) {
    return (
      <article className="col-lg-9 articleClass">
        <Loader />
      </article>
    );
  } else {
    return (
      <article className="col-lg-9 articleClass">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <BackButton />

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3>{"Add New Student"}</h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12">
              <FormikProvider value={formik}>
                <form className="form-style" onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="row position-relative">
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Account Holder Name</label>

                          {formik.errors?.userId && formik.touched?.userId && (
                            <span className="text-red ms-1">
                              {formik.errors?.userId}
                            </span>
                          )}
                          <div className="input-container">
                            <Select
                              isSearchable
                              onChange={(val) => {
                                formik.setFieldValue(`userId`, val);
                              }}
                              value={formik.values?.userId || ""}
                              options={list || undefined}
                              getOptionLabel={(option) => {
                                return `${option?.first_name} ${option?.last_name}`;
                              }}
                              getOptionValue={(option) => {
                                return option?.id;
                              }}
                              className="form-control"
                              styles={styles}
                              name="userId"
                            />

                            <img
                              src="assets/images/user.png"
                              className="field-icon"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Student First Name</label>
                          {formik.errors?.first_name &&
                            formik.touched?.first_name && (
                              <span className="text-red ms-1">
                                {formik.errors?.first_name}
                              </span>
                            )}
                          <div className="input-container">
                            <input
                              type="text"
                              className="form-control pl-5"
                              placeholder="Student First Name"
                              name={`first_name`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.first_name}
                            />
                            <img
                              src="assets/images/First-Name.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Student Last Name</label>
                          {formik.errors?.last_name &&
                            formik.touched?.last_name && (
                              <span className="text-red ms-1">
                                {formik.errors?.last_name}
                              </span>
                            )}
                          <div className="input-container">
                            <input
                              type="text"
                              className="form-control pl-5"
                              placeholder="Student Last Name"
                              name={`last_name`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.last_name}
                            />
                            <img
                              src="assets/images/First-Name.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-sm-12 col-md-12 mb-4">
                        <div className="">
                          <label className="fw-bold">Gender</label>

                          <div className="termCustomRadio">
                            <p>
                              <span className="me-3">
                                <input
                                  type="radio"
                                  id={`male`}
                                  name={`gender`}
                                  value="male"
                                  checked={formik.values?.gender === "male"}
                                  onChange={formik.handleChange}
                                />
                                <label htmlFor={`male`}>Male</label>
                              </span>
                              <span className="me-3">
                                <input
                                  type="radio"
                                  id={`female`}
                                  name={`gender`}
                                  value="female"
                                  checked={formik.values?.gender === "female"}
                                  onChange={formik.handleChange}
                                />
                                <label htmlFor={`female`}>Female</label>
                              </span>
                              <span className="me-3">
                                <input
                                  type="radio"
                                  id={`other`}
                                  name={`gender`}
                                  value="other"
                                  checked={formik.values?.gender === "other"}
                                  onChange={formik.handleChange}
                                />
                                <label htmlFor={`other`}>Other</label>
                              </span>
                            </p>
                          </div>
                        </div>
                        {formik.errors?.gender && formik.touched.gender && (
                          <span className="text-danger">
                            {formik.errors?.gender}
                          </span>
                        )}
                      </div>
                      <div className="col-sm-12 mb-3">
                        <label className="fw-bold">
                          Student's Date Of Birth
                        </label>
                        {/* {formik?.errors?.student_type ? (
                          <span className="text-red ms-1">
                            {formik.errors?.student_type}
                          </span>
                        ) : null} */}
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Year</label>

                          {formik.errors?.dob_year &&
                            formik.touched?.dob_year && (
                              <span className="text-red ms-1">
                                {formik.errors?.dob_year}
                              </span>
                            )}
                          <div className="input-container">
                            <select
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `dob_year`,
                                  e.target.value
                                );
                                let st;
                                if (
                                  formik.values.dob_day &&
                                  formik.values.dob_month &&
                                  e.target.value
                                ) {
                                  const date1 = new Date();
                                  const date2 = new Date(
                                    `${formik.values.dob_month}/${formik.values.dob_day}/${e.target.value}`
                                  );
                                  let diff =
                                    (date1.getTime() - date2.getTime()) / 1000;
                                  diff /= 60 * 60 * 24;
                                  diff = Math.abs(Math.round(diff / 365.25));
                                  console.log(diff);

                                  st =
                                    diff >= 4 && diff <= 16 ? "child" : "adult";
                                }
                                getMembershipListings(
                                  st,
                                  formik.values.discipline
                                );
                                let val = formik.values.membership_level;
                                let membership_number_of_days = val?.no_of_days;
                                let period = val?.time_period;
                                let final_date = moment().add(
                                  membership_number_of_days,
                                  "days"
                                );
                                console.log(
                                  val,
                                  final_date.format("YYYY-MM-DD"),
                                  "Membership Numbe of days"
                                );
                                if (
                                  final_date &&
                                  formik.values.dob_year &&
                                  formik.values.dob_month &&
                                  formik.values.dob_day
                                ) {
                                  formik.setFieldValue(
                                    `membership_year`,
                                    final_date?.format("YYYY")
                                  );
                                  formik.setFieldValue(
                                    `membership_month`,
                                    final_date?.format("M")
                                  );
                                  formik.setFieldValue(
                                    `membership_day`,
                                    Number(final_date?.format("DD"))
                                  );
                                }
                              }}
                              value={formik.values?.dob_year || ""}
                              className="form-control ps-4"
                            >
                              <option value="">Select Year</option>
                              {/* <option value="2022">2022</option>
                              <option value="2021">2021</option>
                              <option value="2020">2020</option>
                              <option value="2019">2019</option>
                              <option value="2018">2018</option>
                              <option value="2017">2017</option>
                              <option value="2016">2016</option>
                              <option value="2015">2015</option>
                              <option value="2014">2014</option>
                              <option value="2013">2013</option>
                              <option value="2012">2012</option>
                              <option value="2011">2011</option>
                              <option value="2010">2010</option>
                              <option value="2009">2009</option>
                              <option value="2008">2008</option>
                              <option value="2007">2007</option>
                              <option value="2006">2006</option>
                              <option value="2005">2005</option>
                              <option value="2004">2004</option>
                              <option value="2003">2003</option>
                              <option value="2002">2002</option>
                              <option value="2001">2001</option>
                              <option value="2000">2000</option>
                              <option value="1999">1999</option>
                              <option value="1998">1998</option>
                              <option value="1997">1997</option>
                              <option value="1996">1996</option>
                              <option value="1995">1995</option>
                              <option value="1994">1994</option>
                              <option value="1993">1993</option>
                              <option value="1992">1992</option>
                              <option value="1991">1991</option>
                              <option value="1990">1990</option>
                              <option value="1989">1989</option>
                              <option value="1987">1987</option>
                              <option value="1986">1986</option>
                              <option value="1985">1985</option>
                              <option value="1984">1984</option>
                              <option value="1983">1983</option>
                              <option value="1982">1982</option>
                              <option value="1981">1981</option>
                              <option value="1980">1980</option> */}
                              {yearList?.map((item) => {
                                return (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                );
                              })}
                            </select>

                            <img
                              src="assets/images/choose-date.png"
                              className="field-icon"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Month</label>

                          {formik.errors?.dob_month &&
                            formik.touched?.dob_month && (
                              <span className="text-red ms-1">
                                {formik.errors?.dob_month}
                              </span>
                            )}
                          <div className="input-container">
                            <select
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `dob_month`,
                                  e.target.value
                                );
                                // handleStudentType(
                                //   formik.values.dob_day,
                                //   e.target.value,
                                //   formik.values.dob_year
                                // );
                              }}
                              value={formik.values.dob_month || ""}
                              className="form-control ps-4"
                            >
                              <option value="">Select Month</option>
                              {monthOption?.map((item) => (
                                <option value={item.id} key={item.id}>
                                  {item.value}
                                </option>
                              ))}
                            </select>

                            <img
                              src="assets/images/choose-date.png"
                              className="field-icon"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Day</label>

                          {formik.errors?.dob_day &&
                            formik.touched?.dob_day && (
                              <span className="text-red ms-1">
                                {formik.errors?.dob_day}
                              </span>
                            )}
                          <div className="input-container">
                            <select
                              onChange={(e) => {
                                formik.setFieldValue(`dob_day`, e.target.value);
                                // handleStudentType(
                                //   e.target.value,
                                //   formik.values.dob_month,
                                //   formik.values.dob_year
                                // );
                              }}
                              value={formik.values.dob_day || ""}
                              className="form-control ps-4"
                            >
                              <option value={""}>Select Day</option>
                              {[
                                ...Array(
                                  formik.values.dob_day === "2" ? 28 : 31
                                ).keys(),
                              ].map((item) => {
                                return (
                                  <option value={item + 1} key={item + 1}>
                                    {item + 1}
                                  </option>
                                );
                              })}
                            </select>

                            <img
                              src="assets/images/choose-date.png"
                              className="field-icon"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-4 d-none">
                        <div className="form-group addUser">
                          <label className="fw-bold">Student Type</label>
                          {formik.errors.student_type &&
                            formik.touched.student_type && (
                              <span className="text-red ms-1">
                                {formik.errors.student_type}
                              </span>
                            )}
                          <div className="checkboxs">
                            <label>
                              <input
                                type="radio"
                                value="child"
                                checked={
                                  formik.values?.student_type === "child"
                                }
                                name={`student_type`}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `student_type`,
                                    e.target.value
                                  );
                                  getMembershipListings(
                                    e.target.value,
                                    formik.values.discipline
                                  );
                                }}
                                onBlur={formik.handleBlur}
                              />
                              <div className="div-bg">
                                <p>
                                  <span>child</span>
                                </p>
                              </div>
                            </label>

                            <label>
                              <input
                                type="radio"
                                value="adult"
                                name={`student_type`}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `student_type`,
                                    e.target.value
                                  );
                                  getMembershipListings(
                                    e.target.value,
                                    formik.values.discipline
                                  );
                                }}
                                checked={
                                  formik.values?.student_type === "adult"
                                }
                                onBlur={formik.handleBlur}
                              />
                              <div className="div-bg">
                                <p>
                                  <span>adult</span>
                                </p>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 mb-4">
                        <div className="form-group addUser">
                          <label className="fw-bold">
                            {/* <img src="assets/images/discipline.png" alt="" />{" "} */}
                            Discipline
                          </label>
                          {formik.errors.discipline &&
                            formik.touched.discipline && (
                              <span className="text-red ms-1">
                                {formik.errors.discipline}
                              </span>
                            )}
                          <div className="checkboxs">
                            {disciplines
                              ?.filter(
                                (it) =>
                                  it?.name?.toLowerCase() !== "all disciplines"
                              )
                              ?.map((disc, ind) => {
                                return (
                                  <label key={ind}>
                                    <input
                                      type="radio"
                                      value={disc?.id}
                                      name={`discipline`}
                                      checked={
                                        formik.values?.discipline?.toString() ===
                                        disc?.id?.toString()
                                      }
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `discipline`,
                                          e.target.value
                                        );
                                        formik.setFieldValue(
                                          `membership_level`,
                                          ""
                                        );
                                        getMembershipListings(
                                          st_type,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <div className="div-bg">
                                      <p>
                                        <span>{disc?.name}</span>
                                      </p>
                                    </div>
                                  </label>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      {
                        <div className="col-sm-12 mb-4">
                          <div className="form-group addUser">
                            <label className="fw-bold mb-2">
                              Membership Type
                            </label>

                            <div className="checkboxs">
                              <label>
                                <input
                                  type="radio"
                                  checked={
                                    formik.values?.trialAccountStatus === "1"
                                  }
                                  name={`trialAccountStatus`}
                                  onChange={() => {
                                    formik.setFieldValue(
                                      `trialAccountStatus`,
                                      "1"
                                    );
                                    formik.setFieldValue(
                                      `membership_level`,
                                      ""
                                    );
                                    formik.setFieldValue(`membership_year`, "");
                                    formik.setFieldValue(
                                      `membership_month`,
                                      ""
                                    );
                                    formik.setFieldValue(`membership_day`, "");
                                  }}
                                />
                                <div className="div-bg">
                                  <p>
                                    <span>Pay For Trail Lessons</span>
                                  </p>
                                </div>
                              </label>

                              <label>
                                <input
                                  type="radio"
                                  checked={
                                    formik.values?.trialAccountStatus === "3"
                                  }
                                  name={`trialAccountStatus`}
                                  onChange={() => {
                                    formik.setFieldValue(
                                      `trialAccountStatus`,
                                      "3"
                                    );
                                    let st = handleStudentType();
                                    getMembershipListings(
                                      st,
                                      formik.values.discipline
                                    );
                                  }}
                                />
                                <div className="div-bg">
                                  <p>
                                    <span>Full Membership</span>
                                  </p>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      }
                      {formik.values?.trialAccountStatus === "3" &&
                        st_type &&
                        formik.values?.discipline && (
                          <div className="col-sm-6 col-md-6 mb-4">
                            <div className="form-group">
                              <label className="fw-bold">Membership</label>
                              {formik.errors?.membership_level &&
                                formik.touched?.membership_level && (
                                  <span className="text-red ms-1">
                                    {formik.errors?.membership_level}
                                  </span>
                                )}
                              <div className="input-container">
                                {/* <select
                                  className="form-control pl-5"
                                  placeholder="Select Membership"
                                  name={`membership_level`}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values?.membership_level}
                                >
                                  <option value="">Select Membership</option>
                                  {formik.values?.membership_levels?.map(
                                    (item, i) => {
                                      return (
                                        <option value={item?.id} key={i}>
                                          {item?.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </select> */}
                                <Select
                                  isSearchable
                                  onChange={(val) => {
                                    formik.setFieldValue(
                                      `membership_level`,
                                      val
                                    );
                                    let membership_number_of_days =
                                      val?.no_of_days;
                                    let period = val?.time_period;
                                    let final_date = moment().add(
                                      membership_number_of_days,
                                      period
                                    );
                                    console.log(
                                      final_date.format("YYYY-MM-DD"),
                                      "Membership Numbe of days"
                                    );
                                    // if (final_date) {
                                    //   formik.setFieldValue(
                                    //     `membership_year`,
                                    //     final_date?.format("YYYY")
                                    //   );
                                    //   formik.setFieldValue(
                                    //     `membership_month`,
                                    //     Number(final_date?.format("MM"))
                                    //   );
                                    //   formik.setFieldValue(
                                    //     `membership_day`,
                                    //     Number(final_date?.format("DD"))
                                    //   );
                                    // }
                                  }}
                                  isOptionSelected={
                                    formik.values.membership_levels?.findIndex(
                                      (it) =>
                                        it?.id?.toString() ===
                                        formik.values.membership_level?.id?.toString()
                                    ) !== -1
                                  }
                                  styles={styles}
                                  className="form-control"
                                  value={formik.values?.membership_level || ""}
                                  options={
                                    formik.values.membership_levels || undefined
                                  }
                                  getOptionLabel={(option) => {
                                    return option?.name;
                                  }}
                                  getOptionValue={(option) => option.id}
                                  name="membership_level"
                                />

                                <img
                                  src="assets/images/png"
                                  className="input-img"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      {formik.values?.trialAccountStatus === "3" &&
                      formik.values.membership_level &&
                      st_type ? (
                        <>
                          {" "}
                          <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                            <div className="form-group">
                              <p>
                                Your Membership Expires on:{" "}
                                {formik.values.membership_year &&
                                  formik.values.membership_month &&
                                  formik.values.membership_day &&
                                  st_type && (
                                    <span className="fw-bold">
                                      {" "}
                                      {moment(
                                        `${formik.values.membership_year}-${formik.values.membership_month}-${formik.values.membership_day}`
                                      ).format("DD MMM YYYY")}
                                    </span>
                                  )}
                              </p>
                            </div>
                            {/* <button
                              className="btn btn-save p-2 fs-6"
                              type="button"
                              onClick={() => {
                                formik.setFieldValue(
                                  "isEditable",
                                  !formik.values.isEditable
                                );
                              }}
                            >
                              Edit
                            </button> */}
                          </div>
                          <>
                            <>
                              {" "}
                              <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                                <div className="form-group">
                                  <label className="fw-bold">Year</label>

                                  {/* {formik.errors?.dob_year &&
                                      formik.touched?.dob_year && (
                                        <span className="text-red ms-1">
                                          {formik.errors?.dob_year}
                                        </span>
                                      )} */}
                                  <div className="input-container">
                                    <select
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "membership_year",
                                          e.target.value
                                        );
                                      }}
                                      value={
                                        formik.values.membership_year || ""
                                      }
                                      className="form-control ps-4"
                                    >
                                      <option value="">Select Year</option>
                                      {/* <option value="2022">2022</option>
                              <option value="2021">2021</option>
                              <option value="2020">2020</option>
                              <option value="2019">2019</option>
                              <option value="2018">2018</option>
                              <option value="2017">2017</option>
                              <option value="2016">2016</option>
                              <option value="2015">2015</option>
                              <option value="2014">2014</option>
                              <option value="2013">2013</option>
                              <option value="2012">2012</option>
                              <option value="2011">2011</option>
                              <option value="2010">2010</option>
                              <option value="2009">2009</option>
                              <option value="2008">2008</option>
                              <option value="2007">2007</option>
                              <option value="2006">2006</option>
                              <option value="2005">2005</option>
                              <option value="2004">2004</option>
                              <option value="2003">2003</option>
                              <option value="2002">2002</option>
                              <option value="2001">2001</option>
                              <option value="2000">2000</option>
                              <option value="1999">1999</option>
                              <option value="1998">1998</option>
                              <option value="1997">1997</option>
                              <option value="1996">1996</option>
                              <option value="1995">1995</option>
                              <option value="1994">1994</option>
                              <option value="1993">1993</option>
                              <option value="1992">1992</option>
                              <option value="1991">1991</option>
                              <option value="1990">1990</option>
                              <option value="1989">1989</option>
                              <option value="1987">1987</option>
                              <option value="1986">1986</option>
                              <option value="1985">1985</option>
                              <option value="1984">1984</option>
                              <option value="1983">1983</option>
                              <option value="1982">1982</option>
                              <option value="1981">1981</option>
                              <option value="1980">1980</option> */}
                                      {membershipYearList?.map((item) => {
                                        return (
                                          <option value={item} key={item}>
                                            {item}
                                          </option>
                                        );
                                      })}
                                    </select>

                                    <img
                                      src="assets/images/choose-date.png"
                                      className="field-icon"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                                <div className="form-group">
                                  <label className="fw-bold">Month</label>

                                  {/* {formik.errors?.dob_month &&
                                      formik.touched?.dob_month && (
                                        <span className="text-red ms-1">
                                          {formik.errors?.dob_month}
                                        </span>
                                      )} */}
                                  <div className="input-container">
                                    <select
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "membership_month",
                                          e.target.value
                                        );
                                      }}
                                      value={
                                        formik.values.membership_month || ""
                                      }
                                      className="form-control ps-4"
                                    >
                                      <option value="">Select Month</option>
                                      {monthOption?.map((item) => (
                                        <option value={item.id} key={item.id}>
                                          {item.value}
                                        </option>
                                      ))}
                                    </select>

                                    <img
                                      src="assets/images/choose-date.png"
                                      className="field-icon"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                                <div className="form-group">
                                  <label className="fw-bold">Day</label>

                                  {/* {formik.errors?.dob_day &&
                                      formik.touched?.dob_day && (
                                        <span className="text-red ms-1">
                                          {formik.errors?.dob_day}
                                        </span>
                                      )} */}
                                  <div className="input-container">
                                    <select
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "membership_day",
                                          Number(e.target.value)
                                        );
                                      }}
                                      value={formik.values.membership_day || ""}
                                      className="form-control ps-4"
                                    >
                                      <option value={""}>Select Day</option>
                                      {[
                                        ...Array(
                                          formik.values.dob_month === "2"
                                            ? 28
                                            : 31
                                        ).keys(),
                                      ].map((item, index) => {
                                        return (
                                          <option
                                            value={item + 1}
                                            key={item + 1}
                                          >
                                            {item + 1}
                                          </option>
                                        );
                                      })}
                                    </select>

                                    <img
                                      src="assets/images/choose-date.png"
                                      className="field-icon"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          </>
                        </>
                      ) : formik.values?.trialAccountStatus === "3" &&
                        formik.values.membership_level &&
                        !st_type ? (
                        <span className="text-danger mb-4">
                          Kindly add date of birth to view the membership plan.
                        </span>
                      ) : null}
                      <div className="col-sm-6 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Area Name</label>
                          {formik.errors.areacode &&
                            formik.touched.areacode && (
                              <span className="text-red ms-1">
                                {formik.errors.areacode}
                              </span>
                            )}
                          <div className="input-container">
                            <select
                              className="form-control pl-5 form-select"
                              name={`areacode`}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `areacode`,
                                  e.target.value
                                );
                                setAreacode(e.target.value);
                                getList(e.target.value);
                              }}
                              value={formik.values?.areacode}
                            >
                              <option value="school">School</option>
                              {areaList?.map((item) => (
                                <option value={item?.id} key={item?.id}>
                                  {item?.areacode}
                                </option>
                              ))}
                            </select>
                            <img
                              src="assets/images/png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>{" "}
                      {formik.values.areacode && (
                        <div className="col-sm-6 col-md-6 mb-4">
                          <div className="form-group">
                            <label className="fw-bold">School Venue</label>
                            {formik.errors.schoolId &&
                              formik.touched.schoolId && (
                                <span className="text-red ms-1">
                                  {formik.errors.schoolId}
                                </span>
                              )}
                            <div className="input-container">
                              <select
                                className="form-control pl-5 form-select"
                                name={`schoolId`}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `schoolId`,
                                    e.target.value
                                  );
                                }}
                                value={formik.values?.schoolId}
                              >
                                <option value="school">School</option>
                                {formik.values?.venueList?.map((item, i) => (
                                  <option value={item?.id} key={item?.id}>
                                    {item?.name}
                                  </option>
                                ))}
                              </select>
                              <img
                                src="assets/images/png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-sm-6 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">
                            Emergency Contact Number
                          </label>
                          {formik.errors.mobile && (
                            <span className="text-red ms-1">
                              {formik.errors.mobile}
                            </span>
                          )}
                          <div className="input-container">
                            <Phone
                              onChange={(val) => {
                                console.log(val);
                                if (val) {
                                  isValid(val);
                                } else {
                                  formik.setFieldValue(`mobile`, "");
                                }
                              }}
                              onBlur={() => {
                                isValid(formik.values.mobile);
                              }}
                              value={formik.values.mobile}
                              name={`mobile`}
                              className="form-control signupClass"
                            />
                            {/* <img
                              src="assets/images/mobile.png"
                              className="input-img"
                              alt=""
                            /> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">
                            {/* <img
                                        src="assets/images/medical.png"
                                        alt=""
                                      />{" "} */}
                            Medical Note
                          </label>
                          {formik.errors.medical_note &&
                            formik.touched.medical_note && (
                              <span className="text-red ms-1">
                                {formik.errors.medical_note}
                              </span>
                            )}
                          <div className="input-container">
                            <textarea
                              className="textarea-control p-3"
                              rows="7"
                              placeholder="Write here..."
                              name={`medical_note`}
                              value={formik.values.medical_note}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-save"
                        disabled={submitting}
                      >
                        {submitting ? <Loader /> : "Done"}
                      </button>
                    </div>
                  </div>
                </form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default AddStudent;
