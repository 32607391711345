import React from "react";
import { Link } from "react-router-dom";

function AddPaymentDetail() {
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy px-5">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
            <h3>Add Payment Detail</h3>
          </div>
        </div>
        <div className="row mt-3 align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <h5>Set your account details</h5>
            <p>In this payment source, you will be receiving your money</p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="account-box shadow">
              <img src="assets/images/bank.png" alt="" className="bank-icon" />
              <h5>thomas kors</h5>
              <p>xxxxxxxxx7895</p>

              <img
                src="assets/images/delete.png"
                alt=""
                className="bank-icon acc-delete-icon"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 text-center">
            <Link to="/add/account" className="theme-color fs-4 fw-bold">
              + Add Account
            </Link>
          </div>
        </div>
        <div className="my-5">
          <hr />
        </div>
        <div className="row mt-3 align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <h5>Credit/Debit Card</h5>
            <p>In this payment source, you will be sending the refund money</p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="account-box shadow">
              <img src="assets/images/bank.png" alt="" className="bank-icon" />
              <h5>thomas kors</h5>
              <p>xxxxxxxxx7895</p>

              <img
                src="assets/images/delete.png"
                alt=""
                className="bank-icon acc-delete-icon"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 text-center">
            <Link to="/add/account" className="theme-color fs-4 fw-bold">
              + Add Account
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddPaymentDetail;
