import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { LoginApi } from "../api/setting";
import axios from "axios";
import {
  AddClassApi,
  AddSchoolApi,
  ClassDeleteApi,
  ClassDetailApi,
  SchoollDeleteApi,
  SchoollDetailApi,
  SchoolListApi,
  TeacherdetailApi,
  UpdateClassApi,
} from "../api/class";
import { ClassListApi } from "./../api/class";
import { patchFormData, postFormData } from "../api";

const initialState = {
  school_list: [],
  total_school: 0,
  total_active_class: 0,
  total_class: 0,
  total_inactive_class: 0,
  classDetail: {},
  schoolDetail: {},
  teacherDetail: {},
  classesForASchool: [],
  id: localStorage.getItem("id") ? localStorage.getItem("id") : "",
};

export const LoginReducer = createAsyncThunk(
  "loginRedcer",
  async (obj, { dispatch }) => {
    try {
      const response = await LoginApi(obj);

      await localStorage.setItem("token", response?.data?.token);
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const SchoolListReducer = createAsyncThunk(
  "SchoolListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await SchoolListApi(obj);

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

// name, image, description, address, longitude, latitude, school_id

export const AddSchoolReducer = createAsyncThunk(
  "AddSchoolReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await AddSchoolApi(obj);
      dispatch(SchoolListReducer({ page: 1, limit: 10 }));
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const AddClassReducer = createAsyncThunk(
  "AddClassReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await AddClassApi(obj);
      //dispatch(SchoolListReducer({ page: 1, limit: 10 }));
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const ClassListReducer = createAsyncThunk(
  "ClassListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await ClassListApi(obj);

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const ClassDetailReducer = createAsyncThunk(
  "ClassDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await ClassDetailApi(obj);

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const SchoolDetailReducer = createAsyncThunk(
  "SchoolDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await SchoollDetailApi(obj);

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const SchoolDeleteReducer = createAsyncThunk(
  "SchoolDeleteReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await SchoollDeleteApi(obj);

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const ClassDeleteReducer = createAsyncThunk(
  "ClassDeleteReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await ClassDeleteApi(obj);

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const ClassActiveInactiveReducer = createAsyncThunk(
  "ClassActiveInactiveReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await UpdateClassApi(obj);

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const TeacherDetailReducer = createAsyncThunk(
  "TeacherDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await TeacherdetailApi(obj);

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const CancelClass = createAsyncThunk(
  "cancelclass",
  async (obj, { dispatch }) => {
    try {
      const response = await patchFormData("class/cancelClass", obj);

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const MainSlice = createSlice({
  name: "mainSlice",
  initialState,
  reducers: {
    clearTeacherDetail: (state, action) => {
      state.teacherDetail = null;
    },
    clearClassDetail: (state, action) => {
      state.classDetail = action.payload;
    },
    adminId: (state, action) => {
      state.id = action.payload;
    },
  },
  extraReducers: {
    [SchoolListReducer.fulfilled]: (state, action) => {
      state.schools = action?.payload?.data?.school_list;
      state.total_school = action?.payload?.data?.total_school;
    },
    [ClassListReducer.fulfilled]: (state, action) => {
      state.class = action?.payload?.data?.class_list;
      state.total_class = action?.payload?.data?.total_class;
      state.total_active_class = action?.payload?.data?.total_active_class;
      state.total_inactive_class = action?.payload?.data?.total_inactive_class;
    },
    [ClassDetailReducer.fulfilled]: (state, action) => {
      state.classDetail = action?.payload?.data?.class_details;
    },
    [SchoolDetailReducer.fulfilled]: (state, action) => {
      state.schoolDetail = action?.payload?.data?.school_details;
      state.classesForASchool = action.payload.data.class_list;
    },
    [TeacherDetailReducer.fulfilled]: (state, action) => {
      state.teacherDetail = action?.payload?.data;
    },
  },
});

export const { clearTeacherDetail, adminId, clearClassDetail } =
  MainSlice.actions;

export default MainSlice.reducer;
