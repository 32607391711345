import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { addAreaCode } from "../../api/areacode";
import Loader from "./../../component/Loader";
import { areaCodeDetailSelector, details, FetchAreaCodeDetail } from "./slice";
import BackButton from "./../../component/BackButton";
function AddAreaCode() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(areaCodeDetailSelector);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(FetchAreaCodeDetail(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(details(null));
    };
  }, [dispatch]);

  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <BackButton />
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>Add Area</h3>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <Formik
              initialValues={{
                areacode: data?.areacode || "",
              }}
              enableReinitialize={true}
              validationSchema={Yup.object({
                areacode: Yup.string().required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setLoading(true);

                addAreaCode({
                  areacode: values.areacode,
                }).then((res) => {
                  setLoading(false);
                  if (res?.code === 1) {
                    toast.success(res?.message);
                    navigate("/area-code", { replace: true });
                  } else {
                    toast.error(res?.message);
                  }
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFormikState,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form className="form-style" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 mb-5">
                      <div className="form-group">
                        <label className="fw-bold">Area Name</label>
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Area Name"
                            name="areacode"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.areacode}
                          />
                          <img
                            src="assets/images/First-Name.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                        {errors.areacode && touched.areacode && (
                          <div className="text-red">{errors.areacode}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 text-center">
                      <button type="submit" className="btn btn-save">
                        {loading ? <Loader /> : "Done"}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddAreaCode;
