import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "../../component/Loader";
import BackButton from "./../../component/BackButton";
import {
  fetchMembershipdetails,
  membershipDetailSelector,
} from "./MembershipSlice";

function ViewMembership() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const details = useSelector(membershipDetailSelector);
  const [loading, setLoading] = useState(false);
  const getDetails = useCallback(() => {
    setLoading(true);
    dispatch(fetchMembershipdetails(id)).then(() => {
      setLoading(false);
    });
  }, [dispatch, id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);
  if (loading) {
    return (
      <article className="col-lg-9 articleClass">
        {" "}
        <Loader />
      </article>
    );
  }
  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <BackButton />

          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>Member Level Details</h3>
          </div>
        </div>
        <div className="row mt-5 px-md-5">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-5">
            <div className="bg-white p-4 rounded h-100 shadow position-relative">
              <div className="row">
                <div className="col-12 col-sm-10 col-md-10 col-lg-10">
                  <div className="event-box-flex align-items-normal">
                    <span>
                      <img
                        src={
                          details?.image?.includes("http")
                            ? details?.image
                            : "assets/images/headerlogo.png"
                        }
                        className="event-img"
                        alt=""
                      />
                    </span>
                    <span>
                      <h4>{details?.name || "NA"}</h4>
                      <p className="mb-0 d-flex flex-wrap gap-row-col">
                        <span>
                          <b>discipline:</b> {details?.discipline || "NA"}
                        </span>
                        <span>
                          <b>category:</b> {details?.student_type || "NA"}
                        </span>
                        <span>
                          <b>duration:</b>{" "}
                          {details?.no_of_days === 1
                            ? `${details?.no_of_days} day`
                            : details?.no_of_days > 1
                            ? `${details?.no_of_days} days`
                            : "NA"}
                        </span>
                      </p>
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-2 col-md-2 col-lg-2">
                  <span className="event-price">
                    <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                    {details?.total_price
                      ? details?.total_price
                      : details?.student_type === "adult"
                      ? details?.adult_price
                      : details?.student_type === "child"
                      ? details?.child_price
                      : "0"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {details?.about && (
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-5">
              <h3>About Membership</h3>
              <div className="bg-white p-4 rounded shadow">
                {details?.about || "NA"}
                {/* <ul className="mt-2">
                <li className="mb-2">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                </li>
                <li className="mb-2">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                </li>
                <li className="mb-2">Lorem ipsum dolor sit amet.</li>
                <li className="mb-2">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                </li>
                <li className="mb-2">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                </li>
                <li className="mb-2">Lorem ipsum dolor sit amet.</li>
                <li className="mb-2">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                </li>
                <li className="mb-2">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                </li>
              </ul> */}
              </div>
            </div>
          )}
          {details?.terms_and_condition && (
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-5">
              <h3>Terms & Conditions</h3>
              <div className="bg-white p-4 rounded shadow">
                <p className="mt-2">{details?.terms_and_condition || "NA"}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </article>
  );
}

export default ViewMembership;
