import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { registerDetails, registerList } from "../../api/register";
import {
  allStudentListForAClass,
  allStudentListForAnEvent,
  getAllOtherStudents,
  getAllOtherStudentsForEvent,
} from "../../api/student";
const initialState = {
  list: [],
  total: 0,
  details: {},
  register_for: localStorage.getItem("register_for")
    ? localStorage.getItem("register_for")
    : "class",
  otherStudentsListForClass: [],
  otherStudentsListForEvents: [],
  level: localStorage.getItem("level") ? localStorage.getItem("level") : "",
  allStudentForClass: [],
  allStudentForEvent: [],
};

export const RegisterSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    list: (state, action) => {
      state.list = action.payload;
    },
    detail: (state, action) => {
      state.details = action.payload;
    },
    totalCount: (state, action) => {
      state.total = action.payload;
    },
    changeRegisterFor: (state, action) => {
      state.register_for = action.payload;
    },
    otherStudentListingForClass: (state, action) => {
      state.otherStudentsListForClass = action.payload;
    },
    otherStudentListingForEvent: (state, action) => {
      state.otherStudentsListForEvents = action.payload;
    },
    changeLevel: (state, action) => {
      state.level = action.payload;
    },
    allStudentForClass: (state, action) => {
      state.allStudentForClass = action.payload;
    },
    allStudentForEvent: (state, action) => {
      state.allStudentForEvent = action.payload;
    },
    clearAllStudentList: (state, action) => {
      state.allStudentForEvent = [];
      state.allStudentForClass = [];
    },
  },
});

export const FetchRegisterList = createAsyncThunk(
  "registerList",
  async (obj, { dispatch }) => {
    try {
      dispatch(totalCount(0));

      const response = await registerList(obj);
      if (response?.code === 1) {
        dispatch(list(response?.data?.register_list));
        dispatch(totalCount(response?.data?.register_count));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchRegisterDetail = createAsyncThunk(
  "registerDetail",
  async (obj, { dispatch }) => {
    const response = await registerDetails(obj);
    if (response?.code === 1) {
      dispatch(detail(response?.data?.register_details));
    }
    return response;
  }
);

export const FetchAllOtherStudents = createAsyncThunk(
  "AllOtherStudents",
  async (obj, { dispatch }) => {
    try {
      const response = await getAllOtherStudents(obj);
      if (response?.code === 1) {
        console.log(response);
        dispatch(otherStudentListingForClass(response?.data?.student_list));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchAllOtherStudentsForEvent = createAsyncThunk(
  "AllOtherStudentsForEvent",
  async (obj, { dispatch }) => {
    try {
      const response = await getAllOtherStudentsForEvent(obj);
      if (response?.code === 1) {
        console.log(response);
        dispatch(otherStudentListingForEvent(response?.data?.student_list));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchAllStudentsForClass = createAsyncThunk(
  "AllStudentsForClass",
  async (obj, { dispatch }) => {
    try {
      const response = await allStudentListForAClass(obj);
      if (response?.code === 1) {
        console.log(response);
        dispatch(allStudentForClass(response?.data?.student_list));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchAllStudentsForEvent = createAsyncThunk(
  "AllStudentsForEvent",
  async (obj, { dispatch }) => {
    try {
      const response = await allStudentListForAnEvent(obj);
      if (response?.code === 1) {
        console.log(response);
        dispatch(allStudentForEvent(response?.data?.student_list));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const {
  list,
  detail,
  totalCount,
  clearAllStudentList,
  changeRegisterFor,
  allStudentForClass,
  allStudentForEvent,
  otherStudentListingForEvent,
  otherStudentListingForClass,
  changeLevel,
} = RegisterSlice.actions;

export default RegisterSlice.reducer;
