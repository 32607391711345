import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { useDispatch } from "react-redux";
import { SchoolListReducer } from "../slice.js";
import { useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate, useParams } from "react-router";
// import { uploadFile } from "react-s3";
import { Progress } from "react-sweet-progress";
import { config } from "../../utils/index.js";
import {
  details,
  fetchEventDetail,
  getDetails,
  getStatus,
} from "./EventSlice.js";
import Loader from "./../../component/Loader";
import moment from "moment";
import { addEvent } from "../../api/event.js";
import { toast } from "react-toastify";
import { uploadFile } from "react-s3";
import {
  areaCodeListingSelector,
  FetchAreaCodeList,
} from "../areacode/slice.js";
import { GetTeacher } from "../teacher/TeacherSlice.js";
import { FetchEventTypeList } from "../eventType/EventTypeSlice.js";
import { FetchDisciplineList } from "../discipline/DisciplineSlice.js";
import BackButton from "../../component/BackButton.jsx";
function AddEvent() {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const schoolList = useSelector((state) => state.main.schools);
  const areaList = useSelector(areaCodeListingSelector);
  const eventTypes = useSelector((state) => state.eventType.list);
  const [time, setTime] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const status = useSelector(getStatus);
  const detail = useSelector(getDetails);
  const [loading, setLoading] = useState(false);
  const teacherList = useSelector((state) => state.teacher.list);
  const disciplines = useSelector((state) => state.discipline.list);
  const [submitting, setSubmitting] = useState(false);

  const getDisciplines = useCallback(() => {
    dispatch(FetchDisciplineList({ page: 1, limit: 1000000 }));
  }, [dispatch]);

  const schema = Yup.object({
    event_name: Yup.string().required("Required"),
    event_type: Yup.string().required("Required"),
    event_date: Yup.string().required("Required"),
    event_time: Yup.string().required("Required"),
    child_price: Yup.number().when(["hasLevels", "age_group"], {
      is: (hasLevel, age_group) => {
        console.log(hasLevel, age_group);
        return (
          (hasLevel === false || hasLevel === undefined) &&
          (age_group?.includes("child") || age_group?.includes("both"))
        );
      },
      then: Yup.number()
        .positive("Kindly enter a valid number")
        .typeError("Kindly enter a valid number")
        .required("Required"),
      otherwise: Yup.number()
        .nullable(true)
        .transform((_, val) => (val === Number(val) ? val : null))
        .notRequired()
        .optional(),
    }),
    adult_price: Yup.number().when(["hasLevels", "age_group"], {
      is: (hasLevel, age_group) => {
        return (
          (hasLevel === false || hasLevel === undefined) &&
          (age_group?.includes("adult") || age_group?.includes("both"))
        );
      },
      then: Yup.number()
        .positive("Kindly enter a valid number")
        .typeError("Kindly enter a valid number")
        .required("Required"),
      otherwise: Yup.number()
        .nullable(true)
        .transform((_, val) => (val === Number(val) ? val : null))
        .notRequired()
        .optional(),
    }),
    area: Yup.string().required("Required"),
    venue: Yup.string().required("Required"),
    age_group: Yup.array().min(1, "Required"),
    discipline: Yup.string().typeError("Required").required("Required"),
    hasLevels: Yup.boolean().required("Required"),
    image: Yup.string().notRequired(),
    teacherId: Yup.string().notRequired().nullable().optional(),
    level: Yup.array().when("hasLevels", {
      is: true,
      then: Yup.array().of(
        Yup.object({
          name: Yup.string().required("Required"),
          child_price: Yup.number().when(["hasLevels", "age_group"], {
            is: (hasLvl, value) =>
              hasLvl &&
              (value?.includes("child") ||
                value?.includes("both") ||
                value === undefined),
            then: Yup.number()
              .positive("Must be valid amount.")
              .typeError("Number only please")
              .required("Number only please"),
            otherwise: Yup.number()
              .nullable(true)
              .notRequired()
              .optional()
              .transform((value) =>
                isNaN(value) || value === null || value === undefined
                  ? 0
                  : value
              ),
          }),
          adult_price: Yup.number().when(["hasLevels", "age_group"], {
            is: (hasLvl, value) =>
              hasLvl &&
              (value?.includes("adult") ||
                value?.includes("both") ||
                value === undefined),
            then: Yup.number()
              .positive("Must be valid amount.")
              .typeError("Number only please.")
              .required("Number only please"),
            otherwise: Yup.number()
              .nullable(true)
              .notRequired()
              .optional()
              .transform((value) =>
                isNaN(value) || value === null || value === undefined
                  ? 0
                  : value
              ),
          }),
          time: Yup.string().required("Required"),
          note: Yup.string(),
        })
      ),
    }),
  });

  const {
    register,
    getValues,
    setValue,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      age_group: [],
    },
  });
  console.log(errors, getValues(), "Add event errors");
  const watchAgeGroup = watch("age_group");
  const watchImage = watch("image");
  const watchHasLevels = watch("hasLevels");

  const watchArea = watch("area");
  const watchDiscipline = watch("discipline");
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "level", // unique name for your Field Array
    }
  );

  const originalLevels = detail?.levels || [];

  const onSubmit = (data) => {
    setSubmitting(true);
    console.log(data);
    const payload = {
      name: data?.event_name,
      schoolId: data?.venue,
      type: data.event_type,
      discipline: data?.discipline,
      about: data?.about || "",
      date: moment(data?.event_date).format("YYYY-MM-DD"),
      start_time: moment(data?.event_time).format("HH:mm"),
      child_price: data?.level?.length > 0 ? "" : data?.child_price,
      adult_price: data?.level?.length > 0 ? "" : data?.adult_price,

      areacode: data?.area,
      image: data?.image ? data?.image : "",
      age_group: data?.age_group.length > 1 ? "both" : data?.age_group[0],
      teacherId: data?.teacherId || "",
      // levels: JSON.stringify(
      //   data?.level?.map((item) => {
      //     return {
      //       level_id: item?.level_id || "",
      //       name: item?.name || "",
      //       child_price:
      //         (data?.age_group.length > 1 ||
      //           data?.age_group?.includes("child")) &&
      //         item?.child_price
      //           ? item?.child_price
      //           : "",
      //       adult_price:
      //         (data?.age_group.length > 1 ||
      //           data?.age_group?.includes("adult")) &&
      //         item?.adult_price
      //           ? item?.adult_price
      //           : "",
      //       note: item?.note || "",
      //       time: item?.time ? moment(item?.time).format("HH:mm") : "",
      //     };
      //   })
      // ),
      levels: JSON.stringify(
        data?.level?.map((item, index) => {
          // Compare the current value with the original to detect changes
          const originalItem = originalLevels[index] || {};
          const originalTime = originalItem?.time
            ? moment(originalItem.time, "HH:mm").format("HH:mm")
            : "";
          const newTime = item?.time ? moment(item.time).format("HH:mm") : "";

          const isModified =
            originalItem?.name != item?.name ||
            originalItem?.child_price != item?.child_price ||
            originalItem?.adult_price != item?.adult_price ||
            originalItem?.note != item?.note ||
            originalTime !== newTime;

          return {
            level_id:
              item?.level_id && location?.pathname?.includes("/edit-event")
                ? item?.level_id
                : "",
            name: item?.name || "",
            child_price:
              (data?.age_group.length > 1 ||
                data?.age_group?.includes("child")) &&
              item?.child_price
                ? item?.child_price
                : "",
            adult_price:
              (data?.age_group.length > 1 ||
                data?.age_group?.includes("adult")) &&
              item?.adult_price
                ? item?.adult_price
                : "",
            note: item?.note || "",
            time: item?.time ? moment(item?.time).format("HH:mm") : "",
            isChanged: isModified, // Add modified flag if the field has been changed
          };
        })
      ),
    };
    console.log(payload);
    if (id && location?.pathname?.includes("/edit-event")) {
      addEvent({ ...payload, event_id: id }).then((response) => {
        setSubmitting(false);
        if (response.code === 1) {
          toast.success(response?.message);
          navigate("/events");
        } else {
          toast.error(response?.message);
        }
      });
    } else {
      addEvent(payload).then((response) => {
        setSubmitting(false);
        if (response.code === 1) {
          toast.success(response?.message);
          navigate("/events");
        } else {
          toast.error(response?.message);
        }
      });
    }
  };

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  useEffect(() => {
    if (id) {
      dispatch(fetchEventDetail(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    getDisciplines();
  }, [getDisciplines]);

  useEffect(() => {
    return () => {
      dispatch(details(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (id && detail) {
      reset({
        event_name: detail?.name,
        event_type: detail?.type,
        event_date: new Date(detail?.date),
        event_time: moment(detail?.start_time, "HH:mm:ss").toISOString(),
        child_price:
          detail?.levels?.length > 0
            ? ""
            : detail?.child_price
            ? detail?.child_price
            : "",
        adult_price:
          detail?.levels?.length > 0
            ? ""
            : detail?.adult_price
            ? detail?.adult_price
            : "",
        discipline: detail?.discipline,
        about: detail?.about,
        area: detail?.areacode,
        venue: detail?.schoolId,
        image: detail?.image.includes("http") ? detail?.image : "",
        age_group:
          detail?.age_group === "both"
            ? ["child", "adult"]
            : [detail.age_group],
        hasLevels: detail?.levels?.length > 0 ? true : false,
        teacherId: detail?.teacherId || "",
        level: detail?.levels?.map((item) => {
          return {
            name: item?.name,
            level_id: item?.id,
            child_price: item?.child_price || "",
            adult_price: item?.adult_price || "",
            note: item?.note,
            time: moment(item?.time, "HH:mm:ss").toISOString(),
          };
        }),
      });
    }
  }, [id, detail, reset]);

  useEffect(() => {
    dispatch(FetchEventTypeList({ page: 1, limit: 10000 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(FetchAreaCodeList({}));
    dispatch(GetTeacher({ page: 1, limit: 1000000 }));
  }, [dispatch]);

  const getSchoolList = useCallback(() => {
    dispatch(SchoolListReducer({ areacode: watchArea }));
  }, [dispatch, watchArea]);

  useEffect(() => {
    if (watchArea) {
      getSchoolList();
    }
  }, [getSchoolList, watchArea]);

  console.log(errors, getValues());

  if (id && !detail) {
    return (
      <div className="col-sm-12 ">
        <Loader />;
      </div>
    );
  } else {
    return (
      <article className="col-lg-9 articleClass">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <BackButton />

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              {/* <h3>{id ? "Edit" : " New"} Event</h3> */}
              <h3>
                {location?.pathname?.includes("/edit-event")
                  ? "Edit"
                  : location?.pathname?.includes("/duplicate-event")
                  ? "Duplicate"
                  : " New"}{" "}
                Event
              </h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12">
              <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group text-center">
                      <div className="upload-btn-wrapper">
                        <button className="btn">
                          {time > 0 && isActive ? (
                            <Progress type="circle" width={50} percent={time} />
                          ) : (
                            <img
                              src={
                                watchImage
                                  ? watchImage
                                  : "assets/images/upload-event.png"
                              }
                              alt=""
                            />
                          )}

                          {time > 0 && isActive ? null : (
                            <div className="camera-icon">
                              <i className="fa fa-camera"></i>
                            </div>
                          )}
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          onChange={(e) => {
                            if (e.target.files.length > 0) {
                              const { files } = e.target;
                              setIsActive(true);

                              uploadFile(files[0], config)
                                .then(async (data) => {
                                  setValue("image", data.location);

                                  setTime(100);
                                  setIsActive(true);
                                  setTimeout(() => {
                                    setTime(0);
                                    setIsActive(false);
                                  }, 2000);
                                })
                                .catch((err) => console.error(err));
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Event Name</label>
                      {errors?.event_name && (
                        <span className="text-red ms-1">
                          {errors?.event_name?.message}
                        </span>
                      )}
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Event Name"
                          name="event_name"
                          {...register("event_name")}
                        />
                        <img
                          src="assets/images/event-name.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Event Type</label>
                      {errors?.event_type && (
                        <span className="text-red ms-1">
                          {errors?.event_type?.message}
                        </span>
                      )}
                      <div className="input-container">
                        {/* <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Event Type"
                        name="event_type"
                        {...register("event_type")}
                      /> */}

                        <Controller
                          name="event_type"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <select
                              className="form-control pl-5"
                              placeholder="Select Event Type"
                              value={value}
                              onChange={(e) => {
                                onChange(e.target.value);
                              }}
                            >
                              <option value="">Select Event Type</option>

                              {eventTypes?.map((item, i) => (
                                <option value={item.id} key={i}>
                                  {item?.name}
                                </option>
                              ))}

                              {/*also add from backend   */}
                            </select>
                          )}
                        />
                        <img
                          src="assets/images/event-name.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Event Date</label>
                      {errors?.event_date && (
                        <span className="text-red ms-1">
                          {errors?.event_date?.message}
                        </span>
                      )}
                      <div className="input-container">
                        <Controller
                          control={control}
                          name="event_date"
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              containerClassName="form-control pl-5"
                              value={value}
                              onChange={(val) => {
                                onChange(val.toDate());
                              }}
                              placeholder="Event Date"
                              inputClass="dateInputClass"
                              minDate={new Date()}
                              name="event_date"
                            />
                          )}
                        />
                        <img
                          src="assets/images/Date-of-Birth.png"
                          className="input-img"
                          alt=""
                        />
                        <img
                          src="assets/images/choose-date.png"
                          className="field-icon"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Event Time</label>
                      {errors?.event_time && (
                        <span className="text-red ms-1">
                          {errors?.event_time?.message}
                        </span>
                      )}
                      <div className="input-container">
                        <Controller
                          control={control}
                          name="event_time"
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              containerClassName="form-control pl-5"
                              disableDayPicker
                              value={value}
                              format="hh:mm A"
                              plugins={[<TimePicker hideSeconds />]}
                              inputClass="dateInputClass"
                              name="event_time"
                              placeholder="Event Time"
                              onChange={(val) => {
                                onChange(val.toDate());
                              }}
                            />
                          )}
                        />
                        <img
                          src="assets/images/time.png"
                          className="input-img"
                          alt=""
                        />
                        <img
                          src="assets/images/time-colored.png"
                          className="field-icon"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Area</label>
                      {errors?.area && (
                        <span className="text-red ms-1">
                          {errors?.area?.message}
                        </span>
                      )}
                      <div className="input-container">
                        {/* <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Area"
                          name="area"
                          {...register("area")}
                        /> */}
                        <Controller
                          name="area"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <select
                              className="form-control pl-5"
                              placeholder="Select Area Name"
                              value={value}
                              onChange={(e) => {
                                onChange(e.target.value);
                              }}
                            >
                              <option value="">Select Area Name</option>
                              {areaList?.map((item, i) => (
                                <option value={item.id} key={item.id}>
                                  {item?.areacode}
                                </option>
                              ))}
                            </select>
                          )}
                        />
                        <img
                          src="assets/images/event-name.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  {watchArea && (
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Venue</label>
                        {errors?.venue && (
                          <span className="text-red ms-1">
                            {errors?.venue?.message}
                          </span>
                        )}
                        <div className="input-container">
                          <Controller
                            name="venue"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <select
                                type="text"
                                className="form-control pl-5"
                                placeholder="Venue"
                                name="venue"
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                value={value}
                              >
                                <option value="">Select Venue</option>
                                {schoolList?.map((item, i) => (
                                  <option value={item?.id} key={i}>
                                    {item?.name}
                                  </option>
                                ))}
                              </select>
                            )}
                          />
                          <img
                            src="assets/images/total-seats.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-sm-6 col-md-6 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Teacher</label>
                      {errors?.teacherId && (
                        <span className="text-red ms-1">
                          {errors?.teacherId?.message}
                        </span>
                      )}
                      <div className="input-container">
                        <Controller
                          name="teacherId"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <select
                              type="text"
                              className="form-control pl-5"
                              placeholder="Select Teacher"
                              name="teacher"
                              onChange={(e) => {
                                onChange(e.target.value);
                              }}
                              value={value}
                            >
                              <option value="">Select Teacher</option>
                              {teacherList?.map((it) => {
                                return (
                                  <option value={it?.id} key={it?.id}>
                                    {it.full_name}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                        />
                        <img
                          src="assets/images/total-seats.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 mb-4">
                    <label className="fw-bold">Age Groups</label>
                    {errors?.age_group && (
                      <span className="text-red ms-1">
                        {errors?.age_group?.message}
                      </span>
                    )}
                    <div className="form-group">
                      <div className="checkboxs AddStdtCheck">
                        <label>
                          <input
                            type="checkbox"
                            checked={watchAgeGroup?.includes("adult")}
                            name={`age_group`}
                            onChange={(e) => {
                              let checked = e.target.checked;
                              let smthng = watchAgeGroup;
                              let arr = [...smthng];
                              if (arr[0] === "") {
                                arr.splice(0, 1);
                              }
                              const ind = arr.indexOf("adult");
                              if (checked) {
                                if (ind === -1) {
                                  arr.push("adult");
                                }
                              } else {
                                if (ind !== -1) {
                                  arr.splice(ind, 1);
                                }
                              }
                              setValue("age_group", arr);
                            }}
                          />
                          <div className="div-bg">
                            <p>
                              <span>Adult</span>
                            </p>
                          </div>
                        </label>

                        <label htmlFor="junior">
                          <input
                            type="checkbox"
                            id="junior"
                            //value="junior"
                            checked={watchAgeGroup?.includes("child")}
                            name={`age_group`}
                            onChange={(e) => {
                              let checked = e.target.checked;
                              let smthng = watchAgeGroup;
                              let arr = [...smthng];
                              if (arr[0] === "") {
                                arr.splice(0, 1);
                              }
                              const ind = arr.indexOf("child");
                              if (checked) {
                                if (ind === -1) {
                                  arr.push("child");
                                }
                              } else {
                                if (ind !== -1) {
                                  arr.splice(ind, 1);
                                }
                              }
                              setValue("age_group", arr);
                            }}
                          />
                          <div className="div-bg">
                            <p>
                              <span>Child</span>
                            </p>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group addUser">
                      <label className="fw-bold">
                        {/* <img src="assets/images/discipline.png" alt="" />{" "} */}
                        Discipline
                      </label>
                      {errors && errors?.discipline && (
                        <span className="text-red ms-1">
                          {errors.discipline?.message}
                        </span>
                      )}
                      <div className="checkboxs">
                        {disciplines?.map((disc, ind) => {
                          return (
                            <label key={ind}>
                              <input
                                type="radio"
                                value={disc?.id}
                                name={`discipline`}
                                checked={
                                  watchDiscipline?.toString() ===
                                  disc?.id?.toString()
                                }
                                onChange={(e) => {
                                  setValue(`discipline`, e.target.value);
                                }}
                              />
                              <div className="div-bg">
                                <p>
                                  <span>{disc?.name}</span>
                                </p>
                              </div>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4 ">
                    <div className="form-group">
                      <label className="fw-bold">About Event</label>
                      {errors && (
                        <span className="text-red ms-1">
                          {errors?.about?.message}
                        </span>
                      )}
                      <div className="input-container">
                        <Controller
                          name={`about`}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <textarea
                              className="textarea-control p-3"
                              rows="7"
                              value={value}
                              placeholder="Write here..."
                              name="about"
                              onChange={(e) => {
                                onChange(e.target.value);
                              }}
                            ></textarea>
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  {((!watchHasLevels && watchAgeGroup?.includes("child")) ||
                    (!watchHasLevels && watchAgeGroup?.includes("both"))) && (
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Event Child Price</label>
                        {errors?.child_price && (
                          <span className="text-red ms-1">
                            {errors?.child_price?.message}
                          </span>
                        )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Event Child Price"
                            name="child_price"
                            {...register("child_price")}
                          />
                          <img
                            src="assets/images/event-name.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {((!watchHasLevels && watchAgeGroup?.includes("adult")) ||
                    (!watchHasLevels && watchAgeGroup?.includes("both"))) && (
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Event Adult Price</label>
                        {errors?.adult_price && (
                          <span className="text-red ms-1">
                            {errors?.adult_price?.message}
                          </span>
                        )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Event Adult Price"
                            name="adult_price"
                            {...register("adult_price")}
                          />
                          <img
                            src="assets/images/event-name.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-sm-12 col-md-12 mb-4 text-center">
                    Are there different levels and times ?
                    <label className="mx-1">
                      <Controller
                        name="hasLevels"
                        control={control}
                        render={() => {
                          return (
                            <input
                              type="checkbox"
                              id="yes"
                              className="mx-1"
                              checked={watchHasLevels === true}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setValue("hasLevels", true);
                                  // setValue("child_price", "");
                                  // setValue("adult_price", "");
                                  append({
                                    level_id: "",
                                    name: "",
                                    price: "",
                                    time: "",
                                    note: "",
                                  });
                                }
                              }}
                            />
                          );
                        }}
                      />
                      <label htmlFor="yes">Yes</label>
                    </label>
                    <label className="mx-1">
                      <Controller
                        name="hasLevels"
                        control={control}
                        render={() => {
                          return (
                            <input
                              type="checkbox"
                              className="mx-1"
                              id="no"
                              checked={watchHasLevels === false}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setValue("hasLevels", false);
                                  remove();
                                }
                              }}
                            />
                          );
                        }}
                      />

                      <label htmlFor="no">No</label>
                    </label>
                    {errors?.hasLevels && (
                      <span className="text-red ms-1">
                        {errors?.hasLevels?.message}
                      </span>
                    )}
                  </div>

                  {watchHasLevels && (
                    <div className="col-sm-12 col-md-12 mt-4 mb-2">
                      <div className="form-group">
                        <h5>Levels</h5>
                      </div>
                    </div>
                  )}
                  {watchHasLevels &&
                    fields?.map((item, i) => (
                      <React.Fragment key={item?.level_id || item?.id}>
                        <div className="col-sm-12 position-relative mb-5">
                          <span
                            className="crossButton"
                            onClick={() => {
                              remove(i);
                            }}
                          >
                            x
                          </span>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 mb-4">
                          <div className="form-group">
                            <label className="fw-bold">Level Name</label>
                            {errors?.level && (
                              <span className="text-red ms-1">
                                {errors?.level[i]?.name?.message}
                              </span>
                            )}
                            <div className="input-container">
                              <Controller
                                name={`level[${i}].name`}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <input
                                    type="text"
                                    className="form-control pl-5"
                                    placeholder="Level Name"
                                    name={"name"}
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e.target.value);
                                    }}
                                  />
                                )}
                              />

                              <img
                                src="assets/images/total-seats.png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        {(watchHasLevels && watchAgeGroup.includes("adult")) ||
                        (watchHasLevels && watchAgeGroup.includes("both")) ? (
                          <div className="col-sm-6  col-md-6 col-lg-6 mb-4">
                            <div className="form-group">
                              <label className="fw-bold">Adult Price</label>
                              {errors?.level && (
                                <span className="text-red ms-1">
                                  {errors?.level[i]?.adult_price?.message}
                                </span>
                              )}
                              <div className="input-container">
                                <Controller
                                  name={`level[${i}].adult_price`}
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="text"
                                      className="form-control pl-5"
                                      placeholder="Level Price"
                                      name={"adult_price"}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e.target.value);
                                      }}
                                    />
                                  )}
                                />

                                <img
                                  src="assets/images/total-seats.png"
                                  className="input-img"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {(watchHasLevels && watchAgeGroup.includes("child")) ||
                        (watchHasLevels && watchAgeGroup?.includes("both")) ? (
                          <div className="col-sm-6  col-md-6 col-lg-6 mb-4">
                            <div className="form-group">
                              <label className="fw-bold">Child Price</label>
                              {errors?.level && (
                                <span className="text-red ms-1 small-font-size">
                                  {errors?.level[i]?.child_price?.message}
                                </span>
                              )}
                              <div className="input-container">
                                <Controller
                                  name={`level[${i}].child_price`}
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="text"
                                      className="form-control pl-5"
                                      placeholder="Level Price"
                                      name={"child_price"}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e.target.value);
                                      }}
                                    />
                                  )}
                                />

                                <img
                                  src="assets/images/total-seats.png"
                                  className="input-img"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="col-sm-6  col-md-6 col-lg-6 mb-4">
                          <div className="form-group">
                            <label className="fw-bold">Level Time</label>
                            {errors?.level && (
                              <span className="text-red ms-1">
                                {errors?.level[i]?.time?.message}
                              </span>
                            )}
                            <div className="input-container">
                              <Controller
                                control={control}
                                name={`level[${i}].time`}
                                render={({ field: { onChange, value } }) => (
                                  <DatePicker
                                    containerClassName="form-control pl-5"
                                    disableDayPicker
                                    value={value}
                                    format="hh:mm A"
                                    plugins={[<TimePicker hideSeconds />]}
                                    inputClass="dateInputClass"
                                    name="time"
                                    placeholder="Event Time"
                                    onChange={(val) => {
                                      onChange(val.toDate());
                                    }}
                                  />
                                )}
                              />

                              <img
                                src="assets/images/total-seats.png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-4 ">
                          <div className="form-group">
                            <label className="fw-bold">Level Note</label>
                            {errors?.level && (
                              <span className="text-red ms-1">
                                {errors?.level[i]?.note?.message}
                              </span>
                            )}
                            <div className="input-container">
                              <Controller
                                name={`level[${i}].note`}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <textarea
                                    className="textarea-control p-3"
                                    rows="7"
                                    value={value}
                                    placeholder="Write here..."
                                    name="note"
                                    onChange={(e) => {
                                      onChange(e.target.value);
                                    }}
                                  ></textarea>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        {fields.length > 1 && (
                          <div className="col-sm-12">
                            <hr />
                          </div>
                        )}
                      </React.Fragment>
                    ))}

                  {watchHasLevels && (
                    <div className="col-sm-12 position-relative mb-5">
                      <button
                        type="button"
                        className="btn btn-save plusButton"
                        onClick={() => {
                          append({
                            level_id: "",
                            name: "",
                            price: "",
                            time: "",
                            note: "",
                          });
                        }}
                      >
                        + Add Additional Level
                      </button>
                    </div>
                  )}

                  <div className="col-sm-12 col-md-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-save"
                      disabled={submitting}
                    >
                      {submitting ? <Loader /> : "Save"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default AddEvent;
