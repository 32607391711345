import React from "react";
import { Modal } from "react-bootstrap";

function FilterModal({ show, handleShow }) {
  const Comp = () => {
    return (
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content body-bg">
          <div className="modal-header border-0 justify-content-center">
            <h6>Filter</h6>
            <button
              type="button"
              className="btn-close"
              onClick={handleShow}
            ></button>
          </div>
          <div className="modal-body mb-4">
            <form className="form-style">
              <div className="form-group">
                <h5>Event</h5>
                <select className="form-control form-select">
                  <option>Select Event Name</option>
                </select>
              </div>
              <div className="form-group">
                <h5>Age Group</h5>
                <div className="checkboxs">
                  <label>
                    <input type="radio" value="child" name="membership-lavel" />
                    <div className="div-bg">
                      <p>
                        <span>child</span>
                      </p>
                    </div>
                  </label>

                  <label>
                    <input type="radio" value="adult" name="membership-lavel" />
                    <div className="div-bg">
                      <p>
                        <span>adult</span>
                      </p>
                    </div>
                  </label>
                </div>
              </div>
              <div className="form-group">
                <h5>Location</h5>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Location"
                />
              </div>
              <div className="text-center">
                <a href="#" className="text-dark">
                  Reset All
                </a>
              </div>
              <div className="text-center">
                <button className="btn btn-accept btn-view mt-2">Apply</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };
  return <Modal show={show} onHide={handleShow} dialogAs={Comp} />;
}

export default FilterModal;
