import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { importStudentFromExcel } from "../../api/student";
import Loader from "../../component/Loader";
import DeleteModal from "../../component/Modal/Delete";
import Paginations from "../../component/Pagination";
import { variants } from "../../constant/Variants";
import { areaCodeListingSelector, FetchAreaCodeList } from "../areacode/slice";
import { FetchDisciplineList } from "../discipline/DisciplineSlice";
import { SchoolListReducer } from "../slice";
import {
  FetchStudentList,
  studentListSelector,
  totalStudentCountSelector,
} from "../student/studentslice";

function ImportExportStudent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const arealist = useSelector(areaCodeListingSelector);
  const Schoollist = useSelector((state) => state.main.schools);
  const disciplineList = useSelector((state) => state.discipline.list);
  const list = useSelector(studentListSelector);
  const total = useSelector(totalStudentCountSelector);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const csvLink = useRef();
  const [filters, setFilters] = useState({
    schoolId: "",
    areacode: "",
    discipline: "",
    membership_status: "",
    age: "",
  });

  const getVenues = useCallback(() => {
    dispatch(SchoolListReducer({ areacode: filters.areacode }));
  }, [filters.areacode, dispatch]);

  useEffect(() => {
    dispatch(FetchAreaCodeList({}));
    dispatch(FetchDisciplineList({}));
    getVenues();
  }, [getVenues, dispatch]);

  const handlePage = (val) => {
    setPage(val);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const getList = useCallback(() => {
    setLoading(true);
    dispatch(
      FetchStudentList({
        page: page,
        limit: 10,
        search: search,
        discipline: filters?.discipline,
        schoolId: filters?.schoolId,
        areacode: filters?.areacode,
        trialAccountStatus: filters?.membership_status,
        // age: filters?.age,
        student_type: filters?.student_type,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [
    dispatch,
    page,
    search,
    filters.discipline,
    filters.schoolId,
    filters.areacode,
    filters.membership_status,
    filters.student_type,
  ]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleDOwnloadCSV = (done) => {
    setDownloading(true);
    dispatch(
      FetchStudentList({
        search: search,
        discipline: filters?.discipline,
        schoolId: filters?.schoolId,
        areacode: filters?.areacode,
        trialAccountStatus: filters?.membership_status,
        // age: filters?.age,
        student_type: filters?.student_type,
      })
    )
      .then((res) => {
        let list = res?.payload?.data?.student_list;
        if (list?.length > 0) {
          let arr = list?.map((item) => {
            let data = {
              user_email: item?.parent_details?.email,
              first_name: item?.first_name,
              last_name: item?.last_name,
              dob: item?.dob,
              dob_year: item?.dob_year,
              dob_month: item?.dob_month,
              dob_day: item?.dob_day,
              gender: item?.gender,
              student_type: item?.student_type,
              discipline: item?.discipline,
              emergency_countryCode: item?.emergency_countryCode,
              emergency_contactNo: item?.emergency_contactNo,
              medical_note: item?.medical_note,
              schoolId: item?.schoolId,
              areacode: item?.areacode,
              trialAccountStatus: item?.trialAccountStatus,
              membership_level: item?.membership_details?.membership_level,
              valid_till:
                moment(item?.membership_details?.valid_till).format(
                  "DD MMM YYYY"
                ) || "",
              membership_start_date: moment(
                item?.membership_details?.start_date
              ).format("DD MMM YYYY"),
            };
            return data;
          });

          setCsvData(arr);
        }
      })
      .then((res) => {
        setDownloading(false);

        csvLink.current.link.click();
      });
  };

  const handleImportStudent = (file) => {
    importStudentFromExcel({ file: file?.[0] }).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getList();
      } else {
        toast.error(res?.message);
      }
    });
  };

  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="link-active shadow">
              <p>
                <img src="assets/images/event-calender-selected.png" alt="" />{" "}
                Imp/Exp Data {total}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search mx-2">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search by name"
                  value={search}
                  onChange={(e) => {
                    setPage(1);

                    setSearch(e.target.value);
                  }}
                />
              </form>
              <input
                type="file"
                id="myfile"
                name="myfile"
                className="d-none"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => {
                  let file = e.target.files;

                  handleImportStudent(file);
                }}
              />
              <button className="btn filter btn-accept px-3 fs-6 d-flex align-items-center">
                {/* <img src="assets/images/filter.png" alt="" /> */}
                <img
                  src="assets/images/download.png"
                  alt=""
                  className="download-icon"
                />{" "}
                <label htmlFor="myfile"> Import</label>
              </button>

              <CSVLink
                data={csvData}
                asyncOnClick={true}
                filename={"student_list.csv"}
                //   onClick={(event, done) => {
                //     handleDOwnloadCSV(done);
                //   }}
                className="d-none"
                ref={csvLink}
              ></CSVLink>
              <button
                className="btn btn-accept btn-view px-3 fs-6 ms-0 fs-normal"
                onClick={handleDOwnloadCSV}
              >
                <img
                  src="assets/images/download.png"
                  alt=""
                  className="download-icon"
                />
                {"Export CSV"}
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-sm-9 col-md-9 col-lg-10">
            <ul
              className="nav nav-pills group-nav mb-3"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link "
                  id="pills-new-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-new"
                  type="button"
                  role="tab"
                  aria-controls="pills-new"
                  aria-selected="true"
                  onClick={() => {
                    navigate("/import-export-data/user");
                  }}
                >
                  Users
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-completed-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-completed"
                  type="button"
                  role="tab"
                  aria-controls="pills-completed"
                  aria-selected="false"
                  onClick={() => {
                    navigate("/import-export-data/student");
                  }}
                >
                  Students
                </button>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 my-2">
            <div className="dropSchool">
              <select
                className="form-control form-select"
                onChange={(e) => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      areacode: e.target.value,
                    };
                  });
                }}
                value={filters?.areacode}
              >
                <option value="">Select Area</option>
                {arealist?.map((item, i) => (
                  <option value={item?.id} key={i}>
                    {item?.areacode}
                  </option>
                ))}
              </select>
              {filters?.areacode && (
                <select
                  className="form-control form-select"
                  onChange={(e) => {
                    setFilters((prev) => {
                      return {
                        ...prev,
                        schoolId: e.target.value,
                      };
                    });
                  }}
                  value={filters?.schooldId}
                >
                  <option value="">Select Venue</option>
                  {Schoollist?.map((item, i) => (
                    <option value={item?.id} key={i}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              )}

              <select
                className="form-control form-select"
                onChange={(e) => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      discipline: e.target.value,
                    };
                  });
                }}
                value={filters?.discipline}
              >
                <option value="">Select Discipline</option>
                {disciplineList?.map((item, i) => (
                  <option value={item?.id} key={i}>
                    {item?.name}
                  </option>
                ))}
              </select>
              <select
                className="form-control form-select"
                onChange={(e) => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      membership_status: e.target.value,
                    };
                  });
                }}
                value={filters?.membership_status}
              >
                <option value="">Select Membership Status</option>
                <option value="1">Trail</option>
                <option value="2">Active</option>
                <option value="3">Inactive</option>
              </select>
              <select
                className="form-control form-select"
                onChange={(e) => {
                  setPage(1);
                  setFilters((prev) => {
                    return {
                      ...prev,
                      student_type: e.target.value,
                    };
                  });
                }}
                value={filters?.student_type}
              >
                <option value="">Select Student Type</option>
                <option value="child">Child</option>
                <option value="adult">Adult</option>
              </select>
              {/* <input
                type="number"
                className="form-control"
                value={filters?.age}
                onChange={(e) => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      age: e.target.value,
                    };
                  });
                }}
                placeholder={"Search Student By Age"}
              /> */}
              <button
                type="button"
                className="btn btn-save p-2 fs-6"
                onClick={() => {
                  setFilters({
                    schoolId: "",
                    areacode: "",
                    discipline: "",
                    membership_status: "",
                    age: "",
                    student_type: "",
                  });
                }}
              >
                Reset
              </button>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Parent Email</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Student Type</th>
                    <th scope="col">Discipline</th>
                    <th scope="col">Date of Birth</th>
                    <th scope="col">Emergency Contact Number</th>
                    <th scope="col">Venue</th>
                    <th scope="col">Membership Status</th>

                    {/* <th scope="col">action</th> */}
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td colSpan={10}>
                      <Loader />
                    </td>
                  ) : (
                    <>
                      {list?.length > 0 ? (
                        list?.map((item, i) => {
                          return (
                            <tr variants={variants.card} key={i}>
                              <td>{item?.id || "NA"}</td>
                              <td>{item?.parent_details?.email || "NA"}</td>
                              <td>
                                {item?.first_name ? item?.first_name : "NA"}
                              </td>
                              <td>
                                {item?.last_name ? item?.last_name : "NA"}
                              </td>
                              <td>
                                {item?.student_type ? item?.student_type : "NA"}
                              </td>
                              <td>
                                {item?.discipline_details
                                  ? item?.discipline_details?.name
                                  : "NA"}
                              </td>
                              <td>
                                {item?.dob && item?.dob !== "0000-00-00"
                                  ? moment(item?.dob).format("DD MMM YYYY")
                                  : item?.dob_year
                                  ? moment(
                                      `${item?.dob_year}-${item?.dob_month}-${item?.dob_day}`
                                    ).format("DD MMM YYYY")
                                  : "NA"}
                              </td>
                              <td>
                                {item?.emergency_contactNo
                                  ? `${item?.emergency_countryCode}${item?.emergency_contactNo}`
                                  : "NA"}
                              </td>
                              {/* <td>
                            {item?.address_details
                              ? `${item?.address_details?.property_number}, ${
                                  item?.address_details?.property_name
                                    ? item?.address_details?.property_name +
                                      ", "
                                    : ", "
                                } ${item?.address_details?.street_number}, ${
                                  item?.address_details?.town
                                }, ${item?.address_details?.country}-${
                                  item?.address_details?.postal_code
                                }`
                              : "NA"}
                          </td> */}
                              <td>
                                {item?.school_details
                                  ? `${item?.school_details?.name}, ${item?.school_details?.address}`
                                  : "NA"}
                              </td>
                              <td>
                                {item?.trialAccountStatus === "1"
                                  ? "Trial"
                                  : item?.membership_details &&
                                    moment(
                                      item?.membership_details?.valid_till
                                    ).diff(moment()) < 0
                                  ? "Inactive"
                                  : item?.trialAccountStatus === "3"
                                  ? "Active"
                                  : "NA"}
                              </td>

                              {/* <td>
                                <div className="d-flex justify-content-center action align-items-center">
                                  <button className="btn" title="View Student">
                                    <Link to={`/student/${item?.id}`}>
                                      <img
                                        src="assets/images/view.png"
                                        alt=""
                                      />
                                    </Link>
                                  </button>
                                  <button className="btn">
                                    <Link to={`/edit-student/${item?.id}`}>
                                      <img
                                        src="assets/images/edit.png"
                                        alt=""
                                      />
                                    </Link>
                                  </button>
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      setDelId(item.id);
                                      handleShow();
                                    }}
                                    title="Delete Student"
                                  >
                                    <img
                                      src="assets/images/delete.png"
                                      alt=""
                                    />
                                  </button>
                                  <span
                                    className="px-1 cursor-pointer"
                                    title="Make Teacher"
                                    onClick={() => {
                                      navigate(
                                        `/student-to-teacher/${item?.id}`
                                      );
                                    }}
                                  >
                                    <i
                                      class="fa fa-exchange fa-xl theme-color"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </div>
                              </td> */}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10}>No data found.</td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {list?.length > 0 && (
            <Paginations
              handlePage={handlePage}
              page={page}
              total={total && total}
            />
          )}
        </div>
      </div>
      {/* {modalName === "filter" && (
        <FilterModal show={show} handleShow={handleShow} />
      )} */}
      {modalName === "delete" && (
        <DeleteModal
          page="Event"
          show={show}
          handleShow={handleShow}
          cancel={() => {
            setShow(false);
          }}
        />
      )}
    </article>
  );
}

export default ImportExportStudent;
