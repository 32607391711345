import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../component/Loader";
import { details, FetchUserDetail } from "./UserSlice";
import BackButton from "./../../component/BackButton";
import { FetchConsentList } from "../consent/ConsentSlice";
import { IoIosAddCircle } from "react-icons/io";
import { FaMinus } from "react-icons/fa";

function ViewUser() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const data = useSelector((state) => state.user.detail);
  const [loading, setLoading] = useState(false);
  const [consents, setConsents] = useState([]);
  const navigate = useNavigate();
  const consentList = useSelector((state) => state.consent.list);
  const [consnetToggle, setConsentToggle] = useState(false);

  useEffect(() => {
    dispatch(FetchConsentList({ page: 1, limit: 100000 }));
  }, [dispatch]);
  const getDetails = useCallback(() => {
    setLoading(true);
    dispatch(FetchUserDetail({ userId: id })).then((res) => {
      if (res?.payload?.code === 1) {
        setLoading(false);
      }
    });
  }, [dispatch, id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  useEffect(() => {
    return () => {
      dispatch(details(null));
    };
  }, [dispatch]);

  if (loading) {
    return (
      <article className="col-lg-9 articleClass">
        <Loader />
      </article>
    );
  } else {
    return (
      <article className="col-lg-9 articleClass">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <BackButton />
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3>User Details</h3>
            </div>
            <div className="col-sm-12 text-end">
              <button
                type="button"
                className="btn btn-save p-3 fs-6"
                onClick={() => {
                  navigate(`/user/${id}/wallet/${data?.wallet}`);
                }}
              >
                Wallet <i class="fa fa-gbp" aria-hidden="true"></i>{" "}
                {data?.wallet}
              </button>
            </div>
          </div>
          <div className="row mt-3 px-md-5">
            <div className="col-sm-12 mb-3">
              <h3>Account Holder Details</h3>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3  position-relative h-100">
                <p className="text-capitalize mb-1">full name</p>
                <h6 className="">
                  <img
                    src="assets/images/First-Name.png"
                    className="detail-icon"
                    alt=""
                  />
                  {data?.first_name
                    ? data?.first_name + " " + data?.last_name
                    : ""}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3  position-relative h-100">
                <p className="text-capitalize mb-1">username</p>
                <h6 className="">
                  <img
                    src="assets/images/First-Name.png"
                    className="detail-icon"
                    alt=""
                  />
                  {data?.username || "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3  position-relative h-100">
                <p className="text-capitalize mb-1">email ID</p>
                <h6 className="text-break">
                  <img
                    src="assets/images/Email-Address.png"
                    className="detail-icon"
                    alt=""
                  />
                  {data?.email || "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3  position-relative h-100">
                <p className="text-capitalize mb-1">Contact Number</p>
                <h6 className="">
                  <img
                    src="assets/images/address.png"
                    className="detail-icon"
                    alt=""
                  />
                  {data?.address_details?.mobile
                    ? data?.address_details?.countryCode +
                      data?.address_details?.mobile
                    : "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3  position-relative h-100">
                <p className="text-capitalize mb-1">date of birth</p>
                <h6 className="">
                  <img
                    src="assets/images/Date-of-Birth.png"
                    className="detail-icon"
                    alt=""
                  />
                  {data?.address_details?.dob &&
                  data?.address_details?.dob !== "0000-00-00"
                    ? moment(data?.address_details?.dob).format("DD MMM YYYY")
                    : data?.address_details?.dob_year
                    ? moment(
                        `${data?.address_details?.dob_year}-${data?.address_details?.dob_month}-${data?.address_details?.dob_day}`
                      ).format("DD MMM YYYY")
                    : "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3  position-relative h-100">
                <p className="text-capitalize mb-1">full address</p>
                <h6 className="">
                  <img
                    src="assets/images/address.png"
                    className="detail-icon"
                    alt=""
                  />
                  {data?.address_details
                    ? `${
                        data?.address_details?.property_number
                          ? `${data?.address_details?.property_number}, `
                          : ""
                      }${
                        data?.address_details?.property_name
                          ? `${data?.address_details?.property_name}, `
                          : ""
                      }${
                        data?.address_details?.street_number
                          ? `${data?.address_details?.street_number}, `
                          : ""
                      }${
                        data?.address_details?.town
                          ? `${data?.address_details?.town}, `
                          : ""
                      }${
                        data?.address_details?.country
                          ? `${data?.address_details?.country}`
                          : ""
                      }${
                        data?.address_details?.postal_code
                          ? `-${data?.address_details?.postal_code}`
                          : ""
                      }`
                    : "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <div
                className="bg-white px-5 py-3  position-relative h-100"
                style={{
                  textTransform: "uppercase",
                }}
              >
                <div className="d-flex">
                  <h5 className="mb-1">
                    {data?.consent?.length > 1 ? "Consents" : "Consent"}
                  </h5>
                  <span
                    className="ms-auto cursor-pointer"
                    onClick={() => setConsentToggle(!consnetToggle)}
                  >
                    {consnetToggle ? (
                      <FaMinus size={35} />
                    ) : (
                      <IoIosAddCircle size={35} />
                    )}
                  </span>
                </div>
                {data?.consent?.length > 0
                  ? consnetToggle &&
                    data?.consent?.map((item, i) => {
                      return (
                        <>
                          <p>
                            <span>{item?.consent_details?.label}: </span>
                            <span className="fw-bold">{item?.value}</span>
                          </p>
                          {item?.consent_details?.label
                            ?.toLowerCase()
                            ?.includes("medication prescribed") &&
                            item?.note && (
                              <p>
                                <span>Medication: </span>
                                <span className="fw-bold">{item?.note}</span>
                              </p>
                            )}
                        </>
                      );
                    })
                  : consentList?.map((item, i) => {
                      return (
                        <>
                          <p>
                            <span>{item?.label}: </span>
                            <span className="fw-bold">
                              {item?.value || "NA"}
                            </span>
                          </p>
                          {item?.label
                            ?.toLowerCase()
                            ?.includes("medication prescribed") &&
                            item?.note && (
                              <p>
                                <span>Medication: </span>
                                <span className="fw-bold">{item?.note}</span>
                              </p>
                            )}
                        </>
                      );
                    })}
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <hr />
            </div>

            <div className="col-sm-12 mb-3">
              <h3>Student Details</h3>
            </div>
            {data?.student_list?.length > 0 ? (
              data?.student_list?.map((item, i) => {
                return (
                  <>
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                        <div className="bg-white px-5 py-3  position-relative h-100">
                          <p className="text-capitalize mb-1">full name</p>
                          <h6 className="">
                            <img
                              src="assets/images/First-Name.png"
                              className="detail-icon"
                              alt=""
                            />
                            {item?.first_name
                              ? item?.first_name + " " + item?.last_name
                              : "NA"}
                          </h6>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                        <div className="bg-white px-5 py-3  position-relative h-100">
                          <p className="text-capitalize mb-1">Gender</p>
                          <h6 className="">
                            <img
                              src="assets/images/gender.png"
                              className="detail-icon"
                              alt=""
                            />
                            {item?.gender || "NA"}
                          </h6>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                        <div className="bg-white px-5 py-3  position-relative h-100">
                          <p className="text-capitalize mb-1">
                            membership level
                          </p>
                          <h6 className="">
                            <img
                              src="assets/images/membership-status.png"
                              className="detail-icon"
                              alt=""
                            />
                            {item?.membership_details
                              ? item?.membership_details?.membership_details
                                  ?.name
                              : item?.trialAccountStatus === "1"
                              ? "Trial"
                              : item?.trialAccountStatus === "2"
                              ? "Trial Membership Expired. Kindly upgrade."
                              : !item?.membership_details
                              ? "No membership. Kindly purchase membership."
                              : "Membership expired. Kindly upgrade."}
                          </h6>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                        <div className="bg-white px-5 py-3  position-relative h-100">
                          <p className="text-capitalize mb-1">data of birth</p>
                          <h6 className="">
                            <img
                              src="assets/images/Date-of-Birth.png"
                              className="detail-icon"
                              alt=""
                            />
                            {item?.dob
                              ? moment(item?.dob).format("DD MMM YYYY")
                              : item?.dob_year
                              ? moment(
                                  `${item?.dob_year}-${item?.dob_month}-${item?.dob_day}`
                                ).format("DD MMM YYYY")
                              : "NA"}
                          </h6>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                        <div className="bg-white px-5 py-3  position-relative h-100">
                          <p className="text-capitalize mb-1">discipline</p>
                          <h6 className="">
                            <img
                              src="assets/images/discipline.png"
                              className="detail-icon"
                              alt=""
                            />
                            {item?.discipline_details
                              ? item?.discipline_details?.name
                              : item?.trialAccountStatus === "1"
                              ? "Trial"
                              : "NA"}
                          </h6>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                        <div className="bg-white px-5 py-3  position-relative h-100">
                          <p className="text-capitalize mb-1">school venue</p>
                          <h6 className="">
                            <img
                              src="assets/images/address.png"
                              className="detail-icon"
                              alt=""
                            />
                            {item?.school_details
                              ? `${item?.school_details?.name}, ${item?.school_details?.areacode_details?.areacode}`
                              : ""}
                          </h6>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                        <div className="bg-white px-5 py-3  position-relative h-100">
                          <p className="text-capitalize mb-1">
                            Emergency Contact Number
                          </p>
                          <h6 className="">
                            <img
                              src="assets/images/address.png"
                              className="detail-icon"
                              alt=""
                            />
                            {item?.emergency_contactNo
                              ? item?.emergency_countryCode +
                                item?.emergency_contactNo
                              : "NA"}
                          </h6>
                        </div>
                      </div>
                      {item?.membership_details && (
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                          <div className="bg-white px-5 py-3  position-relative h-100">
                            <p className="text-capitalize mb-1">
                              next renewal date
                            </p>
                            <h6 className="">
                              <img
                                src="assets/images/membership-status.png"
                                className="detail-icon"
                                alt=""
                              />
                              {moment(
                                item?.membership_details?.valid_till
                              ).format("DD MMM YYYY")}
                            </h6>
                          </div>
                        </div>
                      )}
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                        <div className="bg-white px-5 py-3  position-relative h-100">
                          <p className="text-capitalize mb-1">medical note</p>
                          <h6 className="">
                            <img
                              src="assets/images/medical.png"
                              className="detail-icon"
                              alt=""
                            />
                            {item?.medical_note || "NA"}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <hr />
                    </div>
                  </>
                );
              })
            ) : (
              <div>No student found.</div>
            )}
          </div>
        </div>
      </article>
    );
  }
}

export default ViewUser;
