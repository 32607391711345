import moment from "moment";
import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteModal from "../../../component/Modal/Delete.jsx";
import Paginations from "../../../component/Pagination.jsx";
import {
  ChangeEditProfileRequestStatus,
  DeleteEditProfileRequest,
  FetchEditProfileRequestList,
} from "./EditUserRequestSlice.js";

function EditUserConsentRequest() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const list = useSelector((state) => state.edit.list);
  console.log(list);
  const total = useSelector((state) => state.edit.total);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState("");
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(0);

  const handleShow = () => {
    setShow(!show);
  };

  const getList = useCallback(() => {
    dispatch(
      FetchEditProfileRequestList({
        page,
        limit: 10,
        search: search,
        status: status,
        request_for: "edit consent",
      })
    );
  }, [dispatch, page, search, status]);

  useEffect(() => {
    getList();
  }, [getList, dispatch]);
  const confirmDelete = () => {
    dispatch(DeleteEditProfileRequest(delId)).then((res) => {
      if (res?.payload?.code === 1) {
        getList();
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  const handleAcceptReject = (data) => {
    dispatch(ChangeEditProfileRequestStatus(data)).then((res) => {
      if (res?.payload?.code === 1) {
        getList();
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="link-active shadow">
              <p>
                <img src="assets/images/event-calender-selected.png" alt="" />{" "}
                Edit Requests{" "}
                {status === 0
                  ? total?.pending
                  : status === 1
                  ? total?.active
                  : status === 2
                  ? total?.inactive
                  : 0}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search request by teacher name"
                  value={search}
                  onChange={(e) => {
                    setPage(1);

                    setSearch(e.target.value);
                  }}
                />
              </form>
              <Link to={`/add-event`} className="">
                <img
                  src="assets/images/addBtn.png"
                  alt=""
                  className="add-new"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 text-center">
          <ul className="nav nav-pills group-nav mb-1 mt-2 justify-content-center">
            <li className="nav-item">
              <button
                className={"nav-link"}
                id="pills-new-tab"
                type="button"
                onClick={() => {
                  navigate("/edit-request");
                }}
              >
                Teacher
              </button>
            </li>
            <li className="nav-item">
              <button
                className={"nav-link "}
                id="pills-new-tab"
                type="button"
                onClick={() => {
                  navigate("/user-edit-request");
                }}
              >
                User
              </button>
            </li>{" "}
            <li className="nav-item">
              <button
                className={"nav-link active"}
                id="pills-new-tab"
                type="button"
                onClick={() => {
                  navigate("/user-consent-edit-request");
                }}
              >
                Consent
              </button>
            </li>
          </ul>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 ">
          <ul className="nav nav-pills group-nav mb-1 mt-2">
            <li className="nav-item">
              <button
                className={status === 0 ? "nav-link active" : "nav-link"}
                id="pills-new-tab"
                type="button"
                onClick={() => {
                  setStatus(0);
                  setPage(1);
                }}
              >
                Pending
              </button>
            </li>
            <li className="nav-item">
              <button
                className={status === 1 ? "nav-link active" : "nav-link"}
                id="pills-accepted-tab"
                type="button"
                onClick={() => {
                  setStatus(1);
                  setPage(1);
                }}
              >
                Accepted
              </button>
            </li>
            <li className="nav-item">
              <button
                className={status === 2 ? "nav-link active" : "nav-link"}
                id="pills-accepted-tab"
                type="button"
                onClick={() => {
                  setStatus(2);
                  setPage(1);
                }}
              >
                Rejected
              </button>
            </li>
          </ul>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">Photo</th>
                    <th scope="col">User Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Contact Number</th>

                    <th scope="col">Action</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {list?.length > 0 ? (
                    list?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <img
                              src={
                                item?.user_details?.image?.includes("http")
                                  ? item?.user_details?.image
                                  : "assets/images/headerlogo.png"
                              }
                              className="table-img"
                              alt=""
                            />
                          </td>
                          <td>
                            {item?.user_details?.first_name
                              ? `${item?.user_details?.first_name} ${item?.user_details?.last_name}`
                              : "NA"}
                          </td>

                          <td>{item?.user_details?.email || "NA"}</td>

                          <td>
                            {item?.user_details?.address_details?.mobile
                              ? `${item?.user_details?.address_details?.countryCode}${item?.user_details?.address_details?.mobile}`
                              : "NA"}
                          </td>

                          <td>
                            <div className="d-flex justify-content-center action">
                              <button className="btn" title="View">
                                <Link to={`/user/${item?.user_details?.id}`}>
                                  <img src="assets/images/view.png" alt="" />
                                </Link>
                              </button>
                              {item?.consent_request_status !== "1" && (
                                <button className="btn" title="Approve">
                                  <span
                                    onClick={() => {
                                      handleAcceptReject({
                                        request_id: item?.id,
                                        consent_request_status: 1,
                                      });
                                    }}
                                  >
                                    <img
                                      src="assets/images/approve.png"
                                      alt=""
                                    />
                                  </span>
                                </button>
                              )}
                              {item?.consent_request_status !== "2" && (
                                <button className="btn" title="Reject">
                                  <span
                                    onClick={() => {
                                      handleAcceptReject({
                                        request_id: item?.id,
                                        consent_request_status: 2,
                                      });
                                    }}
                                  >
                                    <img
                                      src="assets/images/canceled.png"
                                      alt=""
                                    />
                                  </span>
                                </button>
                              )}
                              <button
                                className="btn"
                                onClick={() => {
                                  setDelId(item.id);
                                  handleShow();
                                }}
                                title="Delete"
                              >
                                <img src="assets/images/delete.png" alt="" />
                              </button>
                              {/* <button className="btn">
                                <input
                                  type="checkbox"
                                  className="toggle"
                                  checked=""
                                />
                              </button> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={12}>No data found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {(total?.pending > 10 ||
              total?.active > 10 ||
              total?.inactive > 10) && (
              <Paginations
                handlePage={setPage}
                page={page}
                total={
                  status === 0
                    ? total?.pending
                    : status === 1
                    ? total?.active
                    : status === 2
                    ? total?.inactive
                    : 0
                }
              />
            )}
          </div>
        </div>
      </div>

      <DeleteModal
        page="User Edit Request"
        show={show}
        handleShow={handleShow}
        cancel={() => {
          setShow(false);
        }}
        confirmDelete={confirmDelete}
      />
    </article>
  );
}

export default EditUserConsentRequest;
