import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { config } from "./../../utils/index";
import S3FileUpload from "react-s3";
import { Progress } from "react-sweet-progress";
import { AddTeacherReducer } from "./TeacherSlice";
import { toast } from "react-toastify";
import CreatableSelectInput from "./../../component/CreatableSelectField/CreatableSelectInput";
import moment from "moment";
import Phone from "../../component/Phone/Phone";
import {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import BackButton from "../../component/BackButton";
import Loader from "../../component/Loader";
window.Buffer = window.Buffer || require("buffer").Buffer;

const getYears = () => {
  let startYear = 1970;
  let endYear = moment().get("y");
  let datesArray = [];
  while (startYear <= endYear) {
    datesArray.unshift(startYear);
    startYear++;
  }
  return datesArray;
};

export const months = [
  {
    id: 1,
    value: "January",
  },
  {
    id: 2,
    value: "February",
  },
  {
    id: 3,
    value: "March",
  },
  {
    id: 4,
    value: "April",
  },
  {
    id: 5,
    value: "May",
  },
  {
    id: 6,
    value: "June",
  },
  {
    id: 7,
    value: "July",
  },
  {
    id: 8,
    value: "August",
  },
  {
    id: 9,
    value: "September",
  },
  {
    id: 10,
    value: "October",
  },
  {
    id: 11,
    value: "November",
  },
  {
    id: 12,
    value: "December",
  },
];

function AddTeacher() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const [yearList, setYearList] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (yearList?.length === 0) {
      let minYear = moment().subtract(4, "years").format("YYYY");
      let years = [];
      for (let i = 1920; i <= minYear; i++) {
        years.push(i?.toString());
      }
      years.sort((a, b) => b - a);
      setYearList(years);
    }
  }, [yearList?.length]);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);
  const years = getYears();

  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <BackButton />
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>Add New Teacher</h3>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <Formik
              initialValues={{
                image: "",
                fullName: "",
                email: "",
                mobile: "",
                password: "",
                highest_qualification: [],
                expertise_in_subject: [],
                award_name: [],
                document: [],
                address: {
                  property_number: "",
                  property_name: "",
                  street_number: "",
                  town: "",
                  country: "",
                  postal_code: "",
                  // dob: "",
                  day: "",
                  month: "",
                  year: "",
                },
                toggle: "false",
              }}
              validationSchema={Yup.object({
                image: Yup.string().notRequired(),
                fullName: Yup.string().required("Required"),
                email: Yup.string()
                  .email("Kindly enter a valid email.")
                  .required("Required"),
                password: Yup.string()
                  .matches(
                    // eslint-disable-next-line no-useless-escape
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#£€\$%\^&\*])(?=.{8,})/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                  )
                  .required("Required"),
                mobile: Yup.string()
                  // .matches(phoneRegExp, "Kindly enter a valid phone number.")
                  .required("Required"),
                // countryCode: Yup.string().required("Required"),
                expertise_in_subject: Yup.array().notRequired("Required"),
                award_name: Yup.array().notRequired(),
                highest_qualification: Yup.array().notRequired(),
                document: Yup.array().notRequired(),
                address: Yup.object({
                  property_number: Yup.string().required("Required"),
                  property_name: Yup.string().notRequired(),
                  street_number: Yup.string().required("Required"),
                  town: Yup.string().required("Required"),
                  country: Yup.string().required("Required"),
                  postal_code: Yup.string().required("Required"),

                  // dob: Yup.string().required("Required"),
                  day: Yup.string().required("Required"),
                  month: Yup.string().required("Required"),
                  year: Yup.string().required("Required"),
                }),
              })}
              onSubmit={(values) => {
                setSubmitting(true);
                console.log(values);
                const isValid = values?.mobile
                  ? isValidPhoneNumber(values?.mobile)
                  : true;
                console.log(isValid);
                const countryCode = values?.mobile
                  ? formatPhoneNumberIntl(values?.mobile).split(" ")[0]
                  : "";
                if (isValid) {
                  dispatch(
                    AddTeacherReducer({
                      image: values?.image,
                      full_name: values?.fullName,
                      email: values?.email,
                      password: values?.password,
                      mobile: values?.mobile
                        ? values?.mobile.replace(countryCode, "")
                        : "",
                      countryCode: values?.mobile ? countryCode : "",
                      awards: JSON.stringify(
                        values.award_name?.map((it) => it.value)
                      ),
                      qualifications: JSON.stringify(
                        values.highest_qualification?.map((it) => it.value)
                      ),
                      expertise_in_subjects: JSON.stringify(
                        values.expertise_in_subject?.map((it) => it.value)
                      ),
                      // dob: moment(values.address.dob).format("YYYY-MM-DD"),

                      dob_day: values?.address?.day,
                      dob_month: values?.address?.month,
                      dob_year: values?.address?.year,
                      document: JSON.stringify(values?.document),
                      property_number: values?.address?.property_number,
                      property_name: values?.address?.property_name,
                      street_number: values?.address?.street_number,
                      town: values?.address?.town,
                      country: values?.address?.country,
                      postal_code: values?.address?.postal_code,
                    })
                  ).then((res) => {
                    setSubmitting(false);

                    if (res?.payload?.code === 1) {
                      toast.success(res?.payload?.message);

                      navigate("/teacher", { replace: true });
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  });
                } else {
                  setSubmitting(false);

                  toast.error("Kindly enter a valid phone number.");
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFormikState,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form className="form-style" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 mb-4">
                      <div className="form-group text-center">
                        <div className="upload-btn-wrapper">
                          <button className="btn">
                            {time > 0 && isActive ? (
                              <Progress
                                type="circle"
                                width={80}
                                percent={time}
                              />
                            ) : (
                              <img
                                src={
                                  values.image
                                    ? values.image
                                    : "assets/images/upload-img.png"
                                }
                                alt=""
                              />
                            )}

                            {time > 0 && isActive ? null : (
                              <div className="camera-icon">
                                <i className="fa fa-camera"></i>
                              </div>
                            )}
                          </button>
                          <input
                            type="file"
                            onChange={(e) => {
                              if (e.target.files.length > 0) {
                                setIsActive(true);
                                S3FileUpload.uploadFile(
                                  e.target.files[0],
                                  config
                                )
                                  .then((data) => {
                                    setFieldValue("image", data.location);
                                    setTime(100);
                                    setIsActive(true);
                                    setTimeout(() => {
                                      setTime(0);
                                      setIsActive(false);
                                    }, 3000);
                                  })
                                  .catch((data) => console.log(data));
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Full Name</label>
                        {errors.fullName && touched.fullName && (
                          <span className="text-red ms-1">
                            {errors.fullName}
                          </span>
                        )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Full Name"
                            name="fullName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.fullName}
                          />
                          <img
                            src="assets/images/First-Name.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Email</label>
                        {errors.email && touched.email && (
                          <span className="text-red ms-1">{errors.email}</span>
                        )}
                        <div className="input-container">
                          <input
                            type="email"
                            className="form-control pl-5"
                            placeholder="Email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <img
                            src="assets/images/Email-Address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Mobile No.</label>
                        {errors.mobile && (
                          <span className="text-red ms-1">{errors.mobile}</span>
                        )}
                        <div className="input-container">
                          {/* <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Mobile Number"
                          /> */}

                          {/* <PhoneInput
                            country="gb"
                            inputProps={{
                              name: "mobile",
                            }}
                            enableSearch
                            value={values.countryCode + values.mobile}
                            onChange={(phone, country, e, formattedValue) => {
                              if (phone.length > 0) {
                                const raw = phone.slice(
                                  country?.dialCode?.length
                                );
                                setFieldValue("mobile", raw);

                                setFieldValue(
                                  "countryCode",
                                  formattedValue?.split(" ")[0]
                                );
                              } else {
                                setFieldValue("mobile", "");

                                setFieldValue("countryCode", "");
                              }
                            }}
                            onBlur={handleBlur}
                            placeholder="Mobile Number"
                            containerClass="form-control signupClass"
                            inputClass="phoneInputClass"
                          /> */}

                          <Phone
                            onChange={(val) => {
                              if (val) {
                                setFieldValue("mobile", val);
                              } else {
                                setFieldValue("mobile", "");
                              }
                            }}
                            value={values.mobile || undefined}
                            name={`mobile`}
                            className="form-control signupClass"
                          />
                          {/* <img
                            src="assets/images/mobile.png"
                            className="input-img"
                            alt=""
                          /> */}
                        </div>
                      </div>
                    </div>
                    {
                      <div className="col-sm-6 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Password</label>
                          {errors.password && touched.password && (
                            <span className="text-red ms-1">
                              {errors.password}
                            </span>
                          )}
                          <div className="input-container">
                            <input
                              type={values.toggle ? "password" : "text"}
                              className="form-control pl-5"
                              placeholder="Set Password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                            <img
                              src="assets/images/password.png"
                              className="input-img"
                              alt=""
                            />
                            <span
                              className={
                                values.toggle
                                  ? "pass-view field-icon "
                                  : "pass-hide field-icon "
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                setFormikState((prevState) => {
                                  return {
                                    ...prevState,
                                    values: {
                                      ...prevState.values,
                                      toggle: !prevState.values.toggle,
                                    },
                                  };
                                });
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="col-sm-12 col-md-12 mt-5 mb-4">
                      <div className="form-group">
                        <h3>Qualification Info</h3>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Highest Qualification</label>
                        {errors.highest_qualification &&
                          touched.highest_qualification && (
                            <span className="text-red ms-1">
                              {errors.highest_qualification}
                            </span>
                          )}
                        <div className="input-container">
                          {/* <select
                            className="form-control pl-5 form-select"
                            name="highest_qualification"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.highest_qualification}
                          >
                            <option value="">Highest Qualification</option>
                            <option value="BA">BA</option>
                            <option value="MA">MA</option>
                          </select> */}

                          {/* <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Type of qualification"
                            name="highest_qualification"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.highest_qualification}
                          /> */}
                          <CreatableSelectInput
                            className="form-control ps-4 py-2"
                            name="highest_qualification"
                            handleChange={(val) => {
                              setFieldValue("highest_qualification", val);
                            }}
                            value={values.highest_qualification}
                            onBlur={handleBlur}
                            placeholder="Type Qualifications and press tab to add"
                          />
                          <img
                            src="assets/images/edu-black.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Expertise in Subject</label>
                        {errors.expertise_in_subject &&
                          touched.expertise_in_subject && (
                            <span className="text-red ms-1">
                              {errors.expertise_in_subject}
                            </span>
                          )}
                        <div className="input-container">
                          {/* <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Expertise in Subject"
                            name="expertise_in_subject"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.expertise_in_subject}
                          /> */}
                          <CreatableSelectInput
                            className="form-control ps-4 py-2"
                            name="expertise_in_subject"
                            handleChange={(val) => {
                              setFieldValue("expertise_in_subject", val);
                            }}
                            value={values.expertise_in_subject}
                            onBlur={handleBlur}
                            placeholder="Type Subject Expertise and press tab to add"
                          />
                          <img
                            src="assets/images/expertise.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Award</label>
                        {errors.award_name && touched.award_name && (
                          <span className="text-red ms-1">
                            {errors.award_name}
                          </span>
                        )}
                        <div className="input-container">
                          {/* <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Award Name"
                            name="award_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.award_name}
                          /> */}
                          <CreatableSelectInput
                            className="form-control ps-4 py-2"
                            name="award_name"
                            handleChange={(val) => {
                              setFieldValue("award_name", val);
                            }}
                            value={values.award_name}
                            onBlur={handleBlur}
                            placeholder="Type Award Name and press tab to add"
                          />
                          <img
                            src="assets/images/award.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 mb-4">
                      <div className="form-group">
                        <h3>Address Details</h3>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Property Number</label>
                        {errors?.address?.property_number &&
                          touched?.address?.property_number && (
                            <span className="text-red ms-1">
                              {errors?.address?.property_number}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Property Number"
                            name="address.property_number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address.property_number}
                          />

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Property Name</label>
                        {errors?.address?.property_name &&
                          touched?.address?.property_name && (
                            <span className="text-red ms-1">
                              {errors?.address?.property_name}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Property Name"
                            name="address.property_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address.property_name}
                          />

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Street Name</label>
                        {errors?.address?.street_number &&
                          touched?.address?.street_number && (
                            <span className="text-red ms-1">
                              {errors?.address?.street_number}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Street Name"
                            name="address.street_number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address.street_number}
                          />

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Town</label>
                        {errors?.address?.town && touched?.address?.town && (
                          <span className="text-red ms-1">
                            {errors?.address?.town}
                          </span>
                        )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Town"
                            name="address.town"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address.town}
                          />

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Country</label>
                        {errors?.address?.country &&
                          touched?.address?.country && (
                            <span className="text-red ms-1">
                              {errors?.address?.country}
                            </span>
                          )}
                        <div className="input-container">
                          <select
                            className="form-control pl-5"
                            placeholder="Country"
                            name="address.country"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address.country}
                          >
                            <option value="">Select Country</option>
                            <option value="U.K">U.K</option>
                          </select>

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Postal Code</label>
                        {errors?.address?.postal_code &&
                          touched?.address?.postal_code && (
                            <span className="text-red ms-1">
                              {errors?.address?.postal_code}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Postal Code"
                            name="address.postal_code"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address.postal_code}
                          />

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Date of Birth</label>

                        {errors?.address?.dob && touched?.address?.dob && (
                          <span className="text-red ms-1">
                            {errors?.address?.dob}
                          </span>
                        )}
                        <div className="input-container">
                          <DatePicker
                            containerClassName="form-control pl-5"
                            value={values.address.dob || null}
                            onChange={(val) => {
                              setFieldValue("address.dob", val.toDate());
                            }}
                            placeholder="DOB"
                            inputClass="dateInputClass"
                            maxDate={new Date()}
                            name="address.dob"
                          />
                          <img
                            src="assets/images/Date-of-Birth.png"
                            className="input-img"
                            alt=""
                          />
                          <img
                            src="assets/images/choose-date.png"
                            className="field-icon"
                            alt=""
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="col-sm-12 col-md-12 mb-2">
                      <label className="fw-bold">Date of Birth</label>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Year</label>

                        <div className="input-container">
                          <select
                            className="form-control pl-5"
                            placeholder="Year"
                            name="address.year"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address.year}
                          >
                            <option value="">Select Year</option>
                            {yearList?.map((item, i) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {errors?.address?.year && touched?.address?.year && (
                        <span className="text-red ms-1">
                          {errors?.address?.year}
                        </span>
                      )}
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Month</label>

                        <div className="input-container">
                          <select
                            className="form-control pl-5"
                            placeholder="Month"
                            name="address.month"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address.month}
                          >
                            <option value="">Select Month</option>
                            {months?.map((item, i) => (
                              <option value={item?.id}>{item?.value}</option>
                            ))}
                          </select>

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {errors?.address?.month && touched?.address?.month && (
                        <span className="text-red ms-1">
                          {errors?.address?.month}
                        </span>
                      )}
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Day</label>

                        <div className="input-container">
                          <select
                            className="form-control pl-5"
                            placeholder="Select Day"
                            name="address.day"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address.day}
                          >
                            <option value="">Select Day</option>
                            {[
                              ...Array(
                                values.address.month === "2" ? 28 : 31
                              ).keys(),
                            ].map((item) => {
                              return (
                                <option value={item + 1}>{item + 1}</option>
                              );
                            })}
                          </select>

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {errors?.address?.month && touched?.address?.month && (
                        <span className="text-red ms-1">
                          {errors?.address?.month}
                        </span>
                      )}
                    </div>

                    <div className="col-sm-12 col-md-12 mt-5 mb-4">
                      <div className="form-group">
                        <h5>
                          <img src="assets/images/document.png" alt="" />
                          Upload Documents
                        </h5>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-4">
                      <div className="row">
                        {values?.document?.map((item, i) => (
                          <div className="form-group col-6 col-sm-4 col-md-4 col-lg-4 mb-4 position-relative ">
                            <div className="upload-btn-wrapper documents ">
                              <button className="btn">
                                <img
                                  src={item || "assets/images/upload-doc.png"}
                                  alt=""
                                  className="rounded-0"
                                />
                              </button>
                            </div>
                            <img
                              src={"assets/images/delete.png"}
                              alt=""
                              className="rounded-0"
                              onClick={() => {
                                let val = [...values.document];
                                val.splice(i, 1);
                                setFieldValue("document", val);
                              }}
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                bottom: -20,
                                right: 0,
                              }}
                            />
                          </div>
                        ))}

                        <div className="form-group col-6 col-sm-4 col-md-4 col-lg-4 mb-4 ps-4">
                          <div className="upload-btn-wrapper documents">
                            <button className="btn">
                              {time > 0 && isActive ? (
                                <Progress
                                  type="circle"
                                  width={80}
                                  percent={time}
                                />
                              ) : (
                                <img
                                  src="assets/images/upload-doc.png"
                                  alt=""
                                  className="rounded-0"
                                />
                              )}
                            </button>
                            <input
                              type="file"
                              name="myfile"
                              onChange={(e) => {
                                if (e.target.files.length > 0) {
                                  setIsActive(true);
                                  S3FileUpload.uploadFile(
                                    e.target.files[0],
                                    config
                                  )
                                    .then((data) => {
                                      setFieldValue("document", [
                                        ...values.document,
                                        data.location,
                                      ]);
                                      setTime(100);
                                      setIsActive(true);
                                      setTimeout(() => {
                                        setTime(0);
                                        setIsActive(false);
                                      }, 3000);
                                    })
                                    .catch((data) => console.log(data));
                                }
                              }}
                            />
                          </div>
                        </div>
                        {errors.document && touched.document && (
                          <div className="text-red">{errors.document}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 text-center">
                      <button type="submit" className="btn btn-save" disabled={submitting}>
                        {submitting ? <Loader /> :"Done"}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddTeacher;
