import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { SchoolListReducer } from "../slice";
import { FetchStudentList, studentListSelector } from "../student/studentslice";
import { userListSelector, FetchUserList } from "./../user/UserSlice";
import Select from "react-select";
import { sendNewsLetterToUsers } from "../../api/newsletter";
import { toast } from "react-toastify";
function FilterModal({ show, handleShow, newsletterId }) {
  const dispatch = useDispatch();
  const userList = useSelector(userListSelector);
  const schoolList = useSelector((state) => state.main.schools);
  const [selectedValues, setSelectedValues] = useState({
    age_group: "",
    venues: [],
    users: [],
    allVenues: false,
    allUsers: false,
  });

  useEffect(() => {
    dispatch(SchoolListReducer());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      FetchUserList({
        venue: JSON.stringify(selectedValues?.venues?.map((it) => it?.id)),
        student_type: selectedValues?.age_group,
      })
    );
  }, [dispatch, selectedValues]);

  const selectAll = (checked, name) => {
    if (checked) {
      console.log({
        [name]:
          name === "venues"
            ? schoolList
            : name === "users"
            ? userList
            : undefined,
      });
      setSelectedValues((prev) => {
        return {
          ...prev,
          [name]:
            name === "venues"
              ? schoolList
              : name === "users"
              ? userList
              : undefined,
          allVenues:
            name === "venues"
              ? !selectedValues?.allVenues
              : selectedValues?.allVenues,
          allUsers:
            name === "users"
              ? !selectedValues?.allUsers
              : selectedValues?.allUsers,
        };
      });
    } else if (!checked) {
      setSelectedValues((prev) => {
        return { ...prev, [name]: [] };
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      users: JSON.stringify(
        selectedValues?.users?.map((it) => it?.id?.toString())
      ),
      newsLetter_id: newsletterId,
    };
    console.log(payload, "Send Newsletter payload");
    sendNewsLetterToUsers(payload).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    });
  };

  return (
    <Modal show={show} onHide={handleShow}>
      <div class="modal-content body-bg">
        <div class="modal-header border-0 justify-content-center">
          <h6>Filter</h6>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleShow}
          ></button>
        </div>
        <div class="modal-body mb-4">
          <form class="newsletterForm form-style">
            <div class=" form-group">
              <h5>Age Group</h5>
              <select
                className="form-control form-select "
                value={selectedValues?.age_group}
                onChange={(e) => {
                  setSelectedValues((prev) => {
                    return {
                      ...prev,
                      age_group: e.target.value,
                    };
                  });
                }}
              >
                <option value="">Select Age Group</option>
                <option value="child">Child</option>
                <option value="adult">Adult</option>
                <option value="both">All</option>
              </select>
            </div>
            <div class="form-group">
              <h5>Select Venue</h5>
              {/* <select class="form-control form-select">
                <option value="">Select Venue</option>
                {schoolList?.map((item, i) => (
                  <option value={item?.id}>{item?.name}</option>
                ))}
              </select> */}
              <Select
                isMulti
                options={schoolList}
                getOptionLabel={(option) => `${option?.name}`}
                getOptionValue={(option) => option?.id}
                value={selectedValues?.venues || undefined}
                onChange={(val) => {
                  setSelectedValues((prev) => {
                    return {
                      ...prev,
                      venues: val,
                    };
                  });
                }}
              />
              <input
                type="checkbox"
                id={"allVenues"}
                className="px-1"
                checked={selectedValues?.allVenues}
                onChange={(e) => {
                  let checked = e.target.checked;
                  selectAll(checked, "venues");
                }}
              />
              <label htmlFor="allVenues">All Venues</label>
            </div>
            {selectedValues?.venues?.length > 0 || selectedValues?.age_group ? (
              <div class="form-group">
                <h5>Select Users</h5>

                <Select
                  isMulti
                  options={userList}
                  getOptionLabel={(option) =>
                    `${option?.first_name} ${option?.last_name}`
                  }
                  getOptionValue={(option) => option?.id}
                  value={selectedValues?.users || undefined}
                  onChange={(val) => {
                    setSelectedValues((prev) => {
                      return {
                        ...prev,
                        users: val,
                      };
                    });
                  }}
                />
                <input
                  type="checkbox"
                  className="px-1"
                  id={"allusers"}
                  checked={selectedValues?.allUsers}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    selectAll(checked, "users");
                  }}
                />
                <label htmlFor="allusers">All Users</label>
              </div>
            ) : null}

            <div class="text-center">
              <span
                onClick={() => {
                  setSelectedValues({
                    age_group: "",
                    venues: [],
                    users: [],
                    allVenues: false,
                    allUsers: false,
                  });
                }}
                class="text-dark cursor-pointer"
              >
                Reset All
              </span>
            </div>
            <div class="text-center">
              <button
                class="btn btn-accept btn-view mt-2"
                type="button"
                onClick={handleSubmit}
              >
                Send Newsletter
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default FilterModal;
