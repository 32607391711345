import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  changeBookingStatus,
  removeBooking,
  removeEventBooking,
} from "../../api/booking";
import Cancel from "../../component/Modal/Cancel";
import DeleteModal from "../../component/Modal/Delete";
import UnArchive from "../../component/Modal/UnArchive";
import Paginations from "../../component/Pagination";
import { variants } from "../../constant/Variants";
import { areaCodeListingSelector, FetchAreaCodeList } from "../areacode/slice";
import { SchoolListReducer } from "../slice";
import { GetTeacher } from "../teacher/TeacherSlice";
import Accepted from "./../../component/Modal/Accepted";
import {
  changeBooking_for,
  fetchBookingList,
  getTotalCount,
  list,
} from "./BookingSlice";

function EventBooking() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lists = useSelector((state) => state.booking.list);
  console.log(lists, "Events list");
  const total = useSelector(getTotalCount);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState("");
  const [modalName, setModalName] = useState("");
  const [page, setPage] = useState(1);
  const [bookingId, setBookingId] = useState("");

  const Schoollist = useSelector((state) => state.main.schools);
  const arealist = useSelector(areaCodeListingSelector);
  const teacherlist = useSelector((state) => state.teacher.list);
  const [schoolId, setSchoolId] = useState("");
  const [areacode, setAreacode] = useState("");
  const [teacherId, setTeacherId] = useState("");
  useEffect(() => {
    dispatch(FetchAreaCodeList({ page: 1, limit: 100000000 }));
    dispatch(GetTeacher({ page: 1, limit: 1000000000 }));
    dispatch(SchoolListReducer({ page: 1, limit: 100000000 }));
  }, [dispatch]);

  const handleModal = (val) => {
    setModalName(val);
    handleShow();
  };

  const handleShow = () => {
    setShow(!show);
  };

  const getList = useCallback(() => {
    dispatch(
      fetchBookingList({
        page: page,
        limit: 10,
        search: search,
        booking_for: "event",
        areacode: areacode,
        school_id: schoolId,
        teacherId: teacherId,
      })
    );
  }, [dispatch, page, search, areacode, schoolId, teacherId]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleAcceptReject = () => {
    changeBookingStatus({
      bookingId: bookingId,
      status: modalName === "Accept" ? 1 : modalName === "Cancel" ? 2 : "",
    })
      .then((res) => {
        setBookingId("");
        return res;
      })
      .then((re) => {
        if (re?.code === 1) {
          toast.success(re?.message);
          setModalName("");
          setShow(false);
          getList();
        } else {
          toast.error(re?.message);
        }
      });
  };

  const confirmDelete = () => {
    removeEventBooking({ bookingEventId: delId })
      .then((res) => {
        setBookingId("");
        return res;
      })
      .then((re) => {
        if (re?.code === 1) {
          toast.success(re?.message);
          setModalName("");
          setShow(false);
          getList();
        } else {
          toast.error(re?.message);
        }
      });
  };

  useEffect(() => {
    return () => {
      dispatch(list(null));
    };
  }, [dispatch]);

  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="link-active shadow">
              <p>
                <img src="assets/images/bookings-selected.png" alt="" />{" "}
                bookings {(total && total) || 0}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search by name"
                  value={search}
                  onChange={(e) => {
                    setPage(1);
                    setSearch(e.target.value);
                  }}
                />
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <ul className="nav nav-pills group-nav mb-1 mt-2">
              <li className="nav-item">
                <button
                  className={"nav-link"}
                  id="pills-new-tab"
                  type="button"
                  onClick={() => {
                    (async () => {
                      await localStorage.setItem("booking_for", "class");
                    })();
                    dispatch(changeBooking_for("class"));
                    navigate("/bookings");
                  }}
                >
                  Class
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={"nav-link active"}
                  id="pills-accepted-tab"
                  type="button"
                  onClick={() => {
                    (async () => {
                      await localStorage.setItem("booking_for", "event");
                    })();
                    dispatch(changeBooking_for("event"));
                    navigate("/event-bookings");
                  }}
                >
                  Events
                </button>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="dropSchool mt-2">
              <select
                className="form-control form-select"
                onChange={(e) => {
                  setAreacode(e.target.value);
                }}
              >
                <option value="">Select Area</option>
                {arealist?.map((item, i) => (
                  <option value={item?.id} key={i}>
                    {item?.areacode}
                  </option>
                ))}
              </select>

              <select
                className="form-control form-select"
                onChange={(e) => {
                  setTeacherId(e.target.value);
                }}
              >
                <option value="">Select Teacher</option>
                {teacherlist?.map((item, i) => (
                  <option value={item?.id} key={i}>
                    {item?.full_name}
                  </option>
                ))}
              </select>

              <select
                className="form-control form-select"
                onChange={(e) => {
                  setSchoolId(e.target.value);
                }}
              >
                <option value="">Select Venue</option>
                {Schoollist?.map((item, i) => (
                  <option value={item?.id} key={i}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-sm-12">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-new"
                role="tabpanel"
                aria-labelledby="pills-new-tab"
              >
                <div className="table-responsive text-center">
                  <table className="table storetable">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">photo</th>
                        <th scope="col">user name</th>
                        <th scope="col">Event name</th>
                        <th scope="col">date</th>
                        <th scope="col">level</th>
                        <th scope="col">price</th>
                        <th scope="col">location</th>
                        <th scope="col">action</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {lists?.length > 0 ? (
                        lists?.map((item, i) => {
                          if (item?.status === "1") {
                            return (
                              <tr key={i} variants={variants.card}>
                                <td>{item?.id || "NA"}</td>
                                <td>
                                  <img
                                    src={
                                      item?.booking_details?.user_details?.image?.includes(
                                        "http"
                                      )
                                        ? item?.booking_details?.user_details
                                            ?.image
                                        : "assets/images/headerlogo.png"
                                    }
                                    className="table-img"
                                    alt=""
                                  />
                                </td>
                                <td>
                                  {item?.student_list
                                    ? `${item?.student_list?.[0]?.student_details?.first_name} ${item?.student_list?.[0]?.student_details?.last_name}`
                                    : "NA"}
                                </td>
                                <td> {item?.event_details?.name || "NA"}</td>
                                <td>
                                  {item?.date
                                    ? moment(item?.date).format("DD MMM YYYY")
                                    : "NA"}
                                </td>
                                <td>{item?.level_details?.name || "NA"}</td>
                                <td>
                                  {" "}
                                  {item?.price && (
                                    <i
                                      className="fa fa-gbp"
                                      aria-hidden="true"
                                    ></i>
                                  )}{" "}
                                  {item?.price ? `${item?.price}` : "NA"}
                                </td>
                                <td>
                                  {item
                                    ? `${item?.school_details?.name} ${item?.school_details?.address}`
                                    : "NA"}
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center action">
                                    <button className="btn" title="View">
                                      <Link
                                        to={{
                                          pathname: `/event-booking/${item?.id}`,
                                        }}
                                      >
                                        <img
                                          src="assets/images/view.png"
                                          alt=""
                                        />
                                      </Link>
                                    </button>
                                    {/* <button
                                      className="btn"
                                      onClick={() => {
                                        handleModal("Accept");
                                        setBookingId(item?.id);
                                      }}
                                    >
                                      <img
                                        src="assets/images/approve.png"
                                        alt=""
                                      />
                                    </button> */}
                                    {/* <button
                                      className="btn"
                                      onClick={() => {
                                        handleModal("Cancel");
                                        setBookingId(item?.id);
                                      }}
                                    >
                                      <img
                                        src="assets/images/canceled.png"
                                        alt=""
                                      />
                                    </button> */}
                                    <button
                                      className="btn"
                                      onClick={() => {
                                        handleModal("delete");
                                        setDelId(item?.id);
                                      }}
                                    >
                                      <img
                                        src="assets/images/delete.png"
                                        alt=""
                                      />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        })
                      ) : (
                        <tr>
                          <td colSpan={9}>No data found.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {lists?.length > 0 && (
                <Paginations
                  handlePage={(val) => {
                    setPage(val);
                  }}
                  page={page}
                  total={total && total}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {modalName === "delete" && (
        <DeleteModal
          page="Booking"
          show={show}
          handleShow={handleShow}
          cancel={() => {
            setShow(false);
          }}
          confirmDelete={confirmDelete}
        />
      )}

      {/* {modalName === "cancel" && delId && (
        <Cancel page="Booking" show={show} handleShow={handleShow} />
      )} */}
      {/* {modalName === "archive" && (
        <UnArchive show={show} handleShow={handleShow} />
      )} */}
      {(modalName === "Accept" || modalName === "Cancel") && bookingId && (
        <Accepted
          show={show}
          handleShow={handleShow}
          actionName={modalName}
          pageName={"Booking"}
          approvedReject={() => {
            handleAcceptReject();
          }}
        />
      )}
    </article>
  );
}

export default EventBooking;
