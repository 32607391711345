import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  cancelEarningDetails,
  earning,
  earningDetails,
} from "../../api/payment";
import { toast } from "react-toastify";

const initialState = {
  list: [],
  detail: null,
  adminPercent: 0,
};

export const payment = createSlice({
  name: "paymentSlice",
  initialState,
  reducers: {
    listing: (state, action) => {
      state.list = action.payload;
    },
    details: (state, action) => {
      state.detail = action.payload;
    },
    totalCount: (state, action) => {
      state.total = action.payload;
    },
    adminPercentile: (state, action) => {
      state.adminPercent = action.payload;
    },
  },
});

export const FetchEarnings = createAsyncThunk(
  "earnings",
  async (obj, { dispatch }) => {
    try {
      const response = await earning(obj);
      if (response?.code === 1) {
        console.log(response, "Earnings");
        dispatch(listing(response?.data?.earning_list));
        dispatch(totalCount(response?.data?.total_earning));
        dispatch(adminPercentile(response?.data?.admin_percentage));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchEarningDetails = createAsyncThunk(
  "earnings",
  async (obj, { dispatch }) => {
    try {
      const response = await earningDetails(obj);
      if (response?.code === 1) {
        console.log(response, "Earning details");
        dispatch(details(response?.data?.earning_details));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const FetchCancelledEarningDetails = createAsyncThunk(
  "cancelled earnings",
  async (obj, { dispatch }) => {
    try {
      const response = await cancelEarningDetails(obj);
      if (response?.code === 1) {
        console.log(response, "Earning details");
        dispatch(details(response?.data?.earning_details));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const { listing, details, totalCount, adminPercentile } =
  payment.actions;

export default payment.reducer;
