import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useSelector } from "react-redux";
import { getEventDetails, getEventList } from "../../api/event";
const initialState = {
  list: [1, 1],
  total: {
    active: 0,
    inactive: 0,
    total: 0,
  },
  status: "idle",
};

export const fetchEventList = createAsyncThunk(
  "eventList",
  async (obj, { dispatch }) => {
    getEventList(obj).then((response) => {
      if (response.code === 1) {
        dispatch(list(response.data.event_list));
        dispatch(
          count({
            active: response.data.total_active_event,
            inactive: response?.data?.total_inactive_event,
            total: response?.data?.total_event,
          })
        );
      }
    });
  }
);

export const fetchEventDetail = createAsyncThunk(
  "eventList",
  async (obj, { dispatch }) => {
    getEventDetails(obj).then((response) => {
      dispatch(details(response?.data?.event_details));
    });
  }
);
export const getStatus = (state) => state.event.status;
export const getDetails = (state) => state.event.detail;
export const getList = (state) => state.event.list;
export const getActiveCount = (state) => state.event.total.active;
export const getInActiveCount = (state) => state.event.total.inactive;
export const getTotalCount = (state) => state.event.total;

export const EventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    loading: (state, action) => {
      state.status = "loading";
    },
    idle: (state, action) => {
      state.status = "idle";
    },
    list: (state, action) => {
      state.list = action.payload;
    },
    details: (state, action) => {
      state.detail = action.payload;
    },
    count: (state, action) => {
      state.total.active = action.payload.active;
      state.total.inactive = action.payload.inactive;
      state.total.total = action.payload.total;
    },
  },
});

export const { list, details, count, loading, idle } = EventSlice.actions;

export default EventSlice.reducer;
