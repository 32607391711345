import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FetchEarnings, listing } from "./PaymentSlice";
import moment from "moment";
import Paginations from "../../component/Pagination";
import Loader from "../../component/Loader";
import { Modal } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import { CSVLink } from "react-csv";

function EarningByMembership() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const list = useSelector((state) => state?.payment?.list);
  const total = useSelector((state) => state?.payment?.total);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [show, setShow] = useState(false);
  const [limit, setLimit] = useState(10);
  const [totalAmt, setTotalAmt] = useState(0);

  useEffect(() => {
    setLoading(true);
    dispatch(
      FetchEarnings({
        page: limit ? page : "",
        limit,
        search,
        type: "membership",
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, page, search, limit]);

  useEffect(() => {
    return () => {
      dispatch(listing([]));
    };
  }, [dispatch]);

  const handleSubmit = () => {
    dispatch(
      FetchEarnings({
        page: page,
        limit,
        search,
        type: "membership",
        start_date: moment(start_date).format("YYYY-MM-DD"),
        end_date: moment(end_date).format("YYYY-MM-DD"),
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        setShow(!show);
      }
    });
  };

  useEffect(() => {
    if (list?.length > 0) {
      let amt = 0;
      list?.forEach((it) => {
        amt += parseInt(it?.total_price);
      });
      setTotalAmt(amt);
    }
  }, [list]);

  const getCsvData = () => {
    let arr = [...list];
    let datas = arr.map((earning) => {
      return {
        Username: earning?.user_details?.first_name,
        "Membership name": earning?.name,
        "Age Group": earning?.student_type,
        "Date & Time": moment(earning?.createdAt).format(
          "hh:mm a, DD MMM YYYY"
        ),
        "Amount Earned": earning?.total_price,
        "Payment Mode": "net banking",
      };
    });
    return datas;
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-7 mb-3">
            <ul
              className="nav nav-pills group-nav mb-0 mt-0 payment-refund"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <Link to="/earnings-by-staff" className="nav-link">
                  earnings by teacher
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link to="/earnings-by-membership" className="nav-link active">
                  earnings by membership
                </Link>
              </li>
              {/* <li className="nav-item" role="presentation">
                <Link to="/refund" className="nav-link">
                  refund
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-5 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  value={search}
                  onChange={(e) => {
                    setPage(1);

                    setSearch(e.target.value);
                  }}
                  placeholder="Search earning by membership name"
                />
              </form>
              <div
                className=""
                onClick={() => {
                  setShow(!show);
                }}
              >
                <img
                  src="assets/images/filter.png"
                  alt=""
                  className="p-0 m-0"
                  height={"70px"}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
            {list && (
              <CSVLink
                filename={"earnings.csv"}
                data={list?.length ? getCsvData() : []}
              >
                <button type="button" className="btn btn-save p-2 fs-6">
                  Download CSV
                </button>
              </CSVLink>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">user name</th>
                    <th scope="col">membership name</th>
                    <th scope="col">age group</th>
                    <th scope="col">payment mode</th>
                    <th scope="col">payment date</th>
                    <th scope="col">amount earned</th>
                    {/* <th scope="col">action</th> */}
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={6}>
                        <Loader />
                      </td>
                    </tr>
                  ) : list?.length > 0 ? (
                    list?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {item?.user_details
                              ? `${item?.user_details?.first_name} ${item?.user_details?.last_name}`
                              : "NA"}
                          </td>
                          <td>{item?.name || "NA"}</td>
                          <td>{item?.student_type || "NA"}</td>
                          <td>net banking</td>
                          <td>
                            {item?.createdAt
                              ? moment(item?.createdAt).format("DD MMM YYYY")
                              : "NA"}
                          </td>
                          <td>
                            <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                            {item?.total_price || 0}
                          </td>
                          {/* <td>
                            <div className="d-flex justify-content-center action">
                              <button
                                className="btn"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteModal"
                              >
                                <img src="assets/images/delete.png" alt="" />
                              </button>
                              <button className="btn">
                                <input
                                  type="checkbox"
                                  className="toggle"
                                  checked=""
                                />
                              </button>
                            </div>
                          </td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>No data found.</td>
                    </tr>
                  )}

                  {list?.length > 0 && (
                    <tr>
                      <td colSpan={5}></td>
                      <td>
                        <b>Total:</b> {totalAmt && totalAmt}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* {list?.length > 0 && total > 10 && (
            <Paginations
              handlePage={(val) => {
                setPage(val);
              }}
              page={page}
              total={total && total}
            />
          )} */}
          {list?.length > 0 && (
            <div className="text-center">
              <span
                className={
                  limit === 10 ? "manualPagination active" : "manualPagination"
                }
                onClick={() => {
                  setLimit(10);
                  setPage("1");
                }}
              >
                10
              </span>
              <span
                className={
                  limit === 25 ? "manualPagination active" : "manualPagination"
                }
                onClick={() => {
                  setLimit(25);
                  setPage("1");
                }}
              >
                25
              </span>
              <span
                className={
                  limit === 100 ? "manualPagination active" : "manualPagination"
                }
                onClick={() => {
                  setLimit(100);
                  setPage("1");
                }}
              >
                100
              </span>
              <span
                className={
                  limit === "" ? "manualPagination active" : "manualPagination"
                }
                onClick={() => {
                  setLimit("");
                  // setPage("1");
                }}
              >
                All
              </span>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          setShow(!show);
        }}
        centered
        size="md"
      >
        <div class="modal-content body-bg p-3">
          <div class="modal-header border-0 justify-content-center">
            <h6>Filter</h6>
            <button
              type="button"
              class="btn-close"
              onClick={() => {
                setShow(!show);
              }}
            ></button>
          </div>
          <div class="modal-body mb-4">
            <form class="form-style">
              {/* <div class="form-group">
                  <h5>By Teacher</h5>
                  <select class="form-control form-select">
                    <option>Select Teacher Name</option>
                  </select>
                </div> */}
              <div class="form-group">
                <h5>Start Date</h5>
                <div class="input-container">
                  {/* <input
                      type="text"
                      class="form-control"
                      placeholder="Date"
                    /> */}
                  <DatePicker
                    value={start_date}
                    onChange={(val) => {
                      setStart_date(val.toDate());
                    }}
                    containerClassName="form-control"
                  />
                  <img
                    src="assets/images/choose-date.png"
                    class="field-icon"
                    alt=""
                  />
                </div>
              </div>
              <div class="form-group">
                <h5>End Date</h5>
                <div class="input-container">
                  {/* <input
                      type="text"
                      class="form-control"
                      placeholder="Date"
                    /> */}
                  <DatePicker
                    value={end_date}
                    onChange={(val) => {
                      setEnd_date(val.toDate());
                    }}
                    containerClassName="form-control"
                    minDate={start_date || new Date()}
                  />
                  <img
                    src="assets/images/choose-date.png"
                    class="field-icon"
                    alt=""
                  />
                </div>
              </div>
              <div class="text-center">
                <span
                  onClick={() => {
                    setStart_date("");
                    setEnd_date("");
                  }}
                  className="text-dark cursor-pointer"
                >
                  Reset
                </span>
              </div>
              <div class="text-center">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("cliked");
                    handleSubmit();
                  }}
                  class="btn btn-accept btn-view mt-2"
                >
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </article>
  );
}

export default EarningByMembership;
