import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getSubAdminDetails, subAdminList } from "../../api/subAdmin";

const initialState = {
  list: [],
  total: {
    active: 0,
    inactive: 0,
    all: 0,
  },
  detail: null,
};

export const SubAdminSlice = createSlice({
  name: "subadmin",
  initialState,
  reducers: {
    list: (state, action) => {
      state.list = action.payload;
    },
    total: (state, action) => {
      state.total = action.payload;
    },
    detail: (state, action) => {
      state.detail = action.payload;
    },
  },
});

export const FetchSubAdmins = createAsyncThunk(
  "FetchSubAdmins",
  async (obj, { dispatch }) => {
    try {
      const response = await subAdminList(obj);
      console.log(response, "Subadmins");
      if (response?.code === 1) {
        dispatch(list(response?.data?.sub_admin_list));
        dispatch(
          total({
            active: response?.data?.total_active_sub_admin,
            inactive: response?.data?.total_inactive_sub_admin,
            all: response?.data?.total_sub_admin,
          })
        );
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchSubAdminsDetails = createAsyncThunk(
  "FetchSubAdminsDetails",
  async (obj, { dispatch }) => {
    try {
      const response = await getSubAdminDetails(obj);
      console.log(response, "Subadmins detail");
      if (response?.code === 1) {
        dispatch(detail(response?.data?.user_details));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const { list, total, detail } = SubAdminSlice.actions;

export default SubAdminSlice.reducer;
