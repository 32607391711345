import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = "https://api.bookinclass.co.uk/api/";
//const BASE_URL = "http://13.40.94.154:4060/api/";
//const BASE_URL = "http://localhost:4060/api/";

export const postFormDataNoToken = async (url = "", data = {}) => {
  try {
    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.post(BASE_URL + url, formData);

    return response.data;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};
export const postFormData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.post(BASE_URL + url, formData, {
      headers: { token },
    });

    return response.data;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};
export const patchFormData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.patch(BASE_URL + url, formData, {
      headers: { token },
    });

    return response.data;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};
export const postRawData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const response = await axios.post(BASE_URL + url, data, {
      headers: {
        token,
      },
    });

    return response.data;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};

export const getDataNoToken = async (url = "", data = {}) => {
  try {
    const response = await axios.get(BASE_URL + url, {
      params: data,
    });

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getDataforUrl = async (url = "", data = "") => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(BASE_URL + url + `/${data}`, {
      headers: {
        token,
      },
    });

    return response.data;
  } catch (error) {
    return null;
  }
};
export const getDataforUrlWithParams = async (url = "", data = {}) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(BASE_URL + url + `/${data?.id}`, {
      params: data.params,
      headers: {
        token,
      },
    });

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const response = await axios.get(BASE_URL + url, {
      params: data,
      headers: {
        token,
      },
    });

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getDataStringify = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const response = await axios.get(BASE_URL + url, {
      params: data,

      headers: {
        token,
      },
    });

    return response.data;
  } catch (error) {
    return null;
  }
};

export const putFormData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");
    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.put(BASE_URL + url, formData, {
      headers: {
        token,
      },
    });

    return response.data;
  } catch (error) {
    return null;
  }
};

export const deleteData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");
    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.delete(BASE_URL + url, {
      headers: {
        token: token,
      },
      data: formData,
    });

    return response.data;
  } catch (error) {
    return null;
  }
};

export const deleteURLData = async (url = "", data = "") => {
  try {
    const token = await localStorage.getItem("token");

    const response = await axios.delete(BASE_URL + url + `/${data}`, {
      headers: {
        token: token,
      },
    });

    return response.data;
  } catch (error) {
    return null;
  }
};
