import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { SchoolListReducer } from "../../features/slice";

function SelectSchool({ show, handleShow, setSchoolId, setModalName }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const list = useSelector((state) => state.main.schools);

  const [search, setSearch] = useState("");

  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    dispatch(
      SchoolListReducer({ page: activePage, limit: 1000, search: search })
    );
  }, [dispatch, activePage, search]);

  return (
    <Modal show={show} onHide={handleShow} centered size="lg">
      <Modal.Body>
        <div className="modal-header border-0">
          <label className="text-start fw-bold mb-2">Select School</label>
          <button
            type="button"
            className="btn-close"
            onClick={handleShow}
          ></button>
        </div>
        <div className="modal-body text-center mb-4">
          <div className="row">
            <div className="col-sm-12 mt-3r">
              <form className="has-search w-100">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search School by name"
                  value={search}
                  onChange={(e) => {
                    setActivePage(1);
                    setSearch(e.target.value);
                  }}
                />
              </form>
            </div>
            <div className="col-sm-12 mt-3 schoolListContainer">
              {list?.map((item, i) => (
                <div
                  className="schoolListItems mb-3"
                  key={i}
                  onClick={() => {
                    //setSchoolId(item.id);
                    handleShow();
                  }}
                >
                  <Link to={`/add-class/${item.id}`}>
                    <p>
                      <label className="fw-bold">School Name: </label>{" "}
                      {item?.name}
                    </p>
                    <p>
                      <label className="fw-bold">Address: </label>{" "}
                      {item?.address}
                    </p>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SelectSchool;
