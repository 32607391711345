import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "../Loader";

function Cancel({
  show,
  handleShow,
  page,
  handleCancel,
  submitting,
  setSubmitting,
}) {
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <Modal show={show} handleShow={handleShow} size="md" centered>
      <div className="modal-content body-bg">
        <div className="modal-header border-0">
          <button
            type="button"
            className="btn-close"
            onClick={handleShow}
          ></button>
        </div>
        <div className="modal-body text-center mb-4">
          <h4>Cancel this {page}?</h4>
          <p>Are you sure, you want to cancel this {page}?</p>
          <div className="my-3 text-start">
            <label className="fw-bold theme-color">
              {" "}
              Reason for Cancellation
            </label>
            <textarea
              rows={5}
              type="text"
              className="form-control h-100"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </div>
          <div className="view-btns">
            <button
              className="btn btn-reject btn-view mt-2"
              onClick={handleShow}
            >
              No
            </button>
            <button
              className="btn btn-accept btn-view mt-2"
              onClick={() => {
                if (reason !== "") {
                  handleCancel(reason);
                } else {
                  toast.error("Kindly state the reason for the cancellation.");
                }
              }}
              disabled={submitting}
            >
              {submitting ? <Loader /> : "Yes"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Cancel;
