import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import DeleteModal from "../../component/Modal/Delete";
import { useLocation } from "react-router-dom";
import { fetchBookingDetails } from "./BookingSlice";
import moment from "moment";
import BackButton from "./../../component/BackButton";

function BookingDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const data = useSelector((state) => state.booking.detail);
  console.log(data, "Class detail booking");
  const booking_for = useSelector((state) => state.booking.booking_for);

  useEffect(() => {
    if (id) {
      dispatch(fetchBookingDetails({ id: id, params: { booking_for } }));
    }
  }, [dispatch, id, booking_for]);
  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <BackButton />
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>New Booking Details</h3>
          </div>
        </div>
        <div className="row mt-3 px-md-5 booking-dtl">
          <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3">
            <div className="bg-white ps-4 py-3 text-capitalize position-relative h-100">
              <p className="mb-1">User name</p>
              <h6 className="">
                <img
                  src="assets/images/First-Name.png"
                  className="detail-icon"
                  alt=""
                />
                {data?.student_list
                  ?.map((student, i) => {
                    return `${student?.student_details?.first_name} ${student?.student_details?.last_name}`;
                  })
                  .join(", ")}
              </h6>
            </div>
          </div>
          {/* <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3">
            <div className="bg-white ps-4 py-3 text-capitalize position-relative h-100">
              <p className="mb-1">discipline</p>
              <h6 className="">
                <img
                  src="assets/images/discipline.png"
                  className="detail-icon"
                  alt=""
                />
                karate
              </h6>
            </div>
          </div> */}

          <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3">
            <div className="bg-white ps-4 py-3 text-capitalize position-relative h-100">
              <p className="mb-1">
                {booking_for === "class" ? "Class" : "Event"} Name
              </p>
              <h6 className="">
                <img
                  src="assets/images/grade.png"
                  className="detail-icon"
                  alt=""
                />
                {booking_for === "class"
                  ? data?.booking_classes?.class_details?.name
                  : booking_for === "event"
                  ? data?.event_details?.name
                  : "NA"}
              </h6>
            </div>
          </div>

          {booking_for === "event" && data?.level_details && (
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3">
              <div className="bg-white ps-4 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Level Name</p>
                <h6 className="">
                  <img
                    src="assets/images/grade.png"
                    className="detail-icon"
                    alt=""
                  />
                  {data?.level_details?.name || "NA"}
                </h6>
              </div>
            </div>
          )}
          {data?.booking_classes?.areacode_details && (
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3">
              <div className="bg-white ps-4 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Area</p>
                <h6 className="">
                  <img
                    src="assets/images/address.png"
                    className="detail-icon"
                    alt=""
                  />
                  {booking_for === "class"
                    ? data?.booking_classes?.areacode_details?.areacode
                    : booking_for === "event"
                    ? data?.areacode_details?.areacode
                    : "NA"}
                </h6>
              </div>
            </div>
          )}
          {/* <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3">
            <div className="bg-white ps-4 py-3 text-capitalize position-relative h-100">
              <p className="mb-1">membership status</p>
              <h6 className="">
                <img
                  src="assets/images/membership-status.png"
                  className="detail-icon"
                  alt=""
                />
                active
              </h6>
            </div>
          </div> */}
          {data?.booking_details?.age_group && (
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3">
              <div className="bg-white ps-4 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">membership level</p>
                <h6 className="">
                  <img
                    src="assets/images/membership-status.png"
                    className="detail-icon"
                    alt=""
                  />
                  {data?.booking_details?.age_group}
                </h6>
              </div>
            </div>
          )}
          {data?.time ||
          (data?.event_details?.start_time && !data?.level_details) ? (
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3">
              <div className="bg-white ps-4 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Time</p>
                <h6 className="">
                  <img
                    src="assets/images/Date-of-Birth.png"
                    className="detail-icon"
                    alt=""
                  />
                  {data?.time
                    ? moment(data?.time, "hh:mm:ss").format("hh:mm a")
                    : data?.event_details?.start_time && !data?.level_details
                    ? moment(
                        data?.event_details?.start_time,
                        "hh:mm:ss"
                      ).format("hh:mm a")
                    : ""}
                </h6>
              </div>
            </div>
          ) : null}
          {booking_for === "class" && data?.start_time && (
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3">
              <div className="bg-white ps-4 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Time</p>
                <h6 className="">
                  <img
                    src="assets/images/Date-of-Birth.png"
                    className="detail-icon"
                    alt=""
                  />
                  {`${moment(data?.start_time, "hh:mm:ss").format("hh:mm a")}-
                    ${moment(data?.end_time, "hh:mm:ss").format("hh:mm a")}`}
                </h6>
              </div>
            </div>
          )}
          <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3">
            <div className="bg-white ps-4 py-3 text-capitalize position-relative h-100">
              <p className="mb-1">Venue</p>
              <h6 className="">
                <img
                  src="assets/images/address.png"
                  className="detail-icon"
                  alt=""
                />
                {booking_for === "class"
                  ? `${data?.booking_classes?.school_details?.name}, ${data?.booking_classes?.school_details?.address}`
                  : booking_for === "event"
                  ? `${data?.school_details?.name}, ${data?.school_details?.address}`
                  : "NA"}
              </h6>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3">
            <div className="bg-white ps-4 py-3 text-capitalize position-relative h-100">
              <p className="mb-1">Date Booked</p>
              <h6 className="">
                <img
                  src="assets/images/membership-status.png"
                  className="detail-icon"
                  alt=""
                />
                {data?.date ? moment(data?.date).format("DD MMM YYYY") : "NA"}
              </h6>
            </div>
          </div>
          {data?.level_details && (
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3">
              <div className="bg-white ps-4 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Time</p>
                <h6 className="">
                  <img
                    src="assets/images/membership-status.png"
                    className="detail-icon"
                    alt=""
                  />
                  {data?.level_details
                    ? moment(data?.level_details?.time, "hh:mm:ss").format(
                        "hh:mm a"
                      )
                    : "NA"}
                </h6>
              </div>
            </div>
          )}
          {
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3">
              <div className="bg-white ps-4 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Total Amount Paid</p>
                <h6 className="">
                  <img
                    src="assets/images/membership-status.png"
                    className="detail-icon"
                    alt=""
                  />
                  <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                  {booking_for === "class" &&
                  !data?.booking_classes?.booking_details
                    ? null
                    : booking_for === "class" &&
                      data?.booking_classes?.booking_details
                    ? data?.booking_classes?.booking_details?.total_amount
                    : booking_for === "event"
                    ? data?.price
                    : "NA"}
                </h6>
              </div>
            </div>
          }
          {/* {location.pathname.includes("/booking/") ? (
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <label className="fw-bold mb-2 largeLabel">Dates Booked</label>
              <div className="bg-white ps-4 py-3 text-capitalize position-relative h-100 row">
                <div className="row">
                  {" "}
                  {data?.booking_details?.booking_classes?.length > 0 ? (
                    <>
                      {data?.booking_details?.booking_classes?.map(
                        (clas, i) => {
                          return clas?.time_slot_list?.map((time, i) => (
                            <>
                              <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="card shadow p-4">
                                  <span>
                                    <p>
                                      <label className="fw-bold">
                                        Student Name:{" "}
                                      </label>{" "}
                                      {time?.student_list
                                        ?.map(
                                          (it) =>
                                            it?.student_details?.first_name +
                                            " " +
                                            it?.student_details?.last_name
                                        )
                                        ?.join(", ")}{" "}
                                    </p>
                                    <p>
                                      <label className="fw-bold">Class: </label>{" "}
                                      {time?.date
                                        ? clas?.class_details?.name
                                        : "NA"}
                                    </p>
                                    <p>
                                      <label className="fw-bold">
                                        School:{" "}
                                      </label>{" "}
                                      {clas?.school_details
                                        ? clas?.school_details?.name +
                                          ", " +
                                          clas?.school_details?.address
                                        : "NA"}
                                    </p>
                                    <p>
                                      <label className="fw-bold">Date: </label>{" "}
                                      {time?.date
                                        ? moment(time?.date).format(
                                            "DD MMM YYYY"
                                          )
                                        : "NA"}
                                    </p>
                                    <p>
                                      <label className="fw-bold">Time: </label>{" "}
                                      {time?.date
                                        ? moment(time?.time, "HH:mm:ss").format(
                                            "hh:mm a"
                                          )
                                        : "NA"}
                                    </p>
                                    <p>
                                      <label className="fw-bold">Price: </label>{" "}
                                      <i
                                        class="fa fa-gbp"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {clas?.class_details?.class_type ===
                                      "child"
                                        ? clas?.child_price
                                        : clas?.class_details?.class_type ===
                                          "adult"
                                        ? clas?.adult_price
                                        : "NA"}
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </>
                          ));
                        }
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}

          {location.pathname.includes("/event-booking/") ? (
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <label className="fw-bold mb-2 largeLabel">Dates Booked</label>
              <div className="bg-white ps-4 py-3 text-capitalize position-relative h-100 row">
                <div className="row">
                  {data?.booking_details?.booking_events?.length > 0 ? (
                    <>
                      {data?.booking_details?.booking_events?.map(
                        (event, i) => {
                          return (
                            <>
                              <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="card shadow p-4">
                                  <span>
                                    <p>
                                      <label className="fw-bold">
                                        Student Name:{" "}
                                      </label>{" "}
                                      {event?.student_list
                                        ?.map(
                                          (it) =>
                                            it?.student_details?.first_name +
                                            " " +
                                            it?.student_details?.last_name
                                        )
                                        ?.join(", ")}{" "}
                                    </p>
                                    <p>
                                      <label className="fw-bold">
                                        Discipline:{" "}
                                      </label>{" "}
                                      {event?.student_list
                                        ?.map(
                                          (it) =>
                                            it?.student_details
                                              ?.discipline_details?.name
                                        )
                                        ?.join(", ")}{" "}
                                    </p>
                                    <p>
                                      <label className="fw-bold">Venue: </label>{" "}
                                      {event?.school_details
                                        ? event?.school_details?.name +
                                          ", " +
                                          event?.school_details?.address
                                        : "NA"}
                                    </p>
                                    <p>
                                      <label className="fw-bold">Date: </label>{" "}
                                      {event?.date
                                        ? moment(event?.date).format(
                                            "DD MMM YYYY"
                                          )
                                        : "NA"}
                                    </p>
                                    <p>
                                      <label className="fw-bold">Level: </label>{" "}
                                      {event?.level_details?.name}
                                    </p>
                                    <p>
                                      <label className="fw-bold">Time: </label>{" "}
                                      {event?.level_details?.time
                                        ? moment(
                                            event?.level_details?.time,
                                            "hh:mm:ss"
                                          ).format("hh:mm a")
                                        : "NA"}
                                    </p>
                                    <p>
                                      <label className="fw-bold">
                                        Level Price:{" "}
                                      </label>{" "}
                                      <i
                                        class="fa fa-gbp"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {event?.price}
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null} */}
        </div>
      </div>
      {/* <DeleteModal
        page="Booking"
        show={show}
        handleShow={handleShow}
        cancel={() => {
          setShow(false);
        }}
      /> */}
    </article>
  );
}

export default BookingDetail;
