import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import DatePicker from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../component/Loader";
import Paginations from "../../component/Pagination";
import { areaCodeListingSelector, FetchAreaCodeList } from "../areacode/slice";
import { SchoolListReducer } from "../slice";
import { FetchEarnings, listing } from "./PaymentSlice";
import { Modal } from "react-bootstrap";

function EarningByTeacher() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const list = useSelector((state) => state?.payment?.list);
  const total = useSelector((state) => state?.payment?.total);
  const adminPercent = useSelector((state) => state?.payment?.adminPercent);
  const [show, setShow] = useState(false);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState("1");
  const [search, setSearch] = useState("");

  const [schoolId, setSchoolId] = useState("");
  const [areacode, setAreacode] = useState("");
  const Schoollist = useSelector((state) => state.main.schools);
  const arealist = useSelector(areaCodeListingSelector);
  const [limit, setLimit] = useState(10);
  const [amount, setAmount] = useState({
    total: 0,
    netTotal: 0,
    adminEarning: 0,
  });
  useEffect(() => {
    dispatch(FetchAreaCodeList({ page: 1, limit: 100000000 }));
  }, [dispatch]);

  useEffect(() => {
    if (areacode) {
      dispatch(
        SchoolListReducer({
          page: 1,
          limit: 100000000,
          search: "",
          areacode,
        })
      );
    }
  }, [dispatch, areacode]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      FetchEarnings({
        page: page,
        limit,
        search,
        type: "teacher",
        areacode,
        schoolId,
      })
    ).then((res) => {
      setLoading(false);
      let arr = res?.payload?.data?.earning_list;
      var total = 0;
      var netTotal = 0;
      let adminEarning = 0;
      arr.forEach((values) => {
        total += Number(values.total_amount);
        adminEarning += (Number(values.total_amount) * 10) / 100;
        netTotal = total - adminEarning;
      });

      setAmount({
        total: parseFloat(total).toFixed(2),
        netTotal: parseFloat(netTotal).toFixed(2),
        adminEarning: parseFloat(adminEarning).toFixed(2),
      });
    });
  }, [dispatch, page, search, areacode, schoolId, limit]);

  useEffect(() => {
    return () => {
      dispatch(listing([]));
    };
  }, [dispatch]);

  const getCsvData = () => {
    let arr = [...list];
    let datas = arr.map((earning) => {
      return {
        teacher_name: earning?.teacher_details?.full_name,
        class_name: earning?.class_details?.name,
        class_type: earning?.class_details?.class_type,
        date: moment(earning?.date).format("DD MMM YYYY"),
        gross_amount: earning?.total_amount,
        admin_percentage: "10%",
        admin_earnings: (earning?.total_amount * 10) / 100,
        net_erarning:
          earning?.total_amount - (earning?.total_amount * 10) / 100,
      };
    });
    return datas;
  };
  const handleSubmit = () => {
    dispatch(
      FetchEarnings({
        page: page,
        limit: 10,
        search,
        type: "teacher",
        areacode,
        schoolId,
        start_date: moment(start_date).format("YYYY-MM-DD"),
        end_date: moment(end_date).format("YYYY-MM-DD"),
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        setShow(!show);
      }
    });
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-7 col-xl-7 mb-3">
            <ul
              className="nav nav-pills group-nav mb-0 mt-0 payment-refund"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <Link to="/earnings-by-staff" className="nav-link active">
                  earnings by teacher
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link to="/earnings-by-membership" className="nav-link">
                  earnings by membership
                </Link>
              </li>
              {/* <li className="nav-item" role="presentation">
                <Link to="/refund" className="nav-link">
                  refund
                </Link>
              </li> */}
            </ul>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-5 col-xl-5 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search earning by teacher name"
                  value={search}
                  onChange={(e) => {
                      setPage(1);

                    setSearch(e.target.value);
                  }}
                />
              </form>
              {/* <div
                className=""
                onClick={() => {
                  setShow(!show);
                }}
              >
                <img src="assets/images/filter.png" alt="" height={"70px"} />
              </div> */}
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div className="dropSchool">
              <select
                className="form-control form-select"
                onChange={(e) => {
                  if (e.target.value === "") {
                    setSchoolId("");
                  }
                  setAreacode(e.target.value);
                }}
              >
                <option value="">Select Area</option>
                {arealist?.map((item, i) => (
                  <option value={item?.id} key={i}>
                    {item?.areacode}
                  </option>
                ))}
              </select>
              {areacode && (
                <select
                  className="form-control form-select"
                  onChange={(e) => {
                    setSchoolId(e.target.value);
                  }}
                >
                  <option value="">Select Venue</option>
                  {Schoollist?.map((item, i) => (
                    <option value={item?.id} key={i}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-end">
            {list && (
              <CSVLink
                filename={"earnings.csv"}
                data={list?.length ? getCsvData() : []}
              >
                <button type="button" className="btn btn-save p-2 fs-6">
                  Download CSV
                </button>
              </CSVLink>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Teacher name</th>
                    <th scope="col">Admin Percentage</th>
                    <th scope="col">Gross Amount</th>
                    <th scope="col">Admin's earning</th>
                    <th scope="col">Net Amount</th>
                    <th scope="col">Action</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={6}>
                        <Loader />
                      </td>
                    </tr>
                  ) : list?.length > 0 ? (
                    list?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item?.teacher_id || "NA"}</td>
                          <td>{item?.teacher_details?.full_name || "NA"}</td>
                          <td>{adminPercent + "%" || "NA"}</td>
                          <td>
                            <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                            {item?.total_amount || 0}
                          </td>

                          <td>
                            <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                            {item?.total_amount
                              ? (item?.total_amount * adminPercent) / 100
                              : "0"}
                          </td>
                          <td>
                            <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                            {item?.total_amount -
                              (item?.total_amount * adminPercent) / 100 || 0}
                          </td>

                          <td>
                            <div className="d-flex justify-content-center action">
                              <button className="btn">
                                <Link
                                  to={`/earnings-by-staff/${item?.teacher_id}`}
                                >
                                  <img src="assets/images/view.png" alt="" />
                                </Link>
                              </button>
                              {/* <button
                          className="btn"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteModal"
                        >
                          <img src="assets/images/delete.png" alt="" />
                        </button>
                        <button className="btn">
                          <input
                            type="checkbox"
                            className="toggle"
                            checked=""
                          />
                        </button> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <td colSpan={7}>No data found.</td>
                  )}

                  {list?.length > 0 && (
                    <tr>
                      <td colSpan={3}></td>
                      <td>
                        <b>Total:</b> {amount?.total || 0}
                      </td>
                      <td>
                        <b>Total:</b> {amount?.adminEarning || 0}
                      </td>
                      <td>
                        <b>Total:</b> {amount?.netTotal || 0}
                      </td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {list?.length > 0 && (
              <div className="text-center">
                <span
                  className={
                    limit === 10
                      ? "manualPagination active"
                      : "manualPagination"
                  }
                  onClick={() => {
                    setPage("1");
                    setLimit(10);
                  }}
                >
                  10
                </span>
                <span
                  className={
                    limit === 25
                      ? "manualPagination active"
                      : "manualPagination"
                  }
                  onClick={() => {
                    setPage("1");
                    setLimit(25);
                  }}
                >
                  25
                </span>
                <span
                  className={
                    limit === 100
                      ? "manualPagination active"
                      : "manualPagination"
                  }
                  onClick={() => {
                    setPage("1");
                    setLimit(100);
                  }}
                >
                  100
                </span>
                <span
                  className={
                    limit === ""
                      ? "manualPagination active"
                      : "manualPagination"
                  }
                  onClick={() => {
                    setLimit("");
                    // setPage("");
                  }}
                >
                  All
                </span>
              </div>
            )}
          </div>
          {/* {list?.length > 0 && total > 10 && (
            <Paginations
              handlePage={(val) => {
                setPage(val);
              }}
              page={page}
              total={total && total}
            />
          )} */}
        </div>
      </div>
      <div
        class="modal fade"
        id="FilterModal"
        tabindex="-1"
        aria-labelledby="FilterModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content body-bg">
            <div class="modal-header border-0 justify-content-center">
              <h6>Filter</h6>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body mb-4">
              <form class="form-style">
                <div class="form-group">
                  <h5>By Teacher</h5>
                  <select class="form-control form-select">
                    <option>Select Teacher Name</option>
                  </select>
                </div>
                <div class="form-group">
                  <h5>Select Date</h5>
                  <div class="input-container">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Date"
                    />
                    <img
                      src="assets/images/choose-date.png"
                      class="field-icon"
                      alt=""
                    />
                  </div>
                </div>
                <div class="text-center">
                  <a href="#" class="text-dark">
                    Reset All
                  </a>
                </div>
                <div class="text-center">
                  <button class="btn btn-accept btn-view mt-2">Apply</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          setShow(!show);
        }}
        centered
        size="md"
      >
        <div class="modal-content body-bg p-3">
          <div class="modal-header border-0 justify-content-center">
            <h6>Filter</h6>
            <button
              type="button"
              class="btn-close"
              onClick={() => {
                setShow(!show);
              }}
            ></button>
          </div>
          <div class="modal-body mb-4">
            <form class="form-style">
              {/* <div class="form-group">
                  <h5>By Teacher</h5>
                  <select class="form-control form-select">
                    <option>Select Teacher Name</option>
                  </select>
                </div> */}
              <div class="form-group">
                <h5>Start Date</h5>
                <div class="input-container">
                  {/* <input
                      type="text"
                      class="form-control"
                      placeholder="Date"
                    /> */}
                  <DatePicker
                    value={start_date}
                    onChange={(val) => {
                      setStart_date(val.toDate());
                    }}
                    containerClassName="form-control"
                  />
                  <img
                    src="assets/images/choose-date.png"
                    class="field-icon"
                    alt=""
                  />
                </div>
              </div>
              <div class="form-group">
                <h5>End Date</h5>
                <div class="input-container">
                  {/* <input
                      type="text"
                      class="form-control"
                      placeholder="Date"
                    /> */}
                  <DatePicker
                    value={end_date}
                    onChange={(val) => {
                      setEnd_date(val.toDate());
                    }}
                    containerClassName="form-control"
                    minDate={start_date || new Date()}
                  />
                  <img
                    src="assets/images/choose-date.png"
                    class="field-icon"
                    alt=""
                  />
                </div>
              </div>
              <div class="text-center">
                <span
                  onClick={() => {
                    setStart_date("");
                    setEnd_date("");
                  }}
                  className="text-dark cursor-pointer"
                >
                  Reset
                </span>
              </div>
              <div class="text-center">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  class="btn btn-accept btn-view mt-2"
                >
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </article>
  );
}

export default EarningByTeacher;
