import { FormikProvider, useFormik } from "formik";
import React, { useMemo, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import BackButton from "../../component/BackButton";
import { SchoolListReducer } from "../slice";
import { FetchUserList, userListSelector } from "../user/UserSlice";
import Select from "react-select";
import * as Yup from "yup";
import { addNewsLetter, saveEflyerEmail } from "../../api/newsletter";
import { toast } from "react-toastify";
import { detailing, FetchNewsletterDetail } from "./NewsLetterSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../../component/Loader";
import S3FileUpload from "react-s3/lib/ReactS3";
import { config } from "../../utils";
import { areaCodeListingSelector, FetchAreaCodeList } from "../areacode/slice";
import { FetchDisciplineList } from "../discipline/DisciplineSlice";
function AddNewsletter() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const detail = useSelector((state) => state.newsletter.detail);
  const userList = useSelector(userListSelector);
  const schoolList = useSelector((state) => state.main.schools);
  console.log(schoolList, "SchoolList");
  const [loading, setLoading] = useState(false);
  const areacodeList = useSelector(areaCodeListingSelector);
  const disciplineList = useSelector((state) => state.discipline.list);
  const quillRef = useRef();
  // useEffect(() => {
  //   dispatch(SchoolListReducer());
  // }, [dispatch]);
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(FetchNewsletterDetail(id)).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, id]);
  useEffect(() => {
    return () => {
      dispatch(detailing(null));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(FetchAreaCodeList({}));
    dispatch(FetchDisciplineList({}));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      areacode: detail?.areacode || [],
      discipline: detail?.discipline || [],
      schoolId: detail?.schoolId || [],
      users: detail?.user_list?.map((it) => it?.user_details) || [],
      // news_letter: detail?.news_letter || "",
      email_content: detail?.email_content,
      email_subject: detail?.email_subject || "",
      allVenues: false,
      status: 1,
      onlySubscribedUser: false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      // age_group: Yup.string().required("Required"),
      // venues: Yup.array().min(1, "Required"),
      // users: Yup.array().min(1, "Required"),
      email_subject: Yup.string().required("Required"),
      email_content: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      setSubmitting(true);
      const {
        areacode,
        discipline,
        schoolId,
        users,
        status,
        email_content,
        email_subject,
        onlySubscribedUser,
      } = values;
      let { allVenues, ...rest } = values;
      let payload;
      if (
        areacode?.length === 0 &&
        discipline?.length === 0 &&
        schoolId?.length === 0 &&
        users?.length === 0 &&
        status === 1
      ) {
        toast.error(
          "Kindly select one of areacode or discipline or venue or users to send email to."
        );
      } else if (status === 0) {
        console.log(values);
        payload = {
          areacode: JSON.stringify(areacode?.map((it) => it?.id)),
          discipline: JSON.stringify(discipline?.map((it) => it?.id)),
          schoolId: JSON.stringify(schoolId?.map((it) => it?.id)),
          users: JSON.stringify(users?.map((it) => it?.id)),
          status,
          email_content,
          email_subject,
          onlySubscribedUser,
          eflyer_id: id ? id : "",
        };
        console.log(payload, "Payload 1");
        saveEflyerEmail(payload).then((res) => {
          setSubmitting(false);

          if (res?.code === 1) {
            toast.success(res?.message);
            navigate(-1);
          } else {
            toast.error(res?.message);
          }
        });
      } else if (status === 1) {
        payload = {
          areacode: JSON.stringify(areacode?.map((it) => it?.id)),
          discipline: JSON.stringify(discipline?.map((it) => it?.id)),
          schoolId: JSON.stringify(schoolId?.map((it) => it?.id)),
          users: JSON.stringify(users?.map((it) => it?.id)),
          status,
          email_content,
          onlySubscribedUser,
          email_subject,
          eflyer_id: id ? id : "",
        };
        console.log(payload, "Payload 2");

        saveEflyerEmail(payload).then((res) => {
          setSubmitting(false);

          if (res?.code === 1) {
            toast.success(res?.message);
            navigate(-1);
          } else {
            toast.error(res?.message);
          }
        });
      }
    },
  });
  useEffect(() => {
    dispatch(
      FetchUserList({
        venues:
          formik.values.schoolId?.length > 0
            ? JSON.stringify(formik.values.schoolId?.map((it) => it?.id))
            : "",
        areacode:
          formik.values.areacode?.length > 0
            ? JSON.stringify(formik.values?.areacode?.map((it) => it?.id))
            : "",
        student_type: formik.values.age_group,
        discipline:
          formik.values.discipline?.length > 0
            ? JSON.stringify(formik.values?.discipline?.map((it) => it?.id))
            : "",
      })
    );
  }, [
    dispatch,
    formik.values.schoolId,
    formik.values.age_group,
    formik.values.discipline,
    formik.values?.areacode,
  ]);

  const [value, setValue] = useState("");

  const imageHandler = () => {
    const editor = quillRef.current.getEditor();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async function () {
      const file = input.files[0];
      console.log("User trying to uplaod this:", file);
      const uploadFile = async (file) => {
        console.log(file);
        return S3FileUpload.uploadFile(file, config)
          .then((data) => {
            console.log(data?.location);
            return data?.location;
          })
          .catch((data) => console.log(data));
      };
      const id = await uploadFile(file); // I'm using react, so whatever upload function
      const range = editor.getSelection();

      // this part the image is inserted
      // by 'image' option below, you just have to put src(link) of img here.
      editor.insertEmbed(range, "image", id);
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
          ["link", "image", "video"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  useEffect(() => {
    if (formik.values?.areacode) {
      dispatch(
        SchoolListReducer({
          areacode_ids:
            formik.values?.areacode?.length > 0
              ? JSON.stringify(formik.values?.areacode?.map((it) => it?.id))
              : "",
        })
      );
    }
  }, [dispatch, formik?.values?.areacode]);

  const selectAllVenues = (checked) => {
    if (checked) {
      let arr = [...schoolList];
      formik.setFieldValue("schoolId", arr);
      formik.setFieldValue("allVenues", true);
    } else if (!checked) {
      formik.setFieldValue("schoolId", []);
      formik.setFieldValue("allVenues", false);
    }
  };

  const subscribedUser = () => {
    FetchUserList({ isSubscribed: 1 }).then((res) => {
      if (res?.code === 1) {
        let arr = res?.data?.user_list?.map((it) => it?.id);
        formik.setFieldValue("users", arr);
        formik.setFieldValue("onlySubscribedUser", true);
      }
    });
  };
  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <BackButton />

          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>{id ? "Edit Newsletter" : "Add New Newsletter"}</h3>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            {loading ? (
              <Loader />
            ) : (
              <FormikProvider value={formik}>
                <form class="form-style" onSubmit={formik.handleSubmit}>
                  <div class="form-group">
                    <div className="checkboxs">
                      <label>
                        <input
                          type="radio"
                          value={"onlySubscribedUser"}
                          name={`onlySubscribedUser`}
                          checked={formik.values.onlySubscribedUser === false}
                          onChange={(e) => {
                            formik.setFieldValue(`onlySubscribedUser`, false);
                          }}
                        />
                        <div className="div-bg">
                          <p>
                            <span>All user</span>
                          </p>
                        </div>
                      </label>
                      <label>
                        <input
                          type="radio"
                          value={"onlySubscribedUser"}
                          name={`onlySubscribedUser`}
                          checked={formik.values?.onlySubscribedUser}
                          onChange={(e) => {
                            formik.setFieldValue(`onlySubscribedUser`, true);
                            subscribedUser();
                          }}
                        />
                        <div className="div-bg">
                          <p>
                            <span>Susbscribed User</span>
                          </p>
                        </div>
                      </label>
                    </div>
                  </div>
                  {!formik.values.onlySubscribedUser && (
                    <>
                      {" "}
                      <div class="form-group">
                        <h5>Area</h5>
                        <Select
                          name="areacode"
                          isMulti
                          options={areacodeList}
                          getOptionLabel={(option) => `${option?.areacode}`}
                          getOptionValue={(option) => option?.id}
                          value={formik.values?.areacode || undefined}
                          onChange={(val) => {
                            console.log(val);
                            formik.setFieldValue("areacode", val);
                          }}
                        />
                        {/* <select
                      class="form-control form-select"
                      name="age_group"
                      onChange={formik.handleChange}
                      value={formik.values.age_group}
                    >
                      <option value="">Select Area</option>
                      {areacodeList?.map((it, i) => (
                        <option value={it?.areacode} key={it?.id}>
                          {it?.areacode}
                        </option>
                      ))}
                    </select> */}
                      </div>
                      <div class="form-group">
                        <h5>Discipline</h5>
                        <Select
                          name="discipline"
                          isMulti
                          options={disciplineList}
                          getOptionLabel={(option) => `${option?.name}`}
                          getOptionValue={(option) => option?.id}
                          value={formik.values?.discipline || undefined}
                          onChange={(val) => {
                            formik.setFieldValue("discipline", val);
                          }}
                        />
                        {/* <select
                      class="form-control form-select"
                      name="age_group"
                      onChange={formik.handleChange}
                      value={formik.values.discipline}
                    >
                      <option value="">Select Discipline</option>
                      {disciplineList?.map((it, i) => (
                        <option value={it?.name} key={it?.id}>
                          {it?.name}
                        </option>
                      ))}
                    </select> */}
                      </div>
                      <div class="form-group">
                        <h5>Select Venue</h5>

                        <Select
                          name="schoolId"
                          isMulti
                          options={schoolList}
                          getOptionLabel={(option) => `${option?.name}`}
                          getOptionValue={(option) => option}
                          value={formik.values?.schoolId || undefined}
                          onChange={(val) => {
                            console.log(val, "Venue");
                            formik.setFieldValue("schoolId", val);
                          }}
                        />
                        {schoolList?.length > 0 && (
                          <>
                            {" "}
                            <input
                              type="checkbox"
                              className="px-1"
                              checked={formik.values.allVenues}
                              onChange={(e) => {
                                let checked = e.target.checked;
                                selectAllVenues(checked);
                              }}
                            />
                            <label htmlFor="allVenues">All Venues</label>
                          </>
                        )}
                      </div>
                      <div class="form-group">
                        <h5>Select Users</h5>

                        <Select
                          name="users"
                          isMulti
                          options={userList}
                          getOptionLabel={(option) =>
                            `${option?.first_name} ${option?.last_name} - ${option?.email}`
                          }
                          getOptionValue={(option) => option?.id}
                          value={formik.values?.users || undefined}
                          onChange={(val) => {
                            console.log(val);
                            formik.setFieldValue("users", val);
                          }}
                        />
                      </div>
                    </>
                  )}
                  <div className="form-group">
                    <h5>Email Subject</h5>
                    {formik.errors.email_subject &&
                      formik.touched.email_subject && (
                        <span className="text-danger">
                          {formik.errors.email_subject}
                        </span>
                      )}
                    <input
                      type="text"
                      className="form-control"
                      value={formik.values.email_subject}
                      name="email_subject"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>

                  <div className="form-group">
                    <h5>Content</h5>
                    {formik.errors.email_content &&
                      formik.touched.email_content && (
                        <span className="text-danger">
                          {formik.errors.email_content}
                        </span>
                      )}
                    <div>
                      {/* <textarea
                      rows={20}
                      height={"100px"}
                      value={formik.values.news_letter}
                      name="news_letter"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Write content..."
                      className="form-control p-2"
                    ></textarea> */}
                      <ReactQuill
                        theme="snow"
                        ref={quillRef}
                        modules={modules}
                        className="form-control h-auto p-2"
                        value={formik.values.email_content}
                        onChange={(val, delta, source, editor) => {
                          console.log(val);
                          formik.setFieldValue(
                            "email_content",
                            editor.getHTML()
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div class="text-center">
                    <button
                      class="btn btn-accept btn-view mt-2"
                      type="submit"
                      // onClick={handleSubmit}
                    >
                      Add
                    </button>
                    <button
                      class="btn btn-accept btn-view mt-2"
                      type="button"
                      onClick={(e) => {
                        formik.setFieldValue("status", 0);
                        formik.handleSubmit(e);
                      }}
                      disabled={submitting}
                    >
                      {submitting ? <Loader /> : "Save as Draft"}
                    </button>
                  </div>
                </form>
              </FormikProvider>
            )}
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddNewsletter;
