import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { addDiscipline } from "../../api/Discipline";
import BackButton from "../../component/BackButton";
import Loader from "../../component/Loader";
import { FetchDisciplineDetail } from "./DisciplineSlice";

function AddDiscipline() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const schema = Yup.object({
    name: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(FetchDisciplineDetail(id)).then((res) => {
        if (res?.payload?.code === 1) {
          setLoading(false);
          reset({
            name: res?.payload?.data?.discipline_details?.name,
          });
        }
      });
    }
  }, [dispatch, id]);

  const onSubmit = (data) => {
    console.log(data);
    setSubmitting(true);

    const payload = {
      name: data?.name,
      image: data?.image ? data?.image : "",
      discipline_id: id ? id : "",
    };
    addDiscipline(payload).then((res) => {
      setSubmitting(false);

      if (res?.code === 1) {
        navigate("/discipline", { replace: true });
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    });
  };

  if (loading) {
    return (
      <article className="col-lg-9 articleClass">
        <Loader />
      </article>
    );
  } else {
    return (
      <article className="col-lg-9 articleClass">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <BackButton />

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3>{id ? "Edit" : " New"} Discipline</h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12">
              <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-sm-6 col-md-6 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Discipline Name</label>
                      {errors?.name && (
                        <span className="text-red ms-1">
                          {errors?.name?.message}
                        </span>
                      )}
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Discipline Name"
                          name="name"
                          {...register("name")}
                        />
                        <img
                          src="assets/images/event-name.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-save"
                      disabled={submitting}
                    >
                      {submitting ? <Loader /> : "Save"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default AddDiscipline;
