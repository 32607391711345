import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { disciplineList, getDisciplineDetails } from "../../api/Discipline";

const initialState = {};

export const DisciplineSlice = createSlice({
  name: "discipline",
  initialState,
  reducers: {
    list: (state, action) => {
      state.list = action.payload;
    },
    detail: (state, action) => {
      state.details = action.payload;
    },
    totalCount: (state, action) => {
      state.total = action.payload;
    },
  },
});

export const FetchDisciplineList = createAsyncThunk(
  "DisciplineList",
  async (obj, { dispatch }) => {
    try {
      const response = await disciplineList(obj);
      if (response?.code === 1) {
        dispatch(list(response?.data?.discipline_list));
        dispatch(totalCount(response?.data?.total_discipline));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchDisciplineDetail = createAsyncThunk(
  "DisciplineDetail",
  async (obj, { dispatch }) => {
    try {
      const response = await getDisciplineDetails(obj);
      if (response?.code === 1) {
        dispatch(list(response?.data?.discipline_details));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const { list, detail, totalCount } = DisciplineSlice.actions;

export default DisciplineSlice.reducer;
