import React from "react";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { EditorState } from "draft-js";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function WYSIWYG({ value, setValue }) {
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };
  return (
    // <Editor
    //   editorStyle={{
    //     background: "white",
    //     padding: "10px",
    //     height: "200px",
    //   }}
    //   wrapperClassName={"editor-wrapper"}
    //   onChange={(e) => {
    //     console.log(e);
    //     // setValue(e.target.value);
    //   }}
    // />
    <ReactQuill
      theme="snow"
      modules={modules}
      value={value}
      onChange={setValue}
      className="bg-white"
    />
  );
}

export default WYSIWYG;
