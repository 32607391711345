import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { termAndConditionDetails, termAndConditionList } from "../../api/tnc";

const initialState = {
  list: [],
  total: 0,
  detail: null,
};

export const tncSlice = createSlice({
  name: "tncSlice",
  initialState,
  reducers: {
    list: (state, action) => {
      state.list = action.payload;
    },
    total: (state, action) => {
      state.total = action.payload;
    },
    detail: (state, action) => {
      state.detail = action.payload;
    },
  },
});

export const FetchTNC = createAsyncThunk(
  "FetchTNC",
  async (obj, { dispatch }) => {
    try {
      const response = await termAndConditionList(obj);
      console.log(response, "tnc list");
      if (response?.code === 1) {
        dispatch(list(response?.data?.list));
        dispatch(total(response?.data?.count));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const FetchTNCDetail = createAsyncThunk(
  "FetchTNCDetail",
  async (obj, { dispatch }) => {
    try {
      const response = await termAndConditionDetails(obj);
      console.log(response, "tnc detail");
      if (response?.code === 1) {
        dispatch(detail(response?.data?.details));
        // dispatch(total(response?.data?.list));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const { list, total, detail } = tncSlice.actions;

export default tncSlice.reducer;
