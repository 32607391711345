import { useFormik, FormikProvider } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import BackButton from "../../component/BackButton";
import * as Yup from "yup";
import { creditAmount } from "../../api/user";
import Loader from "../../component/Loader";
function Wallet() {
  const { id, amt } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      userId: id,
      amount: 0,
      note: "Added By Admin",
    },
    validationSchema: Yup.object({
      amount: Yup.number().required("Amount is required"),
      note: Yup.string(),
    }),
    onSubmit: (values) => {
      setLoading(true);
      creditAmount(values).then((res) => {
        setLoading(false);

        if (res?.code === 1) {
          toast.success(res?.message);
          navigate(-1, { replace: true });
        } else {
          toast.error(res?.message);
        }
      });
    },
  });

  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <BackButton />

          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>User Wallet Info</h3>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <FormikProvider value={formik}>
              <form className="form-style" onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group d-flex justify-content-between align-items-baseline">
                      <h3>Update Wallet</h3>
                      <p>
                        <label className="fw-bold">
                          Current Wallet Balance:
                        </label>{" "}
                        <span className="fw-bold theme-color">
                          &#163; {amt || 0}
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group"></div>
                  </div> */}

                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Amount</label>
                      {formik.errors.amount && formik.touched.amount && (
                        <span className="text-red ms-1">
                          {formik.errors.amount}
                        </span>
                      )}
                      <div className="input-container">
                        <input
                          type="number"
                          className="form-control pl-5"
                          placeholder="Amount"
                          name="amount"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.amount}
                          // pattern="\d*"
                          // inputMode="numeric"
                          // onKeyDown={(e) => {
                          //   // Prevent entering the decimal point (.)
                          //   if (e.key === "." || e.key === "e") {
                          //     e.preventDefault();
                          //   }
                          // }}
                        />
                        <img
                          src="assets/images/First-Name.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Note</label>
                      {formik.errors.note && formik.touched.note && (
                        <span className="text-red ms-1">
                          {formik.errors.note}
                        </span>
                      )}
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Note"
                          name="note"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.note}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-save"
                      disabled={loading}
                    >
                      {loading ? <Loader /> : "Done"}
                    </button>
                  </div>
                </div>
              </form>
            </FormikProvider>
          </div>
        </div>
      </div>
    </article>
  );
}

export default Wallet;
