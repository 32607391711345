import {
  deleteData,
  getData,
  getDataforUrl,
  patchFormData,
  postFormData,
  postRawData,
} from "./index";

export const addSubadmin = async (payload) => {
  return postRawData("admin/addSubadmin", payload).then((data) => {
    return data;
  });
};

export const subAdminList = async (payload) => {
  return getData("admin/subAdminList", payload).then((data) => {
    return data;
  });
};

export const getSubAdminDetails = async (payload) => {
  return getDataforUrl("admin/getSubAdminDetails", payload).then((data) => {
    return data;
  });
};

export const changeSubAdminStatus = async (payload) => {
  return patchFormData("admin/changeSubAdminStatus", payload).then((data) => {
    return data;
  });
};
export const removeSubAdmin = async (payload) => {
  return deleteData("admin/removeSubAdmin", payload).then((data) => {
    return data;
  });
};
