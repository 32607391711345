import { deleteData, getData, getDataforUrl, postFormData } from "./index";

export const sizeList = async (payload) => {
  return getData("admin/sizeList", payload).then((data) => {
    return data;
  });
};

export const addSize = async (payload) => {
  return postFormData("admin/addSize", payload).then((data) => {
    return data;
  });
};

export const removeSize = async (payload) => {
  return deleteData("admin/removeSize", payload).then((data) => {
    return data;
  });
};
