import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Accepted from "../../component/Modal/Accepted";
import Cancel from "../../component/Modal/Cancel";
import DeleteModal from "../../component/Modal/Delete";
import SelectSchool from "../../component/Modal/SelectSchool";
import UnArchive from "../../component/Modal/UnArchive";
import { variants } from "../../constant/Variants";
import {
  CancelClass,
  ClassActiveInactiveReducer,
  ClassDeleteReducer,
  ClassListReducer,
  SchoolListReducer,
} from "../slice.js";
import { useToggle } from "./../../utils/hooks/index";
import { useNavigate } from "react-router";
import Paginations from "../../component/Pagination";
import Loader from "./../../component/Loader";
import { areaCodeListingSelector, FetchAreaCodeList } from "../areacode/slice";
import { GetTeacher } from "../teacher/TeacherSlice";
import moment from "moment";
function CancelledClass() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [list, setlist] = useState([]);
  const [total_class, setTotal_Class] = useState(0);
  const [total_active_class, setTotal_active_class] = useState(0);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState("");
  const [modalName, setModalName] = useState("");
  const [page, setPage] = useState(1);
  const [schoolId, setSchoolId] = useState("");
  const [areacode, setAreacode] = useState("");
  const [teacherId, setTeacherId] = useState("");
  const Schoollist = useSelector((state) => state.main.schools);

  const arealist = useSelector(areaCodeListingSelector);
  const teacherlist = useSelector((state) => state.teacher.list);

  useEffect(() => {
    dispatch(FetchAreaCodeList({ page: 1, limit: 100000000 }));
    dispatch(GetTeacher({ page: 1, limit: 1000000000 }));
  }, [dispatch]);

  const handleModal = (val) => {
    setModalName(val);
    handleShow();
  };

  const handleShow = () => {
    setShow(!show);
  };
  const handlePage = (value) => {
    setPage(value);
  };
  const getList = useCallback(() => {
    dispatch(
      ClassListReducer({
        page: page,
        limit: 10,
        schoolId,
        status: 1,
        search,
        areacode,
        teacherId,
        cancel_class: 1,
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        console.log(res, "Classes");
        const data = res?.payload?.data;
        setTotal_Class(data?.total_removed_time_slot);
        setTotal_active_class(data?.total_removed_time_slot);
        setlist(data?.removed_time_slot_list);
      }
    });
  }, [dispatch, search, page, schoolId, areacode, teacherId]);

  //   const approvedReject = (data) => {
  //     dispatch(
  //       ClassActiveInactiveReducer({ class_id: delId, status: confirmStatus })
  //     ).then((res) => {
  //       if (res?.payload?.code === 1) {
  //         toast.success(res?.payload?.message);
  //         getList();
  //       } else {
  //         toast?.error(res?.payload?.message);
  //       }
  //     });
  //   };

  const confirmDelete = (data) => {
    dispatch(ClassDeleteReducer({ class_id: delId })).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        getList();
      } else {
        toast?.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    if (areacode) {
      dispatch(
        SchoolListReducer({
          page: page,
          limit: 1000,
          search: search,
          areacode,
        })
      );
    }
  }, [dispatch, page, search, areacode]);

  return (
    <>
      <article className="col-lg-9 articleClass">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="link-active shadow">
                <p>
                  <img src="assets/images/memberships-selected.png" alt="" />{" "}
                  total Classes {total_class && total_class}
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <form className="has-search">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control shadow"
                    placeholder="Search class by name"
                    value={search}
                    onChange={(e) => {
                      handlePage(1);
                      setSearch(e.target.value);
                    }}
                  />
                </form>

                <Link to="/add-class" className=" cursor-pointer">
                  <img
                    src="assets/images/addBtn.png"
                    alt=""
                    className="add-new"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="row mt-3 align-items-center">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <ul
                className="nav nav-pills group-nav mb-3 mt-0"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-new-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-new"
                    type="button"
                    role="tab"
                    aria-controls="pills-new"
                    aria-selected="true"
                    onClick={() => {
                      navigate("/class");
                    }}
                  >
                    Active
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-cancelled-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-cancelled"
                    type="button"
                    role="tab"
                    aria-controls="pills-cancelled"
                    aria-selected="false"
                    onClick={() => {
                      navigate("/cancelled-class");
                    }}
                  >
                    Cancelled
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="dropSchool">
                <select
                  className="form-control form-select"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setSchoolId("");
                    }
                    setAreacode(e.target.value);
                  }}
                >
                  <option value="">Select Area</option>
                  {arealist?.map((item, i) => (
                    <option value={item?.id} key={i}>
                      {item?.areacode}
                    </option>
                  ))}
                </select>
                {areacode && (
                  <select
                    className="form-control form-select"
                    onChange={(e) => {
                      setSchoolId(e.target.value);
                    }}
                  >
                    <option value="">Select Venue</option>
                    {Schoollist?.map((item, i) => (
                      <option value={item?.id} key={i}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                )}

                <select
                  className="form-control form-select"
                  onChange={(e) => {
                    setTeacherId(e.target.value);
                  }}
                >
                  <option value="">Select Teacher</option>
                  {teacherlist?.map((item, i) => (
                    <option value={item?.id} key={i}>
                      {item?.full_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="table-responsive text-center">
                <table className="table storetable classLineLmp">
                  <thead className="text-center">
                    <tr>
                      <th scope="col">IDs</th>
                      <th scope="col">Photo</th>
                      <th scope="col">Class name</th>
                      <th scope="col">Adult price</th>
                      <th scope="col">Child price</th>
                      <th scope="col">Discipline</th>
                      <th scope="col">Area</th>
                      <th scope="col">Venue</th>
                      <th scope="col">Teacher</th>
                      <th scope="col">Total seats</th>
                      <th scope="col">Date</th>
                      <th scope="col">Reason</th>

                      <th scope="col">Action</th>
                    </tr>
                    <tr className="border-0 bg-none invisible">
                      <td className="border-0 bg-none"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {list && list?.length > 0 ? (
                      list?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item?.id || "NA"}</td>
                            <td>
                              <img
                                src={
                                  item?.class_details?.image?.includes("http")
                                    ? item?.class_details?.image
                                    : "assets/images/headerlogo.png"
                                }
                                className="table-img"
                                alt=""
                              />
                            </td>
                            <td>{item?.class_details?.name || "NA"}</td>
                            <td>
                              <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                              {item?.class_details?.adult_price || "NA"}
                            </td>
                            <td>
                              <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                              {item?.class_details?.child_price || "NA"}
                            </td>
                            <td>
                              <div className="line-lmp">
                                {item?.class_details?.discipline_details
                                  ?.name || "NA"}
                              </div>
                            </td>{" "}
                            <td>
                              <div className="line-lmp">
                                {item?.class_details?.areacode_details
                                  ?.areacode || "NA"}
                              </div>
                            </td>{" "}
                            <td>
                              <div className="line-lmp">
                                {item?.class_details?.school_details?.name ||
                                  "NA"}
                              </div>
                            </td>
                            <td>
                              <div className="line-lmp">
                                {item?.class_details?.assigned_teacher
                                  ?.teacher_details?.full_name || "NA"}
                              </div>
                            </td>
                            <td>
                              {item?.class_details?.total_seats === 0
                                ? "Unlimited"
                                : item?.class_details?.total_seats
                                ? item?.class_details?.total_seats
                                : "NA"}
                            </td>{" "}
                            <td>
                              {item?.date
                                ? moment(item?.date).format("DD MMM YYYY")
                                : "NA"}
                            </td>{" "}
                            <td>{item?.cancel_reason || "NA"}</td>{" "}
                            <td>
                              <div className="d-flex justify-content-center action">
                                <button className="btn" title="View">
                                  <Link
                                    to={`/class-detail/${item?.class_details?.id}`}
                                  >
                                    <img src="assets/images/view.png" alt="" />
                                  </Link>
                                </button>
                                {/* <button className="btn" title="Edit">
                                      <Link
                                        to={`/edit-class/${item?.schoolId}/${item?.id}`}
                                      >
                                        <img
                                          src="assets/images/edit.png"
                                          alt=""
                                        />
                                      </Link>
                                    </button> */}
                                {/* <button
                                      className="btn"
                                      title="Cancel Class"
                                      onClick={() => {
                                        handleModal("cancel");
                                        setDelId(item?.id);
                                      }}
                                    >
                                      <img
                                        src="assets/images/canceled.png"
                                        alt=""
                                      />
                                    </button> */}
                                {/* <button
                                  title="Delete"
                                  className="btn"
                                  onClick={() => {
                                    handleModal("delete");
                                    setDelId(item?.id);
                                  }}
                                >
                                  <img src="assets/images/delete.png" alt="" />
                                </button> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={13}>No data found.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {list?.length > 0 && (
                <Paginations
                  handlePage={handlePage}
                  page={page}
                  total={total_active_class && total_active_class}
                />
              )}
            </div>
          </div>
        </div>
        {modalName === "SelectSchool" && (
          <SelectSchool
            show={show}
            handleShow={handleShow}
            //setSchoolId={setSchoolId}
            setModalName={setModalName}
          />
        )}
        {modalName === "delete" && (
          <DeleteModal
            page="Class"
            show={show}
            handleShow={handleShow}
            confirmDelete={confirmDelete}
            cancel={() => {
              setModalName("");
              setShow(false);
            }}
          />
        )}
      </article>
    </>
  );
}

export default CancelledClass;
