import JsPDF from "jspdf";
import html2canvas from "html2canvas";
export const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEY,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
  dirName: "dev",
};

export const monthOption = [
  {
    id: 1,
    value: "January",
  },
  {
    id: 2,
    value: "February",
  },
  {
    id: 3,
    value: "March",
  },
  {
    id: 4,
    value: "April",
  },
  {
    id: 5,
    value: "May",
  },
  {
    id: 6,
    value: "June",
  },
  {
    id: 7,
    value: "July",
  },
  {
    id: 8,
    value: "August",
  },
  {
    id: 9,
    value: "September",
  },
  {
    id: 10,
    value: "October",
  },
  {
    id: 11,
    value: "November",
  },
  {
    id: 12,
    value: "December",
  },
];

export const htmlStringToPdf = async (htmlString) => {
  let iframe = document.createElement("iframe");
  iframe.style.visibility = "hidden";
  document.body.appendChild(iframe);
  let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
  iframedoc.body.innerHTML = htmlString;

  let canvas = await html2canvas(iframedoc.body, { opacity: 1, scale: 4 });

  // Convert the iframe into a PNG image using canvas.
  let imgData = canvas.toDataURL("image/png");

  // Create a PDF document and add the image as a page.
  const doc = new JsPDF({
    orientation: "l",
    format: [58, 104],
    unit: "mm",
  });
  doc.addImage(imgData, "PNG", 1, 1, 100, 56);

  // Get the file as blob output.
  let blob = doc.output("blob");

  // Remove the iframe from the document when the file is generated.
  document.body.removeChild(iframe);
  return blob;
};
