import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { areaCodeList, getAreaCodeDetails } from "../../api/areacode";

const initialState = {};

export const AreaSlice = createSlice({
  name: "area",
  initialState,
  reducers: {
    list: (state, action) => {
      state.list = action.payload;
    },
    details: (state, action) => {
      state.detail = action.payload;
    },
    totalCount: (state, action) => {
      state.total = action.payload;
    },
  },
});

export const { list, details, totalCount } = AreaSlice.actions;

export const areaCodeListingSelector = (state) => state.area.list;

export const totalAreaCodeSelector = (state) => state.area.total;

export const FetchAreaCodeList = createAsyncThunk(
  "FetchAreaCodeList",
  async (obj, { dispatch }) => {
    try {
      const response = await areaCodeList(obj);
      if (response?.code === 1) {
        dispatch(list(response?.data?.areacode_list));
        dispatch(totalCount(response?.data?.total_areacode));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const areaCodeDetailSelector = (state) => state.area.detail;

export const FetchAreaCodeDetail = createAsyncThunk(
  "FetchAreaCodeDetail",
  async (obj, { dispatch }) => {
    try {
      const response = await getAreaCodeDetails(obj);
      if (response?.code === 1) {
        dispatch(details(response?.data?.areacode_details));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export default AreaSlice.reducer;
