import React from "react";
import { Modal } from "react-bootstrap";
import Loader from "../Loader";

function DeleteModal({
  show,
  handleShow,
  page,
  confirmDelete,
  cancel,
  submitting,
}) {
  const Comp = () => {
    return (
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content body-bg">
          <div className="modal-header border-0">
            <button
              type="button"
              className="btn-close"
              onClick={handleShow}
            ></button>
          </div>
          <div className="modal-body text-center mb-4">
            <h3>Delete the {page}?</h3>
            <p>Are you sure, you want to delete this {page}?</p>
           {page === "student" && <p className="text-danger">Deleting the student will delete all the booking for the student.</p>}
            <div className="view-btns">
              <button
                className="btn btn-reject btn-view mt-2"
                onClick={() => {
                  cancel();
                }}
              >
                No
              </button>
              <button
                className="btn btn-accept btn-view mt-2"
                onClick={() => {
                  handleShow();
                  confirmDelete();
                }}
                disabled={submitting}
              >
                {submitting ? <Loader /> : "Yes"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <Modal show={show} onHide={handleShow} dialogAs={Comp} />;
}

export default DeleteModal;
