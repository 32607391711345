import { getData } from "./index";
export const earning = async (payload) => {
  return getData("admin/earning", payload).then((data) => {
    return data;
  });
};
export const earningDetails = async (payload) => {
  return getData("admin/earningDetails", payload).then((data) => {
    return data;
  });
};
export const cancelEarningDetails = async (payload) => {
  return getData("admin/cancelEarningDetails", payload).then((data) => {
    return data;
  });
};
