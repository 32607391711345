import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DeleteModal from "../../component/Modal/Delete";
import { useToggle } from "./../../utils/hooks/index";
import { variants } from "../../constant/Variants";
import { SchoolDeleteReducer, SchoolListReducer } from "../slice";
import { toast } from "react-toastify";
import Paginations from "../../component/Pagination";
import { areaCodeListingSelector, FetchAreaCodeList } from "../areacode/slice";

function SchoolList() {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.main.schools);
  const total = useSelector((state) => state.main.total_school);
  const areaList = useSelector(areaCodeListingSelector);
  const [search, setSearch] = useState("");
  const [delId, setDelId] = useState("");
  const [show, toggle] = useToggle();
  const [activePage, setActivePage] = useState(1);
  const [page, setPage] = useState(1);
  const [areacode, setAreacode] = useState("");
  const handlePage = (value) => {
    setPage(value);
  };

  const getList = useCallback(() => {
    dispatch(
      SchoolListReducer({
        page: page,
        limit: 10,
        search: search,
        areacode,
      })
    );
  }, [dispatch, page, search, areacode]);

  const confirmDelete = (data) => {
    dispatch(SchoolDeleteReducer({ school_id: delId })).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        getList();
      } else {
        toast?.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getList();
    }

    return () => {
      isRendered = false;
    };
  }, [getList]);

  useEffect(() => {
    dispatch(FetchAreaCodeList({ page: 1, limit: 10000 }));
  }, [dispatch]);

  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="link-active shadow">
              <p>
                <img src="assets/images/users-selected.png" alt="" /> Total
                Venues {total}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search Venue by Name"
                  value={search}
                  onChange={(e) => {
                    setPage(1);

                    setSearch(e.target.value);
                  }}
                />
              </form>
              <Link to="/add-school" className="">
                <img
                  src="assets/images/addBtn.png"
                  alt=""
                  className="add-new"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 col-md-12 col-lg-12 float-right mb-2">
            <div className="form-group selectAreaCode">
              <div className="input-container">
                <select
                  className="form-control pl-5 form-select"
                  name="areacode"
                  onChange={(e) => {
                    setAreacode(e.target.value);
                  }}
                  value={areacode}
                >
                  <option value="">Search By Area Name</option>

                  {areaList?.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.areacode}
                    </option>
                  ))}
                </select>
                <img
                  src="assets/images/event-name.png"
                  className="input-img"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Venue name</th>
                    <th scope="col">Area</th>
                    <th scope="col">Address</th>

                    <th scope="col">action</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {list?.length > 0 ? (
                    list?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item?.id}</td>
                          {/* <td>
                            <span>
                              <img
                                src={
                                  item?.image?.includes("http")
                                    ? item?.image
                                    : "assets/images/employe.png"
                                }
                                className="table-img"
                                alt="school"
                              />{" "}
                            </span>
                          </td> */}
                          <td>{item?.name}</td>
                          <td>{item?.areacode_details?.areacode || "NA"}</td>
                          <td>{item?.address}</td>

                          <td>
                            <div className="d-flex justify-content-center action">
                              <button className="btn" title="View">
                                <Link to={`/view-school/${item?.id}`}>
                                  <img src="assets/images/view.png" alt="" />
                                </Link>
                              </button>
                              <button className="btn" title="Edit">
                                <Link to={`/edit-school/${item?.id}`}>
                                  <img src="assets/images/edit.png" alt="" />
                                </Link>
                              </button>
                              <button
                                className="btn"
                                onClick={() => {
                                  toggle();
                                  setDelId(item?.id);
                                }}
                                title="Delete"
                              >
                                <img src="assets/images/delete.png" alt="" />
                              </button>
                              {/* <button className="btn">
                                <input
                                  type="checkbox"
                                  className="toggle"
                                  checked=""
                                />
                              </button> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10}>No data found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {list?.length > 0 && (
            <Paginations
              handlePage={handlePage}
              page={page}
              total={total && total}
            />
          )}
        </div>
      </div>
      <DeleteModal
        page="School"
        show={show}
        handleShow={toggle}
        confirmDelete={confirmDelete}
      />
    </article>
  );
}

export default SchoolList;
