import { Formik } from "formik";
import React, { useCallback, useEffect } from "react";
import * as Yup from "yup";
import DatePicker from "react-multi-date-picker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import GooglePlace from "../../component/GooglePlace.js";

function AddRegister() {
  const getList = useCallback(() => {}, []);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getList();
    }

    return () => {
      isRendered = false;
    };
  }, [getList]);
  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>Create New Register</h3>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <Formik
              initialValues={{
                registerName: "",
                eventName: "",
                eventDate: "",
                eventTime: "",
                price: "",
                address: "",
                lat: "",
                lng: "",
              }}
              validationSchema={Yup.object({
                eventName: Yup.string().required("Required"),
                registerName: Yup.string().required("Required"),
                eventDate: Yup.string().required("Required"),
                eventTime: Yup.string().required("Required"),

                price: Yup.number()
                  .positive("Kindly enter a valid number.")
                  .typeError("Kindly enter a valid number.")
                  .required("Required"),
                address: Yup.string().required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                console.log(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFormikState,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form className="form-style" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Register Name"
                            name="registerName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.registerName}
                          />
                          <img
                            src="assets/images/event-name.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                        {errors.registerName && touched.registerName && (
                          <div className="text-red">{errors.registerName}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4"></div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Event Name"
                            name="eventName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.eventName}
                          />
                          <img
                            src="assets/images/event-name.png"
                            className="input-img"
                            alt=""
                          />
                          <span className="field-icon small text-muted">
                            Auto-generated
                          </span>
                        </div>
                        {errors.eventName && touched.eventName && (
                          <div className="text-red">{errors.eventName}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <div className="input-container">
                          <DatePicker
                            containerClassName="form-control pl-5"
                            value={values.eventDate}
                            onChange={(val) => {
                              setFieldValue("eventDate", val.toDate());
                            }}
                            placeholder="Event Date"
                            inputClass="dateInputClass"
                            minDate={new Date()}
                            name="eventDate"
                          />
                          <img
                            src="assets/images/Date-of-Birth.png"
                            className="input-img"
                            alt=""
                          />
                          <span className="field-icon small text-muted">
                            Auto-generated
                          </span>
                        </div>
                        {errors.eventDate && touched.eventDate && (
                          <div className="text-red">{errors.eventDate}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <div className="input-container">
                          <DatePicker
                            containerClassName="form-control pl-5"
                            disableDayPicker
                            format="hh:mm A"
                            plugins={[<TimePicker hideSeconds />]}
                            inputClass="dateInputClass"
                            name="eventTime"
                            placeholder="Event Time"
                          />
                          <img
                            src="assets/images/time.png"
                            className="input-img"
                            alt=""
                          />
                          <span className="field-icon small text-muted">
                            Auto-generated
                          </span>
                        </div>
                        {errors.eventTime && touched.eventTime && (
                          <div className="text-red">{errors.eventTime}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <div className="input-container">
                          <GooglePlace
                            name="address"
                            value={values?.address}
                            latLong={{ lat: values.lat, lng: values.lng }}
                            onBlur={handleBlur}
                            onChange={(val, latLong) => {
                              if (val) {
                                setFormikState((prevState) => {
                                  return {
                                    ...prevState,
                                    values: {
                                      ...prevState.values,
                                      address: val,
                                      lat: latLong.lat,
                                      lng: latLong.lng,
                                    },
                                  };
                                });
                              } else {
                                setFormikState((prevState) => {
                                  return {
                                    ...prevState,
                                    values: {
                                      ...prevState.values,
                                      address: "",
                                      lat: "",
                                      lng: "",
                                    },
                                  };
                                });
                              }
                            }}
                          />
                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                          <span className="field-icon small text-muted">
                            Auto-generated
                          </span>
                        </div>
                        {errors.address && touched.address && (
                          <div className="text-red">{errors.address}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <div className="input-container">
                          <input
                            className="form-control pl-5"
                            placeholder="Event Price"
                            name="price"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.price}
                          />
                          <img
                            src="assets/images/price.png"
                            className="input-img"
                            alt=""
                          />
                          <span className="field-icon small text-muted">
                            Auto-generated
                          </span>
                        </div>
                        {errors.price && touched.price && (
                          <div className="text-red">{errors.price}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 text-center">
                      <button type="submit" className="btn btn-save">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddRegister;
