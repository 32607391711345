import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useSelector } from "react-redux";
import { getEventDetails, getEventList } from "../../api/event";
import { sizeList } from "../../api/size";
const initialState = {
  list: [],
  total: 0,
  status: "idle",
};

export const fetchSizeList = createAsyncThunk(
  "SizeList",
  async (obj, { dispatch }) => {
    return sizeList(obj).then((response) => {
      if (response.code === 1) {
        dispatch(list(response.data.size_list));
        dispatch(count(response.data.total_size));
      }
      return response;
    });
  }
);

export const getSizeListSelector = (state) => state.size.list;
export const getTotalCount = (state) => state.size.total;

export const SizeSlice = createSlice({
  name: "size",
  initialState,
  reducers: {
    loading: (state, action) => {
      state.status = "loading";
    },
    idle: (state, action) => {
      state.status = "idle";
    },
    list: (state, action) => {
      state.list = action.payload;
    },
    count: (state, action) => {
      state.total = action.payload;
    },
  },
});

export const { list, details, count, loading, idle } = SizeSlice.actions;

export default SizeSlice.reducer;
