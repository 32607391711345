import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import OtpInput from "react-otp-input";
import { Formik } from "formik";
import * as Yup from "yup";
import { resendOtp, verifyOtp } from "../../api/setting";
import { toast } from "react-toastify";
import Loader from "../../component/Loader";

export const padTime = (time) => {
  return String(time).length === 1 ? `0${time}` : `${time}`;
};
export const format = (time) => {
  // Convert seconds into minutes and take the whole part
  const minutes = Math.floor(time / 60);

  // Get the seconds left after converting minutes
  const seconds = time % 60;

  //Return combined values as string in format mm:ss
  return `${minutes}:${padTime(seconds)}`;
};

function OtpVerification() {
  const navigate = useNavigate();
  const { email } = useParams();
  const [counter, setCounter] = React.useState(10);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter((c) => c - 1), 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);

  const handleResend = () => {
    setLoading(true);
    resendOtp({ email: email, user_type: "admin" }).then(async (res) => {
      setLoading(false);
      setCounter(10);
      if (res?.code === 1) {
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    });
  };
  return (
    <section className="bg-image">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-5 col-lg-6">
            <div className="mb-5">
              <img
                src="assets/images/headerlogo.png"
                className="img-fluid "
                alt="logo"
              />
            </div>

            <div className="logo-bg">
              <div className="mt-2">
                <img
                  src="assets/images/activity.png"
                  className="img-fluid lft-img"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-7 col-lg-6 mr-0 pr-0">
            <div className="form-bg">
              <Formik
                initialValues={{
                  otpValues: "",
                }}
                validationSchema={Yup.object({
                  otpValues: Yup.string()
                    .required("Required")
                    .matches(/^[0-9]+$/, "Must be only digits")
                    .min(6, "Kindly enter valid otp.")
                    .max(6, "Kindly enter valid otp."),
                })}
                onSubmit={(values, { isSubmitting, resetForm }) => {
                  verifyOtp({ code: values.otpValues, email: email }).then(
                    (res) => {
                      if (res?.code === 1) {
                        (async () => {
                          await localStorage.setItem("token", res?.data?.token);
                        })();
                        navigate("/reset-password", { replace: true });

                        toast.success(res?.message);
                      } else {
                        toast.error(res?.message);
                      }
                    }
                  );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form className="row form-style" onSubmit={handleSubmit}>
                    <div className="col-sm-12 text-center">
                      <img
                        src="assets/images/otp.png"
                        className="img-fluid my-3"
                        alt=""
                      />
                      <h2 className="mdl-ttl">OTP Verification</h2>
                      <p className="mdl-sbttl">
                        Please enter 6 digit code, which has been sent on your
                        registered email address
                      </p>
                    </div>
                    <div className="form-group col-sm-12">
                      <div className="passcode-wrapper">
                        <OtpInput
                          value={values.otpValues}
                          onChange={(val) => {
                            if (val) {
                              setFieldValue("otpValues", val);
                            }
                          }}
                          placeholder="000000"
                          inputStyle="otpInputs"
                          numInputs={6}
                          separator={<span> </span>}
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors.otpValues && touched.otpValues && (
                        <div className="text-red">{errors.otpValues}</div>
                      )}
                    </div>

                    <div className="col-sm-12 mt-4 text-center">
                      <button type="submit" className="btn btn-save" disabled={loading}>
                        {loading ? <Loader /> : "Verify"}
                      </button>
                    </div>
                    <div className="form-group mt-4 col-sm-12 col-md-12 text-center mb-4">
                      <div>
                        {counter === 0 ? (
                          <span
                            className="forgot-pass theme-color cursor-pointer p-0 fw-5"
                            onClick={() => {
                              handleResend();
                            }}
                          >
                            Resend Code
                          </span>
                        ) : (
                          <div>Resend code in {format(counter)}</div>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OtpVerification;
