import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { FetchNewsletterDetail, detailing } from "./NewsLetterSlice";
import moment from "moment";
import BackButton from "../../component/BackButton";
import Loader from "../../component/Loader";

function NewsletterDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.newsletter.detail);
  console.log(data, "Detail");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(FetchNewsletterDetail(id)).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(detailing(null));
    };
  }, [dispatch]);

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <BackButton />
        <h2 class=" text-center">Newsletter Details</h2>
        {loading ? (
          <Loader />
        ) : (
          <div class="notification-details-box ">
            <h4 class="theme-color text-left f-w-700">{data?.email_subject}</h4>
            <span class="notification-date mb-2">
              Date: {moment(data?.createdAt).format("DD MMM YYYY")}
            </span>
            <div
              className="mt-2 shadow bg-white p-3 editorImage"
              dangerouslySetInnerHTML={{ __html: data?.email_content }}
            ></div>

            <hr />
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                <h4 class="theme-color text-left f-w-700">Sent to</h4>
                {/* <p>All Users</p> */}
                {data?.user_list?.map((item, i) => {
                  return (
                    <div key={i}>
                      <ul className="sentUserList">
                        <li>
                          <p
                            onClick={() => {
                              navigate(`/user/${item?.user_details?.id}`);
                            }}
                          >
                            {item?.user_details?.first_name}{" "}
                            {item?.user_details?.last_name}
                          </p>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
              {/* <div class="col-sm-6 col-md-4 col-lg-3 mb-2">
              <h4 class="theme-color text-left f-w-700">Karate Venue</h4>
              <p>Liverpool</p>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3 mb-2">
              <h4 class="theme-color text-left f-w-700">Kick-Boxing Venue</h4>
              <p>Liverpool</p>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3 mb-2">
              <h4 class="theme-color text-left f-w-700">Age Group</h4>
              <p>Child</p>
            </div> */}
            </div>
          </div>
        )}
      </div>
    </article>
  );
}

export default NewsletterDetail;
