import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getUserDetails, userList } from "../../api/user";

const initialState = {
  list: [],
  total: 0,
};

export const UserSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    lists: (state, action) => {
      state.list = action.payload;
    },
    totalCount: (state, action) => {
      state.total = action.payload;
    },
    details: (state, action) => {
      state.detail = action.payload;
    },
  },
});

export const { lists, totalCount, details } = UserSlice.actions;

export const userListSelector = (state) => state.user.list;
export const totalUserCountSelector = (state) => state.user.total;

export const FetchUserList = createAsyncThunk(
  "FetchUserList",
  async (obj, { dispatch }) => {
    try {
      const response = await userList(obj);
      if (response?.code === 1) {
        dispatch(lists(response?.data?.user_list));
        dispatch(totalCount(response?.data?.total_user));
      }

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const userDetailSelector = (state) => state.user.detail;

export const FetchUserDetail = createAsyncThunk(
  "FetchUserDetail",
  async (obj, { dispatch }) => {
    try {
      const response = await getUserDetails(obj);
      if (response?.code === 1) {
        dispatch(details(response?.data?.user_details));
      }

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export default UserSlice.reducer;
