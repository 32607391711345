import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { SchoolListReducer, TeacherDetailReducer } from "../slice";
import moment from "moment";
import Loader from "../../component/Loader";
import BackButton from "./../../component/BackButton";

function ViewTeacher() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const details = useSelector((state) => state.main.teacherDetail);
  const Schoollist = useSelector((state) => state.main.schools);
  const [loading, setLoading] = useState(false);

  const getDetails = useCallback(() => {
    setLoading(true);
    dispatch(
      TeacherDetailReducer({
        id: id,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, id]);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getDetails();
      dispatch(SchoolListReducer({}));
    }

    return () => {
      isRendered = false;
    };
  }, [getDetails, dispatch]);
  if (loading) {
    return (
      <article className="col-lg-9 articleClass">
        <Loader />
      </article>
    );
  } else {
    return (
      <article className="col-lg-9 articleClass">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <BackButton />
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3>Teacher Details</h3>
            </div>
          </div>
          <div className="row mt-5 px-md-5">
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">full name</p>
                <h6 className="">
                  <img
                    src="assets/images/First-Name.png"
                    className="detail-icon"
                    alt=""
                  />
                  {details?.full_name || "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">email ID</p>
                <h6 className="text-break text-transform-lowercase">
                  <img
                    src="assets/images/Email-Address.png"
                    className="detail-icon"
                    alt=""
                  />
                  {details?.email || "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">mobile Number</p>
                <h6 className="">
                  <img
                    src="assets/images/mobile.png"
                    className="detail-icon"
                    alt=""
                  />
                  {details?.mobile
                    ? `${details?.countryCode} ${details?.mobile}`
                    : "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Date of Birth</p>
                <h6 className="text-break">
                  <img
                    src="assets/images/Date-of-Birth.png"
                    className="detail-icon"
                    alt=""
                  />
                  {details?.address
                    ? moment(
                        `${details?.address?.dob_year}-${details?.address?.dob_month}-${details?.address?.dob_day}`
                      ).format("DD MMM YYYY")
                    : "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Address</p>
                <h6 className="">
                  <img
                    src="assets/images/address.png"
                    className="detail-icon addressIcon"
                    alt=""
                  />
                  {details?.address
                    ? `${details?.address?.property_number}, ${
                        details?.address?.property_name
                          ? details?.address?.property_name + ", "
                          : ""
                      } ${details?.address?.street_number}, ${
                        details?.address?.town
                      }, ${details?.address?.country} - ${
                        details?.address?.postal_code
                      }`
                    : "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-12 mt-5 mb-2">
              <h5>Qualification Info</h5>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Highest Qualification</p>
                <h6 className="">
                  <img
                    src="assets/images/edu-black.png"
                    className="detail-icon"
                    alt=""
                  />
                  {details?.qualification_list
                    ?.map((it) => it?.qualification)
                    ?.join(", ") || "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Expertise in Subject</p>
                <h6 className="">
                  <img
                    src="assets/images/expertise.png"
                    className="detail-icon"
                    alt=""
                  />
                  {details?.subject_list
                    ?.map((it) => it?.subject)
                    ?.join(", ") || "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">award name</p>
                <h6 className="text-break">
                  <img
                    src="assets/images/award.png"
                    className="detail-icon"
                    alt=""
                  />
                  {details?.award_list?.map((it) => it?.award)?.join(", ") ||
                    "NA"}
                </h6>
              </div>
            </div>

            {details?.assigned_class_list?.length > 0 && (
              <>
                <div className="col-sm-12 col-md-12 mt-5 mb-2">
                  <div className="form-group">
                    <h5>Assigned Classes</h5>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="row">
                    {details?.assigned_class_list?.map((item, i) => (
                      <div
                        className="form-group col-12 col-sm-6 col-md-6 col-lg-4 mb-4"
                        key={i}
                      >
                        <div className="teacherCard documents bg-white shadow p-4">
                          <span className="row">
                            <div className="col-sm-12 col-md-12 col-lg-3 text-center">
                              <img
                                src={
                                  item?.class_details?.image?.includes("http")
                                    ? item?.class_details?.image
                                    : "assets/images/headerlogo.png"
                                }
                                alt=""
                                className="rounded-0"
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  paddingTop: "5px",
                                  objectFit: "contain",
                                }}
                              />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-9 teacherSchoolCard">
                              <p className="m-1">
                                <label className="fw-bold">Class: </label>{" "}
                                <span>{item?.class_details?.name}</span>
                              </p>
                              <p className="m-1">
                                <label className="fw-bold">School:</label>{" "}
                                <span>
                                  {item?.class_details?.school_details?.name}
                                </span>
                              </p>
                            </div>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {details?.document_list?.length > 0 && (
              <div className="col-sm-12 col-md-12 mt-5 mb-2">
                <div className="form-group">
                  <h5>Documents</h5>
                </div>
              </div>
            )}
            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
              <div className="row">
                {details?.document_list?.map((item, i) => (
                  <div
                    className="form-group col-6 col-sm-4 col-md-3 col-lg-2 mb-4"
                    key={i}
                  >
                    <div className="upload-btn-wrapper documents">
                      <button className="btn">
                        <img
                          src={
                            item?.document?.includes("http")
                              ? item?.document
                              : "assets/images/employe.png"
                          }
                          alt=""
                          className="rounded-0"
                        />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default ViewTeacher;
