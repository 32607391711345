import {
  deleteData,
  getData,
  getDataforUrl,
  patchFormData,
  postFormData,
} from "./index";

export const getEventList = async (payload) => {
  return getData("admin/eventList", payload).then((data) => {
    return data;
  });
};
export const getEventDetails = async (payload) => {
  return getDataforUrl("admin/getEventDetails", payload).then((data) => {
    return data;
  });
};
export const addEvent = async (payload) => {
  return postFormData("admin/addEvent", payload).then((data) => {
    return data;
  });
};
export const deleteEvent = async (payload) => {
  return deleteData("admin/removeEvent", payload).then((data) => {
    return data;
  });
};
export const cancelEvent = async (payload) => {
  return patchFormData("class/cancelEvent", payload).then((data) => {
    return data;
  });
};
