import { Formik } from "formik";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { resetPassword } from "../../api/setting";
import Loader from "../../component/Loader";

function ResetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  return (
    <section className="bg-image">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-5 col-lg-6">
            <div className="mb-5">
              <img
                src="assets/images/headerlogo.png"
                className="img-fluid"
                alt="logo"
              />
            </div>

            <div className="logo-bg">
              <div className="mt-2">
                <img
                  src="assets/images/activity.png"
                  className="img-fluid lft-img"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-7 col-lg-6 mr-0 pr-0">
            <div className="form-bg">
              <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                validationSchema={Yup.object({
                  password: Yup.string().required("Required"),
                  confirmPassword: Yup.string()
                    .oneOf(
                      [Yup.ref("password"), null],
                      "Password doesn't match."
                    )
                    .required("Required"),
                })}
                onSubmit={(values, { isSubmitting, resetForm }) => {
                  resetPassword({ password: values.password }).then((res) => {
                    if (res?.code === 1) {
                      console.log(res);
                      navigate("/", { replace: true });

                      toast.success(res?.message);
                    } else {
                      toast.error(res?.message);
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form className="form-style" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 text-center mb-4">
                        <img
                          src="assets/images/change-password.png"
                          className="img-fluid my-3"
                          alt=""
                        />
                        <h2 className="mdl-ttl">Reset Password</h2>
                        <p className="mdl-sbttl">Please reset your password</p>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                        <div className="form-group">
                          <div className="input-container">
                            <input
                              type="text"
                              className="form-control pl-5"
                              placeholder="New Password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                            <img
                              src="assets/images/password.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                          {errors.password && touched.password && (
                            <div className="text-red">{errors.password}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                        <div className="form-group">
                          <div className="input-container">
                            <input
                              type="text"
                              className="form-control pl-5"
                              placeholder="Confirm New Password"
                              name="confirmPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                            />
                            <img
                              src="assets/images/password.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                          {errors.confirmPassword &&
                            touched.confirmPassword && (
                              <div className="text-red">
                                {errors.confirmPassword}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 text-center">
                        <button type="submit" className="btn btn-save" disabled={loading}>
                         {loading ? <Loader /> : "Done"}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
