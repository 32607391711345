import { deleteData, getData, getDataforUrl, postFormData } from "./index";

export const areaCodeList = async (payload) => {
  return getData("admin/areaCodeList", payload).then((data) => {
    return data;
  });
};
export const getAreaCodeDetails = async (payload) => {
  return getDataforUrl("admin/getAreaCodeDetails", payload).then((data) => {
    return data;
  });
};

export const addAreaCode = async (payload) => {
  return postFormData("admin/addAreaCode", payload).then((data) => {
    return data;
  });
};

export const removeAreaCode = async (payload) => {
  return deleteData("admin/removeAreaCode", payload).then((data) => {
    return data;
  });
};
